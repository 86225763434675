import React from "react";
// import { Tabs } from "antd";
import { PageHeader } from "components";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks";
// import RequestList from "./Requests/List";
import OrderList from "./Orders/List";

const Tracker = () => {
  const { roles: authRoles, hasRole } = useAuth();

  if (
    authRoles &&
    !hasRole(authRoles?.request_tracking)
  ) {
    return <Navigate to="/acesso-negado" />;
  }

  return (
    <>
      <PageHeader title="Acompanhamentos" breadcrumbItems={[""]} />
      {/* <Tabs
        type='card'
        defaultActiveKey='1'
        items={[
          {
            label: 'Requisições',
            key: '1',
            children: <RequestList />
          },
          {
            label: 'Compra direta',
            key: '2',
            children: <OrderList />
          }]}
      /> */}
      <OrderList />
    </>
  );
};

export default Tracker;
