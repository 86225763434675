import useRest from 'hooks/useRest'
import { useState } from 'react'
import {
  TUserFunction,
  TUserArea,
  TProductCategory,
  TAddressBrasilApi,
  TBank
} from '.'

const params = {
  sort: 'name,ASC',
  size: 100
}

const useCommon = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findUserFunctionByFilter = async (triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/user-function'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return [] as TUserFunction[]

    return response.result.content as TUserFunction[]
  }

  const findUserAreaByFilter = async (triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/user-area'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return [] as TUserArea[]

    return response.result.content as TUserArea[]
  }

  const findProductCategoryByFilter = async (
    description: string,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/category'
    const _params = {
      ...params,
      sort: 'description,ASC'
    }
    const response = await rest.get(url, _params)
    if (triggerLoading) setLoading(false)
    if (!response) return [] as TProductCategory[]

    return response.result.content as TProductCategory[]
  }

  const findBanksByFilter = async (
    name: string,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/supplier/bank-account/bank'
    const _params = {
      name,
      sort: 'name,ASC'
    }
    const response = await rest.get(url, _params)
    if (triggerLoading) setLoading(false)
    if (!response) return [] as TBank[]

    return response.result.content as TBank[]
  }

  const getAddressByBrasilApi = async (
    cep: string,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const clearCep = cep.replace('-', '')
    const url = `https://brasilapi.com.br/api/cep/v1/${clearCep}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TAddressBrasilApi

    return response as TAddressBrasilApi
  }

  return {
    loading,
    findUserFunctionByFilter,
    findUserAreaByFilter,
    findProductCategoryByFilter,
    findBanksByFilter,
    getAddressByBrasilApi
  }
}

export default useCommon
