import React from 'react'
import { Form, Input } from 'antd'
import { Rule } from 'antd/lib/form'
import type { SizeType } from 'antd/lib/config-provider/SizeContext'

import { TextFieldStyled } from "./styles";

type TProps = {
  name: string
  label?: string
  extra?: string
  rules?: Rule[]
  disabled?: boolean
  required?: boolean
  textHelp?: string
  maxLength?: number
  placeholder?: string
  type?: 'password' | 'input'
  size?: SizeType
}

const TextField = (props: any) => {
  const {
    name,
    label,
    extra,
    rules,
    disabled = false,
    required = false,
    maxLength = 255,
    textHelp,
    placeholder = '',
    type = 'input',
    size = 'middle',
    onChange,
    value,
    ...rest
  } = props

  return (
    <Form.Item
      required={required}
      id={name}
      name={name}
      label={label}
      extra={extra}
      rules={rules}
      help={textHelp}
      {...rest}
    >
      {type === 'input' ? (
        <TextFieldStyled
          size={size}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          onChange={onChange}
          value={value}
        />
      ) : (
        <Input.Password
          size={size}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
        />
      )}
    </Form.Item>
  )
}

export default TextField
