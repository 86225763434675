import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  message,
  Space
} from 'antd'
import {
  useNavigate,
  useParams
} from 'react-router'
import {
  TSupplierCreateUserRequest,
  useSuppliers,
  useUsers
} from 'repositories'
import {
  SupplierStatus,
  UserStatus,
  YesNo
} from 'utils/constants'
import {
  CheckField,
  Col,
  Row,
  TextField
} from 'components'
import type { Rule } from 'antd/lib/form'
import Attachments from 'pages/Suppliers/Components/Attachments'
import { Content } from '../styles'

type TSupplierData = {
  id?: TStringableNumber
  cnpj: string
  corporateName: string
  fantasyName: string
  principal: string
  email: string
  financialPrincipal: string
  financialPrincipalEmail: string
  telephone: string
  typeSupplier: string
  status: string
  contributorType?: string
  municipalInscription?: string
  stateInscription?: string
  simpleNational?: string
  taxpayer: string
  termsAcceptance: boolean
}

type TAttachmentStepForm = {
  password: string
  confirmPassword: string
  termsAcceptance: boolean
}

const validations: Record<string, Rule[]> = {
  password: [
    { required: true, message: 'Campo "Senha" é obrigatório' },
    { min: 6, message: 'Senha deve possuir no mínimo 6 caracteres' }
  ],
  confirmPassword: [
    { required: true, message: 'Confirmação da senha é obrigatório' },
    ({ getFieldValue }) => ({
      validator(_, confirmPassword: string) {
        if (getFieldValue('password') !== confirmPassword) {
          return Promise.reject(new Error('Senhas não conferem'))
        }

        return Promise.resolve()
      },
    })
  ]
}

type TProps = {
  onChangeStep(value: number): void
}

const Attachment = ({ onChangeStep }: TProps) => {
  const [supplierData, setSupplierData] = useState<TSupplierData>({} as TSupplierData)

  const { id } = useParams<{ id: string }>()
  const repository = useSuppliers()
  const [form] = Form.useForm<TAttachmentStepForm>()
  const watchedTerm = Form.useWatch('termsAcceptance', form)
  const userRepository = useUsers()
  const navigate = useNavigate()

  const onFinish = async (values: TAttachmentStepForm) => {
    const user: TSupplierCreateUserRequest = {
      name: supplierData.principal,
      email: supplierData.email,
      password: values.password,
      status: UserStatus.DEACTIVATED,
      supplierId: Number(id)
    }
    const userResponse = await userRepository.createUserToSupplier(user)
    if (!userResponse) return

    const response = await repository.updateSupplier({
      ...supplierData,
      status: SupplierStatus.HOMOLOGATED,
      simpleNational: supplierData.simpleNational === YesNo.YES,
      termsAcceptance: true
    })
    if (!response) return

    navigate('/login')
    message.success('Cadastro atualizado com sucesso!')
  }

  useEffect(() => {
    const getSupplier = async () => {
      const response = await repository.findSupplierById(Number(id))
      if (!response) return
      const data = {
        ...response,
        simpleNational: response.simpleNational ? YesNo.YES : YesNo.NO,
        termsAcceptance: false
      }

      form.setFieldsValue(data)
      setSupplierData(data)
    }

    getSupplier()
  }, [id])

  const onClickGoBack = () => onChangeStep(2)

  return (
    <Content size='small'>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row>
          <Col>
            <Divider plain orientation='left'>Anexos</Divider>
          </Col>
        </Row>
        <Row>
          <Col>
            <Divider plain orientation='left'>Senha de acesso ao sistema</Divider>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField
              required
              type='password'
              name='password'
              label='Senha'
              rules={validations.password}
            />
          </Col>
          <Col xl={12}>
            <TextField
              required
              type='password'
              name='confirmPassword'
              label='Confirmar senha'
              rules={validations.confirmPassword}
            />
          </Col>
        </Row>
        <Attachments
          supplierId={Number(id)}
          isFullWidth
        />
        <Row>
          <Col>
            <Divider plain orientation='left'>Termo de aceite</Divider>
          </Col>
        </Row>
        <Row>
          <Col>
            <CheckField
              name='termsAcceptance'
              label='Aceito os termos e condições'
            />
          </Col>
        </Row>
        <Form.Item>
          <Space size='small'>
            <Button
              onClick={onClickGoBack}
              htmlType='button'
            >
              Voltar
            </Button>
            <Button
              loading={repository.loading}
              disabled={!watchedTerm || repository.loading}
              type='primary'
              htmlType='submit'
            >
              Finalizar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  )
}

export default Attachment
