import React from 'react'
import { Input, Form } from 'antd'
import type { Rule } from 'antd/lib/form'

import { TextArea } from "./styles";

// const { TextArea } = Input



type TProps = {
  name: string
  label?: string
  rules?: Rule[]
  rows?: number
  disabled?: boolean
  required?: boolean
  bordered?: boolean
  maxLength?: number,
  onChange?(value: any): void
}

const AreaField = (props: TProps) => {
  const {
    name,
    label,
    rules,
    rows = 3,
    disabled = false,
    required = false,
    bordered = true,
    maxLength = 255,
    onChange,
    ...rest
  } = props

  return (
    <Form.Item
      label={label}
      name={name}
      required={required}
      id={name}
      rules={rules}
      {...rest}
    >
      <TextArea
        disabled={disabled}
        maxLength={maxLength}
        bordered={bordered}
        rows={rows}
        onChange={onChange}
      />
    </Form.Item>
  )
}

export default AreaField
