import React, { useEffect } from 'react'
import {
  Form,
  Drawer,
  Button,
  Space
} from 'antd'
import {
  AreaField,
  Col,
  CurrencyField,
  NumberField,
  Row,
  SelectField
} from 'components'
import { purchaseOrderStatusOptions } from 'utils/constants'
import { formatCurrencyToServer } from 'utils/helpers'

type TFilterPurchaseOrderParamsForm = {
  status: string
  description: string
  purchaseValue: string
  payDay: string
}

const initialValues: TFilterPurchaseOrderParamsForm = {
  status: '',
  description: '',
  purchaseValue: '',
  payDay: ''
}

type TProps = {
  isVisibleDrawer: boolean
  setIsVisibleDrawer: React.Dispatch<boolean>,
  filters: TFiltersHook
}

const FiltersForm = (props: TProps) => {
  const {
    isVisibleDrawer,
    setIsVisibleDrawer,
    filters,
  } = props
  const [form] = Form.useForm<TFilterPurchaseOrderParamsForm>()

  const handleClose = () => {
    setIsVisibleDrawer(false)
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
    handleClose()
  }

  useEffect(() => {
    if (!filters.items?.length) form.resetFields()
  }, [filters.items])

  const onFinish = (values: TFilterPurchaseOrderParamsForm) => {
    const _values = Object.entries(values)

    const filterNonNullValues = _values.filter(value => {
      const [, term] = value
      return Boolean(term)
    })
    const _filters = [] as TFilter[]
    filterNonNullValues.forEach(value => {
      const [field, term] = value
      if (field === 'purchaseValue') {
        _filters.push({
          field,
          term: formatCurrencyToServer(term)
        })
        return
      }

      _filters.push({ field, term: String(term) })
    })

    filters.clearAndAdd(_filters)
  }

  const onClickResetFilters = () => filters.clearAndAdd([])

  return (
    <Drawer
      title='Filtros'
      width={500}
      onClose={handleClose}
      visible={isVisibleDrawer}
      destroyOnClose
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col xl={12}>
            <NumberField
              name='payDay'
              label='Dia de pagamento'
              max={31}
            />
          </Col>
          <Col xl={12}>
            <CurrencyField
              name='purchaseValue'
              label='Valor do pedido'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <AreaField
              name='description'
              label='Descrição'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <SelectField
              name='status'
              label='Status'
              options={purchaseOrderStatusOptions}
            />
          </Col>
        </Row>
        <Form.Item className='mt-1'>
          <Space size='small'>
            <Button
              onClick={handleSubmit}
              type='primary'
            >
              Filtrar
            </Button>
            <Button
              onClick={onClickResetFilters}
            >
              Limpar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FiltersForm
