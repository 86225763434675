import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Divider,
  message,
  Modal,
  Space,
  Table,
  TableProps,
  Typography,
  Upload,
  UploadProps
} from 'antd'
import {
  Col,
  Row
} from 'components'
import {
  AiOutlineCloudUpload,
  AiOutlineExclamationCircle
} from 'react-icons/ai'
import type { RcFile } from 'antd/lib/upload'
import {
  TSupplierAttachment,
  useSupplierAttachment
} from 'repositories'
import { useAuth, usePagination } from 'hooks'
import { tableLocale } from 'utils/lang'
import { ColumnsType } from 'antd/lib/table'
import { downloadBase64File, validFile } from 'utils/helpers'

const { Dragger } = Upload
const { Title } = Typography
const { confirm } = Modal

type TAttachmentData = TSupplierAttachment & {
  key: string
}

const columns: ColumnsType<TAttachmentData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Tipo',
    dataIndex: 'typeDocument',
    key: 'typeDocument'
  }
]

type TProps = {
  supplierId: number
  isView?: boolean
  isFullWidth?: boolean
}

const Attachments = (props: TProps) => {
  const {
    supplierId,
    isView = false,
    isFullWidth = false
  } = props

  const [attachments, setAttachments] = useState<TAttachmentData[]>([])

  const { roles: authRoles, hasRole } = useAuth()
  const repository = useSupplierAttachment()
  const pagination = usePagination()

  const getAttachments = async () => {
    const currentPage = pagination.data.current ?? 0
    const response = await repository.findAttachmentsByFilter(supplierId, {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
    })
    if (!response) return

    const mappedAttachments = response?.map(content => ({
      ...content,
      key: String(content.id)
    }))
    setAttachments(mappedAttachments)
  }

  const handleChange: TableProps<TAttachmentData>['onChange'] = paginationConfig => {
    pagination.onChangeTable(paginationConfig)
  }

  useEffect(() => {
    if (supplierId && pagination.data.current) getAttachments()
  }, [pagination.data.current, pagination.data.pageSize, supplierId])

  const handleUpload = async (file: RcFile) => {
    const formData = new FormData()

    formData.append('supplierId', String(supplierId))
    formData.append('file', file)

    const response = await repository.uploadAttachment(formData)
    if (!response) return ''

    setAttachments([
      ...attachments,
      {
        ...response,
        key: String(response.id)
      }
    ])
    message.success('Upload realizado com sucesso.')
    return ''
  }

  const uploadProps: UploadProps = {
    customRequest: () => false,
    showUploadList: false,
    disabled: isView,
    name: 'file',
    action: handleUpload,
    beforeUpload: file => validFile(file),
    accept: '.png,.jpeg,.jpg,.bmap,application/pdf,application/msword,application/vnd.ms-excel,.docx,application/vnd.ms-powerpoint,application/ods,application/xps,text/plain,.ods,.odt,.xlsx',
  }

  const onDownloadFile = (filename: string) => async () => {
    const response = await repository.downloadAttachment(supplierId, filename)
    if (!response) return

    downloadBase64File(filename, response)
  }

  const showDeleteConfirm = (
    attachmentId: number,
    _attachments: TAttachmentData[]
  ) => () => {
    confirm({
      title: 'Confirmação',
      content: 'Deseja excluir esse anexo?',
      icon: (
        <AiOutlineExclamationCircle
          size={18}
          color='orange'
        />
      ),
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      async onOk() {
        const filteredContacts = _attachments.filter(filteredAttachments => filteredAttachments.id !== attachmentId)
        await repository.deleteAttachment(attachmentId)
        message.success('Anexo excluído com sucesso')
        setAttachments(filteredContacts)
      }
    })
  }

  const hasPermission = !hasRole(
    authRoles?.supplier_add ||
    authRoles?.supplier_edit ||
    authRoles?.supplier_view ||
    authRoles?.supplier_pre_registration
  )

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            disabled={isView || hasPermission}
            onClick={onDownloadFile(record.name)}
            size='small'
            type='text'
          >
            Download
          </Button>
          <Button
            disabled={isView || hasPermission}
            onClick={showDeleteConfirm(record.id, attachments)}
            size='small'
            type='text'
          >
            Excluir
          </Button>
        </Space>
      ),
    }
  ], [attachments, authRoles])

  const fullWidth = isFullWidth ? 24 : 16

  return (
    <>
      <Row>
        <Col xl={fullWidth}>
          <Divider plain orientation='left'>Anexos</Divider>
        </Col>
      </Row>
      <Row>
        <Col xl={fullWidth}>
          <Dragger {...uploadProps}>
            <Space direction='vertical' size='small'>
              <AiOutlineCloudUpload
                size={30}
              />
              <Title level={5}>Clique ou arraste para realizar o upload</Title>
            </Space>
          </Dragger>
        </Col>
      </Row>
      <Row>
        <Col xl={fullWidth}>
          <Table
            size='small'
            className='mt-1'
            columns={updatedColumns}
            locale={tableLocale}
            loading={repository.loading}
            dataSource={attachments}
            pagination={pagination.data}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </>
  )
}

export default Attachments
