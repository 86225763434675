import { TableLocale } from 'antd/lib/table/interface'
import { TransferLocale } from 'antd/lib/transfer'
import { RoleOperator } from './constants'

export const transferLocale: Partial<TransferLocale> = {
  selectAll: 'Selecionar todos',
  remove: 'Remover',
  removeAll: 'Remover todos',
  notFoundContent: 'Nenhum resultado encontrado',
  removeCurrent: 'Remover atual',
  selectInvert: 'inverter a página atual'
}

export const tableLocale: Partial<TableLocale> = {
  emptyText: 'Nenhum resultado encontrado'
}

export const translateTerms = (term: string) => {
  switch (term) {
    case RoleOperator.ROLE_VIEW: return 'Visualizar Permissões'
    case RoleOperator.ROLE_ADD: return 'Adicionar Permissão'
    case RoleOperator.ROLE_EDIT: return 'Editar Permissão'
    case RoleOperator.ROLE_DELETE: return 'Deletar Permissões'
    case RoleOperator.GROUP_VIEW: return 'Visualizar Grupos'
    case RoleOperator.GROUP_ADD: return 'Adicionar Grupo'
    case RoleOperator.GROUP_EDIT: return 'Editar Grupo'
    case RoleOperator.GROUP_DELETE: return 'Deletar Grupos'
    case RoleOperator.CLIENT_VIEW: return 'Visualizar Clientes'
    case RoleOperator.CLIENT_ADD: return 'Adicionar Cliente'
    case RoleOperator.CLIENT_EDIT: return 'Editar Cliente'
    case RoleOperator.CLIENT_DELETE: return 'Deletar Clientes'
    case RoleOperator.PROJECT_VIEW: return 'Visualizar Projetos'
    case RoleOperator.PROJECT_ADD: return 'Adicionar Projeto'
    case RoleOperator.PROJECT_EDIT: return 'Editar Projeto'
    case RoleOperator.PROJECT_DELETE: return 'Deletar Projetos'
    case RoleOperator.PRODUCT_VIEW: return 'Visualizar Produtos'
    case RoleOperator.PRODUCT_ADD: return 'Adicionar Produto'
    case RoleOperator.PRODUCT_EDIT: return 'Editar Produto'
    case RoleOperator.PRODUCT_DELETE: return 'Deletar Produtos'
    case RoleOperator.COMPANY_VIEW: return 'Visualizar Empresas'
    case RoleOperator.COMPANY_ADD: return 'Adicionar Empresa'
    case RoleOperator.COMPANY_EDIT: return 'Editar Empresa'
    case RoleOperator.COMPANY_DELETE: return 'Deletar Empresas'
    case RoleOperator.CATEGORY_VIEW: return 'Visualizar Categorias'
    case RoleOperator.CATEGORY_ADD: return 'Adicionar Categoria'
    case RoleOperator.CATEGORY_EDIT: return 'Editar Categoria'
    case RoleOperator.CATEGORY_DELETE: return 'Deletar Categorias'
    case RoleOperator.APPROVE_DEFAULT: return 'Aprovador Padrão'
    case RoleOperator.REQUEST_BUYER: return 'Comprador Padrão'
    case RoleOperator.PROFILE_EDIT: return 'Editar Perfil'
    case RoleOperator.PROFILE_VIEW: return 'Visualizar Perfil'
    case RoleOperator.SUPPLIER_VIEW: return 'Visualizar Fornecedores'
    case RoleOperator.SUPPLIER_ADD: return 'Adicionar Fornecedor'
    case RoleOperator.SUPPLIER_EDIT: return 'Editar Fornecedor'
    case RoleOperator.SUPPLIER_DELETE: return 'Deletar Fornecedores'
    case RoleOperator.SUPPLIER_EVALUATION: return 'Avaliar Fornecedores'
    case RoleOperator.SUPPLIER_SEND: return 'Enviar email a Fornecedores'
    case RoleOperator.SUPPLIER_APPROVE_FISCAL: return 'Aprovar Fornecedor (Dep. Fiscal)'
    case RoleOperator.SUPPLIER_APPROVE_FINANCIAL: return 'Aprovar Fornecedor (Dep. Financeiro)'
    case RoleOperator.SUPPLIER_ACCOUNT_VIEW: return 'Visualizar Contas do Fornecedor'
    case RoleOperator.SUPPLIER_ACCOUNT_ADD: return 'Adicionar Contas do Fornecedor'
    case RoleOperator.SUPPLIER_ACCOUNT_EDIT: return 'Editar Contas do Fornecedor'
    case RoleOperator.SUPPLIER_ACCOUNT_DELETE: return 'Deletar Contas do Fornecedor'
    case RoleOperator.SUPPLIER_ATTACHMENT_VIEW: return 'Visualizar Anexos do Fornecedor'
    case RoleOperator.SUPPLIER_ATTACHMENT_ADD: return 'Adicionar Anexos do Fornecedor'
    case RoleOperator.SUPPLIER_ATTACHMENT_EDIT: return 'Editar Anexos do Fornecedor'
    case RoleOperator.SUPPLIER_ATTACHMENT_DELETE: return 'Deletar Anexos do Fornecedor'
    case RoleOperator.SUPPLIER_CONTACT_VIEW: return 'Visualizar Contatos do Fornecedor'
    case RoleOperator.SUPPLIER_CONTACT_ADD: return 'Adicionar Contatos do Fornecedor'
    case RoleOperator.SUPPLIER_CONTACT_EDIT: return 'Editar Contatos do Fornecedor'
    case RoleOperator.SUPPLIER_CONTACT_DELETE: return 'Deletar Contatos do Fornecedor'
    case RoleOperator.SUPPLIER_ADDRESS_VIEW: return 'Visualizar Endereços do Fornecedor'
    case RoleOperator.SUPPLIER_ADDRESS_ADD: return 'Adicionar Endereços do Fornecedor'
    case RoleOperator.SUPPLIER_ADDRESS_EDIT: return 'Editar Endereços do Fornecedor'
    case RoleOperator.SUPPLIER_ADDRESS_DELETE: return 'Deletar Endereços do Fornecedor'
    case RoleOperator.BANK_VIEW: return 'Visualizar Instituições bancárias'
    case RoleOperator.APPROVALHIERARCHY_VIEW: return 'Visualizar Alçadas'
    case RoleOperator.APPROVALHIERARCHY_ADD: return 'Adicionar Alçada'
    case RoleOperator.APPROVALHIERARCHY_EDIT: return 'Editar Alçada'
    case RoleOperator.APPROVALHIERARCHY_DELETE: return 'Deletar Alçadas'
    case RoleOperator.USER_VIEW: return 'Visualizar Usuários'
    case RoleOperator.USER_ADD: return 'Adicionar Usuário'
    case RoleOperator.USER_EDIT: return 'Editar Usuário'
    case RoleOperator.USER_DELETE: return 'Deletar Usuários'
    case RoleOperator.PURCHASE_ORDER_VIEW: return 'Visualizar pedidos de compra'
    case RoleOperator.PURCHASE_ORDER_ADD: return 'Adicionar pedidos de compra'
    case RoleOperator.PURCHASE_ORDER_EDIT: return 'Editar pedidos de compra'
    case RoleOperator.PURCHASE_ORDER_DELETE: return 'Deletar pedidos de compra'
    case RoleOperator.COSTCENTER_VIEW: return 'Visualizar centro de custo'
    case RoleOperator.COSTCENTER_ADD: return 'Adicionar centro de custo'
    case RoleOperator.COSTCENTER_EDIT: return 'Editar centro de custo'
    case RoleOperator.COSTCENTER_DELETE: return 'Deletar centro de custo'
    case RoleOperator.SIMPLE_ROLE_REQUESTS: return 'Criar requisições simples com e sem projeto'
    case RoleOperator.CREATE_REQUEST_SIMPLE: return 'Adicionar Requisições simples'
    case RoleOperator.VIEW_REQUEST_SIMPLE: return 'Visualizar Requisições simples'
    case RoleOperator.REQUEST_TRACKING: return 'Acessar painel de acompanhamento de requisições'
    case RoleOperator.REQUEST_APPROVALS: return 'Aprovar requisições'
    default: return ''
  }
}
