import React, { useEffect, useMemo, useState } from 'react'
import { v4 as genId } from 'uuid'
import {
  Button,
  Divider,
  Form,
  message,
  Space,
  Modal
} from 'antd'
import {
  TApprovalHierarchy,
  TCreateProjectRequest,
  TGroupMemberChat,
  TItemProjectRequest,
  TMemberChat,
  TProjectRequestOutput,
  TRequestItem,
  TSupplierProjectRequest,
  TUpdateProjectRequestBuyer,
  TUpdateProjectRequestItemBuyer,
  useApprovalHierarchy,
  useProjectRequest,
  TCompany,
  TSubsidiary
} from 'repositories'
import useCompany from 'repositories/useCompany'
import useCostCenter from 'repositories/useCostCenter'
import {
  paymentMethodsOptions,
  paymentTypeOptions,
  purchaseTypeOptions,
  StatusRequest,
  statusRequestOptions,
  TypeRequest
} from 'utils/constants'
import {
  CheckField,
  Col,
  DateField,
  HiddenField,
  NumberField,
  Row,
  SearchField,
  SelectField
} from 'components'
import moment, { Moment } from 'moment'
import type { Rule } from 'antd/lib/form'
import { DefaultOptionType } from 'antd/lib/select'
import { useNavigate, useLocation, } from 'react-router'
import {
  convertMomentToDate,
  formatDateToServer,
  formatDateToView,
  formatStringToNumber
} from 'utils/helpers'
import { useAuth } from 'hooks'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import SupplierRequestList from './SupplierRequest'
import ItemList from './ItemList'
import ChatRequisition from './ChatRequisition'

const { confirm } = Modal

type TProjectRequestForm = {
  statusRequest: string
  issueDate: Moment
  paymentType: string
  purchaseType: string
  serviceStartDate: Moment | string
  serviceEndDate: Moment | string
  approvalHierarchyId: number
  approvalHierarchyName: string
  negotiationDeadline: number
  indicationSingleSupplierJustifiable: boolean
  isRequestNotTiedToProject: boolean
  requireThreeQuotes: boolean
  paymentMethod: string
  paymentTerm: number
  paymentDate: Moment | string
  subsidiaryId?: number
  companyId?: number
}

const initialValues: TProjectRequestForm = {
  statusRequest: StatusRequest.NEW,
  issueDate: moment(),
  paymentType: '',
  purchaseType: '',
  serviceStartDate: '',
  serviceEndDate: '',
  approvalHierarchyId: 0,
  approvalHierarchyName: '',
  negotiationDeadline: 0,
  indicationSingleSupplierJustifiable: false,
  isRequestNotTiedToProject: false,
  requireThreeQuotes: false,
  paymentMethod: '',
  paymentDate: '',
  paymentTerm: 0
}

const validations: Record<string, Rule[]> = {
  serviceStartDate: [
    { required: true, message: 'Campo "Data de início" é obrigatório' },
    { type: 'date', message: 'Insíra uma data válida' },
    ({ getFieldValue }) => ({
      validator(_, serviceStartDate: Moment) {
        const issueDate = getFieldValue('issueDate')
        if (serviceStartDate.isBefore(issueDate)) {
          return Promise.reject(new Error('Data de início não pode ser inferior que emissão'))
        }

        return Promise.resolve()
      },
    })
  ],
  companyId: [
    { required: true, message: 'Campo "Empresa de faturamento" é obrigatório' },
  ],
  subsidiaryId: [
    { required: true, message: 'Campo "Filial" é obrigatório' },
  ],
  serviceEndDate: [
    { required: true, message: 'Campo "Data da finalização" é obrigatório' },
    { type: 'date', message: 'Insíra uma data válida' },
    ({ getFieldValue }) => ({
      validator(_, serviceEndDate: Moment) {
        const serviceStartDate = getFieldValue('serviceStartDate')
        if (serviceEndDate.isBefore(serviceStartDate)) {
          return Promise.reject(new Error('Data de finalização não pode ser inferior que a de início'))
        }

        return Promise.resolve()
      },
    })
  ],
  purchaseType: [
    { required: true, message: 'Campo "Tipo de requisição" é obrigatório' },
  ],
  paymentType: [
    { required: true, message: 'Campo "Tipo de pagamento" é obrigatório' },
  ],
  approvalHierarchyName: [
    { required: true, message: 'Campo "Alçada" é obrigatório' },
  ],
  negotiationDeadline: [
    { required: true, message: 'Campo "Prazo de negociação" é obrigatório' },
    () => ({
      validator(_, negotiationDeadline: number) {
        if (negotiationDeadline === 0) {
          return Promise.reject(new Error('Prazo de negociação não pode ser zero'))
        }

        return Promise.resolve()
      },
    })
  ],
}

type TSupplierRequestData = TSupplierProjectRequest & {
  key: string
}

type TProps = {
  id?: number
  initialItems?: TRequestItem[]
  isView?: boolean
  isClone?: boolean
  actionGoBack(): void
  textActionGoBack?: string
  onDisableTabs?(disable: boolean): void
}

const RequestForm = (props: TProps) => {
  const {
    id,
    initialItems,
    isView = false,
    isClone = false,
    actionGoBack,
    textActionGoBack = 'Voltar',
    onDisableTabs
  } = props
  const [hierarchyValue, setHierarchyValue] = useState('')
  const [hierarchyOptions, setHierarchyOptions] = useState<TSearchOptions[]>([])
  const [hierarchies, setHierarchies] = useState<TApprovalHierarchy[]>([])
  const [companyOptions, setCompanyOptions] = useState<DefaultOptionType[]>([])
  const [costCenterOptions, setCostCenterOptions] = useState<DefaultOptionType[]>([])
  const [subsidiaryOptions, setSubsidiaryOptions] = useState<DefaultOptionType[]>([])
  const [companies, setCompanies] = useState<TCompany[]>([])
  const [suppliers, setSuppliers] = useState<TSupplierRequestData[]>([])
  const [items, setItems] = useState<TRequestItem[]>([])
  const [groupMembersChat, setGroupMembersChat] = useState<TGroupMemberChat[]>([])

  const { roles: authRoles, hasRole, userData } = useAuth()
  const projectRequestRepository = useProjectRequest()
  const costCenterRepository = useCostCenter()
  const companyRepository = useCompany()
  const hierarchyRepository = useApprovalHierarchy()
  const [form] = Form.useForm<TProjectRequestForm>()
  const watchedStatus = Form.useWatch('statusRequest', form)
  const watchedDeadline = Form.useWatch('negotiationDeadline', form)
  const watchedRequireThreeQuotes = Form.useWatch('requireThreeQuotes', form)
  const watchedCompanyId = Form.useWatch('companyId', form)
  const watchedisRequestNotTiedToProject = Form.useWatch('isRequestNotTiedToProject', form)
  const navigate = useNavigate()
  const { state } = useLocation()

  const onHierarchySearch = async (searchText: string) => {
    const response = await hierarchyRepository.findByFilterApprovalHierarchy({
      name: searchText,
      page: 0,
      size: 10
    })
    if (!response) return

    const mappedHierarchies = response.content.map(item => ({ value: item.name }))
    setHierarchyOptions(mappedHierarchies)
    setHierarchies(response.content)
  }

  const onHierarchyChange = (data: string) => {
    const findHierarchy = hierarchies.find(hierarchy => hierarchy.name === data)
    if (!findHierarchy) return

    setHierarchyValue(data)
    form.setFieldsValue({
      approvalHierarchyId: findHierarchy.id,
      approvalHierarchyName: findHierarchy.name
    })
  }

  const validateItems = (itemList: TRequestItem[]) => (
    itemList.every(item => item.costCenterId && item.unitCost)
  )

  const handleSubmit = async (isRedirect = true) => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    const response = await onFinish(validatedData)
    if (isRedirect && response) {
      navigate('/requisicao-compras')
      message.success(
        id
          ? 'Requisição atualizada com sucesso!'
          : 'Requisição criada com sucesso!'
      )
    }
  }

  const onFinish = async (values: TProjectRequestForm) => {
    const {
      issueDate,
      serviceStartDate,
      serviceEndDate
    } = values

    if (watchedRequireThreeQuotes && suppliers.length < 3) {
      message.error('Esta requisição requer pelo menos 3 cotações')
      return false
    }

    if (watchedisRequestNotTiedToProject && !validateItems(items)) {
      message.error('Os itens necessitam possuir centro de custo e custo unitário!')
      return false
    }

    if (form.getFieldValue('indicationSingleSupplierJustifiable') && suppliers.length > 1) {
      message.error('Esta requisição requer apenas uma cotação')
      return false
    }

    if (!items.length) {
      message.error('Não é possivel criar uma requisição sem items!')
      return false
    }

    const suppliersId = suppliers.map(supplier => Number(supplier.id))
    const formattedItems = items.map(item => ({
      ...item,
      unitCost: item.unitCost,
      unitSale: item.unitSale,
      eventDate: formatDateToServer(String(item.eventDate)),
      totalSalesWithoutTax: item.unitSale * item.amount
    }))
    const data: TCreateProjectRequest = {
      ...values,
      isRequestNotTiedToProject: values.isRequestNotTiedToProject,
      issueDate: convertMomentToDate(issueDate),
      serviceStartDate: convertMomentToDate(moment(serviceStartDate)),
      serviceEndDate: convertMomentToDate(moment(serviceEndDate)),
      suppliersId,
      items: formattedItems
    }

    const response = id && !isClone
      ? await projectRequestRepository.updateProjectRequest({ ...data, id })
      : await projectRequestRepository.createProjectRequest(data)
    if (!response) return false

    return true
  }

  useEffect(() => {
    const mappedInitialItems = initialItems?.map(initialItem => {
      const { unitCost, unitSale, amount } = initialItem
      return {
        ...initialItem,
        totalCost: unitCost * amount,
        totalSalesWithoutTax: unitSale * amount
      }
    })
    setItems(mappedInitialItems || [])
  }, [initialItems])

  useEffect(() => {
    if (watchedisRequestNotTiedToProject) form.setFieldValue('paymentType', 'TV1')
  }, [watchedisRequestNotTiedToProject])

  useEffect(() => {
    const getCostCenter = async () => {
      const response = await costCenterRepository.findByFilterCostCenter({
        status: true,
        size: 1000,
        page: 0
      })
      if (!response) return
      const mappedCostCenter = response?.content?.map(item => ({
        value: item.id,
        label: `[${item.erpId}] ${item.description}`
      }) as DefaultOptionType)
      setCostCenterOptions(mappedCostCenter)
    }

    const getMembers = async (projectRequest: TProjectRequestOutput) => {
      const response = await hierarchyRepository.findByIdApprovalHierarchy(projectRequest.approvalHierarchyId)
      if (!response) return

      const userFrom = {
        userFromId: userData.userId,
        userFromName: userData.userName,
      }

      const approvers = response.approvers.map(approver => ({
        key: genId(),
        ...userFrom,
        userToId: approver.userId,
        userToName: approver.userName
      }) as TMemberChat)

      const _suppliers = projectRequest.suppliers.map(_supplier => ({
        key: genId(),
        ...userFrom,
        userToId: _supplier.userId,
        userToName: _supplier.corporateName
      }) as TMemberChat)

      const requester: TMemberChat = {
        key: genId(),
        ...userFrom,
        userToId: projectRequest.creationUserId,
        userToName: projectRequest.creationUserName
      }

      const buyer: TMemberChat = {
        key: genId(),
        ...userFrom,
        userToId: projectRequest.buyerId,
        userToName: projectRequest.buyerName
      }

      if (authRoles?.request_buyer || projectRequest.buyerId === userData.userId) {
        setGroupMembersChat([
          {
            title: 'Requisitante',
            members: [requester]
          },
          {
            title: 'Alçada de aprovação',
            members: approvers
          },
          {
            title: 'Fornecedores',
            members: _suppliers
          }
        ])
        return
      }

      setGroupMembersChat([
        {
          title: 'Comprador',
          members: [buyer]
        },
        {
          title: 'Alçada de aprovação',
          members: approvers
        }
      ])
    }

    const calculateSaving = (
      projectRequest: TProjectRequestOutput,
      totalCost: number[],
      currentItem: TItemProjectRequest
    ) => {
      if (projectRequest.statusRequest !== StatusRequest.APPROVED) return 0

      if (projectRequest.typeRequest === TypeRequest.SIMPLE) {
        return currentItem.unitCost - currentItem.unitPurchasedCost
      }

      const [projectedCost, purchasedCost] = totalCost
      const calculatedSaving = (currentItem.unitCost / projectedCost) * purchasedCost
      return currentItem.unitCost - calculatedSaving
    }

    const getCompanies = async () => {
      const response = await companyRepository.findByFilterCompany()
      if (!response) return

      const mappedCompanies = response?.content?.map(item => ({
        value: item.id,
        label: item.name
      }) as DefaultOptionType)
      setCompanyOptions(mappedCompanies)
      setCompanies(response.content)
      return response.content
    }

    const getProjectRequest = async () => {
      if (!id) return

      const response = await projectRequestRepository.findProjectRequestById(id)
      if (!response) return

      if (isClone && response.typeRequest === TypeRequest.PURCHASE_PACKAGE) {
        navigate('/requisicao-compras')
        message.error('Não é possível clonar uma requisição de pacote de compras!')
        return
      }

      const formattedValues = {
        ...response,
        isRequestNotTiedToProject: response.typeRequest === TypeRequest.REQUISITION_NOT_TIED_A_PROJECT,
        statusRequest: !isClone ? response.statusRequest : StatusRequest.NEW,
        issueDate: !isClone ? moment(response.issueDate) : moment(),
        serviceStartDate: !isClone ? moment(response.serviceStartDate) : '',
        serviceEndDate: moment(response.serviceEndDate),
        paymentMethod: String(response.paymentMethod?.id || ''),
        paymentTerm: Number(response.paymentTerm),
        paymentDate: !isClone ? moment(response.serviceEndDate).add(Number(response.paymentTerm), 'days') : '',
      }

      const formattedSuppliers = response.suppliers.map(supplier => ({
        ...supplier,
        key: String(supplier.id)
      }))

      const { totalProjectedCost, totalPurchasedCost } = response.items.reduce((acc, curr) => ({
        totalProjectedCost: acc.totalProjectedCost + curr.unitCost,
        totalPurchasedCost: acc.totalPurchasedCost + curr.unitPurchasedCost
      }), {
        totalProjectedCost: 0,
        totalPurchasedCost: 0
      })

      const formattedItems = response.items.map(item => ({
        key: genId(),
        id: item.id,
        projectRevisionItemId: item.projectRevisionItemId,
        projectRequestItemId: item.id,
        amount: item.amount,
        daily: item.daily,
        productId: item.productId,
        productName: item.productDescription,
        projectId: item.projectRevision?.project.id,
        projectName: item.projectRevision?.project.name,
        unitCost: item.unitCost,
        totalCost: item.daily ? item.daily * item.unitCost * item.amount : item.unitCost * item.amount,
        saving: calculateSaving(
          response,
          [totalProjectedCost, totalPurchasedCost],
          item
        ),
        unitSale: item.unitSale,
        totalSalesWithoutTax: item.unitSale * item.amount,
        costCenterId: item.costCenter?.id,
        costCenterName: item.costCenter?.description,
        eventDate: (
          item.eventDate && !isClone
            ? formatDateToView(item.eventDate)
            : ''
        ),
        technicalScope: !isClone ? item.technicalScope : '',
        deliveryLocation: !isClone ? item.deliveryLocation : '',
      }) as TRequestItem)

      form.setFieldsValue(formattedValues)
      setSuppliers(formattedSuppliers)
      setItems(formattedItems)

      if (response.subsidiary) {
        const _companies = await getCompanies()
        const selectedCompany = _companies?.find(item => item.id === response.subsidiary.company.id)
        if (!selectedCompany) return
        form.setFieldsValue({
          companyId: response.subsidiary.company.id,
          subsidiaryId: response.subsidiary.id
        })
        setMappedSubsidiariesOptions(selectedCompany.subsidiaries)
      }
      if (onDisableTabs) onDisableTabs(response.statusRequest === StatusRequest.NEW)

      if (!isView && !isClone) getMembers(response)
    }

    if (id) getProjectRequest()

    if (!id) {
      getCompanies()
      form.setFieldsValue({
        isRequestNotTiedToProject: state.isRequestNotTiedToProject
      })
    }
    getCostCenter()
  }, [id])

  const handleStartOrRevisionQuote = async (
    status: string,
    data: TUpdateProjectRequestBuyer,
    dataItems: TUpdateProjectRequestItemBuyer[]
  ) => {
    if (status === StatusRequest.NEW) await handleSubmit(false)
    const responseData = await projectRequestRepository.updateProjectRequestBuyer(data)
    if (!responseData) return

    await Promise.all(dataItems.map(async (item): Promise<void> => {
      await projectRequestRepository.updateProjectRequestItemBuyer(item)
    }))

    if (status === StatusRequest.NEW) {
      await projectRequestRepository.startQuotation({
        projectRequestId: data.id
      })
    }

    navigate('/requisicao-compras')
  }

  const showConfirmStartOrRevisionQuote = () => {
    const status = form.getFieldValue('statusRequest')
    const { paymentMethod, paymentTerm, paymentDate } = form.getFieldsValue()
    const textContent = status === StatusRequest.IN_QUOTATION
      ? 'As cotações em andamento, irá sofrer revisão, deseja prosseguir?'
      : 'Deseja iniciar a cotação para esta requisição?'

    const supplierIds = suppliers.map(supplier => supplier.id)

    if (watchedRequireThreeQuotes && suppliers.length < 3) {
      message.error('Esta requisição requer pelo menos 3 cotações')
      return
    }

    if (!paymentMethod || paymentTerm === 0 || !paymentDate || !supplierIds.length) {
      message.error('Não é possivel prosseguir sem informações de dados do acordo e/ou sem fornecedores')
      return
    }

    const updateData: TUpdateProjectRequestBuyer = {
      id: Number(id),
      paymentMethod,
      paymentDate: formatDateToServer(String(paymentDate)),
      paymentTerm,
      suppliersId: supplierIds
    }

    const updateItemsData = items.map(item => {
      const stringUnitCost = String(item.unitCost)
      const formattedUnitCost = formatStringToNumber(stringUnitCost)

      return {
        projectRequestId: Number(id),
        projectRequestItemId: item.projectRequestItemId,
        eventDate: formatDateToServer(String(item.eventDate)),
        totalCost: formattedUnitCost * item.amount,
        deliveryLocation: item.deliveryLocation || '',
        technicalScope: item.technicalScope || ''
      } as TUpdateProjectRequestItemBuyer
    })

    const isInvalidItems = updateItemsData.some(itemData => (
      !itemData.eventDate || !itemData.deliveryLocation || !itemData.technicalScope
    ))

    if (isInvalidItems) {
      message.error('Não é possivel prosseguir se as informações de data de evento, local de entrega ou escopo técnico não estiver devidamente preenchidos')
      return
    }

    confirm({
      title: 'Confirmação',
      content: textContent,
      okText: 'Sim',
      async onOk() {
        await handleStartOrRevisionQuote(status, updateData, updateItemsData)
      }
    })
  }

  const onBlurPaymentTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    const serviceEndDate = form.getFieldValue('serviceEndDate') as Moment
    const paymentDate = moment(serviceEndDate).add(Number(value), 'days')

    form.setFieldValue('paymentDate', paymentDate)
  }

  const onChangeServiceEndDate = (date: moment.Moment | null) => {
    const paymentTerm = form.getFieldValue('paymentTerm') as number
    const paymentDate = moment(date).add(paymentTerm, 'days')

    form.setFieldValue('paymentDate', paymentDate)
  }

  const productIds = useMemo(() => (
    items.map(item => item.productId)
  ), [items])

  const purchaseTypeOptionsUpdated = useMemo(() => {
    if (!watchedisRequestNotTiedToProject) return purchaseTypeOptions
    return purchaseTypeOptions.filter(purchaseOption => purchaseOption.value !== 'P')
  }, [watchedisRequestNotTiedToProject])

  const isRequestNew = useMemo(() => watchedStatus === StatusRequest.NEW, [watchedStatus])

  const disableFieldsOfAgreement = useMemo(() => (
    !['NEW', 'IN_QUOTATION'].includes(watchedStatus)
  ), [watchedStatus])

  const isBuyer = useMemo(() => (
    hasRole(authRoles?.request_buyer)
  ), [authRoles])

  const { textButtonQuotation, showButtonQuotation } = useMemo(() => ({
    textButtonQuotation: (
      watchedStatus === StatusRequest.IN_QUOTATION
        ? 'Revisar cotação'
        : 'Iniciar cotação'
    ),
    showButtonQuotation: ['NEW', 'IN_QUOTATION'].includes(watchedStatus)
  }), [watchedStatus])

  const setMappedSubsidiariesOptions = (subsidiaries: TSubsidiary[]) => {
    const mappedSubsidiaries = subsidiaries.map(item => ({
      value: item.id,
      label: item.name
    }) as DefaultOptionType)
    setSubsidiaryOptions(mappedSubsidiaries)
  }

  const onChangeCompany = () => {
    const companyId = form.getFieldValue('companyId')
    form.setFieldValue('subsidiaryId', '')

    const selectedCompany = companies.find(item => item.id === companyId)
    if (!selectedCompany) return

    setMappedSubsidiariesOptions(selectedCompany.subsidiaries)
  }

  const onChangeRequireThreeQuotes = (e: CheckboxChangeEvent) => {
    if (e.target.checked) form.setFieldValue('indicationSingleSupplierJustifiable', false)
  }

  const onChangeIndicationSingleSupplierJustifiable = (e: CheckboxChangeEvent) => {
    if (e.target.checked) form.setFieldValue('requireThreeQuotes', false)
  }

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
    >
      {(id && !isView && !isClone) && (
        <Row>
          <Col xl={3}>
            <ChatRequisition
              projectRequestId={Number(id)}
              setGroupMembers={setGroupMembersChat}
              groupMembers={groupMembersChat}
            />
          </Col>
        </Row>
      )}
      <HiddenField name='isRequestNotTiedToProject' />
      <Row>
        <Col xl={4}>
          <DateField
            required
            disabled
            name='issueDate'
            label='Data de emissão'
          />
        </Col>
        <Col xl={4}>
          <DateField
            required
            disabled={isView || !isRequestNew}
            name='serviceStartDate'
            label='Data de início'
            rules={validations.serviceStartDate}
          />
        </Col>
        <Col xl={4}>
          <DateField
            required
            disabled={isView || !isRequestNew}
            name='serviceEndDate'
            label='Data da finalização'
            rules={validations.serviceEndDate}
            onChange={onChangeServiceEndDate}
          />
        </Col>
        <Col xl={6}>
          <SelectField
            required
            disabled={isView || !isRequestNew}
            label='Tipo de requisição'
            name='purchaseType'
            options={purchaseTypeOptionsUpdated}
            rules={validations.purchaseType}
          />
        </Col>
        <Col xl={6}>
          <SelectField
            required
            disabled
            label='Status'
            name='statusRequest'
            options={statusRequestOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <HiddenField
            name='approvalHierarchyId'
          />
          <SearchField
            required
            label='Alçada'
            disabled={isView || !isRequestNew}
            name='approvalHierarchyName'
            isLoading={hierarchyRepository.loading}
            placeholder='Pesquise pela alçada'
            onChange={onHierarchyChange}
            onSearch={onHierarchySearch}
            value={hierarchyValue}
            options={hierarchyOptions}
            rules={validations.approvalHierarchyName}
          />
        </Col>
        <Col xl={6}>
          <SelectField
            required
            disabled={isView || !isRequestNew || watchedisRequestNotTiedToProject}
            label='Tipo de pagamento'
            name='paymentType'
            options={paymentTypeOptions}
            rules={validations.paymentType}
          />
        </Col>
        <Col xl={6}>
          <NumberField
            required
            max={999}
            disabled={isView || !isRequestNew}
            label='Prazo de negociação'
            name='negotiationDeadline'
            rules={validations.negotiationDeadline}
          />
        </Col>
      </Row>
      {watchedisRequestNotTiedToProject && (
        <Row>
          <Col xl={8}>
            <SelectField
              required
              disabled={isView}
              name='companyId'
              rules={validations.companyId}
              label='Empresa de faturamento'
              options={companyOptions}
              bordered
              onChange={onChangeCompany}
            />
          </Col>
          <Col xl={8}>
            <SelectField
              required
              label='Filial'
              name='subsidiaryId'
              disabled={!watchedCompanyId || isView}
              options={subsidiaryOptions}
              rules={validations.subsidiaryId}
              bordered
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xl={6}>
          <CheckField
            disabled={isView || !isRequestNew}
            name='requireThreeQuotes'
            label='Requer três cotações'
            onChange={onChangeRequireThreeQuotes}
          />
        </Col>
        <Col xl={6}>
          <CheckField
            disabled={isView || !isRequestNew}
            name='indicationSingleSupplierJustifiable'
            label='Indicação de fornecedor único'
            onChange={onChangeIndicationSingleSupplierJustifiable}
          />
        </Col>
      </Row>
      {id && (
        <>
          <Row>
            <Col>
              <Divider
                orientation='left'
                plain
              >
                Acordos
              </Divider>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <SelectField
                disabled={isView || disableFieldsOfAgreement}
                name='paymentMethod'
                label='Forma de pagamento'
                options={paymentMethodsOptions}
              />
            </Col>
            <Col xl={6}>
              <NumberField
                disabled={isView || disableFieldsOfAgreement}
                name='paymentTerm'
                label='Prazo de pagamento'
                onBlur={onBlurPaymentTerm}
                max={999}
              />
            </Col>
            <Col xl={4}>
              <DateField
                disabled={isView || disableFieldsOfAgreement}
                name='paymentDate'
                label='Data de pagamento'
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <Divider
            orientation='left'
            plain
          >
            Fornecedores
          </Divider>
        </Col>
      </Row>
      <Row>
        <Col>
          <SupplierRequestList
            isView={isView}
            status={watchedStatus}
            suppliers={suppliers}
            setSuppliers={setSuppliers}
            productIds={productIds}
            negotiationDeadline={watchedDeadline}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Divider
            orientation='left'
            plain
          >
            Items
          </Divider>
        </Col>
      </Row>
      <Row>
        <Col>
          <ItemList
            isView={isView}
            isClone={isClone}
            items={items}
            setItems={setItems}
            status={watchedStatus}
            isRequestNotTiedToProject={watchedisRequestNotTiedToProject}
            costCenterOptions={costCenterOptions}
          />
        </Col>
      </Row>
      <Form.Item>
        <Space
          size='small'
          className='mt-1'
        >
          <Button
            onClick={actionGoBack}
            htmlType='button'
          >
            {textActionGoBack}
          </Button>
          <Button
            type='primary'
            onClick={() => handleSubmit()}
            loading={projectRequestRepository.loading}
            disabled={isView || authRoles?.request_buyer || watchedStatus !== StatusRequest.NEW}
          >
            Salvar
          </Button>
          {(id && !isClone && showButtonQuotation) && (
            <Button
              onClick={showConfirmStartOrRevisionQuote}
              disabled={isView || !isBuyer}
            >
              {textButtonQuotation}
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  )
}

export default RequestForm
