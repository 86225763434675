import { useState } from 'react'
import useRest from 'hooks/useRest'
import {
  TSupplierAttachment,
  TSupplierAttachmentParams
} from 'repositories'

const useSupplierAttachment = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const uploadAttachment = async (
    data: FormData,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/supplier/attachment'
    const response = await rest.post(url, data, {
      'Content-Type': 'multipart/form-data'
    })
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TSupplierAttachment
  }

  const downloadAttachment = async (
    supplierId: number,
    filename: string,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/supplier/attachment'
    const response = await rest.get(url, {
      supplierId,
      filename
    })
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as string
  }

  const findAttachmentsByFilter = async (
    supplierId: number,
    params: TSupplierAttachmentParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/supplier/attachment/supplier-id/${supplierId}`
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TSupplierAttachment[]
  }

  const deleteAttachment = async (
    supplierId: number,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/supplier/attachment/id/${supplierId}`
    const response = await rest.destroy(url)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return true
  }

  return {
    loading,
    uploadAttachment,
    downloadAttachment,
    findAttachmentsByFilter,
    deleteAttachment
  }
}

export default useSupplierAttachment

