import useRest from 'hooks/useRest'
import { useState } from 'react'
import {
  TGroup,
  TFindGroupParams,
  TUserGroupRequest,
  TUserGroupResponse
} from '.'

const useUserGroups = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findGroupsByFilter = async (
    params: TFindGroupParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/user-group'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TUserGroupResponse
  }

  const findGroupById = async (
    id: TStringableNumber,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/user-group/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TGroup

    return response.result as TGroup
  }

  const createGroup = async (group: TUserGroupRequest, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/user-group'
    const response = await rest.post(url, group)
    if (triggerLoading) setLoading(false)
    if (!response) return false

    return true
  }

  const updateGroup = async (group: TUserGroupRequest, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/user-group'
    const response = await rest.put(url, group)
    if (triggerLoading) setLoading(false)
    if (!response) return false

    return true
  }

  return {
    loading,
    findGroupsByFilter,
    findGroupById,
    createGroup,
    updateGroup
  }
}

export default useUserGroups
