import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.LIGHTER_GRAY};
  border-bottom: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  
  > div {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;

    .section {
      font-size: 12px !important;
    }

    > span {
      padding-right: 10px;
      font-weight: 600;
      border-right: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
    }
  }
`
