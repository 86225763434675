import useRest from 'hooks/useRest'
import { useState } from 'react'
import {
  TProfileForm,
  TUser
} from '.'

const useProfile = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findUserProfile = async (triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/system-user/user-profile'
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TUser

    return response.result as TUser
  }

  const updateProfile = async (user: TProfileForm, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/system-user/user-profile'
    const response = await rest.put(url, user)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TUser
  }

  return {
    loading,
    findUserProfile,
    updateProfile
  }
}

export default useProfile
