import React, { useState } from "react";
import {
  AiOutlineBell,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineMessage,
} from "react-icons/ai";
import { Button, Input, Tabs } from "antd";
import Profile from "./Profile";
import { Container, Toggle, Logo, Content, Toolbar } from "./styles";

type TProps = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header = (props: TProps) => {
  const { isSidebarOpen, setIsSidebarOpen } = props;
  const [activeNot, setActiveNot] = useState(false);

  const onClickToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const iconToggle = isSidebarOpen ? (
    <AiOutlineMenuFold />
  ) : (
    <AiOutlineMenuUnfold />
  );

  const onChange = (key: string) => {
  };

  function ItemsNotfications() {
    return (
      <div className="items-not">
        <div className="item-not">
          <div className="header">
            <h2>Verifique seu e-mail</h2>
            <AiOutlineBell />
          </div>
          <div className="content">
            <p>
              Atenção! Por enquanto as notificações do sistema de compras serão enviadas apenas por e-mail. Em breve também disponibilizaremos as notificações na plataforma.</p>
            {/* <a href="">requisição #4534</a> */}
          </div>
          {/* <div className="footer">
            <span>30/10/23 - 12:39</span>
            <span className="status danger">Não lida</span>
          </div> */}
        </div>
        {/* <div className="item-not">
          <div className="header">
            <h2>Houve um questionamento em sua requisição!</h2>
            <AiOutlineBell />
          </div>
          <div className="content">
            <p>Clique no link abaixo para avaliar e responder:</p>
            <a href="">requisição #4534</a>
          </div>
          <div className="footer">
            <span>30/10/23 - 12:39</span>
            <span className="status">Lida</span>
          </div>
        </div> */}
      </div>
    );
  }

  const items: any = [
    {
      key: "1",
      label: "Todas",
      children: ItemsNotfications(),
    },
    // {
    //   key: "2",
    //   label: "Não lidas",
    //   children: ItemsNotfications(),
    // },
  ];

  function toggleNot() {
    setActiveNot(!activeNot);
  }

  return (
    <Container>
      <Toggle>
        <Logo />
        <Button type="text" onClick={onClickToggleSidebar}>
          {iconToggle}
        </Button>
      </Toggle>
      <Content>
        {/* <Input.Search disabled width="300px" placeholder="Pesquise aqui..." /> */}
        <span></span>
        <Toolbar>
          <div style={{ position: "relative" }}>
            <div className="btns-wrapper" onClick={() => toggleNot()}>
              <AiOutlineMessage />
              <AiOutlineBell />
            </div>
            <div
              className="notifications"
              style={{ display: activeNot ? "block" : "none" }}
            >
              <h2>Notificações e Mensagens</h2>
              <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
            <div
              className="overlay"
              style={{ display: activeNot ? "block" : "none" }}
              onClick={(e) =>
                e.target === e.currentTarget ? setActiveNot(false) : ""
              }
            ></div>
          </div>
          <Profile />
        </Toolbar>
      </Content>
    </Container>
  );
};

export default Header;
