import useRest from 'hooks/useRest'
import { useState } from 'react'
import {
  TAcceptOrDeclineQuoteRequest,
  TFilterTSupplierQuoteParams,
  TFindSupplierQuoteDetails,
  TSupplierQuoteDetail,
  TSupplierQuoteResponse,
  TUpdateSupplierQuoteRequest
} from 'repositories'

const useSupplierQuote = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findSupplierQuoteByFilter = async (
    params: TFilterTSupplierQuoteParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/supplier-quote'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TSupplierQuoteResponse

    return response.result as TSupplierQuoteResponse
  }

  const findSupplierQuoteDetails = async (
    params: TFindSupplierQuoteDetails,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/supplier-quote/details'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TSupplierQuoteDetail

    return response.result as TSupplierQuoteDetail
  }

  const answerQuote = async (
    params: TAcceptOrDeclineQuoteRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/supplier-quote/answer'
    const response = await rest.put(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TSupplierQuoteDetail

    return response.result as TSupplierQuoteDetail
  }

  const updateQuote = async (
    params: TUpdateSupplierQuoteRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/supplier-quote'
    const response = await rest.put(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TSupplierQuoteDetail

    return response.result as TSupplierQuoteDetail
  }

  const choiceQuoteWinner = async (
    supplierQuoteId: number,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/supplier-quote/choice-quote/${supplierQuoteId}`
    const response = await rest.post(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TSupplierQuoteDetail

    return response.result as TSupplierQuoteDetail
  }

  const declassifyQuote = async (
    supplierQuoteId: number,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/supplier-quote/declassify-quote/${supplierQuoteId}`
    const response = await rest.post(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TSupplierQuoteDetail

    return response.result as TSupplierQuoteDetail
  }

  return {
    findSupplierQuoteByFilter,
    findSupplierQuoteDetails,
    answerQuote,
    updateQuote,
    choiceQuoteWinner,
    declassifyQuote,
    loading
  }
}

export default useSupplierQuote
