import React, { useEffect, useState } from 'react'
import {
  Descriptions,
  Divider
} from 'antd'
import {
  Col,
  DetailGrid,
  Row
} from 'components'
import { cnpj } from 'cpf-cnpj-validator'
import {
  TProjectDetail,
  TPurchaseOrderItems,
  TSupplierOutput,
  useProject,
  useSuppliers
} from 'repositories'
import {
  formatCurrency,
  formatDateToView
} from 'utils/helpers'

type TDetailProject = Omit<TPurchaseOrderItems, 'percentage' | 'value'> & Partial<TProjectDetail> & {
  percentage: string
  value: string
}

type TProps = {
  supplierId: number
  isRequestNotTiedToProject?: boolean
  initialProjects: TPurchaseOrderItems[]
}

const ContractInfo = (props: TProps) => {
  const {
    supplierId,
    isRequestNotTiedToProject = false,
    initialProjects
  } = props

  const [supplier, setSupplier] = useState<TSupplierOutput>({} as TSupplierOutput)
  const [projects, setProjects] = useState<TDetailProject[]>([])

  const repositorySupplier = useSuppliers()
  const repositoryProject = useProject()

  useEffect(() => {
    const getSuppplier = async () => {
      const response = await repositorySupplier.findSupplierById(supplierId)
      if (!response) return

      setSupplier({
        ...response,
        cnpj: cnpj.format(response.cnpj),
      })
    }

    const getProjectInfo = (projectId: string) => repositoryProject.findProjectById(projectId)

    const getProject = async () => {
      const results: TDetailProject[] = await Promise.all(initialProjects.map(async (item): Promise<TDetailProject> => {
        const projectId = String(item.projectId)
        const project = await getProjectInfo(projectId)
        if (!project) {
          return {
            ...item,
            startDatePayment: formatDateToView(item.startDatePayment || ''),
            endDatePayment: formatDateToView(item.endDatePayment || ''),
            percentage: formatCurrency(item.percentage, false),
            value: formatCurrency(item.value, false),
          }
        }

        const formattedProject = {
          ...project,
          company: {
            ...project.company,
            cnpj: cnpj.format(project.company.cnpj)
          },
          client: {
            ...project.client,
            cnpj: cnpj.format(project.client.cnpj)
          },
          dateOfSale: formatDateToView(project.dateOfSale || ''),
          inclusionDate: formatDateToView(project.inclusionDate || ''),
        }

        return {
          ...item,
          ...formattedProject,
          startDatePayment: formatDateToView(item.startDatePayment || ''),
          endDatePayment: formatDateToView(item.endDatePayment || ''),
          percentage: formatCurrency(item.percentage, false),
          value: formatCurrency(item.value, false),
        }
      }))
      setProjects(results)
    }

    if (supplierId) getSuppplier()
    if (initialProjects.length && !isRequestNotTiedToProject) getProject()
  }, [supplierId, initialProjects])

  return (
    <>
      <Row>
        <Col>
          <Divider plain orientation='left'>
            Fornecedor
          </Divider>
          <DetailGrid>
            <Descriptions.Item label='CNPJ'>
              {supplier?.cnpj}
            </Descriptions.Item>
            <Descriptions.Item label='Razão Social'>
              {supplier?.corporateName}
            </Descriptions.Item>
            <Descriptions.Item label='Email'>
              {supplier?.email}
            </Descriptions.Item>
            <Descriptions.Item label='CEP'>
              {supplier?.supplierAddresses?.zipCode}
            </Descriptions.Item>
            <Descriptions.Item label='Logradouro'>
              {supplier?.supplierAddresses?.publicPlace}
            </Descriptions.Item>
            <Descriptions.Item label='Número'>
              {supplier?.supplierAddresses?.number}
            </Descriptions.Item>
            <Descriptions.Item label='Número'>
              {supplier?.supplierAddresses?.neighborhood}
            </Descriptions.Item>
            <Descriptions.Item label='Cidade'>
              {supplier?.supplierAddresses?.city}
            </Descriptions.Item>
            <Descriptions.Item label='Estado'>
              {supplier?.supplierAddresses?.state}
            </Descriptions.Item>
          </DetailGrid>
        </Col>
      </Row>
      <Row>
        <Col>
          {projects?.map(project => (
            <>
              <Divider plain orientation='left'>
                Projeto
              </Divider>
              <DetailGrid>
                <Descriptions.Item label='Id ERP'>
                  {project?.idERP}
                </Descriptions.Item>
                <Descriptions.Item label='Projeto'>
                  {project?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Criado em'>
                  {project?.inclusionDate}
                </Descriptions.Item>
                <Descriptions.Item label='Data da venda'>
                  {project?.dateOfSale}
                </Descriptions.Item>
                <Descriptions.Item label='Fase'>
                  {project?.phase?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Categoria do Projeto'>
                  {project?.projectCategory?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Valor'>
                  {project?.value}
                </Descriptions.Item>
                <Descriptions.Item label='Percentual'>
                  {project?.percentage}
                </Descriptions.Item>
                <Descriptions.Item label='Início de pagamento'>
                  {project?.startDatePayment}
                </Descriptions.Item>
                <Descriptions.Item label='Fim de pagamento'>
                  {project?.endDatePayment}
                </Descriptions.Item>
              </DetailGrid>
              <Divider plain orientation='left'>
                Empresa de Faturamento
              </Divider>
              <DetailGrid>
                <Descriptions.Item label='CNPJ'>
                  {project?.company?.cnpj}
                </Descriptions.Item>
                <Descriptions.Item label='Razão Social'>
                  {project?.company?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Filial'>
                  {project?.subsidiary?.name}
                </Descriptions.Item>
              </DetailGrid>
              <Divider plain orientation='left'>
                Cliente
              </Divider>
              <DetailGrid>
                <Descriptions.Item label='CNPJ'>
                  {project?.client?.cnpj}
                </Descriptions.Item>
                <Descriptions.Item label='Razão Social'>
                  {project?.client?.corporateName}
                </Descriptions.Item>
                <Descriptions.Item label='Nome Fantasia'>
                  {project?.client?.fantasyName}
                </Descriptions.Item>
                <Descriptions.Item label='Email'>
                  {project?.client?.email}
                </Descriptions.Item>
              </DetailGrid>
            </>
          ))}
        </Col>
      </Row>
    </>
  )
}

export default ContractInfo
