import React, { useEffect, useState } from "react";
import { v4 as genId } from "uuid";
import {
  usePurchaseOrder,
  TPurchaseOrder,
  TInfoUser,
  TMemberChat,
  TChatOutputDTO,
  TCreationUserDTO,
  TSelectedQuestionateButtonData,
  TBuyerDTO,
} from "repositories";
import { BiChevronDown } from "react-icons/bi";
import {
  Spin,
  Button,
  Space,
  Empty,
  Pagination,
  Card,
  Table,
  Typography,
  Divider,
  MenuProps,
  Tooltip,
  Tag,
  message,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Modal as ModalRequests, Modal as ModalDownloads } from "antd";
import Modal from "../../../Tracker/Orders/Components/Modal";
import { useAuth, useFilters, usePagination } from "hooks";
import { FlexEndContainer, Section } from "components";
import {
  formatCurrency,
  formatDateToServer,
  formatDateToView,
} from "utils/helpers";
import Chat from "pages/Components/Chat";
import {Flex, StyledRow, YellowButton, Tarja, ContentBox, ContentTitle, ContentRow} from "./styles";
import {
  purchaseOrderStatus,
  supplierStatus,
  purchaseOrderStatusColor,
  purchaseOrderStatusTextColor,
  labelBackgroundColor,
  labelTextColor,
  purchaseOrderStatusTitleColor
} from "utils/constants";
import {
  Box,
  ButtonBox,
  Content,
  SummaryActions,
  Info,
  Label,
  AdditionalInfo,
  Abbreviation,
  InfoOrder,
  UserInteraction,
  UserApproval,
  SummaryActionsTitle
} from "./styles";
import FiltersForm from "./Components/FiltersForm";
import AcceptOrRefusedForm from "./Components/AcceptOrRefusedForm";
import UserHierarchy from "../UserHierarchy";
import ProjectAndLineInformation from "../ProjectAndLineInformation";

import e from "../../../../assets/icons/ofensores/e.svg";
import eD from "../../../../assets/icons/ofensores/e-disabled.svg";

import porcentagem from "../../../../assets/icons/ofensores/porcentagem.svg";
import porcentagemD from "../../../../assets/icons/ofensores/porcentagem-disabled.svg";

import card from "../../../../assets/icons/ofensores/card.svg";
import cardD from "../../../../assets/icons/ofensores/card-disabled.svg";

import stars from "../../../../assets/icons/ofensores/starss.svg";
import starsD from "../../../../assets/icons/ofensores/starss-disabled.svg";

import calendarDay from "../../../../assets/icons/ofensores/calendar-day.svg";
import calendarDayD from "../../../../assets/icons/ofensores/calendar-day-disabled.svg";

import time from "../../../../assets/icons/ofensores/time.svg";
import timeD from "../../../../assets/icons/ofensores/time-disabled.svg";

import calendar from "../../../../assets/icons/ofensores/calendar.svg";
import calendarD from "../../../../assets/icons/ofensores/calendar-disabled.svg";

import cash from "../../../../assets/icons/ofensores/cash.svg";
import cashD from "../../../../assets/icons/ofensores/cash-disabled.svg";

import moment from "moment";

import { useRequest, useProject } from "repositories";
import Timeline from "./Components/Timeline";
import ApportionmentInformation from "../ApportionmentInformation";

import Popover from "../../../Tracker/Components/Popover";
import { useSearchParams } from "react-router-dom";
import { theme } from "styles";

import axios from "services/axios";

const { Text } = Typography;

export const getOrderNames = (order: TPurchaseOrder) => {
  const setNames = new Set();
  order.items.forEach((item) => {
    if (order.isRequestNotTiedToProject)
      setNames.add(`${item.costCenter?.erpId} \n${item.costCenter?.description}`);
    else {
      setNames.add(`${item.projectIdErp} \n${item.projectName} - ${item.clientName}`);
    }
  });

  return setNames as Set<string>;
};

const OrdersTab = () => {
  const [orders, setOrders] = useState<any>();
  const [supplier, setSupplier] = useState([]);
  const [projectRequests, setProjectRequests] = useState([]);
  const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false);
  const [isActiveApproval, setIsActiveApproval] = useState<boolean>();
  const [isVisibleAcceptOrRefuseForm, setIsVisibleAcceptOrRefuseForm] =
    useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [purchaseOrderId, setPurchaseOrderId] = useState(0);
  const [member, setMember] = useState<TMemberChat>({} as TMemberChat);
  const [isVisibleChat, setIsVisibleChat] = useState(false);
  const { userData } = useAuth();

  const [statusToggle, setStatusToggle] = useState<any>(false)

  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDownloadsOpen, setIsModalDownloadsOpen] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [filesList, setFilesList] = useState<any>([]);
  const [filesListId, setFilesListId] = useState<any>([]);

  const repository = useRequest();

  const filters = useFilters();
  const purchaseRepository = usePurchaseOrder();
  const pagination = usePagination();

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsUrlId = (searchParams.has("reqId")) ? searchParams.get("reqId") : null;

  const requestsColumns: any = [
    {
      title: "Emissão",
      dataIndex: "issueDate",
      render: (_: any, record: any) => {
        return record.issueDate
          ? moment(record.issueDate).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      render: (_: any, record: any) => {
        return record.maturity
          ? moment(record.maturity).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Produto",
      dataIndex: "productName",
    },
    {
      title: "Prestador",
      dataIndex: "typeProvider",
      render: (_: any, record: any) => {
        return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if (record.typeRequest === "SIMPLE") return "Simples";
        if (record.typeRequest === "COMPOSED") return "Composta";
        return "Composta";
      },
    },
    {
      title: "Categoria",
      dataIndex: "category",
    },
    {
      title: "Alocação",
      dataIndex: "allocation",
    },
    {
      title: "Local",
      dataIndex: "buName",
    },
    {
      title: "Cliente",
      dataIndex: "clientName",
    },
    {
      title: "Timing",
      dataIndex: "timing",
      render: (_: any, record: any) => {
        if (record.timing === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;

        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Fatura",
      dataIndex: "paymentType",
    },
    {
      title: "Valor da requisição",
      dataIndex: "totalCost",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return record.status;
        }
      },
    },
  ];

  const formatFilter = (_filters: any) => {
    if (_filters.startDateApprover) {
      _filters.startDateApprover = formatDateToServer(
        _filters.startDateApprover
      );
    }
    if (_filters.endDateApprover) {
      _filters.endDateApprover = formatDateToServer(_filters.endDateApprover);
    }
    if(paramsUrlId !== null) {
      _filters.idRequest = paramsUrlId;
      setTimeout(() => {
        searchParams.delete("reqId");
        setSearchParams(searchParams);
      }, 5000);
    }
    return _filters;
  };

  const getPurchases = async () => {
    const currentPage = pagination.data.current ?? 0;
    const _filters = filters.getObjectifiedFilterItems();
    const formatedFilters = formatFilter(_filters);
    const requestParams = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort: "id,DESC",
      activeApproval: isActiveApproval,
      ...formatedFilters,
    };
    const response = await purchaseRepository.getPurchaseOrderInAnalysis(
      requestParams
    );
    if (!response) return;
    if (response.content.length === 0)
      message.warn("Você não possui requisições no momento");

    pagination.changeTotalPages(response?.totalPages);
    let supplierAndProduct: any = [];
    let projectRequest: any = [];

    response?.content?.forEach((c) => {
      supplierAndProduct = [
        ...supplierAndProduct,
        {
          orderId: c.id,
          supplierId: c.supplierId,
          supplierType: c.supplierType,
          productName: c.productName,
          productId: c.productId,
        },
      ];
      projectRequest = [
        ...projectRequest,
        {
          orderId: c.id,
          projectRequest: c.projectRequest,
        },
      ];
    });

    const ordersMapped = response.content.map((order: any) => ({
      ...order,
      products: Array.from(new Set(order.items.map((item: any) => item.projectRevisionItem.productDescription)))
    }))

    setSupplier(supplierAndProduct);
    setProjectRequests(projectRequest);
    setOrders(ordersMapped);
  };

  const onClickGoToDetail = (orderId?: number) => () => window.open(`/requisicao/visualizar/${orderId}`);

  const onClickOpenFilterForm = () => {
    setIsVisibleFilterForm(true);
  };

  const openDownloads = (requestResponse: any) => {
    setFilesListId(requestResponse.id);
    setFilesList(requestResponse.files);
    setIsModalDownloadsOpen(true)
  }

  const uniqueId = crypto.randomUUID();

  const downloadArchive = async (fileName: any, projectRequestId: any = false) => {
    try {
      setDownloadLoading(true);
      let url = "/v1/manager/project-request/download/" + fileName;
      if(projectRequestId){
        url = "/v1/manager/project-request/download-all/" + projectRequestId;
      }
      const response = await axios.get(
        url,
        {
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadLoading(false);
    }
  };

  
  const onClickAcceptOrRefuse =
    (orderId: number, accept = true) =>
    () => {
      setPurchaseOrderId(orderId);
      setIsVisibleAcceptOrRefuseForm(true);
      setIsAccept(accept);
    };

  const getChat = (chats: TChatOutputDTO[], usersId: number[]) =>
    chats?.find((chat) =>
      chat.users.every((user) => usersId.includes(user.id))
    );

  const makeMemberChat = (
    chat: TChatOutputDTO | undefined,
    userToId: number,
    userToName: string,
    userToDescription?: string
    // eslint-disable-next-line max-params
  ): TMemberChat => ({
    key: genId(),
    chatId: chat?.id || 0,
    messagesNotViewed: chat?.messagesNotViewed || 0,
    userFromId: userData?.userId,
    userFromName: userData?.userName,
    userToId,
    userToName,
    userToDescription,
  });

  const getMemberToCreationUser = (
    userFrom: TInfoUser,
    creationUser: TCreationUserDTO,
    chats: TChatOutputDTO[]
  ) => {
    const findedChat = getChat(chats, [
      userFrom?.userId,
      creationUser.creationUserId,
    ]);
    if (!creationUser) return {} as TMemberChat;

    return makeMemberChat(
      findedChat,
      creationUser.creationUserId,
      creationUser.creationUserName
    );
  };

  const getMemberToBuyer = (
    userFrom: TInfoUser,
    buyer: TBuyerDTO,
    chats: TChatOutputDTO[]
  ) => {
    if (buyer.buyerId && buyer.buyerName) {
      const findedChat = getChat(chats, [userFrom?.userId, buyer.buyerId]);
      if (!buyer) return {} as TMemberChat;
      return makeMemberChat(findedChat, buyer.buyerId, buyer.buyerName);
    }
  };

  const handleOpenQuestionateChat = (
    openChatData: TSelectedQuestionateButtonData
  ) => {
    setMember(openChatData.requester);
    if (openChatData.purchaseOrder) {
      setPurchaseOrderId(openChatData.purchaseOrder);
    }
    setIsVisibleChat(true);
  };

  const handleOpenChat: MenuProps["onClick"] = (e) => {
    const [user, purchaseOrder] = e.key.split("|");
    const objectifyUser = JSON.parse(user);
    setMember(objectifyUser as TMemberChat);
    setPurchaseOrderId(Number(purchaseOrder));
    setIsVisibleChat(true);
  };

  const getSupplierStatus = (status: any) => {
    switch (status) {
      case "APPROVED":
        return (
          <Label className="label" color={labelBackgroundColor["APPROVED"]} textColor={labelTextColor["APPROVED"]}>
            Homologado
          </Label>
        );
        break;

      case "NOT_APPROVED":
        return (
          <Label className="label" color={labelBackgroundColor["NOT_APPROVED"]} textColor={labelTextColor["NOT_APPROVED"]}>
            Bloqueado
          </Label>
        );
        break;

      default:
        return (
          <Label className="label" color={labelBackgroundColor["IN_ANALYSIS"]} textColor={labelTextColor["IN_ANALYSIS"]}>
            {supplierStatus[status]}
          </Label>
        );
        break;
    }
  };

  const getProjectDescription = (description: any) => {
    if(!description) return "Não informado.";
    const maxlenght = 200;
    return description.toString().length >= maxlenght ? description.toString().substring(0,maxlenght).concat("...") : description.toString();
  };

  const getIdErpCorrelationFromComposed = (items : any[]) => {
    return items.map(item => {
      return item.apportions.reduce((accumulator:any, currentData:any) => currentData.idErpCorrelation ? accumulator + currentData.idErpCorrelation + ' ' : accumulator + '' , '')
    }).join(',').replace(',','');
  }

  const openRequests = async (order: any) => {
    const params: any = {
      page: 0,
      size: 2000,
      sort: "issueDate,DESC",
      supplierIdCnpj: order.supplierId,
    };
    const response = await repository.findRequests(params);
    if (!response) return;

    const mappedResponse = response.content.map((request: any) => {
      return {
        ...request,
        productName: request?.items[0]?.productDescription,
        buName: request.items[0].projectRevision?.project.buName,
        clientName: request.items[0].projectRevision?.project.clientName,
        totalCost: formatCurrency(request?.items[0]?.totalCost),
        status: request.statusRequest,
        allocation: request.items[0].projectRevision.project.idERP
      };
    });
    setRequestModal(mappedResponse);
    setIsModalOpen(true);
  };

  const updateList = () => {
    getPurchases();
  };

  useEffect(() => {
    if (filters.items && pagination.data.current) getPurchases();
  }, [pagination.data.current, pagination.data.pageSize, filters.items, isActiveApproval]);

  const resetPagination = () => {
    pagination.onChange(1, pagination.data.pageSize ?? 10);
  };

  return (
    <>
      <StyledRow>
        <ButtonBox >
          <YellowButton  onClick={() =>{ setIsActiveApproval(!isActiveApproval) }}>{isActiveApproval ? 'Exibir tudo' : 'Filtrar pendências'}</YellowButton>
        </ButtonBox>
        <ButtonBox>
          <Button onClick={onClickOpenFilterForm}>Filtros</Button>
        </ButtonBox>
      </StyledRow>
      {!orders?.length && !purchaseRepository.loading && (
        <Card className="mt-1">
          <Empty description="Nenhum pedido de compra encontrado" />
        </Card>
      )}
      <Section
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Spin tip="Carregando" spinning={purchaseRepository.loading}>
          {orders?.map((order: any) => {
            const projectRequestOrder = projectRequests.filter(
              (item: any) => item.orderId === order.id
            );

            const formattedPurchaseValue = formatCurrency(order.purchaseValue);
            const titleContent = order.projectRequest.typeRequest === "COMPOSED" ? (
              [{
                "title": "Rateios e contratos",
                "color": theme.colors.APPROVAL_YELLOW
              }]
            ) : (
              [{
                "title": "Compra e contratação",
                "color": theme.colors.APPROVAL_BLUE
              }]
            );

            return (
             <Box>
              <ContentTitle backgroundColor={titleContent[0].color}>
                <div>{titleContent[0].title}</div>
                <p>#{order?.projectRequest?.id}</p>
              </ContentTitle>
              <ContentBox key={order?.id}>
                <Content>
                  <Info>
                    <div style={{ paddingBottom: "10px" }}>
                      <div className="tagContents">
                        <div className="wrapper">
                          <Label color={purchaseOrderStatusColor[order?.status]} textColor={purchaseOrderStatusTextColor[order?.status]}>
                            {purchaseOrderStatus[order?.status]}
                          </Label>
                          <Label color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>
                            {order?.projectRequest?.approvalHierarchyTiming === "PLANEJADA" ?
                                "Planejada"
                              :
                                "Nâo planejada"
                            }
                          </Label>
                          {order.projectRequest.typeRequest === "COMPOSED" ? (
                              <Label color={labelBackgroundColor["COMPOSED"]} textColor={labelTextColor["COMPOSED"]}>Composta</Label>
                          ) : (
                              <Label color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>Simples</Label>
                          )}
                          <Label color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>Com projeto</Label>
                          {!order.projectRequest.id ? (
                            <Label color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>Verba TV1</Label>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="apportionERP">
                          {order?.items[0]?.apportions[0]?.idErpCorrelation && order?.projectRequest?.typeRequest === "SIMPLE" ? 
                            <span><Button onClick={() => setStatusToggle(order?.id)} type="link">{order?.items[0]?.apportions[0]?.idErpCorrelation}</Button></span>
                          : order?.items[0]?.apportions[0]?.idErpCorrelation && order?.projectRequest?.typeRequest === "COMPOSED" ?
                            <Tooltip title={getIdErpCorrelationFromComposed(order?.items)}><span>Múltiplos IDs</span></Tooltip>
                          : ``}
                        </div>
                      </div>
                      <Popover setNames={getOrderNames(order)} title="Projetos" breakline={true} fontColor={purchaseOrderStatusTitleColor[order?.status]} />
                    </div>
                    
                    <div>
                      <span>{`Emissão: ${formatDateToView(
                        order.issueDate
                      )}`}</span>
                      <br />
                      {
                        order?.projectRequest?.typeRequest === "COMPOSED" ?
                        <span>{`1º Vencimento: ${formatDateToView(order?.items[0]?.apportions[0]?.dueDate)}`}</span>
                        :
                        <span>{`Vencimento: ${formatDateToView(order?.projectRequest?.maturity)}`}</span>
                      }
                    </div>
                  </Info>
                  <AdditionalInfo>
                    <InfoOrder>
                      <span className="productTitle">
                        <Popover setNames={order.products} title="Produtos" showmore={true} />
                      </span>
                      <div className="supplierContent">
                        <span><strong>Prestador:</strong> {order?.supplierFantasyName}</span>
                        <div className="wrapper">
                          {getSupplierStatus(order?.supplierStatus)}
                          <Label className="label" color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>
                            {order?.supplierType === "SUPPLIER"
                              ? "Fornecedor"
                              : "Freelancer"}
                          </Label>
                        </div>
                      </div>
                      <div>
                        <span>
                          <strong>Requisitante:</strong> {order?.creationUserName}
                        </span>
                      </div>
                      <div>
                        <span>
                          <strong>Centro de custos: </strong>
                          {
                            order?.projectRequest?.items[0]?.projectRevision
                              ?.project?.buName
                          }
                        </span>
                      </div>
                      <div className="scope">
                        <span>
                          <strong>Escopo técnico:</strong>
                        </span>
                        <span>
                          <Tooltip title={order?.description} overlayStyle={{maxWidth:"800px", whiteSpace:"pre-line"}}>{getProjectDescription(order?.description)}</Tooltip>
                        </span>
                      </div>
                    </InfoOrder>
                    <UserInteraction>
                      { order?.projectRequest?.files.length > 0 ? (
                        <Button onClick={() => openDownloads(order?.projectRequest)} type="link">Baixar arquivos</Button>
                      ) : (
                        <div></div>
                      )}
                      <Button
                        onClick={onClickGoToDetail(order?.projectRequest.id)}
                      >
                        Mais detalhes
                      </Button>
                    </UserInteraction>
                  </AdditionalInfo>
                  <SummaryActionsTitle>
                    <span><strong>Aprovação</strong></span>
                  </SummaryActionsTitle>
                  <SummaryActions>
                    <UserApproval>
                      <Space size="small" style={{ marginTop: 16 }}>
                        {order.questionateButton}
                      </Space>
                      {order.status === "REFUSED" || order.status === "CANCELED" ? (
                        <Button className="ant-btn-question " disabled>
                          <Flex>
                            <span>Questionar</span>
                            <img
                                src="/notify.svg"
                                alt=""
                                width="16px"
                                style={{ marginTop: "0px", marginLeft: "-5px" }}
                              />
                          </Flex>
                        </Button>
                      ) : (
                        <Modal order={order} updateList={updateList} layoutApproval={true} />
                      )}
                      <Button
                        size="small"
                        onClick={onClickAcceptOrRefuse(Number(order.id), false)}
                        disabled={
                          order.status === "REFUSED" || !order.currentApprover
                        }
                        danger
                      >
                        Reprovar
                      </Button>
                      <Button
                        size="small"
                        onClick={onClickAcceptOrRefuse(Number(order.id), true)}
                        disabled={
                          order.status === "REFUSED" || !order.currentApprover
                        }
                        loading={purchaseRepository.loading}
                      >
                        Aprovar
                      </Button>
                    </UserApproval>
                    <div>
                      <p>{formattedPurchaseValue}</p>
                    </div>
                  </SummaryActions>
                  <Divider />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      gap: "5px",
                      marginBottom: "15px",
                    }}
                  >
                    <Tooltip title="Requisição não planejada">
                      {order?.offenders?.emergencyRequest ? (
                          <img src={e} alt="" />
                      ) : (
                        <img src={eD} alt="" />
                      )}
                    </Tooltip>

                    <Tooltip title="Projeto com margem abaixo do esperado">
                      {order?.offenders?.projectLowerMargin ? (
                          <img src={porcentagem} alt="" />
                      ) : (
                        <img src={porcentagemD} alt="" />
                      )}
                    </Tooltip>

                    <Tooltip title="Fornecedor sem homologação">
                      {order?.offenders?.supplierWithoutApproval ? (
                          <img src={card} alt="" />
                        
                      ) : (
                        <img src={cardD} alt="" />
                      )}
                    </Tooltip>

                    <Tooltip title="Fornecedor com baixa avaliação">
                      {order?.offenders?.lowRatedSupplier ? (
                          <img src={stars} alt="" />
                      ) : (
                        <img
                          src={starsD}
                          alt=""
                          style={{ filter: "brightness(0.6)" }}
                        />
                      )}
                    </Tooltip>

                    <Tooltip title="Início de trabalho x Data da requisição">
                    {order?.offenders?.startWorkRequestDate ? (
                        <img src={calendarDay} alt="" />
                    ) : (
                      <img src={calendarDayD} alt="" />
                    )}
                    </Tooltip>

                    <Tooltip title="Atividade Recente (CNPJ)">
                      {order?.offenders?.requestsForSameCNPJLastDays ? (
                          <img
                            src={time}
                            alt=""
                            onClick={() => openRequests(order)}
                            style={{ cursor: "pointer" }}
                          />
                      ) : (
                        <img src={timeD} alt="" />
                      )}
                    </Tooltip>

                    <Tooltip title="Prazo de pagamento">
                      {order?.offenders?.paymentTerm ? (
                          <img src={calendar} alt="" />
                      ) : (
                        <img src={calendarD} alt="" />
                      )}
                    </Tooltip>

                    <Tooltip title="Preciário">
                      {order?.offenders?.preciary ? (
                          <img src={cash} alt="" />
                      ) : (
                        <img src={cashD} alt="" />
                      )}
                    </Tooltip>
                  </div>
                </Content>
                <Content className="content-spacing">
                  <UserHierarchy order={order || []} />
                  <br/>
                  <ProjectAndLineInformation
                    projectRequest={projectRequestOrder || []}
                    typeRequest={order?.projectRequest?.typeRequest}
                  />
                </Content>
              </ContentBox>
              {
                  order?.projectRequest?.typeRequest === 'COMPOSED' &&
                  <ContentRow>
                    <ApportionmentInformation
                      key={uniqueId}
                      data={projectRequestOrder[0] || []}
                      record={order}
                      toggle={statusToggle}
                      resetStatusToggle={setStatusToggle}
                    />
                  </ContentRow>
                }
             </Box> 
            );
          })}
        </Spin>
      </Section>

      <FlexEndContainer className="mt-1">
        <Pagination onChange={pagination.onChange} {...pagination.data} />
      </FlexEndContainer>
      <Chat
        member={member}
        setMember={setMember}
        purchaseOrderId={purchaseOrderId}
        isVisible={isVisibleChat}
        setIsVisible={setIsVisibleChat}
        refreshCallback={getPurchases}
      />
      <AcceptOrRefusedForm
        accept={isAccept}
        purchaseOrderId={purchaseOrderId}
        isVisibleModal={isVisibleAcceptOrRefuseForm}
        setIsVisibleModal={setIsVisibleAcceptOrRefuseForm}
        requestFn={getPurchases}
      />
      <FiltersForm
        resetPagination={resetPagination}
        filters={filters}
        isVisibleDrawer={isVisibleFilterForm}
        setIsVisibleDrawer={setIsVisibleFilterForm}
      />
      <ModalRequests
        title="Requisições"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={requestsColumns}
          dataSource={requestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </ModalRequests>
      <ModalDownloads
        title="Baixar arquivos"
        className="horizontal-scrollable-modal"
        open={isModalDownloadsOpen}
        onOk={() => setIsModalDownloadsOpen(false)}
        onCancel={() => setIsModalDownloadsOpen(false)}
        width={800}
      >
        <Table
          pagination={false}
          dataSource={filesList}
          columns={[
            {
              title: "Nome do arquivo",
              dataIndex: "fileName",
              key: "fileName",
            },
            {
              title: "Formato",
              dataIndex: "type",
              key: "type",
              render(_: any, record: any) {
                return record?.fileName?.split(".").pop();
              },
            },
            {
              title: "",
              dataIndex: "Download",
              key: "download",
              render(_: any, record: any) {
                return (
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    size="small"
                    onClick={() => downloadArchive(record.fileName)}
                    loading={downloadLoading}
                  >
                    Baixar
                  </Button>
                );
              },
            },
          ]}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    size="small"
                    onClick={() => downloadArchive("project-request-files-" + filesListId + "-" + (Date.now()) + ".zip", filesListId)}
                    loading={downloadLoading}
                  >
                    Baixar todos
                  </Button>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </ModalDownloads>
    </>
  );
};

export default OrdersTab;
