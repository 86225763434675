import { Popover as AntPopover } from 'antd'
import React from 'react'

type TProps = {
  setNames: Set<string>
  title: string
  fontSize?: string
  ellipsis?: number
  breakline?: boolean
  showmore?: boolean
  fontColor?: string
}

const Popover = ({
  setNames,
  title,
  fontSize = '16px',
  ellipsis = 70,
  breakline = false,
  showmore = false,
  fontColor= '#000000d9'
}: TProps) => {
  const style = {
    fontSize,
    fontColor
  }

const styleBadge = {
  background: 'black',
  color: 'white',
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '5px',
  fontSize: '11px'
}

const styleNewLine = {
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'context-menu',
  color: fontColor,
}

const styleShowMore = {
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'context-menu'
}

  const truncate = (str: string, n: number) => ((str && str.length > n) ? `${str.slice(0, n - 1)}...` : str)

  const GetFirstName = (props: any) => {

    const iterator = props.set.values()

    if(props.set.size) {
      return <>
        { props.set.size > 1 ?
          <p style={breakline ? styleNewLine : style}>{truncate(breakline ? firstNameNewLine(iterator.next().value) : iterator.next().value, ellipsis)} 
            <span style={styleBadge}>+{props.set.size - 1}</span>
          </p>
        :
          <p style={breakline ? styleNewLine : style}>{truncate(breakline ? firstNameNewLine(iterator.next().value) : iterator.next().value, ellipsis)}</p>
        }
      </>
    } else {
      return <>
        { props.set.length > 1 ?
          <p style={showmore ? styleShowMore : style}>{truncate(iterator.next().value, ellipsis)} 
            <span style={styleBadge}>+{props.set.length - 1}</span>
          </p>
        :
          <p style={showmore ? styleShowMore : style}>{truncate(iterator.next().value, ellipsis)}</p>
        }
      </>
    }

  }

  const replaceNewlineWithBr = (str:string) => {
    return str.replace(/\n/g, '<br />');
  }
  
  const firstNameNewLine = (str:string) => {
    const newString = replaceNewlineWithBr(str);
    return (
      <>
        <span dangerouslySetInnerHTML={{ __html: newString }} />
      </>
    );
  }

  const showMoreItems = (str:string) => {
    // const items = str.split("")
  }

  const getContent = (set: Set<string>) => {
    const getAllNames = (_set: Set<string>) => {
      const iterator = _set.values()
      const arrayStrings: string[] = []
      set.forEach(() => {
        arrayStrings.push(iterator.next().value)
      })
      return arrayStrings
    }

    const namesArray = getAllNames(set)

    return (
      <ul>
        {namesArray.map(name => (
          <li key={name}>
            {name}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <AntPopover content={getContent(setNames)} title={title}>
      <p><GetFirstName set={setNames} /></p>
    </AntPopover>
  )
}


export default Popover
