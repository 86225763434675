import React, { useEffect, useMemo, useState } from 'react'
import type { ColumnsType, TableProps } from 'antd/lib/table'
import {
  Button,
  Divider,
  Space,
  Table,
  Tag
} from 'antd'
import { tableLocale } from 'utils/lang'
import { usePagination } from 'hooks'
import {
  TRequestItem,
  useProduct,
  TProductParams,
  TProduct
} from 'repositories'
import { useNavigate } from 'react-router'

const columns: ColumnsType<TRequestItem> = [
  {
    title: 'Id',
    dataIndex: 'productId',
    key: 'projectRevisionItemId',
  },
  {
    title: 'Produto',
    dataIndex: 'productName',
    key: 'productName',
  },
]

type TProps = {
  requestItems: TRequestItem[]
  availableRequestItems: TRequestItem[]
  setRequestItems: React.Dispatch<TRequestItem[]>
  setAvailableRequestItems: React.Dispatch<TRequestItem[]>
  currentStep: number
  onChangeStep(goTo: number): void
}

const ProductList = (props: TProps) => {
  const {
    requestItems,
    availableRequestItems,
    setRequestItems,
    currentStep,
    onChangeStep,
    setAvailableRequestItems,
  } = props

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [categories, setCategories] = useState<number[]>([])
  const navigate = useNavigate()
  const pagination = usePagination()
  const productRepository = useProduct()

  const onClickGoBackStep = () => navigate('/requisicao-compras')

  const onClickGoNextStep = () => {
    const filteredItems = availableRequestItems.filter(requestItem => (
      selectedRowKeys.includes(requestItem.key)
    ))
    setRequestItems(filteredItems)
    onChangeStep(2)
  }

  const getProducts = async () => {
    const currentPage = pagination.data.current ?? 0
    const params: TProductParams = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1
    }
    const response = await productRepository.findProductByFilter(params)
    if (!response) return availableRequestItems
    const mappedItems: TRequestItem[] = response.content.map((item: TProduct) => ({
      productId: item.id,
      productName: item.description,
      categories: item.categories,
      amount: 0,
      daily: 0,
      key: String(item.id),
      projectId: null,
      projectName: '',
      projectRevisionItemId: null,
      saving: 0,
      totalCost: 0,
      totalSalesWithoutTax: 0,
      unitCost: 0,
      unitSale: 0,
      costCenterName: ''
    }))
    setAvailableRequestItems(mappedItems)
    pagination.changeTotalPages(response.totalPages)
    const newPageSizeOptions = response.totalElements > 10 ? [10, response.totalElements] : [10]
    pagination.changePageSizeOptions(newPageSizeOptions)
  }

  useEffect(() => {
    const mappedRowKeys = requestItems.map(requestItem => requestItem.key)
    setSelectedRowKeys(mappedRowKeys)
  }, [currentStep])



  useEffect(() => {
    if (pagination.data.current) getProducts()
  }, [pagination.data.current, pagination.data.pageSize])

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (!newSelectedRowKeys.length) {
      setSelectedRowKeys(newSelectedRowKeys)
      setCategories([])
      return
    }

    const findedItem = availableRequestItems.find(_item => (
      newSelectedRowKeys.includes(_item.key)
    ))
    if (!findedItem?.categories) return

    const categoriesId = findedItem.categories.map(category => category.id)

    if (!categories.length) setCategories(categoriesId)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const updatedAvailableRequestItems = useMemo(() => {
    if (!selectedRowKeys.length) {
      return availableRequestItems
    }

    if (!categories.length) return availableRequestItems

    return availableRequestItems.filter(item => (
      item.categories?.some(category => categories.includes(category.id))
    ))
  }, [selectedRowKeys, availableRequestItems, currentStep])

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: 'Categorias',
      key: 'categories',
      render: (_, record) => (
        record.categories?.map(category => (
          <Tag key={category.id}>{category.description}</Tag>
        ))
      ),
    }
  ] as ColumnsType<TRequestItem>, [availableRequestItems])

  const handleChange: TableProps<TRequestItem>['onChange'] = paginationConfig => {
    pagination.onChangeTable(paginationConfig)
  }

  const disableGoToNextStep = useMemo(() => !selectedRowKeys.length, [selectedRowKeys])

  return (
    <>
      <Divider />
      <Table
        size='middle'
        locale={tableLocale}
        columns={updatedColumns}
        dataSource={updatedAvailableRequestItems}
        rowSelection={rowSelection}
        pagination={pagination.data}
        onChange={handleChange}
      />
      <Space
        size='small'
        className='mt-1'
      >
        <Button
          type='primary'
          htmlType='submit'
          disabled={disableGoToNextStep}
          onClick={onClickGoNextStep}
        >
          Próximo
        </Button>
        <Button
          onClick={onClickGoBackStep}
          htmlType='button'
        >
          Cancelar
        </Button>
      </Space>
    </>
  )
}

export default ProductList
