import React, { useEffect, useMemo, useState } from 'react'
import type { ColumnsType } from 'antd/lib/table'
import { formatCurrency } from 'utils/helpers'
import {
  Button,
  Space,
  Table
} from 'antd'
import { tableLocale } from 'utils/lang'
import { TRequestItem } from 'repositories'
import { useParams } from 'react-router'
import { DefaultOptionType } from 'antd/lib/select'
import ItemForm from './Form'

const initialColumnsWithProject: ColumnsType<TRequestItem> = [
  {
    title: 'Id',
    dataIndex: 'projectRevisionItemId',
    key: 'projectRevisionItemId',
  },
  {
    title: 'Produto',
    dataIndex: 'productName',
    key: 'productName',
  },
  {
    title: 'Projeto',
    dataIndex: 'projectName',
    key: 'projectName',
  },
]

const initialColumnsWithoutProject: ColumnsType<TRequestItem> = [
  {
    title: 'Produto',
    dataIndex: 'productName',
    key: 'productName',
  },
  {
    title: 'Centro de Custo',
    dataIndex: 'costCenterName',
    key: 'costCenterName',
    align: 'left',
  },
]

const initialColumns: ColumnsType<TRequestItem> = [
  {
    title: 'Diárias',
    dataIndex: 'daily',
    key: 'daily',
    align: 'center',
  },
  {
    title: 'Quantidade',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
  },
  {
    title: 'Custo unitário',
    dataIndex: 'unitCost',
    key: 'unitCost',
    align: 'right',
    render(_, record) {
      return formatCurrency(record.unitCost, false)
    },
  },
  {
    title: 'Saving',
    dataIndex: 'saving',
    key: 'saving',
    align: 'right',
    render(_, record) {
      return formatCurrency(record.saving || 0, false)
    },
  },
  {
    title: 'Custo total',
    dataIndex: 'totalCost',
    key: 'totalCost',
    align: 'right',
    render(_, record) {
      return formatCurrency(record.totalCost, false)
    },
  },
]

const finalColumnsWithProject: ColumnsType<TRequestItem> = [
  {
    title: 'Valor venda',
    dataIndex: 'unitSale',
    key: 'unitSale',
    align: 'right',
    render(_, record) {
      return formatCurrency(record.unitSale, false)
    },
  },
  {
    title: 'Valor total de venda',
    dataIndex: 'totalSalesWithoutTax',
    key: 'totalSalesWithoutTax',
    align: 'right',
    render(_, record) {
      return formatCurrency(record.totalSalesWithoutTax, false)
    },
  },
]

type TProps = {
  isView?: boolean
  isClone?: boolean
  items: TRequestItem[]
  setItems: React.Dispatch<TRequestItem[]>
  status: string
  isRequestNotTiedToProject?: boolean
  costCenterOptions: DefaultOptionType[]
}

const ItemList = (props: TProps) => {
  const {
    isView = false,
    isClone = false,
    items,
    setItems,
    status,
    isRequestNotTiedToProject = false,
    costCenterOptions
  } = props
  const { id } = useParams<{ id: string }>()

  const [columns, setColumns] = useState<ColumnsType<TRequestItem>>(initialColumns)
  const [index, setIndex] = useState(0)
  const [isVisibleForm, setIsVisibleForm] = useState(false)


  const onClickRemove = (key: string) => () => {
    const filterItems = items.filter(item => item.key !== key)
    setItems(filterItems)
  }

  const onClickEdit = (_index: number) => () => {
    setIsVisibleForm(true)
    setIndex(_index)
  }

  const editableIfStatus = ['NEW', 'IN_QUOTATION']

  const actionColumn: ColumnsType<TRequestItem> = [
    {
      title: '',
      key: 'action',
      render: (_, record, _index) => (
        <Space size='small'>
          <Button
            type='text'
            onClick={onClickEdit(_index)}
            disabled={isView || !editableIfStatus.includes(status)}
          >
            Editar
          </Button>
          <Button
            type='text'
            onClick={onClickRemove(record.key)}
            disabled={isView || !isClone}
          >
            Remover
          </Button>
        </Space>
      ),
    }
  ]

  useEffect(() => {
    const initialColumnsUpdated =
      isRequestNotTiedToProject ?
        initialColumnsWithoutProject :
        initialColumnsWithProject

    const finalColumnsUpdated =
      isRequestNotTiedToProject ?
        [] :
        finalColumnsWithProject

    if (!id || isClone) {
      setColumns([...initialColumnsUpdated, ...initialColumns, ...finalColumnsUpdated, ...actionColumn,])
      // setColumns([...initialColumnsUpdated, ...initialColumns, ...actionColumn,])
      return
    }

    const columnsWithAgreement = [
      ...initialColumnsUpdated,
      ...initialColumns,
      ...finalColumnsUpdated,
      {
        title: 'Data do evento',
        dataIndex: 'eventDate',
        key: 'eventDate',
      },
      {
        title: 'Local de entrega',
        dataIndex: 'deliveryLocation',
        key: 'deliveryLocation',
      },
      {
        title: 'Escopo técnico',
        dataIndex: 'technicalScope',
        key: 'technicalScope',
      },
      ...actionColumn
    ] as ColumnsType<TRequestItem>

    setColumns(columnsWithAgreement)
  }, [id, isClone, items])

  const summary = useMemo(() => {
    const summaryData = items?.reduce((acc, curr) => ({
      totalDaily: acc.totalDaily + curr.daily,
      totalAmount: acc.totalAmount + curr.amount,
      totalUnitCost: acc.totalUnitCost + curr.unitCost,
      totalSaving: acc.totalSaving + (curr.saving || 0),
      totalCost: acc.totalCost + curr.totalCost,
      totalUnitSale: acc.totalUnitSale + curr.unitSale,
      totalSale: acc.totalSale + curr.totalSalesWithoutTax,
    }), {
      totalDaily: 0,
      totalAmount: 0,
      totalUnitCost: 0,
      totalSaving: 0,
      totalCost: 0,
      totalUnitSale: 0,
      totalSale: 0,
    })

    const beginSpan = isRequestNotTiedToProject ? 2 : 3
    const endColspan = columns.length === 13 ? 4 : 1

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell
          colSpan={beginSpan}
          index={1}
          align='right'
        >
          Total
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='center'
        >
          {summaryData.totalDaily}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='center'
        >
          {summaryData.totalAmount}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='right'
        >
          {formatCurrency(summaryData.totalUnitCost, false)}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='right'
        >
          {formatCurrency(summaryData.totalSaving, false)}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='right'
        >
          {formatCurrency(summaryData.totalCost, false)}
        </Table.Summary.Cell>
        {!isRequestNotTiedToProject && (
          <>
            <Table.Summary.Cell
              index={2}
              align='right'
            >
              {formatCurrency(summaryData.totalUnitSale, false)}
            </Table.Summary.Cell>
            <Table.Summary.Cell
              index={2}
              align='right'
            >
              {formatCurrency(summaryData.totalSale, false)}
            </Table.Summary.Cell>
          </>
        )}
        <Table.Summary.Cell
          index={7}
          colSpan={endColspan}
        />
      </Table.Summary.Row>
    )
  }, [columns, items, isRequestNotTiedToProject])

  return (
    <>
      <Table
        size='middle'
        locale={tableLocale}
        columns={columns}
        dataSource={items}
        summary={() => summary}
        scroll={{ x: 1500 }}
      />
      <ItemForm
        index={index}
        isClone={isClone}
        setIndex={setIndex}
        isVisible={isVisibleForm}
        setIsVisible={setIsVisibleForm}
        items={items}
        setItems={setItems}
        status={status}
        isRequestNotTiedToProject={isRequestNotTiedToProject}
        costCenterOptions={costCenterOptions}
      />
    </>
  )
}

export default ItemList
