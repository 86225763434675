import styled from 'styled-components'

type TMessageProps = {
  alignRight: boolean
}

export const ChatMessage = styled.div<TMessageProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignRight }) => (alignRight ? 'flex-end' : 'flex-start')};
  
  margin: 10px;

  span {
    max-width: 60%;
    padding: 10px 15px;
    color: ${({ alignRight, theme }) => (alignRight ? theme.colors.WHITE : theme.colors.BLACK)};
    background-color: ${({ alignRight, theme }) => (alignRight ? theme.colors.GREEN : theme.colors.LIGHT_GRAY)};
    border-radius: ${({ alignRight }) => (alignRight ? '8px 0 8px 8px' : '0 8px 8px 8px')};
    overflow-wrap: break-word;
  }

  p {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }

  span {
    font-size: 12px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 5px;
   
    time {
      font-size: 11px;
    }
  }
`
