import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding: 16px;
  align-items: flex-start;
  align-self: stretch;
  width:100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width:100%;
  padding-bottom: 10px;

  text {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #1C1C1C;
  }

  span {
    font-size: 12px;
  }

  button {
    padding: 0;
    margin: 0;
  }

  button svg {
    width: 16px;
    height: 16px;
  }
`

export const Content = styled.div`
  div {
    font-size: 12px;
    overflow: auto;
    width: 100%;
    max-width: 100%;

    .ant-table-cell {
      border-bottom: 1px solid #D4D4D4;
      border-left: 1px solid #D4D4D4;
    }

    .ant-table-thead{
      border-bottom: 1px solid #D4D4D4;
      background: #F7F7F7;
      color: #404040;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .ant-table-cell {
      border: 1px solid rgb(212, 212, 212);
    }

    .ant-table-tbody{
      color: #1C1C1C;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .ant-table-summary {
      .ant-table-cell {
        color: #1C1C1C;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:first-child  {
          color: #F7F7F7;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: #404040;
        }
      }
    }
  }
`