import { useState } from 'react'
import { Sort } from 'utils/constants'

const useFilters = (): TFiltersHook => {
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState<TFilter[]>([])
  const [sort, setSort] = useState<TSort>()

  const getTerm = (field: string) => {
    const filter = filters.find(item => item.field === field)
    return filter?.term ?? ''
  }

  const add = (filtersToAdd: TFilter[]) => {
    setLoading(true)
    const _filters = [...filters, ...filtersToAdd]
    setFilters(_filters)
  }

  const clearAndAdd = (filtersToAdd: TFilter[]) => {
    setLoading(true)
    setFilters(filtersToAdd)
  }

  const remove = (fields: string[]) => {
    setLoading(true)
    const [field1, field2] = fields
    const filtersWithoutFirst = filters.filter(item => (
      field1 !== item.field
    ))
    const filtersWithoutSecond = filtersWithoutFirst.filter(item => (
      field2 !== item.field
    ))
    setFilters(filtersWithoutSecond)
  }

  const customSetSort = (field: string) => {
    if (sort?.field === field) {
      const direction = sort.direction === Sort.ASC
        ? Sort.DESC
        : Sort.ASC

      setSort({ ...sort, direction })
      return
    }

    setSort({ field, direction: Sort.ASC })
  }

  const stopLoading = () => setLoading(false)

  const getObjectifiedFilterItems = () => {
    const getBooleanString = (term: string) => {
      try {
        return JSON.parse(term)
      } catch (_) {
        return term
      }
    }

    const _filters = filters.reduce((acc, curr) => ({
      ...acc, [curr.field]: getBooleanString(curr.term)
    }), {})
    if (!sort) return _filters

    return {
      ..._filters,
      sort: `${sort.field},${sort.direction}`
    }
  }

  return {
    items: filters,
    loading,
    getTerm,
    add,
    clearAndAdd,
    stopLoading,
    getObjectifiedFilterItems,
    remove,
    sort,
    customSetSort,
  }
}

export default useFilters
