import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  Space
} from 'antd'
import { useParams } from 'react-router'
import { TSupplierRequest, useSuppliers } from 'repositories'
import {
  MaskPhone,
  supplierStatusOptions,
  TaxPayer,
  taxPayerOptions,
  YesNo,
  yesNoOptions
} from 'utils/constants'
import RelatedProducts from 'pages/Suppliers/Components/RelatedProducts'
import {
  Col,
  MaskedField,
  Row,
  SelectField,
  TextField
} from 'components'
import type { Rule } from 'antd/lib/form'
import { Content } from '../styles'

type TRegisterStepForm = {
  id?: TStringableNumber
  cnpj: string
  corporateName: string
  fantasyName: string
  principal: string
  email: string
  financialPrincipal: string
  financialPrincipalEmail: string
  telephone: string
  typeSupplier: string
  status: string
  contributorType?: string
  municipalInscription?: string
  stateInscription?: string
  simpleNational?: string
  taxpayer: string
  termsAcceptance: boolean
}

const validations: Record<string, Rule[]> = {
  telephone: [
    { required: true, message: 'Campo "Telefone" é obrigatório' }
  ],
  corporateName: [
    { required: true, message: 'Campo "Razão Social" é obrigatório' }
  ],
  principal: [
    { required: true, message: 'Campo "Nome do responsável" é obrigatório' }
  ],
  email: [
    { required: true, message: 'Campo "Email responsável" é obrigatório' },
    { type: 'email', message: 'Email está inválido' },
  ],
  financialPrincipalEmail: [
    { type: 'email', message: 'Email está inválido' },
  ],
  taxpayer: [
    { required: true, message: 'Campo "Tipo de inscrição" é obrigatório' },
  ],
  simpleNational: [
    { required: true, message: 'Campo "Optante Simples Nacional" é obrigatório' },
  ],
  stateInscription: [
    ({ getFieldValue }) => ({
      validator(_, stateInscription: string) {
        const taxPayer = getFieldValue('taxpayer')
        if (taxPayer === TaxPayer.CONTRIBUTOR && !stateInscription) {
          return Promise.reject(new Error('Campo "Inscrição Estadual" é obrigatório"'))
        }

        return Promise.resolve()
      },
    })
  ],
  municipalInscription: [
    { required: true, message: 'Campo "Inscrição Municípal" é obrigatório' },
  ],
}

type TProps = {
  onChangeStep(value: number): void
}

const Register = ({ onChangeStep }: TProps) => {
  const [supplierData, setSupplierData] = useState<TRegisterStepForm>({} as TRegisterStepForm)
  const { id } = useParams<{ id: string }>()
  const repository = useSuppliers()
  const [form] = Form.useForm<TRegisterStepForm>()
  const telephoneWatch = Form.useWatch('telephone', form)
  const watchedTaxPayer = Form.useWatch('taxpayer', form)

  const onFinish = async (values: TRegisterStepForm) => {
    const data: TSupplierRequest = {
      ...supplierData,
      ...values,
      simpleNational: values.simpleNational === YesNo.YES
    }
    const response = await repository.updateSupplier(data)
    if (!response) return

    onChangeStep(2)
  }

  useEffect(() => {
    const getSupplier = async () => {
      const response = await repository.findSupplierById(Number(id))
      if (!response) return
      const data = {
        ...response,
        simpleNational: response.simpleNational ? YesNo.YES : YesNo.NO
      }

      form.setFieldsValue(data)
      setSupplierData(data)
    }

    getSupplier()
  }, [id])

  const maskPhone = useMemo(() => {
    const removeMaskChar = telephoneWatch?.replace(/_/g, '')
    return removeMaskChar?.length < 15
      ? MaskPhone.TELEPHONE
      : MaskPhone.CELLPHONE
  }, [telephoneWatch])

  return (
    <Content size='large'>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row>
          <Col xl={12}>
            <MaskedField
              required
              disabled
              name='cnpj'
              label='CNPJ'
              mask='99.999.999/9999-99'
            />
          </Col>
          <Col xl={6}>
            <MaskedField
              required
              name='telephone'
              label='Telefone'
              mask={maskPhone}
              rules={validations.telephone}
            />
          </Col>
          <Col xl={6}>
            <SelectField
              required
              disabled
              name='status'
              label='Status'
              options={supplierStatusOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField
              required
              name='corporateName'
              label='Razão Social'
              rules={validations.corporateName}
            />
          </Col>
          <Col xl={12}>
            <TextField
              name='fantasyName'
              label='Nome Fantasia'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <Divider plain orientation='left'>Responsáveis</Divider>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField
              required
              name='principal'
              label='Nome'
              rules={validations.principal}
            />
          </Col>
          <Col xl={12}>
            <TextField
              required
              name='email'
              label='Email'
              rules={validations.email}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField
              name='financialPrincipal'
              label='Responsável financeiro'
            />
          </Col>
          <Col xl={12}>
            <TextField
              name='financialPrincipalEmail'
              rules={validations.financialPrincipalEmail}
              label='Email'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <Divider plain orientation='left'>Informações tributáveis</Divider>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <SelectField
              required
              name='simpleNational'
              label='Optante simples nacional'
              rules={validations.simpleNational}
              options={yesNoOptions}
            />
          </Col>
          <Col xl={12}>
            <SelectField
              required
              name='taxpayer'
              label='Tipo'
              options={taxPayerOptions}
              rules={validations.taxpayer}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField
              maxLength={50}
              required={watchedTaxPayer === TaxPayer.CONTRIBUTOR}
              name='stateInscription'
              label='Inscrição Estadual'
              rules={validations.stateInscription}
            />
          </Col>
          <Col xl={12}>
            <TextField
              required
              maxLength={50}
              name='municipalInscription'
              label='Inscrição Municipal'
              rules={validations.municipalInscription}
            />
          </Col>
        </Row>
        <RelatedProducts
          supplierId={Number(id)}
          isFullWidth
        />
        <Form.Item>
          <Space size='small'>
            <Button
              loading={repository.loading}
              disabled={repository.loading}
              type='primary'
              htmlType='submit'
            >
              Próximo
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  )
}

export default Register
