import React, { useEffect, useMemo, useState } from 'react'
import type { ColumnsType } from 'antd/lib/table'
import { formatCurrency } from 'utils/helpers'
import {
  Button,
  Divider,
  Space,
  Table,
  Tag
} from 'antd'
import { tableLocale } from 'utils/lang'
import { TRequestItem } from 'repositories'

const columns: ColumnsType<TRequestItem> = [
  {
    title: 'Id',
    dataIndex: 'projectRevisionItemId',
    key: 'projectRevisionItemId',
  },
  {
    title: 'Produto',
    dataIndex: 'productName',
    key: 'productName',
  },
  {
    title: 'Projeto',
    dataIndex: 'projectName',
    key: 'projectName',
  },
  {
    title: 'Quantidade',
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
  },
  {
    title: 'Diárias',
    dataIndex: 'daily',
    key: 'daily',
    align: 'right',
  },
  {
    title: 'Valor venda',
    dataIndex: 'unitSale',
    key: 'unitSale',
    align: 'right',
    render(_, record) {
      return formatCurrency(record.unitSale, false)
    },
  },
]

type TProps = {
  isPackage: boolean
  requestItems: TRequestItem[]
  availableRequestItems: TRequestItem[]
  setRequestItems: React.Dispatch<TRequestItem[]>
  currentStep: number
  onChangeStep(goTo: number): void
}

const ItemList = (props: TProps) => {
  const {
    isPackage,
    requestItems,
    availableRequestItems,
    setRequestItems,
    currentStep,
    onChangeStep
  } = props

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [categories, setCategories] = useState<number[]>([])

  const onClickGoBackStep = () => onChangeStep(0)

  const onClickGoNextStep = () => {
    const filteredItems = availableRequestItems.filter(requestItem => (
      selectedRowKeys.includes(requestItem.key)
    ))
    setRequestItems(filteredItems)
    onChangeStep(2)
  }

  useEffect(() => {
    const mappedRowKeys = requestItems.map(requestItem => requestItem.key)
    setSelectedRowKeys(mappedRowKeys)
  }, [currentStep])

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (isPackage) {
      setSelectedRowKeys(newSelectedRowKeys)
      return
    }

    if (!isPackage && !newSelectedRowKeys.length) {
      setSelectedRowKeys(newSelectedRowKeys)
      setCategories([])
      return
    }

    if (!isPackage) {
      const findedItem = availableRequestItems.find(_item => (
        newSelectedRowKeys.includes(_item.key)
      ))
      if (!findedItem?.categories) return

      const categoriesId = findedItem.categories.map(category => category.id)

      if (!categories.length) setCategories(categoriesId)
      setSelectedRowKeys(newSelectedRowKeys)
    }
  }

  const rowSelection = {
    hideSelectAll: !isPackage,
    selectedRowKeys,
    onChange: onSelectChange
  }

  const updatedAvailableRequestItems = useMemo(() => {
    if (isPackage || !selectedRowKeys.length) {
      return availableRequestItems
    }

    if (!categories.length) return availableRequestItems

    return availableRequestItems.filter(item => (
      item.categories?.some(category => categories.includes(category.id))
    ))
  }, [selectedRowKeys])

  const updatedColumns = useMemo(() => {
    if (isPackage) return columns

    return [
      ...columns,
      {
        title: 'Categorias',
        key: 'categories',
        render: (_, record) => (
          record.categories?.map(category => (
            <Tag key={category.id}>{category.description}</Tag>
          ))
        ),
      }
    ] as ColumnsType<TRequestItem>
  }, [])

  const disableGoToNextStep = useMemo(() => {
    if (isPackage) return selectedRowKeys.length <= 1

    return !selectedRowKeys.length
  }, [selectedRowKeys])

  return (
    <>
      <Divider />
      <Table
        size='middle'
        locale={tableLocale}
        columns={updatedColumns}
        dataSource={updatedAvailableRequestItems}
        rowSelection={rowSelection}
      />
      <Space
        size='small'
        className='mt-1'
      >
        <Button
          type='primary'
          htmlType='submit'
          disabled={disableGoToNextStep}
          onClick={onClickGoNextStep}
        >
          Próximo
        </Button>
        <Button
          onClick={onClickGoBackStep}
          htmlType='button'
        >
          Voltar
        </Button>
      </Space>
    </>
  )
}

export default ItemList
