import styled from 'styled-components'

type TSectionProps = {
  spacedTop?: boolean
}

export const Section = styled.section<TSectionProps>`
  padding: 10px 15px;
  margin-top: ${({ spacedTop }) => (spacedTop ? '15px' : '0')};
`
