import React, {
  useEffect,
  useMemo
} from 'react'
import {
  TClientForm,
  useBusinessUnits,
} from 'repositories'
import {
  Section,
  PageHeader,
  Row,
  Col,
  TextField,
  CheckField
} from 'components'
import { cnpj } from 'cpf-cnpj-validator'
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router'
import {
  Button,
  Form,
  Space
} from 'antd'
import { useAuth } from 'hooks'

const initialValues: TClientForm = {
  id: 0,
  erpId: '',
  cnpj: '',
  corporateName: '',
  fantasyName: '',
  email: '',
  responsible: '',
  status: true
}

const ClientForm = () => {
  const { roles: authRoles, hasRole } = useAuth()
  const { pathname } = useLocation()
  const repository = useBusinessUnits()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [form] = Form.useForm<TClientForm>()
  const watchStatus = Form.useWatch('status', form)

  useEffect(() => {
    const getClient = async () => {
      if (!id) return
      const response = await repository.findBusById(id)

      if (!response) return

      const data: any = {
        id: response.id,
        erpId: response.idERP,
        name: response.name,
        status: response.status ? 'Ativo' : 'Inativo'
      }

      form.setFieldsValue(data)
    }

    form.resetFields()
    getClient()
  }, [id])

  const onFinish = (values: TClientForm) => {
    console.warn(values)
  }

  const onClickGoBack = () => {
    navigate('/unidades-de-negocio')
  }

  const {
    title,
    isView
  } = useMemo(() => {
    const [, , page] = pathname.split('/')
    const getTitle = () => {
      if (page === 'visualizar') return 'Visualizar'
      if (id) return 'Editar'

      return 'Nova'
    }

    return {
      title: getTitle(),
      isView: page === 'visualizar'
    }
  }, [id])

  if (authRoles && !hasRole(authRoles?.client_edit || authRoles?.client_view)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <PageHeader
        title='Unidade de Negócio'
        breadcrumbItems={['Unidade de Negócio', title, String(id)]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col xl={4}>
              <TextField
                name='erpId'
                label='Id ERP'
                disabled={isView}
              />
            </Col>
            <Col xl={12}>
              <TextField
                name='name'
                label='Nome da unidade de negócio (BU)'
                disabled={isView}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <TextField
                name='status'
                label='Status'
                disabled={isView}
              />
            </Col>
          </Row>
          <Form.Item>
            <Space size='small'>
              <Button
                onClick={onClickGoBack}
                htmlType='button'
              >
                Voltar
              </Button>
              {/* <Button
                disabled={isView}
                type='primary'
                htmlType='submit'
              >
                Salvar
              </Button> */}
            </Space>
          </Form.Item>
        </Form>
      </Section>
    </>
  )
}

export default ClientForm
