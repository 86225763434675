import React, { useState } from 'react'
import {
  Form,
  Drawer,
  Button,
  Space
} from 'antd'
import { formatDate } from 'utils/helpers'
import {
  CheckField,
  Col,
  DateField,
  HiddenField,
  NumberField,
  Row,
  SearchField,
  SelectField,
  TextField
} from 'components'
import {
  phasesOptions,
  purchaseTypeOptions,
  statusRequestOptions
} from 'utils/constants'
import {
  TClient,
  TProduct,
  TSupplierForm,
  useClient,
  useProduct,
  useSuppliers
} from 'repositories'
import { Moment } from 'moment'

type TFilterForm = {
  purchaseType: string
  projectId: number
  projectErpId: string
  projectRevision: string
  statusRequest: string
  negotiationDeadline: number
  startIssueDate: Moment | string
  endIssueDate: Moment | string
  approvalHierarchySla: number
  productId: number
  productName: string
  clientId: number
  clientName: string
  supplierId: number
  supplierName: string
  phaseId: number
  indicationSingleSupplierJustifiable: boolean
  requireThreeQuotes: boolean
}

type TProps = {
  isVisibleDrawer: boolean
  setIsVisibleDrawer: React.Dispatch<boolean>,
  filters: TFiltersHook
}

const FiltersForm = (props: TProps) => {
  const {
    isVisibleDrawer,
    setIsVisibleDrawer,
    filters,
  } = props
  const [clientValue, setClientValue] = useState('')
  const [clientOptions, setClientOptions] = useState<TSearchOptions[]>([])
  const [clients, setClients] = useState<TClient[]>([])

  const [supplierValue, setSupplierValue] = useState('')
  const [supplierOptions, setSupplierOptions] = useState<TSearchOptions[]>([])
  const [suppliers, setSuppliers] = useState<TSupplierForm[]>([])

  const [productValue, setProductValue] = useState('')
  const [productOptions, setProductOptions] = useState<TSearchOptions[]>([])
  const [products, setProducts] = useState<TProduct[]>([])

  const clientRepository = useClient()
  const supplierRepository = useSuppliers()
  const productRepository = useProduct()
  const [form] = Form.useForm<TFilterForm>()

  const handleClose = () => {
    setIsVisibleDrawer(false)
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
    handleClose()
  }

  const onFinish = (values: TFilterForm) => {
    const formattedValues = {
      ...values,
      startIssueDate: values.startIssueDate ? formatDate(values.startIssueDate) : '',
      endIssueDate: values.endIssueDate ? formatDate(values.endIssueDate) : '',
    }
    const _values = Object.entries(formattedValues)

    const filterNonNullValues = _values.filter(value => {
      const [, term] = value
      return Boolean(term)
    })
    const _filters = [] as TFilter[]
    filterNonNullValues.forEach(value => {
      const [field, term] = value
      _filters.push({ field, term: String(term) })
    })

    filters.clearAndAdd(_filters)
  }

  const onChangeClient = (data: string) => {
    const findClient = clients.find(client => client.corporateName === data)
    if (!findClient) return

    setClientValue(data)
    form.setFieldsValue({ clientId: findClient.id })
  }

  const onSearchClient = async (searchText: string) => {
    const response = await clientRepository.findClientByFilter({
      corporateName: searchText,
      page: 0,
      size: 10
    })
    if (!response) return

    const mappedClients = response.content.map(item => ({ value: item.corporateName }))
    setClientOptions(mappedClients)
    setClients(response.content)
  }

  const onChangeSupplier = (data: string) => {
    const findSupplier = suppliers.find(supplier => supplier.corporateName === data)
    if (!findSupplier) return

    setSupplierValue(data)
    form.setFieldsValue({ supplierId: Number(findSupplier.id) })
  }

  const onSearchSupplier = async (searchText: string) => {
    const response = await supplierRepository.findSupplierByFilter({
      corporateName: searchText,
      page: 0,
      size: 10
    })
    if (!response) return

    const mappedSuppliers = response.content.map(item => ({ value: item.corporateName }))
    setSupplierOptions(mappedSuppliers)
    setSuppliers(response.content)
  }

  const onChangeProduct = (data: string) => {
    const findProduct = products.find(product => product.description === data)
    if (!findProduct) return

    setProductValue(data)
    form.setFieldsValue({ productId: Number(findProduct.id) })
  }

  const onSearchProduct = async (searchText: string) => {
    const response = await productRepository.findProductByFilter({
      description: searchText,
      page: 0,
      size: 10
    })
    if (!response) return

    const mappedProducts = response.content.map(item => ({ value: item.description }))
    setProductOptions(mappedProducts)
    setProducts(response.content)
  }

  const onClickResetFilters = () => {
    filters.clearAndAdd([])
    form.resetFields()
  }

  return (
    <Drawer
      title='Filtros'
      width={500}
      onClose={handleClose}
      visible={isVisibleDrawer}
      destroyOnClose
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row>
          <Col xl={12}>
            <SelectField
              name='purchaseType'
              label='Tipo de compra'
              options={purchaseTypeOptions}
            />
          </Col>
          <Col xl={12}>
            <SelectField
              name='phaseId'
              label='Fase'
              options={phasesOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <DateField
              label='Filtrar de'
              name='startIssueDate'
            />
          </Col>
          <Col xl={12}>
            <DateField
              name='endIssueDate'
              label='Filtrar até'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={8}>
            <SelectField
              name='statusRequest'
              label='Status'
              options={statusRequestOptions}
            />
          </Col>
          <Col xl={8}>
            <NumberField
              name='approvalHierarchySla'
              label='SLA'
              max={999}
            />
          </Col>
          <Col xl={8}>
            <NumberField
              name='negotiationDeadline'
              label='Prazo de Negociação'
              max={9999}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField
              name='projectErpId'
              label='ID Projeto (ERP)'
            />
          </Col>
          <Col xl={12}>
            <TextField
              name='projectRevision'
              label='Versão (ERP)'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <HiddenField
              name='clientId'
            />
            <SearchField
              name='clientName'
              label='Cliente'
              placeholder='Pesquise pela razão social'
              isLoading={clientRepository.loading}
              onChange={onChangeClient}
              onSearch={onSearchClient}
              value={clientValue}
              options={clientOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <HiddenField
              name='productId'
            />
            <SearchField
              name='productName'
              label='Produto'
              isLoading={productRepository.loading}
              placeholder='Pesquise pela descrição'
              onChange={onChangeProduct}
              onSearch={onSearchProduct}
              value={productValue}
              options={productOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <HiddenField
              name='supplierId'
            />
            <SearchField
              name='supplierName'
              label='Fornecedor'
              isLoading={supplierRepository.loading}
              placeholder='Pesquise pela razão social'
              onChange={onChangeSupplier}
              onSearch={onSearchSupplier}
              value={supplierValue}
              options={supplierOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <CheckField
              name='requireThreeQuotes'
              label='Requer três cotações'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <CheckField
              name='indicationSingleSupplierJustifiable'
              label='Indicação de fornecedor único'
            />
          </Col>
        </Row>
        <Form.Item className='mt-1'>
          <Space size='small'>
            <Button
              onClick={handleSubmit}
              type='primary'
            >
              Filtrar
            </Button>
            <Button
              onClick={onClickResetFilters}
            >
              Limpar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FiltersForm
