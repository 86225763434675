import React, { useEffect, useState } from 'react'
import {
  Form,
  Drawer,
  Button,
  Space
} from 'antd'
import {
  Col,
  CurrencyField, MaskedField,
  Row,
  SelectField
} from 'components'
import {filterListSupplierOptions, supplierStatusOptions} from 'utils/constants'
import { formatCurrencyToServer } from 'utils/helpers'
import TextField from "../../../../components/Form/TextField";
import { DefaultOptionType } from 'antd/lib/select'
import { useProduct } from 'repositories'

type TFilterListFilterSupplierParamsForm = {
  status: string
  cnpj: string
  corporateName: string
  fantasyName: string
  typeSupplier: string
}

const initialValues: TFilterListFilterSupplierParamsForm = {
  status: '',
  cnpj: '',
  corporateName: '',
  fantasyName: '',
  typeSupplier: '',
}

type TProps = {
  isVisibleDrawer: boolean
  setIsVisibleDrawer: React.Dispatch<boolean>,
  filters: TFiltersHook
}

const FiltersForm = (props: TProps) => {

  const productRepository = useProduct()
  const [optionsProduct, setProductOptions] = useState<DefaultOptionType[]>([]);

  const {
    isVisibleDrawer,
    setIsVisibleDrawer,
    filters,
  } = props
  const [form] = Form.useForm<TFilterListFilterSupplierParamsForm>()
  


  const handleClose = () => {
    setIsVisibleDrawer(false)
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
    handleClose()
  }

  useEffect(() => {
    requestProductOptions()
    if (!filters.items?.length) form.resetFields()
  }, [filters.items])

  const onFinish = (values: TFilterListFilterSupplierParamsForm) => {
    const _values = Object.entries(values)

    const filterNonNullValues = _values.filter(value => {
      const [, term] = value
      return Boolean(term)
    })
    const _filters = [] as TFilter[]
    filterNonNullValues.forEach(value => {
      const [field, term] = value
      _filters.push({ field, term: String(term) })
    })

    filters.clearAndAdd(_filters)
  }

  const requestProductOptions = async () => {
    const response = await productRepository.findProductByFilter({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.id, label: item.description }));
    mapped.sort((a, b) => a.label.localeCompare(b.label));

    if (!response) return;
    setProductOptions(mapped);
  };

  const onClickResetFilters = () => filters.clearAndAdd([])

  return (
    <Drawer
      title='Filtros'
      width={500}
      onClose={handleClose}
      visible={isVisibleDrawer}
      destroyOnClose
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col xl={12}>
            <MaskedField
                name='cnpj'
                label='CNPJ'
                mask='99.999.999/9999-99'
            />
          </Col>
          <Col xl={12}>
            <TextField
              name='corporateName'
              label='Razão Social'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              name='fantasyName'
              label='Nome Fantasia'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <SelectField
              name='status'
              label='Status'
              options={supplierStatusOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <SelectField
              name='typeSupplier'
              label='Tipo'
              options={filterListSupplierOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SelectField options={optionsProduct} name="productIds" label="Produto" />
          </Col>
        </Row>
        <Form.Item className='mt-1'>
          <Space size='small'>
            <Button
              onClick={handleSubmit}
              type='primary'
            >
              Filtrar
            </Button>
            <Button
              onClick={onClickResetFilters}
            >
              Limpar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FiltersForm
