import React, { useMemo, useState } from 'react'
import moment from 'moment'
import {
  Button,
  Divider,
  Space,
  Table
} from 'antd'
import {
  TPaymentScheduleData,
  TPurchaseOrderItems
} from 'repositories'
import { tableLocale } from 'utils/lang'
import { Col, Row } from 'components'
import type { ColumnsType } from 'antd/lib/table'
import { formatCurrency } from 'utils/helpers'
import { DefaultOptionType } from 'antd/lib/select'
import ItemForm from './ItemForm'
import PaymentSchedule from './PaymentSchedule'

type TPurchaseOrderItemsData = TPurchaseOrderItems & {
  key: string
}

const columns: ColumnsType<TPurchaseOrderItemsData> = [
  {
    title: 'Percentual (%)',
    dataIndex: 'percentage',
    key: 'percentage',
    align: 'right',
    render: (_, record) => formatCurrency(record.percentage, false)
  },
  {
    title: 'Valor',
    dataIndex: 'value',
    key: 'value',
    align: 'right',
    render: (_, record) => formatCurrency(record.value, false)
  },
  {
    title: 'Periodo de cobrança',
    dataIndex: 'intervalPaymentDate',
    key: 'intervalPaymentDate',
    render: (_, record) => {
      const formattedStartDate = moment(record.startDatePayment).format('MM/YYYY')
      const formattedEndDate = moment(record.endDatePayment).format('MM/YYYY')

      return `De ${formattedStartDate} até ${formattedEndDate}`
    }
  }
]

type TProps = {
  purchaseValue: number
  payDay: number
  productId: number
  items: TPurchaseOrderItems[]
  setItems: React.Dispatch<TPurchaseOrderItems[]>
  apportions: TPaymentScheduleData[]
  isView: boolean
  isRequestNotTiedToProject?: boolean
  costCenters: DefaultOptionType[]
}

const ItemList = (props: TProps) => {
  const {
    purchaseValue,
    payDay,
    productId,
    items,
    setItems,
    isView,
    apportions,
    isRequestNotTiedToProject = false,
    costCenters
  } = props

  const [isVisibleForm, setIsVisibleForm] = useState(false)
  const [selectedItemId, setItemId] = useState(0)

  const onAdd = () => {
    setItemId(0)
    setIsVisibleForm(true)
  }

  const onClickRemoveItem = (record: TPurchaseOrderItemsData) => () => {
    const filterItems = isRequestNotTiedToProject
      ? items.filter(item => item.costCenterId !== record.costCenterId)
      : items.filter(item => item.projectId !== record.projectId)
    setItems(filterItems)
  }

  const onClickEditItem = (record: TPurchaseOrderItemsData) => () => {
    isRequestNotTiedToProject
      ? setItemId(Number(record.costCenterId))
      : setItemId(record.projectId)
    setIsVisibleForm(true)
  }

  const sumData = useMemo(() => (
    items?.reduce((acc, curr) => ({
      totalValue: acc.totalValue + curr.value,
      totalPercentage: acc.totalPercentage + curr.percentage,
    }), {
      totalPercentage: 0,
      totalValue: 0,
    })
  ), [items])

  const summary = useMemo(() => {
    const formattedTotalPercentage = formatCurrency(sumData?.totalPercentage, false)
    const formattedTotalValue = formatCurrency(sumData?.totalValue, false)

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell
          index={1}
          align='right'
        />
        <Table.Summary.Cell
          index={2}
          align='right'
        >
          {formattedTotalPercentage}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={3}
          align='right'
        >
          {formattedTotalValue}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={4}
          colSpan={2}
        />
      </Table.Summary.Row>
    )
  }, [sumData])

  const updatedItems = useMemo(() => (
    items?.map((item, index) => ({
      ...item,
      key: String(index)
    })) as TPurchaseOrderItemsData[]
  ), [items])

  const updatedColumns = useMemo(() => [
    {
      ...isRequestNotTiedToProject
        ? {
          title: 'Centro de custo',
          dataIndex: 'costCenterName',
          key: 'costCenterName',
          render: (_, record) => (
            record.costCenter
              ? `[${record.costCenter?.erpId}] ${record.costCenterName}`
              : record.costCenterName
          ),
        } : {
          title: 'Projeto',
          dataIndex: 'projectName',
          key: 'projectName',
        }
    },
    ...columns,
    {
      title: (
        <Button
          type='primary'
          onClick={onAdd}
          disabled={isView}
        >
          Adicionar
        </Button>
      ),
      key: 'action',
      render: (_, record) => (
        <Space size='small'>
          <Button
            type='text'
            onClick={onClickEditItem(record)}
            disabled={isView}
          >
            Editar
          </Button>
          <Button
            type='text'
            onClick={onClickRemoveItem(record)}
            disabled={isView}
          >
            Remover
          </Button>
        </Space>
      ),
    }
  ] as ColumnsType<TPurchaseOrderItemsData>, [updatedItems, isView, isRequestNotTiedToProject])

  return (
    <>
      <Row>
        <Col>
          <Divider
            plain
            orientation='left'
          >
            {isRequestNotTiedToProject
              ? 'Centro de custos'
              : 'Projetos'}
          </Divider>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            sticky
            size='middle'
            scroll={{ x: 1400 }}
            columns={updatedColumns}
            locale={tableLocale}
            dataSource={updatedItems}
            summary={() => summary}
          />
        </Col>
      </Row>
      <PaymentSchedule
        schedule={apportions}
      />
      <ItemForm
        items={items}
        costCenters={costCenters}
        productId={productId}
        itemId={selectedItemId}
        isVisible={isVisibleForm}
        setIsVisible={setIsVisibleForm}
        purchaseValue={purchaseValue}
        payDay={payDay}
        setItems={setItems}
        isRequestNotTiedToProject={isRequestNotTiedToProject}
      />
    </>
  )
}

export default ItemList
