import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Main = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.GRAY_50};
`

type TContentProps = {
  isSidebarOpen: boolean
}

export const Content = styled.article<TContentProps>`
  height: calc(100vh - 52px);
  margin-left: ${({ isSidebarOpen }) => (
    isSidebarOpen ? '240px' : '70px'
  )};
 
 width: ${({ isSidebarOpen }) => (
    isSidebarOpen ? 'calc(100vw - 240px)' : 'calc(100vw - 70px)'
  )};
  
  overflow-y: auto;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MD}) {
    margin-left: 0;
    width: 100vw;
  }
`
