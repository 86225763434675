import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

type TContainerProps = {
  isSidebarOpen: boolean
}

export const Container = styled.aside<TContainerProps>`
  height: calc(100vh - 52px);
  border-right: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  background-color: ${({ theme }) => theme.colors.WHITE};
  position: absolute;
  padding: 10px 0;
  width: 240px;
  z-index: 999;

  svg {
    width: 20px;
    height: 20px;
  }

  ${({ isSidebarOpen }) => isSidebarOpen && css`
    div div a svg {
      display: none;
    }
  `}

  ${({ isSidebarOpen }) => !isSidebarOpen && css`
    width: 70px;

    a span,
    div div span {
      display: none;
    }
    
    a,
    div div {
      justify-content: center;
    }

    div div:hover svg:last-child {
      display: unset !important;
    }
  `}

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MD}) {
    ${({ isSidebarOpen }) => !isSidebarOpen && css`
      display: unset;
      width: 240px;
      position: relative;

      a span,
      div div span {
        display: unset;
      }

      a {
        justify-content: flex-start;
      }
    `};

    display: ${({ isSidebarOpen }) => (isSidebarOpen ? 'unset' : 'none')};
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 8px;
  margin: 5px 8px;

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.MD}) {
    display: none;
  }
`

type TItemProps = {
  $active?: boolean
}

export const Item = styled(Link)<TItemProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.BLACK};
  padding: 8px;
  margin: 5px 8px;
  border-radius: 8px;
  
  svg, > div {
    width: 25px;
    text-align: center;
  }

  background-color: ${({ $active, theme }) => (
    $active ? theme.colors.LIGHT_GRAY : 'transparent'
  )};
  
  &:hover {
    color: ${({ theme }) => theme.colors.BLACK};
    background-color: ${({ theme }) => theme.colors.LIGHT_GRAY};
  }
`

export const Divider = styled.div`
  border-top: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  margin: 10px 0;
`
