import React from 'react'
import { Row as RowComponent } from 'antd'

type TProps = {
  children?: React.ReactNode
}

const Row = ({ children }: TProps) => (
  <RowComponent gutter={[8, 8]}>
    {children}
  </RowComponent>
)

export default Row
