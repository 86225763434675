import React from 'react'
import {
  Logo,
  Title
} from './styles'

const LogoWithTitle = () => (
  <Title>
    <Logo />
    <span>Manager</span>
  </Title>
)

export default LogoWithTitle
