import React from 'react'
import { Col as ColComponent } from 'antd'

type TProps = {
  md?: number
  xs?: number
  xl?: number
  xxl?: number
  children: React.ReactNode
}

const Col = ({ children, xs = 24, md = 24, xl = 24, xxl = 24 }: TProps) => (
  <ColComponent
    xs={xs}
    sm={12}
    md={md}
    xl={xl}
    xxl={xxl}
  >
    {children}
  </ColComponent>
)

export default Col
