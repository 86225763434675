import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 5px;
`
export const LineWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin-bottom: 8px;
`;

export  const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;

  span {
    font-size: 12px;
  }

  button {
    padding: 0;
    margin: 0;
  }

  button svg {
    width: 16px;
    height: 16px;
  }
`

export const Content = styled.div`
  div {
    font-size: 12px;
  }

  text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 2.2rem;
  }

  input {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #F7F7F7;

    color: #404040;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-form {
    max-height:220px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 1.25rem;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-form-item-label {
    label {
      color: #404040;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
    }
  }
`
