import React, { useEffect, useState } from "react";
import { Steps, message } from "antd";
import { PageHeader, Section } from "components";

import { useNavigate } from "react-router";

import useRequest from "../../../repositories/useRequest";
import FormProvider from "./FormProvider";
import ProjectSelectionRequest from "./ProjectSelection";
import ProductSelectionRequest from "./LinesSelection";
import FormApportionment from "./FormApportionment";
import FormFreelancers from "./Freelancers";

interface Installment {
  value: string;
  dueDate: string;
}

const PurchaseWithApportionment = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const navigate = useNavigate();

  const [project, setProject] = useState<any>([]);
  const [totalValue, setTotalValue] = useState('');
  const [installments, setInstallments] = useState<Installment[]>([{ value: '', dueDate: '' }]);
  const [projectIds, setProjectIds] = useState([]);
  const [dataFormApportionment, setSelectDataFormApportionment] = useState({
    totalValue: "",
    objectiveFunction: "",
    justifyIfItIsAnEmergency: "",
    JustifyUnapprovedSupplier: "",
    JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
    JustifyTheDueDateBeingShorter: "",
    indicationSingleSupplierJustifiable: "",
    installments: [],
    btnStatus: 1,
    files: ""
  });

  const [dataFormProvider, setSelectDataFormProvider] = useState({
    typeRequest: "",
    category: "",
    allocation: "",
    status: "",
    timing: "",
    productSelection: "",
    businessUnits: "",
    providerType: 0,
    seniority: "",
    emission: "",
    startDate: "",
    endDate: "",
    TotalTime: "",
    ExpenseOrInvestment: "",
    unitMinimunPrice: "",
    unitMaximunPrice: "",
  });
  const [products, setProducts] = useState([]);
  const [lines, setLines] = useState([]);
  const [keysSelected, setKeysSelected] = useState([]);
  const [linessSelected, setLinessSelected] = useState([]);
  const [supplier, setSupplier] = useState<any>({});
  const [BU, setBU] = useState([]);
  const [dataFreelancers, setDataFreelancers] = useState<any>({});

  const [items, setItems] = useState([
    {
      title: "Prestador e Produto",
      description: "Base para o rateio",
    },
    {
      title: "Seleção de projetos",
      description: "Selecione os projetos",
    },
    {
      title: "Linhas dos projetos",
      description: "Escolha as linhas / produtos",
    },
    {
      title: "Rateio e cronograma",
      description: "Itens do rateio e cronograma de pgto",
    },
  ]);

  const repository = useRequest();


  const handleStepsUp = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleStepsDown = () => {
    setCurrentStep(currentStep - 1);
  };

  const selectProject = (project: any) => {
    setProject(project);
  };

  const selectInstallments = (installments: any) => {
    setInstallments(installments);
  };

  const selectTotalValue = (value: any) => {
    setTotalValue(value);
  };

  const selectProjectIds = (project: any) => {
    setProjectIds(project);
  };

  const selectProduct = (products: any) => {
    if (products) {
      setProducts(products);
    } else {
      setProducts([]);
    }
  };

  const onSelectKeys = (keys: any) => {
    setKeysSelected(keys)
  }

  const selectLines = (lines: any) => {
    if (lines) {
      setLines(lines);
    } else {
      setLines([]);
    }
  };

  const selectSupplier = (supplier: any) => {
    if (supplier) {
      setSupplier(supplier);
    } else {
      setSupplier([]);
    }
  };
  const selectBU = (supplier: any) => {
    if (supplier) {
      setBU(supplier);
    } else {
      setBU([]);
    }
  };

  const selectDataFormProvider = (data: any) => {
    if (data) {
      setSelectDataFormProvider(data);
    } else {
      setSelectDataFormProvider({
        typeRequest: "",
        category: "",
        allocation: "",
        status: "",
        timing: "",
        productSelection: "",
        businessUnits: "",
        providerType: 0,
        seniority: "",
        emission: "",
        startDate: "",
        endDate: "",
        TotalTime: "",
        ExpenseOrInvestment: "",
        unitMinimunPrice: "",
        unitMaximunPrice: "",
      });
    }
  };
  const selectDataFormApportionment = (data: any) => {
    if (data) {
      setSelectDataFormApportionment(data);
    } else {
      setSelectDataFormApportionment({
        totalValue: "",
        objectiveFunction: "",
        justifyIfItIsAnEmergency: "",
        JustifyUnapprovedSupplier: "",
        JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
        JustifyTheDueDateBeingShorter: "",
        indicationSingleSupplierJustifiable: "",
        installments: [],
        btnStatus: 1,
        files: "",
      });
    }
  };

  const handleProviderType = (providerType: any) => {
    if (providerType !== 1 && items.length === 5) {
      const novoArray = items.slice(0, -1);
      setItems(novoArray);
    } else if (items.length === 4 && providerType === 1) {
      setItems([
        ...items,
        {
          title: "Freelancers",
          description: "Preencha o formulário Funcional",
        },
      ]);
    }
  };

  const createRequest = async () => {
    const formData = new FormData();

    const timing = Number(dataFormProvider.timing) === 1 ? "PLANEJADA" : "EMERGENCIAL"

    formData.append("issueDate", dataFormProvider.emission);
    formData.append("isRequestNotTiedToProject", "true"); // fixo
    formData.append("paymentType", "TV1"); // fixo
    formData.append("purchaseType", "V"); // fixo
    formData.append("suppliersId", supplier.id); // revisar
    formData.append("serviceStartDate", dataFormProvider.startDate);
    formData.append("serviceEndDate", dataFormProvider.endDate);
    formData.append("approvalHierarchyId", '12'); // fixo
    formData.append("negotiationDeadline", "0"); // fixo
    formData.append("requireThreeQuotes", "false"); // fixo
    formData.append(
        "indicationSingleSupplierJustifiable",
        dataFormApportionment.indicationSingleSupplierJustifiable ? "true" : "false"
    );
    formData.append("subsidiaryId", project[0]?.subsidiaryIdErp);
    formData.append("totalCost", totalValue?.replaceAll('.', '')?.replace(',', '.'));
    formData.append("totalApportions", String(installments.length));
    formData.append("projectIds", projectIds.toString());
    formData.append("items", JSON.stringify(dataFormApportionment.installments));
    formData.append("timing", timing);
    formData.append("maturity", installments[0].dueDate);
    formData.append("typeProvider", String(dataFormProvider.providerType === 2 ? "SUPPLIER" : "FREELANCE"));
    formData.append("category", "Rateios e Contratos");
    formData.append("allocation", "Com projeto");
    formData.append("typeRequest", "COMPOSED"); // fixo
    formData.append("hiringPurpose",  dataFormApportionment.objectiveFunction);
    formData.append(
        "justifyChoiceIfRequestForEmergency",
        dataFormApportionment.justifyIfItIsAnEmergency
    );
    formData.append(
        "justifyChoiceSupplierNotHomologated",
        dataFormApportionment.JustifyUnapprovedSupplier
    );
    formData.append(
        "justifyReasonStartDateBeforeDateIssuance",
        dataFormApportionment.JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate
    );
    formData.append(
        "justifyDueDatePayment",
        dataFormApportionment.JustifyTheDueDateBeingShorter
    );

    if (dataFreelancers) {
      formData.append(
          "projectRequestFreelancers",
          JSON.stringify(dataFreelancers)
      );
    }

    for (let i = 0; i < dataFormApportionment.files.length; i++) {
      formData.append("files", dataFormApportionment.files[i]);
    }

    const headers = {
      "content-type": "multipart/form-data",
    };
    try {
      const request = await repository.createRequestsFormData(
          formData,
          headers
      );
      await repository.startRequests({
        projectRequestId: request?.id,
      });
      message.success(`Requisição #${request?.id} adicionada com sucesso!`);
      navigate(`/`);
    } catch (error) {}
  }

  useEffect(() => {
    if (dataFreelancers && dataFreelancers.btnStatus === "save") {
      createRequest();
    }
  }, [dataFreelancers]);

  useEffect(() => {
    if (dataFormProvider.providerType === 2 && dataFormApportionment.btnStatus !== 1) {
      createRequest();
    }
  }, [dataFormApportionment]);

  const handleFreelancersData = (freelancersData: any) => {
    if (freelancersData) {
      const mappedFreelancersData: any = {
        needSupportFromTv1corporateTI:
            freelancersData?.needSupportFromTv1corporateTI === "sim"
                ? true
                : false,
        useTv1Email: freelancersData?.useTv1Email === "sim" ? true : false,
        equipmentFreelancers: freelancersData?.equipmentFreelancers,
        equipmentTypeFreelancers: freelancersData?.equipmentTypeFreelancers,
        workplace: freelancersData?.workplace,
        accessInternalSystem: freelancersData?.accessInternalSystem,
        othersAccessInternalSystem: freelancersData?.othersAccessInternalSystem,

        otherMarketSoftware: freelancersData?.otherMarketSoftware,
        freelanceDirectManager: freelancersData?.freelanceDirectManager,

        contractStartDate:
            freelancersData?.contractStartDate.format("YYYY-MM-DD"),
        contractEndDate: freelancersData?.contractEndDate.format("YYYY-MM-DD"),

        includeRenewal: freelancersData?.includeRenewal,
        btnStatus: freelancersData.btnStatus,
      };
      setDataFreelancers(mappedFreelancersData);
    } else {
      setDataFreelancers({});
    }
  };

  const steps = [
    <FormProvider
      nextStep={handleStepsUp}
      prevStep={handleStepsDown}
      dataForm={dataFormProvider}
      selectDataForm={selectDataFormProvider}
      handleProviderType={handleProviderType}
      productSelected={selectProduct}
      supplierSelected={selectSupplier}
      BUSelected={selectBU}
    />,
    <ProjectSelectionRequest
      nextStep={handleStepsUp}
      prevStep={handleStepsDown}
      product={products}
      BU={BU}
      projectSelected={selectProject}
      projectSelectedIds={selectProjectIds}
    />,
    <ProductSelectionRequest
      nextStep={handleStepsUp}
      prevStep={handleStepsDown}
      linesSelected={selectLines}

      onSetKeys={setKeysSelected}
      onSetLines={setLinessSelected}
      keys={keysSelected}
      lines={linessSelected}

      product={products}
      BU={BU}
      projectIds={projectIds}
      projects={project}
      currentProducts={products}
    />,
    <FormApportionment
      dataForm={dataFormProvider}
      dataFormApportionment={dataFormApportionment}
      selectDataFormApportionment={selectDataFormApportionment}
      selectTotalValue={selectTotalValue}
      installmentsEdit={installments}
      selectInstallments={selectInstallments}
      products={products}
      lines={lines}
      BU={BU}
      nextStep={handleStepsUp}
      prevStep={handleStepsDown}
      loading={repository.loading}
    />,
    <FormFreelancers
      dataForm={dataFormProvider}
      freelancersData={handleFreelancersData}
      nextStep={handleStepsUp}
      prevStep={handleStepsDown}
      loading={repository.loading}
    />,
  ];

  return (
    <>
      <PageHeader
        title="Requisições"
        breadcrumbItems={[
          "Requisições composta",
          "Atrelada a Projeto",
          "Rateios e Contratos",
          "Nova",
        ]}
      />
      <Section>
        <Steps current={currentStep} items={items} />
        {steps[currentStep] || null}
      </Section>
    </>
  );
};

export default PurchaseWithApportionment;
