import useRest from 'hooks/useRest'
import { useState } from 'react'
import {
  TAnalyzeQuote,
  TCreateProjectRequest,
  TFilterProjectRequestParams,
  TFilterRequestHistoricParams,
  TProjectRequestListResponse,
  TProjectRequestOutput,
  TProjectRequestResponse,
  TProjectRequestStartQuotation,
  TRequestHistoricResponse,
  TUpdateProjectRequest,
  TUpdateProjectRequestBuyer,
  TUpdateProjectRequestItemBuyer
} from 'repositories'

const useProjectRequest = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findProjectRequestByFilter = async (
    params: TFilterProjectRequestParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-request'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TProjectRequestResponse

    return response.result as TProjectRequestResponse
  }

  const findProjectRequestListByFilter = async (
    params: TFilterProjectRequestParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-request/list-request/filter'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TProjectRequestListResponse

    return response.result as TProjectRequestListResponse
  }

  const findRequestHistoricByFilter = async (
    params: TFilterRequestHistoricParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-request/historic'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TRequestHistoricResponse

    return response.result as TRequestHistoricResponse
  }

  const findAnalyzeQuoteById = async (
    id: number,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/project-request/analyze/quotes/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TAnalyzeQuote

    return response.result as TAnalyzeQuote
  }

  const findProjectRequestById = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/project-request/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TProjectRequestOutput

    return response.result as TProjectRequestOutput
  }

  const createProjectRequest = async (data: TCreateProjectRequest, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-request'

    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TProjectRequestOutput
  }

  const updateProjectRequest = async (data: TUpdateProjectRequest, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-request'
    const response = await rest.put(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TProjectRequestOutput
  }

  const updateProjectRequestBuyer = async (data: TUpdateProjectRequestBuyer, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-request/buyer'
    const response = await rest.put(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TProjectRequestOutput
  }

  const updateProjectRequestItemBuyer = async (data: TUpdateProjectRequestItemBuyer, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-request/item/buyer'
    const response = await rest.put(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TProjectRequestOutput
  }

  const startQuotation = async (data: TProjectRequestStartQuotation, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-request/start'
    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return false

    return true
  }

  const sendApprovedRequestToERP = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/project-request/send-to-erp/${id}`
    const response = await rest.post(url)
    if (triggerLoading) setLoading(false)
    if (!response) return false

    return true
  }

  const cancelProjectRequest = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/project-request/cancel/${id}`
    const response = await rest.put(url)
    if (triggerLoading) setLoading(false)
    if (!response) return false

    return true
  }

  return {
    loading,
    findProjectRequestByFilter,
    findProjectRequestListByFilter,
    findRequestHistoricByFilter,
    findAnalyzeQuoteById,
    findProjectRequestById,
    createProjectRequest,
    updateProjectRequest,
    updateProjectRequestBuyer,
    updateProjectRequestItemBuyer,
    sendApprovedRequestToERP,
    startQuotation,
    cancelProjectRequest,
  }
}

export default useProjectRequest
