import React, { useEffect, useState } from 'react'
import {
  Form,
  message,
  Modal
} from 'antd'
import {
  TApproveSupplier,
  TSupplierForm,
  TSupplierRequest,
  useSuppliers
} from 'repositories'
import {
  Col,
  Row,
  SelectField
} from 'components'
import {
  analysisSupplierOptions,
  StatusApproveSupplier,
  YesNo
} from 'utils/constants'
import { useAuth } from 'hooks'

type TAnalysisForm = {
  supplierId: number
  status: string
  fiscalAnalysis: string
  financialAnalysis: string
}

const initialValues: TAnalysisForm = {
  supplierId: 0,
  status: '',
  fiscalAnalysis: StatusApproveSupplier.NOT_INFORMED,
  financialAnalysis: StatusApproveSupplier.NOT_INFORMED,
}

type TProps = {
  supplierId: number
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<boolean>
  requestFn(): Promise<void>
}

const AnalysisForm = (props: TProps) => {
  const {
    supplierId,
    isModalVisible,
    setIsModalVisible,
    requestFn
  } = props
  const [supplier, setSupplier] = useState<TSupplierForm>({} as TSupplierForm)
  const { hasRole, roles: authRoles } = useAuth()
  const [form] = Form.useForm<TSupplierForm>()
  const repository = useSuppliers()

  const handleClose = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
  }

  const onFinish = async (values: TSupplierForm) => {
    const data: TApproveSupplier = {
      supplierId,
      financialAnalysis: values.financialAnalysis,
      fiscalAnalysis: values.fiscalAnalysis
    }

    const response = await repository.approveSupplier(data)
    if (!response) return

    const dataUpdate: TSupplierRequest = {
      ...supplier,
      simpleNational: supplier.simpleNational === 'YES',
      status: response.status
    }

    const responseUpdate = await repository.updateSupplier(dataUpdate)
    if (!responseUpdate) return

    message.success('Forncedor atualizado com sucesso!')
    handleClose()
    requestFn()
  }

  useEffect(() => {
    const getSupplier = async () => {
      const response = await repository.findSupplierById(String(supplierId))
      if (!response) return

      const supplierData: TSupplierForm = {
        id: response.id,
        cnpj: response.cnpj,
        corporateName: response.corporateName,
        fantasyName: response.fantasyName,
        principal: response.principal,
        email: response.email,
        financialPrincipal: response.financialPrincipal,
        financialPrincipalEmail: response.financialPrincipalEmail,
        telephone: response.telephone,
        fiscalAnalysis: response.fiscalAnalysis,
        financialAnalysis: response.financialAnalysis,
        status: response.status,
        typeSupplier: response.typeSupplier,
        taxpayer: response.taxpayer,
        simpleNational: response.simpleNational ? YesNo.YES : YesNo.NO,
        stateInscription: response.stateInscription ?? '',
        municipalInscription: response.municipalInscription ?? '',
        bankId: response.bankAccount?.bank.id,
        bankName: response.bankAccount?.bank.name,
        bankAccount: response.bankAccount?.number,
        bankAccountDigit: response.bankAccount?.digit,
        bankAgency: response.bankAccount?.branch.split('-')[0],
        bankAgencyDigit: response.bankAccount?.branch.split('-')[1],
        bankAccountId: response.bankAccount?.id,
        legalPersonAccount: (
          response.bankAccount?.legalPersonAccount
            ? YesNo.YES
            : YesNo.NO
        ),
        isForeign: response.supplierAddresses?.foreigner,
        termsAcceptance: response.termsAcceptance,
        zipCode: response.supplierAddresses?.zipCode ?? '',
        publicPlace: response.supplierAddresses?.publicPlace ?? '',
        number: response.supplierAddresses?.number ?? '',
        complement: response.supplierAddresses?.complement ?? '',
        neighborhood: response.supplierAddresses?.neighborhood ?? '',
        city: response.supplierAddresses?.city ?? '',
        state: response.supplierAddresses?.state ?? '',
        country: response.supplierAddresses?.country ?? '',
        addressId: response.supplierAddresses?.id,
        categories: response.categories,
        products: [],
        businessContacts: response.businessContacts
      }

      const data: TAnalysisForm = {
        supplierId,
        status: response.status,
        fiscalAnalysis: response.fiscalAnalysis,
        financialAnalysis: response.financialAnalysis
      }

      setSupplier(supplierData)
      form.setFieldsValue(data)
    }
    if (isModalVisible && supplierId) getSupplier()
  }, [supplierId, isModalVisible])

  const isAllowedAnalyzeSupplier = !hasRole(
    authRoles?.supplier_approve_fiscal ||
    authRoles?.supplier_approve_financial
  )

  return (
    <Modal
      title='Análise fiscal e financeira'
      okText='Salvar'
      visible={isModalVisible}
      confirmLoading={repository.loading}
      okButtonProps={{
        disabled: isAllowedAnalyzeSupplier
      }}
      cancelText='Fechar'
      onOk={handleSubmit}
      onCancel={handleClose}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col xl={12}>
            <SelectField
              required
              name='fiscalAnalysis'
              label='Análise Fiscal'
              disabled={!hasRole(authRoles?.supplier_approve_fiscal)}
              options={analysisSupplierOptions}
            />
          </Col>
          <Col xl={12}>
            <SelectField
              required
              name='financialAnalysis'
              label='Análise Financeira'
              disabled={!hasRole(authRoles?.supplier_approve_financial)}
              options={analysisSupplierOptions}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default AnalysisForm
