import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  gap: 5px;
  float: right;
  margin-bottom: 10px;
`;
export const WrapperDrop = styled.span`
  .ant-dropdown-menu-item-group-title {
    padding: none !important;
  }
`;

export const Drp = styled.span`
  && {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 5px 15px !important;
    }

    .ant-collapse a,
    .ant-collapse button {
      margin-bottom: 5px !important;
      width: 100% !important;
    }

    .ant-collapse-content-box {
      padding: 5px !important;
    }

    .ant-collapse-content-box button:last-child,
    .ant-collapse-content-box a:last-child {
      margin-bottom: 0 !important;
    }

    .ant-collapse-header {
      background: #019973 !important;
    }

    .ant-collapse-content-box .ant-collapse-header {
      background: #01c494 !important;
    }

    .ant-collapse-header span {
      color: white !important;
    }
  }
`;
