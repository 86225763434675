import React, { useEffect, useMemo, useState } from 'react'
import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import {
  TSupplierQuoteDetail,
  TSupplierQuoteItem
} from 'repositories'
import { formatCurrency, convertStringToNumber, convertNumberToInt } from 'utils/helpers'
import ItemForm from './ItemForm'


type TSupplierQuoteItemData = TSupplierQuoteItem & {
  key: string
  index: number
}

const columns: ColumnsType<TSupplierQuoteItemData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Projeto',
    dataIndex: 'projectName',
    key: 'projectName',
    render(_, record) {
      return record.project?.projectName
    }
  },
  {
    title: 'Produto',
    dataIndex: 'productDescription',
    key: 'productDescription'
  },
  {
    title: 'Quantidade',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
  },
  {
    title: 'Diárias',
    dataIndex: 'daily',
    key: 'daily',
    align: 'center',
  },
  {
    title: 'Valor',
    dataIndex: 'unitValue',
    key: 'unitValue',
    align: 'right',
    render: (_, record) => formatCurrency(record.unitValue, false)
  },
  {
    title: 'Total',
    dataIndex: 'totalValue',
    key: 'totalValue',
    align: 'right',
    render: (_, record) => formatCurrency(record.totalValue, false)
  },
  {
    title: 'Data de entrega',
    dataIndex: 'eventDate',
    key: 'eventDate',
  },
  {
    title: 'Local de entrega',
    dataIndex: 'deliveryLocation',
    key: 'deliveryLocation',
  },
  {
    title: 'Observações',
    dataIndex: 'observation',
    key: 'observation',
    width: '30%'
  },
]

type TProps = {
  isView: boolean
  quote: TSupplierQuoteDetail
  initialQuoteItems: TSupplierQuoteItemData[]
  setQuote: React.Dispatch<TSupplierQuoteDetail>
}

const ItemList = (props: TProps) => {
  const {
    isView,
    quote,
    setQuote,
    initialQuoteItems,
  } = props
  const [itemQuoteIndex, setItemQuoteIndex] = useState(0)
  const [isVisibleItemForm, setIsVisibleItemForm] = useState(false)
  const [quoteItems, setQuoteItems] = useState<TSupplierQuoteItemData[]>([])

  const onEdit = (index: number) => () => {
    setItemQuoteIndex(index)
    setIsVisibleItemForm(true)
  }

  useEffect(() => {
    setQuoteItems(initialQuoteItems)
  }, [initialQuoteItems])

  useEffect(() => {
    setQuote({
      ...quote,
      items: quoteItems
    })
  }, [quoteItems])

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_, record, index) => (
        <Button
          onClick={onEdit(index)}
          disabled={!isView}
          size='small'
          type='text'
        >
          Editar
        </Button>
      ),
    }
  ], [quoteItems])

  const summary = useMemo(() => {
    const summaryData = quoteItems.reduce((acc, curr) => ({
      totalAmount: acc.totalAmount + convertNumberToInt(curr.amount),
      totalDaily: acc.totalDaily + convertNumberToInt(curr.daily),
      totalUnitValue: convertStringToNumber(acc.totalUnitValue) + convertStringToNumber(curr.unitValue),
      totalValue: convertStringToNumber(acc.totalValue) + convertStringToNumber(curr.totalValue),
    }), {
      totalAmount: 0,
      totalDaily: 0,
      totalUnitValue: 0,
      totalValue: 0
    })

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell
          colSpan={3}
          index={1}
          align='right'
        >
          Total
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='center'
        >
          {summaryData.totalAmount}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='center'
        >
          {summaryData.totalDaily}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='right'
        >
          {formatCurrency(summaryData.totalUnitValue, false)}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='right'
        >
          {formatCurrency(summaryData.totalValue, false)}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={7}
          colSpan={4}
        />
      </Table.Summary.Row>
    )
  }, [quoteItems])

  return (
    <>
      <Table
        size='middle'
        columns={updatedColumns}
        dataSource={quoteItems}
        pagination={false}
        summary={() => summary}
      />
      <ItemForm
        quoteItems={quoteItems}
        setQuoteItems={setQuoteItems}
        isVisibleModal={isVisibleItemForm}
        setIsVisibleModal={setIsVisibleItemForm}
        itemQuoteIndex={itemQuoteIndex}
        setItemQuoteIndex={setItemQuoteIndex}
      />
    </>
  )
}

export default ItemList
