import React, { useEffect, useMemo, useState } from "react";
import {
  TProductForm,
  TUpdateProduct,
  useProduct,
  useUsers,
} from "repositories";
import {
  Section,
  PageHeader,
  Row,
  Col,
  TextField,
  CheckField,
  NumberField,
  SelectField,
  CurrencyField,
  MaskedField,
} from "components";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import { Button, Form, message, Select, Space } from "antd";
import { Rule } from "antd/lib/form";
import { useAuth } from "hooks";
import {
  formatCurrency,
  formatStringToNumber,
  convertStringToNumber,
} from "utils/helpers";
import { WrapperMoney } from "./styles";

const { Option } = Select;

const expensesOrInvestmentOptions = [
  {
    value: "Sim",
    label: "Despesa",
  },
  {
    value: "Não",
    label: "Investimento",
  },
];

const productTypeOptions = [
  {
    value: "MC",
    label: "Commodities",
  },
  {
    value: "CE",
    label: "Estratégico",
  },
];

const initialValues = {
  id: 0,
  erpId: "",
  description: "",
  status: true,
  formattedStatus: "",
  financialAccountErp: "",
  financialAccountId: "",
  financialAccount: "",
  measuringUnitErp: "",
  measuringUnitId: "",
  measuringUnit: "",
  productTypeErp: "",
  productTypeId: "",
  productType: "",
  mandatoryContract: false,
  mandatoryQuotation: false,
  preciary: false,
  expensesOrInvestment: "",
  minimumPaymentTermFreelance: "",
  minimumPaymentTermSupplier: "",
  minimumPrice: "",
  maximumPrice: "",
  optionCategories: [],
  categories: [],
};

const validations: Record<string, Rule[]> = {
  minimumPaymentTermFreelance: [
    { required: true, message: "Prazo mínimo é obrigatório" },
  ],
  minimumPaymentTermSupplier: [
    { required: true, message: "Prazo mínimo é obrigatório" },
  ],
  minimumPrice: [
    () => ({
      validator(_, minimumValue: string) {
        const formattedValue = formatStringToNumber(minimumValue);
        if (!minimumValue || formattedValue <= 0) {
          return Promise.reject(
            new Error("Valor mínimo deve ser maior que zero")
          );
        }

        return Promise.resolve();
      },
    }),
  ],
  maximumPrice: [
    () => ({
      validator(_, minimumValue: string) {
        const formattedValue = formatStringToNumber(minimumValue);
        if (!minimumValue || formattedValue <= 0) {
          return Promise.reject(
            new Error("Valor máximo deve ser maior que zero")
          );
        }

        return Promise.resolve();
      },
    }),
    ({ getFieldValue }) => ({
      validator(_, maximumPrice: string) {
        const minimumPrice = formatStringToNumber(
          getFieldValue("minimumPrice")
        );
        const _maximumPrice = formatStringToNumber(maximumPrice);
        if (_maximumPrice < minimumPrice) {
          return Promise.reject(
            new Error("Valor máximo não pode ser inferior que valor mínimo")
          );
        }

        return Promise.resolve();
      },
    }),
  ],
};

const ProductForm = () => {
  const [product, setProduct] = useState({} as any);
  const { roles: authRoles, hasRole } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const repository = useProduct();
  const repoUsers = useUsers();
  const [form] = Form.useForm();
  const [leaderOptions, setLeaderOptions] = useState<any>([]);

  const isPreciary = Form.useWatch("preciary", form);

  const getProduct = async () => {
    if (!id) return;
    const response: any = await repository.findProductById(id);
    const hasProduct = await repository.findHasProduct(id);
    // const r = await repository.findProductTypeByFilter();
    const users: any = await repoUsers.findByFilterUsers({ size: 10000 });
    if (!response) return;

    const mappedOptionsLeader = users.content
      .filter((user: any) => user.approver)
      .map((user: any) => {
        return {
          value: user.id,
          label: user.name,
        };
      });
      

    setLeaderOptions([{value: null, label: 'Sem responsável'}, ...mappedOptionsLeader]);

    const data = {
      ...initialValues,
      id: response?.id,
      erpId: response?.erpId,
      description: response?.description,
      formattedStatus: response?.status ? "Ativo" : "Inativo",
      financialAccountErp: response?.financialAccount?.erpId,
      financialAccountId: response?.financialAccount?.id,
      financialAccount: response?.financialAccount?.description,
      measuringUnitErp: response?.measuringUnit?.unitKey,
      measuringUnitId: String(response?.measuringUnit?.id),
      measuringUnit: response?.measuringUnit?.description,
      productTypeErp: response?.productType?.typeKey,
      productTypeId: String(response?.productType?.id),
      productType: response?.productType?.typeKey,
      status: response?.status,
      mandatoryContract: response?.mandatoryContract,
      mandatoryQuotation: response?.mandatoryQuotation,
      preciary: response?.preciary,
      preciarySupplier: hasProduct,
      expensesOrInvestment: response?.expensesOrInvestment,
      minimumPaymentTermFreelance: response?.minimumPaymentTermFreelance,
      minimumPaymentTermSupplier: response?.minimumPaymentTermSupplier,
      minimumPrice: response?.minimumPrice,
      maximumPrice: response?.maximumPrice,
      minimumPriceJunior: response?.minimumPriceJunior,
      maximumPriceJunior: response?.maximumPriceJunior,
      minimumPricePleno: response?.minimumPricePleno,
      maximumPricePleno: response?.maximumPricePleno,
      minimumPriceSenior: response?.minimumPriceSenior,
      maximumPriceSenior: response?.maximumPriceSenior,
      tecLeaderId: response?.tecLeader?.id ? response?.tecLeader?.id : null,
      categories: response?.categories || [],
      optionCategories:
        response?.categories?.map((category: any) => (
          <Option key={category.description}>{category.description}</Option>
        )) || [],
    };
    setProduct(data);
    form.setFieldsValue(data);
  };

  useEffect(() => {
    form.resetFields();
    getProduct();
  }, [id]);

  const onFinish = async (values: any) => {
    // console.log(values);
    const data = {
      id: Number(id),
      erpId: product.erpId,
      status: product.status,
      categories: product.categories,
      description: product.description,
      financialAccount: product.financialAccountErp,
      // productType: product.productTypeErp,
      productType: values.productType,
      measuringUnit: product.measuringUnitErp,
      mandatoryContract: values.mandatoryContract,
      mandatoryQuotation: values.mandatoryQuotation,
      minimumPaymentTermFreelance: values.minimumPaymentTermFreelance,
      minimumPaymentTermSupplier: values.minimumPaymentTermSupplier,
      tecLeaderId: values.tecLeaderId,

      minimumPrice: values.preciary
        ? formatStringToNumber(String(values.minimumPrice))
        : 0,
      maximumPrice: values.preciary
        ? formatStringToNumber(String(values.maximumPrice))
        : 0,

      preciary: values.preciary,
      preciarySupplier: values.preciarySupplier,

      expensesOrInvestment: values.expensesOrInvestment,

      minimumPriceJunior: values.preciary
        ? formatStringToNumber(values.minimumPriceJunior)
        : 0,
      maximumPriceJunior: values.preciary
        ? formatStringToNumber(values.maximumPriceJunior)
        : 0,
      minimumPricePleno: values.preciary
        ? formatStringToNumber(values.minimumPricePleno)
        : 0,
      maximumPricePleno: values.preciary
        ? formatStringToNumber(values.maximumPricePleno)
        : 0,
      minimumPriceSenior: values.preciary
        ? formatStringToNumber(values.minimumPriceSenior)
        : 0,
      maximumPriceSenior: values.preciary
        ? formatStringToNumber(values.maximumPriceSenior)
        : 0,
    };

    // console.log(values.maximumPriceSenior);

    const response = await repository.updateProduct(data);
    if (!response) return;

    navigate("/produtos");
    message.success("Produto atualizado com sucesso!");
  };

  const changeMandatoryQuotation = (e: any) => {
    if (e.target.checked) {
      form.setFieldValue("preciary", false);
    }
  };

  const onClickGoBack = () => {
    navigate("/produtos");
  };

  const { title, isView } = useMemo(() => {
    const [, , page] = pathname.split("/");
    const getTitle = () => {
      if (page === "visualizar") return "Visualizar";
      if (id) return "Editar";

      return "Novo";
    };

    return {
      title: getTitle(),
      isView: page === "visualizar",
    };
  }, [id]);

  if (
    authRoles &&
    !hasRole(authRoles?.product_edit || authRoles?.product_view)
  ) {
    return <Navigate to="/acesso-negado" />;
  }

  return (
    <>
      <PageHeader
        title={`${title} Produto`}
        breadcrumbItems={["Produtos", title, String(id)]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col xl={8}>
              <TextField disabled name="description" label="Descrição" />
            </Col>
            <Col xl={4}>
              <TextField disabled name="formattedStatus" label="Status" />
            </Col>
            <Col xl={4}>
              <TextField disabled name="erpId" label="ERP Id" />
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <TextField
                disabled
                name="measuringUnit"
                label="Unidade de medida"
              />
            </Col>
            <Col xl={8}>
              {/* <TextField
                disabled
                name='productType'
                label='Tipo do produto/serviço'
              /> */}
              <SelectField
                name="productType"
                label="Tipo do produto/serviço"
                disabled={isView}
                options={productTypeOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <SelectField
                name="tecLeaderId"
                label="Lider técnico do produto (Requisições em projetos ou BUs)"
                disabled={isView}
                options={leaderOptions}
              />
            </Col>
            <Col xl={8}>
              <SelectField
                name="liderCorporativo"
                label="Lider técnico do produto (Requisições no corporativo)"
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col xl={10}>
              <TextField
                disabled
                name="financialAccount"
                label="Conta Contabil"
              />
            </Col>
            <Col xl={6}>
              <TextField disabled name="financialAccountErp" label="N° Conta" />
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <SelectField
                disabled
                mode="multiple"
                name="optionCategories"
                label="Categorias"
              />
            </Col>
            <Col xl={8}>
              {/* <TextField
                disabled
                name="expensesOrInvestment"
                label="Pode ser despesa ou investimento?"
              /> */}
              <SelectField
                name="expensesOrInvestment"
                label="Pode ser CAPEX/OPEX ?"
                disabled={isView}
                options={expensesOrInvestmentOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <NumberField
                required
                disabled={isView}
                name="minimumPaymentTermFreelance"
                label="Prazo mínimo de pagamento para freelancer"
                rules={validations.minimumPaymentTermFreelance}
              />
            </Col>
            <Col xl={8}>
              <NumberField
                required
                disabled={isView}
                name="minimumPaymentTermSupplier"
                label="Prazo mínimo de pagamento para fornecedor"
                rules={validations.minimumPaymentTermFreelance}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <CheckField
                disabled={isView}
                name="mandatoryContract"
                label="Contrato Obrigatório"
              />
            </Col>
            <Col xl={4}>
              <CheckField
                disabled={isView}
                name="mandatoryQuotation"
                label="Cotação obrigatória?"
                onChange={(e) => changeMandatoryQuotation(e)}
              />
            </Col>
            <Col xl={4}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.mandatoryQuotation !==
                  currentValues.mandatoryQuotation
                }
              >
                {({ getFieldValue }) => (
                  <CheckField
                    disabled={isView || getFieldValue("mandatoryQuotation")}
                    name="preciary"
                    label="Preciário Freelancer?"
                  />
                )}
              </Form.Item>
            </Col>
            <Col xl={5}>
              <CheckField
                disabled
                name="preciarySupplier"
                label="Preciário Fornecedor?"
              />
            </Col>
          </Row>
          {isPreciary && (
            <>
              <Row>
                <Col xl={4}>Preciários</Col>
                <Col xl={6}>Valor mínimo do preciário</Col>
                <Col xl={6}>Valor máximo preciário</Col>
              </Row>
              <Row>
                <Col xl={4}>
                  <Button disabled htmlType="button" style={{ width: "100%" }}>
                    Freelancer / Junior
                  </Button>
                </Col>
                <Col xl={6}>
                  <WrapperMoney>
                    <span>R$</span>
                    <CurrencyField
                      required
                      disabled={isView}
                      name="minimumPriceJunior"
                    />
                  </WrapperMoney>
                </Col>
                <Col xl={6}>
                  <WrapperMoney>
                    <span>R$</span>
                    <CurrencyField
                      required
                      disabled={isView}
                      name="maximumPriceJunior"
                    />
                  </WrapperMoney>
                </Col>
              </Row>
              <Row>
                <Col xl={4}>
                  <Button disabled htmlType="button" style={{ width: "100%" }}>
                    Freelancer / Pleno
                  </Button>
                </Col>
                <Col xl={6}>
                  <WrapperMoney>
                    <span>R$</span>
                    <CurrencyField
                      required
                      disabled={isView}
                      name="minimumPricePleno"
                    />
                  </WrapperMoney>
                </Col>
                <Col xl={6}>
                  <WrapperMoney>
                    <span>R$</span>
                    <CurrencyField
                      required
                      disabled={isView}
                      name="maximumPricePleno"
                    />
                  </WrapperMoney>
                </Col>
              </Row>
              <Row>
                <Col xl={4}>
                  <Button disabled htmlType="button" style={{ width: "100%" }}>
                    Freelancer / Sênior
                  </Button>
                </Col>
                <Col xl={6}>
                  <WrapperMoney>
                    <span>R$</span>
                    <CurrencyField
                      required
                      disabled={isView}
                      name="minimumPriceSenior"
                    />
                  </WrapperMoney>
                </Col>
                <Col xl={6}>
                  <WrapperMoney>
                    <span>R$</span>
                    <CurrencyField
                      required
                      disabled={isView}
                      name="maximumPriceSenior"
                    />
                  </WrapperMoney>
                </Col>
              </Row>
              {/* <Row>
                <Col xl={8}>
                  <CurrencyField
                    required
                    disabled={isView}
                    name='minimumPrice'
                    label='Valor mínimo preciário'
                    rules={validations.minimumPrice}
                  />
                </Col>
                <Col xl={8}>
                  <CurrencyField
                    required
                    disabled={isView}
                    name='maximumPrice'
                    label='Valor máximo preciário'
                    rules={validations.maximumPrice}
                  />
                </Col>
              </Row> */}
            </>
          )}
          <Form.Item>
            <Space size="small">
              <Button onClick={onClickGoBack} htmlType="button">
                Voltar
              </Button>
              <Button
                loading={repository.loading}
                disabled={isView || repository.loading}
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Section>
    </>
  );
};

export default ProductForm;
