import styled from 'styled-components'

type TFlexContainerProps = {
  $spaced?: boolean
}

export const FlexContainer = styled.div<TFlexContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: ${({ $spaced }) => ($spaced ? '10px 20px' : '0')};

  > button {
    width: 150px;
  }

  > div {
    display: flex;
  }
`

type TFlexEndContainerProps = {
  $spaced?: boolean
}

export const FlexEndContainer = styled.div<TFlexEndContainerProps>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ $spaced }) => ($spaced ? '20px 0' : '0')};
  gap: 5px;
`

type TFlexCenterContainerProps = {
  $spaced?: boolean
}

export const FlexCenterContainer = styled.div<TFlexCenterContainerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ $spaced }) => ($spaced ? '20px' : '0')};
`
