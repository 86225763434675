import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
  }

  html, body {
    font-family: 'Inter', sans-serif;
    -webkit-text-size-adjust: 100%;
  }

  body,
  input,
  textarea,
  select,
  button {
    font: 400 1rem 'Inter', sans-serif;
  }

  body {
    margin: 0;
  }

  .mt-1 {
    margin-top: 10px;
  }
  
  .mb-1 {
    margin-bottom: 10px;
  }
`
