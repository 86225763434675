import { useState } from 'react'
import useRest from 'hooks/useRest'
import { TSuggestPurchasePackageOutputDTO } from 'repositories'

type TSuggestPurchasePackageInputDTO = {
  dateOfSale?: string
}

const usePurchasePackage = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findPackageByFilter = async (
    params: TSuggestPurchasePackageInputDTO,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/purchase-package/suggest'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return [] as TSuggestPurchasePackageOutputDTO[]

    return response.result as TSuggestPurchasePackageOutputDTO[]
  }

  return {
    loading,
    findPackageByFilter
  }
}

export default usePurchasePackage
