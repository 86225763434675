import { useEffect, useState } from 'react'
import { TablePaginationConfig } from 'antd'

const defaultPagination: TablePaginationConfig = {
  defaultCurrent: 1,
  current: 1,
  total: 1,
  pageSize: 10,
  disabled: false,
  showSizeChanger: true,
  pageSizeOptions: [5, 10, 20, 50],
  size: 'default',
  showQuickJumper: true
}

const usePagination = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination)

  const buildPaginationConfig = () => ({
    ...pagination,
    locale: {
      items_per_page: 'por página',
      jump_to: 'Ir para',
      page: 'Página'
    }
  } as TablePaginationConfig)

  useEffect(() => {
    const _pagination = buildPaginationConfig()
    setPagination(_pagination)
  }, [])

  const onChangeTable = (paginationConfig: TablePaginationConfig) => {
    const _pagination: TablePaginationConfig = {
      ...paginationConfig,
      current: paginationConfig.current ?? 0
    }

    setPagination(_pagination)
  }

  const onChange = (page: number, pageSize: number) => {
    const _pagination = buildPaginationConfig()
    setPagination({
      ..._pagination,
      current: page,
      pageSize
    })
  }

  const changeTotalPages = (totalPages: number) => {
    setPagination(old => ({ ...old, total: totalPages * Number(old?.pageSize) }))
  }

  const changePageSizeOptions = (pageSizeOptions: number[]) => {
    setPagination(old => ({ ...old, pageSizeOptions }))
  }

  const setDefaultPagination = () => {
    const _pagination = {
      ...buildPaginationConfig(),
      ...defaultPagination
    }
    setPagination(_pagination)
  }

  return {
    data: pagination,
    onChange,
    setPagination,
    setDefaultPagination,
    onChangeTable,
    changeTotalPages,
    changePageSizeOptions
  }
}

export default usePagination
