import React, { useEffect, useMemo, useState } from 'react'
import type { ColumnsType, TableProps } from 'antd/lib/table'
import {
  Button,
  Input,
  Table,
  Tag
} from 'antd'
import { Navigate, useNavigate } from 'react-router'
import {
  PageHeader,
  Section
} from 'components'
import {
  TClient,
  TClientParams,
  useClient
} from 'repositories'
import {
  usePagination,
  useAuth
} from 'hooks'
import { cnpj } from 'cpf-cnpj-validator'
import { SorterResult } from 'antd/lib/table/interface'
import { FilterContainer } from './styles'
import {removeMaskCnpj} from 'utils/helpers'

type TClientData = Pick<TClient, 'id' | 'cnpj' | 'corporateName' | 'status'> & {
  key: string
}

const columns: ColumnsType<TClientData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: 'ID TOTVS',
    dataIndex: 'erpId',
    key: 'erpId',
    sorter: true,
    align: 'center'
  },
  {
    title: 'Nome Fantasia',
    dataIndex: 'fantasyName',
    key: 'fantasyName',
    align: 'center',
    sorter: true
  },
  {
    title: 'Razão Social',
    dataIndex: 'corporateName',
    key: 'corporateName',
    sorter: true,
    align: 'center'
  },
  {
    title: 'Cnpj',
    dataIndex: 'cnpj',
    key: 'cnpj',
    sorter: true,
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: (_, record) => {
      if (record.status) return <Tag color='green'>Ativo</Tag>

      return <Tag color='red'>Bloqueado</Tag>
    },
  }
]

const ClientList = () => {
  const [clients, setClients] = useState<TClientData[]>([])
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('')

  const { roles: authRoles, hasRole } = useAuth()
  const repository = useClient()
  const navigate = useNavigate()
  const pagination = usePagination()


  const requestClient = async () => {
    const currentPage = pagination.data.current ?? 0
    const params: TClientParams = {
      search: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
    }

    const response = await repository.findClientByFilter(params)
    if (!response) return

    const mappedData: TClientData[] = response.content.map(client => ({
      key: String(client.id),
      erpId: client.erpId,
      fantasyName: client.fantasyName,
      id: client.id,
      cnpj: cnpj.format(client.cnpj),
      corporateName: client.corporateName,
      status: client.status
    }))
    setClients(mappedData)
    pagination.changeTotalPages(response.totalPages)
  }

  const handleChange: TableProps<TClientData>['onChange'] = (paginationConfig, filters, sorter) => {
    pagination.onChangeTable(paginationConfig)
    const { field, order } = sorter as SorterResult<TClientData>
    if (!field) return

    const dir = order === 'ascend' ? 'ASC' : 'DESC'
    setSort(`${field},${dir}`)
  }

  useEffect(() => {
    if (pagination.data.current) requestClient()
  }, [pagination.data.current, pagination.data.pageSize, sort, search])

  const onClickGoToView = (id: number) => () => navigate(`/clientes/visualizar/${id}`)

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Button
          onClick={onClickGoToView(record.id)}
          disabled={!hasRole(authRoles?.client_view)}
          size='small'
          type='text'
        >
          Visualizar
        </Button>
      ),
    }
  ], [authRoles])

  const onSearch = (value: string) => {
    setSearch(removeMaskCnpj(value))
    pagination.onChange(1, pagination.data.pageSize ?? 1)
  }

  if (authRoles && !hasRole(authRoles?.client_view)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <PageHeader
        title='Clientes'
        breadcrumbItems={['Clientes', 'Listar']}
      />
      <Section>
        <FilterContainer>
          <Input.Search
            placeholder='Pesquise pela razão social'
            onSearch={onSearch}
            enterButton
          />
        </FilterContainer>
        <Table
          size='middle'
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={clients}
          pagination={pagination.data}
        />
      </Section>
    </>
  )
}

export default ClientList
