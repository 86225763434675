import axios, { AxiosRequestConfig } from 'axios'

export const storageKey = 'ManagerTV1:data'

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const storageData = localStorage.getItem(storageKey)

    if (storageData && config.headers) {
      const { token } = JSON.parse(storageData)
      config.headers.Authorization = `Bearer ${token}`

      return config
    }

    return config
  },
  error => Promise.reject(error)
)

export default api
