import React from 'react'
import { Form, InputNumber } from 'antd'
import type { Rule } from 'antd/lib/form'

const style = {
  width: '100%'
}

type TProps = {
  name: string
  label?: string
  rules?: Rule[]
  disabled?: boolean
  required?: boolean
  bordered?: boolean
  textHelp?: string
  placeholder?: string
  min?: number
  max?: number
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void,
  onKeyDown: any
}

const NumberField = (props: any) => {
  const {
    name,
    label,
    rules,
    disabled = false,
    required = false,
    bordered = true,
    placeholder = '',
    textHelp,
    min = 1,
    max = 9999,
    onBlur,
    onKeyDown,
    ...rest
  } = props

  return (
    <Form.Item
      required={required}
      name={name}
      id={name}
      label={label}
      help={textHelp}
      rules={rules}
      {...rest}
    >
      <InputNumber
        min={min}
        max={max}
        bordered={bordered}
        disabled={disabled}
        placeholder={placeholder}
        style={style}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    </Form.Item>
  )
}

export default NumberField
