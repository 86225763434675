import styled from 'styled-components'

export const FilterContainer = styled.div`
    width: 100%;
    ul {
        display: flex;
        justify-content: space-evenly;
        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            &:first-of-type {
                &::after {
                    content: 'Ruim';
                    display:block;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                }
            }
            &:last-of-type {
                &::after {
                    content: 'Excelente';
                    display:block;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                }
            }
        }
    }
    .ant-rate {
        font-size: 40px;
    }
`