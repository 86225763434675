import React, { useMemo } from 'react'
import {
  Form,
  message,
  Modal,
} from 'antd'
import {
  AreaField,
  Col,
  Row
} from 'components'
import {
  TAssessPurchaseOrder,
  usePurchaseOrder
} from 'repositories'

import type { Rule } from 'antd/lib/form'

const initialValues: TAssessPurchaseOrder = {
  purchaseOrderId: 0,
  approverAnswerDate: '',
  approvedAnswer: '',
  observation: '',
  id: 0
}

type TProps = {
  accept: boolean
  purchaseOrderId: number
  isVisibleModal: boolean
  setIsVisibleModal: React.Dispatch<boolean>,
  requestFn(): Promise<void>
}

const AcceptOrRefusedForm = (props: TProps) => {
  const {
    accept,
    purchaseOrderId,
    isVisibleModal,
    setIsVisibleModal,
    requestFn
  } = props
  const [form] = Form.useForm<TAssessPurchaseOrder>()
  const purchaseRepository = usePurchaseOrder()

  const handleClose = () => {
    setIsVisibleModal(false)
    form.resetFields()
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
    handleClose()
  }

  const onFinish = async (values: TAssessPurchaseOrder) => {
    const params = {
      purchaseOrderId,
      approverAnswer: accept ? 'APPROVED' : 'REFUSED',
      observation: values.observation
    }

    const response = await purchaseRepository.assessPurchaseOrder(params)
    if (!response) return

    if (accept){
      message.success('Requisição atualizada com sucesso.')
    }else {
      message.success('Requisição reprovada com sucesso.')
    }


    requestFn()
  }

  const title = useMemo(() => (
    accept ? 'Aprovar compra direta' : 'Reprovar compra direta'
  ), [purchaseOrderId, accept])

  const validations: Record<string, Rule[]> = {
    observation: [
      {
        required: !accept,
        message: 'Compo de preenchimento obrigatório'
      }
    ]
  }

  return (
    <Modal
      title={title}
      visible={isVisibleModal}
      onOk={handleSubmit}
      onCancel={handleClose}
      okText='Salvar'
      cancelText='Fechar'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col xl={24}>
            <AreaField
              name='observation'
              label='Observação'
              maxLength={245}
              rules={validations.observation}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default AcceptOrRefusedForm
