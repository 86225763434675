import React, { useEffect, useMemo, useState } from 'react'
import {
  Section,
  PageHeader as Header,
  FlexEndContainer,
  DropdownButton
} from 'components'
import {
  Button,
  message,
  Modal,
  Space,
  Table,
  Tag,
  Typography,
  Dropdown,
  Menu
} from 'antd'
import { AiOutlineDown } from 'react-icons/ai'
import type { ColumnsType, TableProps } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import { tableLocale } from 'utils/lang'
import { Navigate, useNavigate } from 'react-router'
import { TProjectRequestListOutput, useProjectRequest } from 'repositories'
import { useAuth, useFilters, usePagination } from 'hooks'
import { formatCurrency, formatDateToView } from 'utils/helpers'
import {
  paymentTypes,
  purchaseTypes,
  StatusRequest,
  statusRequest,
  RequestType
} from 'utils/constants'
import FiltersForm from '../Components/FiltersForm'

const { confirm } = Modal
const { Text } = Typography

const columns: ColumnsType<TProjectRequestListOutput> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: true
  },
  {
    title: 'Emissão',
    dataIndex: 'issueDate',
    key: 'issueDate',
    render(_, record) {
      return formatDateToView(record.issueDate)
    }
  },
  {
    title: 'Tipo de Compra',
    dataIndex: 'purchaseType',
    key: 'purchaseType',
    render(_, record) {
      return purchaseTypes[record.purchaseType]
    }
  },
  {
    title: 'Pagamento por',
    dataIndex: 'paymentType',
    key: 'paymentType',
    render(_, record) {
      return paymentTypes[record.paymentType]
    }
  },
  {
    title: 'Alçada',
    dataIndex: 'approvalHierarchyName',
    key: 'approvalHierarchyName'
  },
  {
    title: 'SLA',
    dataIndex: 'approvalHierarchySla',
    key: 'approvalHierarchySla',
    align: 'center',
  },
  {
    title: 'Saving',
    dataIndex: 'saving',
    key: 'saving',
    align: 'right',
    render(_, record) {
      const formattedSaving = formatCurrency(record.saving, false)

      if (record.saving < 0) return <Text type='danger'>{formattedSaving}</Text>

      return formattedSaving
    }
  },
  {
    title: 'Prazo de Negociação',
    dataIndex: 'negotiationDeadline',
    key: 'negotiationDeadline',
    align: 'center',
    render(_, record) {
      return `${record.negotiationDeadline} Dia(s)`
    }
  },
  {
    title: 'Ofensores',
    dataIndex: 'requireThreeQuotes',
    key: 'requireThreeQuotes',
    render(_, record) {
      const tags = []
      if (record.requireThreeQuotes) {
        const isRequiredQuote = (
          <Tag color='orange'>Requer três cotações</Tag>
        )
        tags.push(isRequiredQuote)
      }
      if (record.indicationSingleSupplierJustifiable) {
        const isIndication = (
          <Tag>Indicação de fornecedor único</Tag>
        )
        tags.push(isIndication)
      }
      return (
        <Space size='small'>
          {tags}
        </Space>
      )
    }
  },
  {
    title: 'Tipo',
    dataIndex: 'typeRequest',
    key: 'typeRequest',
    render(_, record) {
      return RequestType[record.typeRequest]
    }
  },
  {
    title: 'Status',
    dataIndex: 'statusRequest',
    key: 'statusRequest',
    render(_, record) {
      switch (record.statusRequest) {
        case 'IN_QUOTATION': return <Tag color='blue'>Em Cotação</Tag>
        case 'PAID_OUT': return <Tag color='green'>Pago</Tag>
        case 'CLASSIFIED': return <Tag color='orange'>Classificada</Tag>
        case 'APPROVED': return <Tag color='green'>Aprovada</Tag>
        case 'REFUSED': return <Tag color='red'>Recusada</Tag>
        case 'CANCELED': return <Tag color='red'>Cancelada</Tag>
        case 'SENT_TO_ERP': return <Tag color='black'>Enviado ao ERP</Tag>
        default: return <Tag>{statusRequest[record.statusRequest]}</Tag>
      }
    }
  },
]

const PurchaseRequisitionList = () => {
  const [requests, setRequests] = useState<TProjectRequestListOutput[]>([])
  const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false)
  const [sort, setSort] = useState('id,DESC')

  const pagination = usePagination()
  const filters = useFilters()
  const projectRequestRepository = useProjectRequest()
  const { roles: authRoles, hasRole } = useAuth()
  const navigate = useNavigate()

  const getProjectRequests = async () => {
    const currentPage = pagination.data.current ?? 0
    const _filters = filters.getObjectifiedFilterItems()
    const params = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
      ..._filters
    }

    const response = await projectRequestRepository.findProjectRequestListByFilter(params)
    if (!response) return

    setRequests(response.content)
    pagination.changeTotalPages(response.totalPages)
  }

  const onClickGoToView = (id: number) => () => navigate(`/requisicao-compras/visualizar/${id}`)
  const onClickGoToEdit = (id: number) => () => navigate(`/requisicao-compras/editar/${id}`)
  const onClickGoToClone = (id: number) => () => navigate(`/requisicao-compras/clonar/${id}`)
  const onClickGoToContract = (id: number) => () => navigate(`/requisicao-compras/contrato/${id}`)

  const handleSendApprovedRequestToErp = async (id: number) => {
    const response = await projectRequestRepository.sendApprovedRequestToERP(id)
    if (!response) return

    message.success('Requisição enviada com sucesso!')
    getProjectRequests()
  }

  const handleCancelProjectRequest = async (id: number) => {
    const response = await projectRequestRepository.cancelProjectRequest(id)
    if (!response) return

    message.success('Requisição cancelada com sucesso!')
    getProjectRequests()
  }

  const showConfirmSendRequestToErp = (id: number) => () => {
    confirm({
      title: 'Confirmação',
      content: 'Deseja enviar a requisição ao ERP (TOTVS)?',
      okText: 'Sim, enviar',
      async onOk() {
        await handleSendApprovedRequestToErp(id)
      },
    })
  }

  const showConfirmCancelRequest = (id: number) => () => {
    confirm({
      title: 'Confirmação',
      content: 'Deseja realmente cancelar esta requisição de compra?',
      okText: 'Sim, cancelar',
      cancelText: 'Não',
      async onOk() {
        await handleCancelProjectRequest(id)
      },
    })
  }

  const handleChange: TableProps<TProjectRequestListOutput>['onChange'] = (paginationConfig, filter, sorter) => {
    pagination.onChangeTable(paginationConfig)
    const { order } = sorter as SorterResult<TProjectRequestListOutput>
    const dir = order === 'ascend' ? 'ASC' : 'DESC'
    setSort(`id,${dir}`)
  }

  useEffect(() => {
    if (pagination.data.current) getProjectRequests()
  }, [pagination.data.current, pagination.data.pageSize, filters.items, sort])

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_, record) => {
        const itemsMenu = [
          {
            key: '1',
            label: 'Visualizar',
            onClick: onClickGoToView(record.id),
            disabled: (
              !hasRole(authRoles?.view_request_simple) ||
              !hasRole(authRoles?.approve_default)
            )
          },
          {
            key: '2',
            label: 'Editar',
            onClick: onClickGoToEdit(record.id),
            disabled: (!hasRole(authRoles?.project_edit || authRoles?.request_buyer))

          },
          {
            key: '3',
            label: 'Clonar',
            onClick: onClickGoToClone(record.id),
            disabled: (
              record.typeRequest === 'PURCHASE_PACKAGE' ||
              !hasRole(authRoles?.project_edit)
            )
          },
          {
            key: '4',
            label: 'Cancelar',
            onClick: showConfirmCancelRequest(record.id),
            disabled: (
              (record.statusRequest !== StatusRequest.NEW &&
                record.statusRequest !== StatusRequest.IN_QUOTATION &&
                record.statusRequest !== StatusRequest.CLASSIFIED) ||
              !hasRole(authRoles?.request_buyer)
            )
          },
          {
            key: '5',
            label: 'Enviar requisição para o ERP',
            onClick: showConfirmSendRequestToErp(record.id),
            disabled: (
              record.statusRequest !== StatusRequest.APPROVED ||
              !hasRole(authRoles?.request_buyer)
            )
          },
          {
            key: '6',
            label: 'Informações de Contrato',
            onClick: onClickGoToContract(record.id),
            disabled: (
              !hasRole(authRoles?.request_buyer) ||
              record.statusRequest === StatusRequest.NEW
            )
          },
        ]
        return (
          <DropdownButton
            items={itemsMenu}
          />
        )
      },
    }
  ], [authRoles])

  const onClickGoToCreate = (isPackage = false, isRequestNotTiedToProject = false) => () => {
    navigate('/requisicao-compras/novo', {
      state: {
        isPackage,
        isRequestNotTiedToProject
      }
    })
  }

  const onClickOpenFilterForm = () => {
    setIsVisibleFilterForm(true)
  }

  const menu = (
    <Menu
      items={[
        {
          label: 'Vinculado a projeto',
          key: '1',
          onClick: onClickGoToCreate()
        },
        {
          label: 'Não vinculado a projeto',
          key: '2',
          onClick: onClickGoToCreate(false, true)
        },
      ]}
    />
  )

  if (authRoles && !hasRole(authRoles?.view_request_simple)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <Header
        title='Requisição de Compra'
        breadcrumbItems={['Requisições', 'Listar']}
      />
      <Section>
        <FlexEndContainer className='mb-1'>
          <Button
            onClick={onClickOpenFilterForm}
          >
            Filtros
          </Button>
          <Dropdown overlay={menu}>
            <Button
              type='primary'
            >
              <Space align='center'>
                Nova Requisição
                <AiOutlineDown />
              </Space>
            </Button>
          </Dropdown>
          <Button
            type='primary'
            onClick={onClickGoToCreate(true, false)}
            disabled={!hasRole(authRoles?.project_add)}
          >
            Novo Pacote
          </Button>
        </FlexEndContainer>
        <Table
          size='middle'
          locale={tableLocale}
          onChange={handleChange}
          columns={updatedColumns}
          loading={projectRequestRepository.loading}
          dataSource={requests}
          pagination={pagination.data}
          scroll={{ x: 1400 }}
        />
      </Section>
      <FiltersForm
        isVisibleDrawer={isVisibleFilterForm}
        setIsVisibleDrawer={setIsVisibleFilterForm}
        filters={filters}
      />
    </>
  )
}

export default PurchaseRequisitionList
