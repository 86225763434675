import styled from 'styled-components'
import logoBlack from 'assets/logoBlack.png'

export const Logo = styled.h1`
  background: url(${logoBlack}) no-repeat;
  width: 45px;
  height: 29px;
  background-size: 45px 29px;
  text-indent: -9999px;
  overflow: hidden;
  margin: 0;
`

export const Title = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  
  span {
    font-size: 24px;
    font-weight: 900;
    padding: 0 10px;
    border-left: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
    text-transform: uppercase;
  }
`
