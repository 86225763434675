import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 0;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
