import React, { useEffect } from 'react'
import {
  Form,
  Drawer,
  Button,
  Space
} from 'antd'
import {
  CheckField,
  Col,
  DateField,
  Row,
  SelectField, TextField
} from 'components'
import {PurchaseOrderStatus} from 'utils/constants'
import {DefaultOptionType} from "antd/lib/select";

export const StatusOptions: DefaultOptionType[] = [
  { value: PurchaseOrderStatus.NEW, label: 'Novo' },
  { value: PurchaseOrderStatus.PAID_OUT, label: 'Pago' },
  { value: PurchaseOrderStatus.IN_ANALYSIS, label: 'Em análise' },
  { value: PurchaseOrderStatus.APPROVED, label: 'Aprovado' },
  { value: PurchaseOrderStatus.REFUSED, label: 'Recusado' },
]

export const categoryOptions: DefaultOptionType[] = [
  {
    label: "Compra & Contratação",
    value: "Compra & Contratação",
  },
  {
    label: "Rateios e Contratos",
    value: "Rateios e Contratos",
  },
]

type TFilterPurchaseOrderParamsForm = {
  status: string
  startDateApprover : string
  endDateApprover : string
  project : string,
  idRequest : string,
  idTotvs : string,
  activeApproval: Boolean
}

const initialValues: TFilterPurchaseOrderParamsForm = {
  status: '',
  startDateApprover: '',
  endDateApprover: '',
  project: '',
  idRequest: '',
  idTotvs: '',
  activeApproval: false
}

type TProps = {
  isVisibleDrawer: boolean
  setIsVisibleDrawer: React.Dispatch<boolean>,
  filters: TFiltersHook
  resetPagination: any
}

const FiltersForm = (props: TProps) => {
  const {
    isVisibleDrawer,
    setIsVisibleDrawer,
    filters,
    resetPagination
  } = props
  const [form] = Form.useForm<TFilterPurchaseOrderParamsForm>()

  const handleClose = () => {
    setIsVisibleDrawer(false)
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    resetPagination()
    onFinish(validatedData)
    handleClose()
  }

  useEffect(() => {
    if (!filters.items?.length) form.resetFields()
  }, [filters.items])

  const onFinish = (values: TFilterPurchaseOrderParamsForm) => {
    const _values = Object.entries(values)

    const filterNonNullValues = _values.filter(value => {
      const [, term] = value
      return Boolean(term)
    })
    const _filters = [] as TFilter[]
    filterNonNullValues.forEach(value => {
      const [field, term] = value

      _filters.push({ field, term: String(term) })
    })

    filters.clearAndAdd(_filters)
  }

  const onClickResetFilters = () => filters.clearAndAdd([])

  return (
    <Drawer
      title='Filtros'
      width={500}
      onClose={handleClose}
      visible={isVisibleDrawer}
      destroyOnClose
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <DateField
              name='startDateApprover'
              label='Data de vencimento inicial'
            />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <DateField
              name='endDateApprover'
              label='Data de vencimento final'
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <TextField
              name='idTotvs'
              label='Numero do pedido'
              // disabled={true}
            />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <TextField
              name='idRequest'
              label='ID da requisição'
              // disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <TextField
                name='project'
                label='Nome ou ID do projeto'
            />
          </Col>
          <Col md={12} xl={12} xxl={12}>
            <SelectField
              name='status'
              label='Status da requisição'
              options={StatusOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} xxl={12}>
            <SelectField
                name='categoryRequest'
                label='Tipo da Requisição'
                options={categoryOptions}
            />
          </Col>
        </Row>
        <Row>
          {/*<Col md={12} xl={12} xxl={12}>*/}
          {/*    <CheckField*/}
          {/*      name='activeApproval'*/}
          {/*      label='Disponível para Aprovações'*/}
          {/*    />*/}
          {/*</Col>*/}
        </Row>
        <Form.Item className='mt-1'>
          <Space size='small'>
            <Button
              onClick={handleSubmit}
              type='primary'
            >
              Filtrar
            </Button>
            <Button
              onClick={onClickResetFilters}
            >
              Limpar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default FiltersForm
