import React from "react";
import { Descriptions as AntDescriptions } from "antd";

type TProps = {
  children: React.ReactNode;
  bordered?: boolean;
};

const DetailGrid = ({ children, bordered = false }: TProps) => (
  <AntDescriptions
    bordered={bordered}
    size="small"
    layout="vertical"
    labelStyle={{
      fontWeight: "bold",
    }}
    column={{
      xxl: 4,
      xl: 4,
    }}
  >
    {children}
  </AntDescriptions>
);

export default DetailGrid;
