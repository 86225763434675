/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from 'react'
import { useAuth } from 'hooks'
import { Avatar } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { Container, Info } from './styles'
import ProfileForm from './ProfileForm'
import { useProfile } from 'repositories'

const Profile = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [img, setImg] = useState('')
  const [userGroup, setUserGroup] = useState<String>()

  const { pathname } = useLocation()
  const { userData, hasRole, saveUserData, roles } = useAuth()

  const repositoryProfile = useProfile()

  const showModal = () => {
    if (hasRole(roles.profile_view)) {
      setIsModalVisible(true)
    }
  }

  const updateData = async () => {
    const userProfile = await repositoryProfile.findUserProfile()
    setImg(userProfile.picture)
    setUserGroup(userProfile?.userGroup?.name)
  }

  const { name, group, initialLetterName } = useMemo(() => {
    if (!userData) return { name: '', group: '' }
    const { userName } = userData
    updateData()
    return {
      name: userName,
      group: userGroup,
      initialLetterName: userName?.substring(0, 2)
    }
  }, [userData])

  const profile = (
    <div>
      {img ? (
        <Avatar
          shape='square'
          src={img}
        />
      ) : (
        <Avatar
          shape='square'
        >
          {initialLetterName}
        </Avatar>
      )}
      <Info>
        <p>{name}</p>
        <span>
          {userGroup || 'Sem grupo'}
        </span>
      </Info>
    </div>
  )

  return (
    <Container>
      <Link
        to={pathname}
        title='Visualizar perfil de acesso'
        onClick={showModal}
      >
        {profile}
      </Link>
      <ProfileForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Container>
  )
}

export default Profile
