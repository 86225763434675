import { useState } from 'react'
import useRest from 'hooks/useRest'

const useBusinessUnits = () => {
    const [loading, setLoading] = useState(false)
    const rest = useRest()

    const findBus = async (params: any, triggerLoading = true) => {
        if (triggerLoading) setLoading(true)
        const url = '/v1/manager/bu'
        const response = await rest.get(url, params)
        if (triggerLoading) setLoading(false)
        if (!response) return {}

        return response.result
    }

    const findBusById = async (id: String, triggerLoading = true) => {
        if (triggerLoading) setLoading(true)
        const url = `/v1/manager/bu/${id}`
        const response = await rest.get(url)
        if (triggerLoading) setLoading(false)
        if (!response) return {}

        return response.result
    }

    return {
        loading,
        findBus,
        findBusById
    }
}

export default useBusinessUnits
