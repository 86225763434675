import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Tag,
  Table,
  Select
} from 'antd'
import {
  PageHeader as Header,
  Section
} from 'components'
import type {
  ColumnsType,
  TableProps
} from 'antd/lib/table'
import {
  statusQuote,
  StatusQuote,
  StatusSupplierAnswer
} from 'utils/constants'
import {
  useAuth,
  usePagination
} from 'hooks'
import {
  TFilterTSupplierQuoteParams,
  useSupplierQuote,
  useSuppliers
} from 'repositories'
import { Navigate, useNavigate } from 'react-router'
import { tableLocale } from 'utils/lang'
import { SorterResult } from 'antd/lib/table/interface'
import { FilterContainer } from './styles'

const { Option } = Select

type TQuotesData = {
  key: string
  id: number
  status: string
  projectRequestId: number
  supplierAnswer: string
  supplierId: number
}

type TSupplier = {
  id: number
  name: string
  email: string
  supplierUserId: number
}

const columns: ColumnsType<TQuotesData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: true
  },
  {
    title: 'Descrição',
    dataIndex: 'projectRequestId',
    key: 'projectRequestId',
    sorter: true,
    render(_, record) {
      return `Requisição #${record.projectRequestId}`
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: (_, record) => {
      switch (record.status) {
        case StatusQuote.PROCESS_FINISHED:
          return <Tag color='green'>{statusQuote[record.status]}</Tag>
        case StatusQuote.QUOTE_REVISED:
          return <Tag color='red'>{statusQuote[record.status]}</Tag>
        case StatusQuote.FORWARD_INVOICE:
          return <Tag color='orange'>{statusQuote[record.status]}</Tag>
        default: return <Tag>{statusQuote[record.status]}</Tag>
      }
    },
  },
  {
    title: 'Aceite?',
    dataIndex: 'supplierAnswer',
    key: 'supplierAnswer',
    sorter: true,
    render: (_, record) => {
      switch (record.supplierAnswer) {
        case StatusSupplierAnswer.ACCEPT:
          return <Tag color='green'>Aceita</Tag>
        case StatusSupplierAnswer.REFUSE:
          return <Tag color='red'>Recusada</Tag>
        case StatusSupplierAnswer.WAITING_ANSWER:
          return <Tag>Aguardando aceite</Tag>
        default: return <div />
      }
    },
  }
]

const QuoteList = () => {
  const [supplier, setSupplier] = useState<TSupplier>({} as TSupplier)
  const [quotes, setQuotes] = useState<TQuotesData[]>([])
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('')

  const {
    roles: authRoles,
    hasRole,
    userData
  } = useAuth()
  const repository = useSupplierQuote()
  const supplierRepository = useSuppliers()
  const navigate = useNavigate()
  const pagination = usePagination()

  const requestQuotes = async () => {
    const currentPage = pagination.data.current ?? 0
    const params: TFilterTSupplierQuoteParams = {
      status: search,
      supplierId: supplier.id,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
    }

    const response = await repository.findSupplierQuoteByFilter(params)
    if (!response) return

    const mappedData: TQuotesData[] = response.content.map(quoteData => ({
      ...quoteData,
      key: String(quoteData.id),
    }))
    setQuotes(mappedData)
    pagination.changeTotalPages(response.totalPages)
  }

  useEffect(() => {
    const getSupplier = async () => {
      const response = await supplierRepository.findSupplierByEmail(userData?.userEmail)
      if (!response) return

      setSupplier({
        id: Number(response.id),
        name: response.corporateName,
        email: response.email,
        supplierUserId: response.supplierUserId
      })
    }

    if (userData?.userEmail) getSupplier()
  }, [userData])

  useEffect(() => {
    if (supplier.id && pagination.data.current) requestQuotes()
  }, [
    supplier,
    pagination.data.current,
    pagination.data.pageSize,
    sort,
    search
  ])

  const onSearch = (value: string) => setSearch(value)

  const onClickGoToDetail = (id: number, supplierId: number) => () => {
    navigate(`/cotacoes/detalhes/${id}`, {
      state: {
        supplierId
      }
    })
  }

  const handleChange: TableProps<TQuotesData>['onChange'] = (paginationConfig, filters, sorter) => {
    pagination.onChangeTable(paginationConfig)
    const { field, order } = sorter as SorterResult<TQuotesData>
    if (!field) return

    const dir = order === 'ascend' ? 'ASC' : 'DESC'
    setSort(`${field},${dir}`)
  }

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Button
          onClick={onClickGoToDetail(record.projectRequestId, record.supplierId)}
          size='small'
          type='text'
        >
          Detalhes
        </Button>
      ),
    }
  ], [])

  if (authRoles && !hasRole(authRoles?.supplier_default)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <Header
        title='Cotações'
        breadcrumbItems={['Cotações', 'Listar']}
      />
      <Section>
        <FilterContainer>
          <Select
            placeholder='Pesquise por um status'
            onChange={onSearch}
          >
            <Option value='' key='0'>Mostrar todos</Option>
            <Option value={StatusQuote.WAITING_ANSWER} key='1'>{statusQuote[StatusQuote.WAITING_ANSWER]}</Option>
            <Option value={StatusQuote.PROPOSAL_SENT} key='2'>{statusQuote[StatusQuote.PROPOSAL_SENT]}</Option>
            <Option value={StatusQuote.QUOTE_REVISED} key='3'>{statusQuote[StatusQuote.QUOTE_REVISED]}</Option>
            <Option value={StatusQuote.FORWARD_INVOICE} key='4'>{statusQuote[StatusQuote.FORWARD_INVOICE]}</Option>
            <Option value={StatusQuote.WAITING_APPROVAL} key='5'>{statusQuote[StatusQuote.WAITING_APPROVAL]}</Option>
            <Option value={StatusQuote.PROCESS_FINISHED} key='6'>{statusQuote[StatusQuote.PROCESS_FINISHED]}</Option>
          </Select>
        </FilterContainer>
        <Table
          size='middle'
          locale={tableLocale}
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={quotes}
          pagination={pagination.data}
        />
      </Section>
    </>
  )
}

export default QuoteList
