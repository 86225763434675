import useRest from 'hooks/useRest'
import { useState } from 'react'
import { TRole } from '.'

const useRoles = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findRolesByFilter = async (
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/role'
    const _params = { sort: 'id,name,ASC', size: 10000 }
    const response = await rest.get(url, _params)
    if (triggerLoading) setLoading(false)
    if (!response) return [] as TRole[]

    return response.result.content as TRole[]
  }

  return {
    loading,
    findRolesByFilter
  }
}

export default useRoles
