import { useState } from 'react'
import useRest from 'hooks/useRest'
import {
  TProjectRequestItemAttachment,
  TProjectRequestItemAttachmentParams
} from 'repositories'

const useProjectRequestItemAttachment = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const uploadAttachment = async (
    data: FormData,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = 'v1/manager/project-request-item/attachment'
    const response = await rest.post(url, data, {
      'Content-Type': 'multipart/form-data'
    })
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TProjectRequestItemAttachment
  }

  const downloadAttachment = async (
    projectRequestItemId: number,
    filename: string,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-request-item/attachment'
    const response = await rest.get(url, {
      projectRequestItemId,
      filename
    })
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as string
  }

  const findAttachmentsByFilter = async (
    projectRequestItemId: number,
    params: TProjectRequestItemAttachmentParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/project-request-item/attachment/project-request-item/${projectRequestItemId}`
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TProjectRequestItemAttachment[]
  }

  const deleteAttachment = async (
    projectRequestItemId: number,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/project-request-item/attachment/id/${projectRequestItemId}`
    const response = await rest.destroy(url)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return true
  }

  return {
    loading,
    uploadAttachment,
    downloadAttachment,
    findAttachmentsByFilter,
    deleteAttachment
  }
}

export default useProjectRequestItemAttachment

