import { useState } from 'react'
import useRest from 'hooks/useRest'
import { TSupplierQuoteItemAttachment, TSupplierQuoteItemAttachmentParams } from 'repositories'

const useSupplierQuoteAttachment = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const uploadAttachment = async (
    data: FormData,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/supplier-quote-item/attachment'
    const response = await rest.post(url, data, {
      'Content-Type': 'multipart/form-data'
    })
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TSupplierQuoteItemAttachment
  }

  const downloadAttachment = async (
    supplierQuoteItemId: number,
    filename: string,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/supplier-quote-item/attachment'
    const response = await rest.get(url, {
      supplierQuoteItemId,
      filename
    })
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as string
  }

  const findAttachmentsByFilter = async (
    supplierQuoteItemId: number,
    params: TSupplierQuoteItemAttachmentParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/supplier-quote-item/attachment/supplier-quote/${supplierQuoteItemId}`
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TSupplierQuoteItemAttachment[]
  }

  const deleteAttachment = async (
    supplierQuoteItemId: number,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/supplier-quote-item/attachment/id/${supplierQuoteItemId}`
    const response = await rest.destroy(url)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return true
  }

  return {
    loading,
    uploadAttachment,
    downloadAttachment,
    findAttachmentsByFilter,
    deleteAttachment
  }
}

export default useSupplierQuoteAttachment

