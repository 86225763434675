import React, { useMemo, useState } from 'react'
import type { ColumnsType } from 'antd/lib/table'
import {
  TInvitationForQuotation,
  TSupplierProjectRequest,
  useNotification
} from 'repositories'
import {
  Button,
  message,
  Rate,
  Table,
  Modal
} from 'antd'
import { tableLocale } from 'utils/lang'
import { DropdownButton } from 'components'
import { StatusRequest } from 'utils/constants'
import { useAuth } from 'hooks'
import SupplierRequestForm from './Form'

const { confirm } = Modal

type TSupplierProjectRequestData = TSupplierProjectRequest & {
  key: string
}

const columns: ColumnsType<TSupplierProjectRequestData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Razão Social',
    dataIndex: 'corporateName',
    key: 'corporateName'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Avaliação',
    dataIndex: 'currentEvaluation',
    key: 'currentEvaluation',
    render: (_, record) => (
      <Rate
        disabled
        value={record.score || 0}
      />
    )
  }
]

type TProps = {
  isView?: boolean
  suppliers: TSupplierProjectRequestData[]
  setSuppliers: React.Dispatch<TSupplierProjectRequestData[]>
  productIds?: number[]
  negotiationDeadline?: number
  status: string
}

const SupplierRequestList = (props: TProps) => {
  const {
    isView = false,
    suppliers,
    setSuppliers,
    productIds,
    negotiationDeadline = 0,
    status
  } = props

  const { roles, hasRole, userData } = useAuth()
  const repositoryNotification = useNotification()

  const [isVisibleForm, setIsVisibleForm] = useState(false)

  const onClickToAdd = () => {
    setIsVisibleForm(true)
  }

  const onClickToRemove = (supplierId: number) => () => {
    const filteredSuppliers = suppliers.filter(supplier => supplier.id !== supplierId)
    setSuppliers(filteredSuppliers)
  }

  const handleResendInvitation = async (data: TInvitationForQuotation) => {
    const response = await repositoryNotification.sendInvitationQuotation(data)
    if (!response) return

    message.success('Convite de cotação enviado com sucesso!')
  }

  const showConfirmationResend = (supplierId: number) => {
    const findSupplier = suppliers.find(supplier => supplier.id === supplierId)
    if (!findSupplier) return

    const data: TInvitationForQuotation = {
      supplierName: findSupplier.corporateName,
      emailRecipient: findSupplier.email,
      buyerName: userData.userName,
      deadline: negotiationDeadline
    }

    confirm({
      title: 'Confirmação',
      content: 'Confirma o reenvio do email com o convite da cotação?',
      okText: 'Sim',
      async onOk() {
        await handleResendInvitation(data)
      }
    })
  }

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: (
        <Button
          type='primary'
          disabled={isView || status !== StatusRequest.NEW}
          onClick={onClickToAdd}
        >
          Adicionar
        </Button>
      ),
      key: 'action',
      render: (_: any, record: any) => {
        const supplierId = Number(record.id)
        const items = [
          {
            key: '1',
            label: 'Remover',
            onClick: onClickToRemove(supplierId),
            disabled: isView || status !== StatusRequest.NEW
          },
          {
            key: '2',
            label: 'Reenviar convite de cotação',
            disabled: isView || status !== StatusRequest.IN_QUOTATION || !hasRole(roles?.request_buyer),
            onClick: () => showConfirmationResend(supplierId)
          }
        ]
        return (
          <DropdownButton
            items={items}
          />
        )
      },
    }
  ], [roles, status, suppliers])

  return (
    <>
      <Table
        size='middle'
        locale={tableLocale}
        columns={updatedColumns}
        dataSource={suppliers}
      />
      <SupplierRequestForm
        isVisible={isVisibleForm}
        setIsVisible={setIsVisibleForm}
        initialSuppliers={suppliers}
        setInitialSuppliers={setSuppliers}
        productIds={productIds}
      />
    </>
  )
}

export default SupplierRequestList
