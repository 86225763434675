import React, {useEffect, useRef, useState} from "react";
import { Steps, message } from "antd";
import { PageHeader, Section } from "components";

import ProjectSelection from "./ProjectSelection";
import ProductSelectionRequest from "./ProductsSelection";
import FormRequest from "./FormRequest";
import FormFreelancers from "./Freelancers";

import { useNavigate } from "react-router";


import useRequest from "../../../repositories/useRequest";

const PurchaseAndHiring = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const navigate = useNavigate();

  const [project, setProject] = useState({});
  const [dataForm, setSelectDataForm] = useState({
    issueDate: "",
    maturity: "",
    typeProvider: "",
    seniority: "",
    category: "",
    allocation: "",
    isRequestNotTiedToProject: "",
    paymentType: "",
    providerType: 0,
    purchaseType: "",
    suppliersId: "",
    serviceStartDate: "",
    serviceEndDate: "",
    approvalHierarchyId: "",
    negotiationDeadline: "",
    requireThreeQuotes: "",
    timing: "",
    indicationSingleSupplierJustifiable: "",
    subsidiaryId: "",
    objectiveFunction: "",
    justifyIfItIsAnEmergency: "",
    JustifyReasonSingleSupplier: "",
    JustifyUnapprovedSupplier: "",
    JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
    JustifyTheDueDateBeingShorter: "",
    items: "",
    files: "",
    btnStatus: 1,
  });
  const [products, setProducts] = useState([]);
  const [provider, setProvider] = useState(0);
  const [dataFreelancers, setDataFreelancers] = useState<any>({});

  const [items, setItems] = useState([
    {
      title: "Projeto",
      description: "Selecione o Projeto",
    },
    {
      title: "Itens",
      description: "Escolha as linhas / produtos",
    },
    {
      title: "Complementos",
      description: "Preencha o formulário",
    },
    {
      title: "Freelancers",
      description: "Preencha o formulário Funcional",
    },
  ]);

  const repository = useRequest();

  const createRequest = async () => {
    const formData = new FormData();
    formData.append("issueDate", dataForm.issueDate);
    formData.append("allocation", dataForm.allocation);
    formData.append("category", dataForm.category);
    formData.append("typeProvider", dataForm.typeProvider);
    formData.append("maturity", dataForm.maturity);
    formData.append(
      "isRequestNotTiedToProject",
      dataForm.isRequestNotTiedToProject ? "true" : "false"
    );
    formData.append("paymentType", dataForm.paymentType);
    formData.append("purchaseType", "V");
    formData.append("suppliersId", dataForm.suppliersId);
    formData.append("serviceStartDate", dataForm.serviceStartDate);
    formData.append("serviceEndDate", dataForm.serviceEndDate);
    formData.append("approvalHierarchyId", dataForm.approvalHierarchyId);
    formData.append("negotiationDeadline", dataForm.negotiationDeadline);
    formData.append("requireThreeQuotes", dataForm.requireThreeQuotes);
    formData.append("timing", dataForm.timing);
    formData.append("hiringPurpose", dataForm.objectiveFunction);
    formData.append(
      "justifyChoiceIfRequestForEmergency",
      dataForm.justifyIfItIsAnEmergency
    );
    formData.append(
      "justifyReasonRequestIsMadeSingleSupplier",
      dataForm.JustifyReasonSingleSupplier
    );
    formData.append(
      "justifyChoiceSupplierNotHomologated",
      dataForm.JustifyUnapprovedSupplier
    );
    formData.append(
      "justifyReasonStartDateBeforeDateIssuance",
      dataForm.JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate
    );
    formData.append(
      "justifyDueDatePayment",
      dataForm.JustifyTheDueDateBeingShorter
    );

    formData.append(
      "indicationSingleSupplierJustifiable",
      dataForm.indicationSingleSupplierJustifiable ? "true" : "false"
    );
    formData.append("subsidiaryId", `${dataForm.subsidiaryId}`);

    formData.append("items", JSON.stringify(dataForm.items));

    if (dataFreelancers) {
      formData.append(
        "projectRequestFreelancers",
        JSON.stringify(dataFreelancers)
      );
    }

    for (let i = 0; i < dataForm.files.length; i++) {
      formData.append("files", dataForm.files[i]);
    }

    const headers = {
      "content-type": "multipart/form-data",
    };
    try {
      const request = await repository.createRequestsFormData(
        formData,
        headers
      );
      await repository.startRequests({
        projectRequestId: request?.id,
      });
      message.success(`Requisição #${request?.id} adicionada com sucesso!`);
      navigate(`/`);
    } catch (error) {}
  };

  const handleStepsUp = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleStepsDown = () => {
    setCurrentStep(currentStep - 1);
  };

  const selectProject = (project: any) => {
    setProject(project);
    //console.log(project);
  };

  const selectProduct = (products: any) => {
    if (products) {
      setProducts(products);
    } else {
      setProducts([]);
    }
  };
  const selectDataForm = (data: any) => {
    if (data) {
      setSelectDataForm(data);
    } else {
      setSelectDataForm({
        issueDate: "",
        maturity: "",
        typeProvider: "",
        seniority: "",
        category: "",
        allocation: "",
        isRequestNotTiedToProject: "",
        paymentType: "",
        providerType: 0,
        purchaseType: "",
        suppliersId: "",
        serviceStartDate: "",
        serviceEndDate: "",
        approvalHierarchyId: "",
        negotiationDeadline: "",
        requireThreeQuotes: "",
        timing: "",
        indicationSingleSupplierJustifiable: "",
        subsidiaryId: "",
        objectiveFunction: "",
        justifyIfItIsAnEmergency: "",
        JustifyReasonSingleSupplier: "",
        JustifyUnapprovedSupplier: "",
        JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
        JustifyTheDueDateBeingShorter: "",
        items: "",
        files: "",
        btnStatus: 1,
      });
    }
  };

  const handleProviderType = (providerType: any) => {
    setProvider(providerType)
    if (providerType !== 1 && items.length === 4) {
      const novoArray = items.slice(0, -1);
      setItems(novoArray);
    } else if (items.length === 3) {
      setItems([
        ...items,
        {
          title: "Freelancers",
          description: "Preencha o formulário Funcional",
        },
      ]);
    }
  };

  useEffect(() => {
    if (dataFreelancers && dataFreelancers.btnStatus === "save") {
      createRequest();
    }
  }, [dataFreelancers]);

  useEffect(() => {
    if (dataForm.providerType === 2 && dataForm.btnStatus !== 1) {
      createRequest();
    }
  }, [dataForm]);

  const handleFreelancersData = (freelancersData: any) => {
    if (freelancersData) {
      const mappedFreelancersData: any = {
        needSupportFromTv1corporateTI:
          freelancersData?.needSupportFromTv1corporateTI === "sim"
            ? true
            : false,
        useTv1Email: freelancersData?.useTv1Email === "sim" ? true : false,
        equipmentFreelancers: freelancersData?.equipmentFreelancers,
        equipmentTypeFreelancers: freelancersData?.equipmentTypeFreelancers,
        workplace: freelancersData?.workplace,
        accessInternalSystem: freelancersData?.accessInternalSystem,
        othersAccessInternalSystem: freelancersData?.othersAccessInternalSystem,

        otherMarketSoftware: freelancersData?.otherMarketSoftware,
        freelanceDirectManager: freelancersData?.freelanceDirectManager,

        contractStartDate:
          freelancersData?.contractStartDate.format("YYYY-MM-DD"),
        contractEndDate: freelancersData?.contractEndDate.format("YYYY-MM-DD"),

        includeRenewal: freelancersData?.includeRenewal,
        btnStatus: freelancersData.btnStatus,
      };
      setDataFreelancers(mappedFreelancersData);
    } else {
      setDataFreelancers({});
    }
  };

  const steps = [
    <ProjectSelection
      nextStep={handleStepsUp}
      projectSelected={selectProject}
      currentProject={project}
    />,
    <ProductSelectionRequest
      nextStep={handleStepsUp}
      prevStep={handleStepsDown}
      project={project}
      productSelected={selectProduct}
      currentProducts={products}
    />,
    <FormRequest
      nextStep={handleStepsUp}
      prevStep={handleStepsDown}
      handleProviderType={handleProviderType}
      productsLinesSelected={products}
      project={project}
      selectDataForm={selectDataForm}
      createRequest={createRequest}
      currentDataForm={dataForm}
      loading={repository.loading}
    />,
    <FormFreelancers
      request={dataForm}
      prevStep={handleStepsDown}
      freelancersData={handleFreelancersData}
      createRequest={createRequest}
      currentFreelancerData={dataFreelancers}
      loading={repository.loading}
    />,
  ];

  const sectionRef = useRef<HTMLDivElement | null>(null);
  sectionRef.current?.scrollIntoView({ behavior: "auto", block: "start" });
  return (
    <>
      {
        provider === 0 &&
        <div ref={sectionRef} ></div>
      }
      <PageHeader
        title="Requisições"
        breadcrumbItems={[
          "Requisições simples",
          "Atrelada a Projeto",
          "Compra & Contratação",
          "Nova",
        ]}
      />
      <Section >
        <Steps current={currentStep} items={items} />
        {steps[currentStep] || null}
      </Section>
    </>
  );
};

export default PurchaseAndHiring;
