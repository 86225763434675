import React, { useEffect, useMemo, useState } from 'react'
import {
  Form,
  Modal,
  message
} from 'antd'
import {
  Col,
  CurrencyField,
  DateField,
  HiddenField,
  Row,
  SearchField,
  SelectField
} from 'components'
import {
  TProject,
  TPurchaseOrderItems,
  useProject
} from 'repositories'
import type { Rule } from 'antd/lib/form'
import {
  currentDate,
  formatCurrency,
  formatDateToServer,
  formatStringToNumber
} from 'utils/helpers'
import moment, { Moment } from 'moment'
import { RangePickerProps } from 'antd/lib/date-picker'
import { DefaultOptionType } from 'antd/lib/select'

type TPurchaseOrderItemForm = Omit<TPurchaseOrderItems, 'projectRevisionItem' | 'clientCnpj' | 'projectIdERP' | 'clientName' | 'value' | 'percentage' | 'startDatePayment' | 'endDatePayment' | 'apportions'> & {
  key: string
  value: string
  percentage: string
  purchaseValue: number
  remainingPercentage: number
  startDatePayment: Moment | string,
  endDatePayment: Moment | string,
}

const initialValues: TPurchaseOrderItemForm = {
  key: '',
  id: 0,
  value: '',
  percentage: '',
  projectId: 0,
  projectName: '',
  costCenterName: '',
  startDatePayment: '',
  endDatePayment: '',
  purchaseValue: 0,
  remainingPercentage: 0
}

const validations: Record<string, Rule[]> = {
  projectName: [
    {
      required: true,
      message: 'Campo "Projeto" é obrigatório'
    },
  ],
  costCenterId: [
    {
      required: true,
      message: 'Campo "Centro de Custo" é obrigatório'
    },
  ],
  percentage: [
    {
      required: true,
      message: 'Campo "Percentual" é obrigatório'
    },
    () => ({
      validator(_, percentage: string) {
        const formattedPercentage = formatStringToNumber(percentage)
        if (!percentage && formattedPercentage <= 0) {
          return Promise.reject(new Error('Percentual deve ser maior que zero'))
        }

        if (formattedPercentage > 100) {
          return Promise.reject(new Error('Percentual deve ser menor ou igual a cem'))
        }

        return Promise.resolve()
      },
    })
  ],
  value: [
    { required: true, message: 'Campo "Valor" é obrigatório' },
    ({ getFieldValue }) => ({
      validator(_, value: string) {
        const formattedValue = formatStringToNumber(value)
        const formattedPurchaseValue = formatStringToNumber(getFieldValue('purchaseValue'))
        if (!value && formattedValue <= 0) {
          return Promise.reject(new Error('Valor deve ser maior que zero'))
        }

        if (formattedValue > formattedPurchaseValue) {
          return Promise.reject(new Error('Valor do projeto não deve ser maior que o valor do contrato'))
        }

        return Promise.resolve()
      },
    })
  ],
  startDatePayment: [
    { required: true, message: 'Campo "Início de pagamento" é obrigatório' },
  ],
  endDatePayment: [
    { required: true, message: 'Campo "Término de pagamento" é obrigatório' },
    { type: 'date', message: 'Insíra uma data válida' },
    ({ getFieldValue }) => ({
      validator(_, endDatePayment: Moment) {
        const startDatePayment = getFieldValue('startDatePayment')
        if (endDatePayment.isBefore(startDatePayment)) {
          return Promise.reject(new Error('Data de término não pode ser inferior que a de início'))
        }

        return Promise.resolve()
      },
    })
  ]
}

type TProps = {
  isVisible: boolean
  itemId: number
  productId: number
  setIsVisible: React.Dispatch<boolean>
  items: TPurchaseOrderItems[]
  setItems: React.Dispatch<TPurchaseOrderItems[]>
  purchaseValue: number
  payDay: number
  isRequestNotTiedToProject?: boolean
  costCenters: DefaultOptionType[]
}

const ItemForm = (props: TProps) => {
  const {
    itemId,
    productId,
    isVisible,
    setIsVisible,
    items,
    setItems,
    purchaseValue,
    payDay,
    isRequestNotTiedToProject = false,
    costCenters
  } = props

  const [value, setValue] = useState('')
  const [options, setOptions] = useState<TSearchOptions[]>([])
  const [projects, setProjects] = useState<Pick<TProject, 'id' | 'name'>[]>([])

  const projectRepository = useProject()
  const [form] = Form.useForm<TPurchaseOrderItemForm>()

  useEffect(() => {
    if (!isVisible) return

    const formattedPurchaseValue = formatStringToNumber(String(purchaseValue))
    const remainingPercentage = 100 - items.reduce((acc, curr) => (
      acc + curr.percentage
    ), 0)

    const calculateValue = items.length
      ? (remainingPercentage / 100) * formattedPurchaseValue
      : formattedPurchaseValue

    form.setFieldsValue({
      value: formatCurrency(calculateValue, false),
      percentage: formatCurrency(remainingPercentage, false),
      purchaseValue,
      remainingPercentage,
    })
  }, [isVisible])

  const onCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value: _value } = e.target

    const formattedValue = formatStringToNumber(_value)
    const formattedPurchaseValue = formatStringToNumber(String(purchaseValue))

    switch (id) {
      case 'percentage':
        const calculatedValue = (formattedValue / 100) * formattedPurchaseValue
        form.setFieldsValue({
          [id]: _value,
          value: formatCurrency(calculatedValue, false)
        })
        break
      case 'value':
        const calculatedPercentage = (formattedValue / formattedPurchaseValue) * 100
        form.setFieldsValue({
          [id]: _value,
          percentage: formatCurrency(calculatedPercentage, false)
        })
        break
      default: break
    }
  }

  const onSearchChange = (data: string) => {
    const findProject = projects.find(project => project.name === data)
    if (!findProject) return

    setValue(data)
    form.setFieldsValue({
      projectId: findProject.id,
      projectName: findProject.name
    })
  }

  const onSearch = async (searchText: string) => {
    const response = await projectRepository.findProjectByNameAndProductId({
      projectName: searchText,
      productId,
    })
    if (!response) return

    const mappedProjects = response.map(item => ({ value: item.name }))
    setOptions(mappedProjects)
    setProjects(response)
  }

  const handleClose = () => {
    setIsVisible(false)
    setValue('')
    setProjects([])
    setOptions([])
    form.resetFields()
  }

  useEffect(() => {
    const getItem = () => {
      const findItem = items.find(item => (
        isRequestNotTiedToProject
          ? item.costCenterId === itemId
          : item.projectId === itemId
      ))
      if (!findItem) return

      const formattedStartDatePayment = formatDateToServer(findItem.startDatePayment || '')
      const formattedEndDatePayment = formatDateToServer(findItem.endDatePayment || '')

      form.setFieldsValue({
        ...findItem,
        costCenterName: (
          findItem.costCenter
            ? `[${findItem.costCenter.erpId}] ${findItem.costCenter.description}`
            : findItem.costCenterName
        ),
        value: formatCurrency(findItem.value, false),
        percentage: formatCurrency(findItem.percentage, false),
        startDatePayment: moment(formattedStartDatePayment),
        endDatePayment: moment(formattedEndDatePayment),
      })
    }

    if (isVisible && itemId) getItem()
  }, [isVisible, itemId])

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
  }

  const onChangeCostCenter = () => {
    const costCenterId = form.getFieldValue('costCenterId')
    const costCenter = costCenters.find(item => item.value === costCenterId)
    if (!costCenter) return

    form.setFieldValue('costCenterName', costCenter.label)
  }

  const onFinish = (values: TPurchaseOrderItemForm) => {
    const isRegistered = items.some(item => (
      isRequestNotTiedToProject
        ? item.costCenterId === values.costCenterId
        : item.projectId === values.projectId
    ))
    if (!itemId && isRegistered) {
      message.error(
        isRequestNotTiedToProject
          ? 'Centro de custo já encontra-se vinculado.'
          : 'Projeto já encontra-se vinculado.'
      )
      return
    }

    const data: TPurchaseOrderItems = {
      ...values,
      startDatePayment: moment(values.startDatePayment).set('date', payDay).format('YYYY-MM-DD'),
      endDatePayment: moment(values.endDatePayment).set('date', payDay).format('YYYY-MM-DD'),
      value: formatStringToNumber(values.value),
      percentage: formatStringToNumber(values.percentage),
      apportions: []
    }

    if (!itemId) {
      setItems([...items, data])
      handleClose()
      return
    }

    const filteredItems = items.filter(item => (
      isRequestNotTiedToProject
        ? item.costCenterId !== values.costCenterId
        : item.projectId !== values.projectId
    ))
    setItems([...filteredItems, data])
    handleClose()
  }

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    const _currentDate = currentDate()
    const [day, month, year] = _currentDate.split('/')
    if (payDay > Number(day)) {
      return current && moment(`${year}-${month}-${payDay}`) >= current
    }

    return current && moment().endOf('month') >= current
  }

  const title = useMemo(() => {
    if (isRequestNotTiedToProject) {
      return itemId
        ? 'Atualizar Centro de custo'
        : 'Adicionar Centro de custo'
    }

    return itemId
      ? 'Atualizar Projeto'
      : 'Adicionar Projeto'
  }, [itemId, isRequestNotTiedToProject])

  return (
    <Modal
      visible={isVisible}
      title={title}
      onCancel={handleClose}
      okText='Salvar'
      cancelText='Fechar'
      onOk={handleSubmit}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <HiddenField
            name='id'
          />
          <HiddenField
            name='payDay'
          />
          {!isRequestNotTiedToProject && (
            <>
              <HiddenField
                name='projectId'
              />
              <Col xl={24}>
                <SearchField
                  required
                  name='projectName'
                  label='Projeto'
                  disabled={!!itemId}
                  isLoading={projectRepository.loading}
                  rules={validations.projectName}
                  placeholder='Pesquise pelo projeto'
                  onChange={onSearchChange}
                  onSearch={onSearch}
                  value={value}
                  options={options}
                />
              </Col>
            </>
          )}
          {isRequestNotTiedToProject && (
            <Col xl={24}>
              <HiddenField
                name='costCenterName'
              />
              <SelectField
                required
                name='costCenterId'
                disabled={!!itemId}
                label='Centro de Custo'
                rules={validations.costCenterId}
                onChange={onChangeCostCenter}
                options={costCenters}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col xl={12}>
            <CurrencyField
              required
              name='value'
              label='Valor'
              rules={validations.value}
              onChange={onCurrencyChange}
            />
          </Col>
          <Col xl={12}>
            <CurrencyField
              required
              name='percentage'
              label='Percentual'
              rules={validations.percentage}
              onChange={onCurrencyChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <DateField
              name='startDatePayment'
              label='Início de pagamento'
              rules={validations.startDatePayment}
              disabledDate={disabledDate}
              format='MM/YYYY'
              picker='month'
              required
            />
          </Col>
          <Col xl={12}>
            <DateField
              name='endDatePayment'
              label='Término de pagamento'
              rules={validations.endDatePayment}
              format='MM/YYYY'
              picker='month'
              required
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ItemForm
