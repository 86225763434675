import React from 'react'
import {
  Checkbox,
  Form,
  Input
} from 'antd'

type TProps = {
  name: string
  checkbox?: boolean
}

const HiddenField = ({ name, checkbox = false }: TProps) => (
  <Form.Item
    id={name}
    name={name}
    hidden
  >
    {checkbox
      ? <Input />
      : <Checkbox />}
  </Form.Item>
)

export default HiddenField
