import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Descriptions,
  Divider,
  Space,
  Table
} from 'antd'
import {
  DetailGrid,
  PageHeader,
  Section
} from 'components'
import {
  TProjectDetail,
  TSupplierRequest,
  useProject,
  useProjectRequest
} from 'repositories'
import {
  formatCurrency,
  formatDateToView
} from 'utils/helpers'
import { cnpj } from 'cpf-cnpj-validator'
import {
  paymentTypes,
  purchaseTypes,
  TypeRequest
} from 'utils/constants'
import printJS from 'print-js'
import type { ColumnsType } from 'antd/lib/table'
import { tableLocale } from 'utils/lang'
import { useNavigate, useParams } from 'react-router'

type TSupplier = Pick<TSupplierRequest, 'cnpj' | 'corporateName' | 'email' | 'telephone'> & {
  city: string
  state: string
  score: number
  formattedScore: string
}

type TProjectRequestItem = {
  key?: string
  product: string
  projectName: string
  project?: TProjectDetail
  unitCost: number
  totalCost: number
  technicalScope: string
  amount: number
}

type TProjectRequest = {
  issueDate: string
  serviceStartDate: string
  serviceEndDate: string
  approvalHierarchy: string
  purchaseType: string
  paymentType: string
  paymentMethod: string
  paymentTerm: string
  typeRequest: string
  items: TProjectRequestItem[]
}

const columns: ColumnsType<TProjectRequestItem> = [
  {
    title: 'Produto',
    dataIndex: 'product',
    key: 'product'
  },
  {
    title: 'Projeto',
    dataIndex: 'projectName',
    key: 'projectName'
  },
  {
    title: 'Quantidade',
    dataIndex: 'amount',
    key: 'amount',
    align: 'right'
  },
  {
    title: 'Custo',
    dataIndex: 'unitCost',
    key: 'unitCost',
    align: 'right',
    render: (_, record) => formatCurrency(record.unitCost, false)
  },
  {
    title: 'Total',
    dataIndex: 'totalCost',
    key: 'totalCost',
    align: 'right',
    render: (_, record) => formatCurrency(record.totalCost, false)
  },
  {
    title: 'Escopo Técnico',
    dataIndex: 'technicalScope',
    key: 'technicalScope'
  }
]

const RequisitionContract = () => {
  const [supplierWinner, setSupplierWinner] = useState<TSupplier>({} as TSupplier)
  const [projectRequest, setProjectRequest] = useState<TProjectRequest>({} as TProjectRequest)

  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const repositoryRequest = useProjectRequest()
  const repositoryProject = useProject()

  useEffect(() => {
    const getProjectInfo = (index: number, projectId: string, typeRequest: string) => {
      if (typeRequest === TypeRequest.SIMPLE && !index) {
        return repositoryProject.findProjectById(projectId)
      }
      if (typeRequest === TypeRequest.PURCHASE_PACKAGE) return repositoryProject.findProjectById(projectId)

      return undefined
    }
    const getRequestInfo = async () => {
      const response = await repositoryRequest.findProjectRequestById(Number(id))
      if (!response) return

      const results: TProjectRequestItem[] = await Promise.all(response.items.map(async (item, index): Promise<TProjectRequestItem> => {
        const projectId = String(item.projectRevision.project.id)
        const project = await getProjectInfo(index, projectId, response.typeRequest)

        return {
          projectName: item.projectRevision.project.name,
          product: item.productDescription,
          amount: item.amount,
          unitCost: item.unitCost,
          totalCost: item.unitCost * item.amount,
          technicalScope: item.technicalScope,
          project: project ? {
            ...project,
            company: {
              ...project.company,
              cnpj: cnpj.format(project.company.cnpj)
            },
            client: {
              ...project.client,
              cnpj: cnpj.format(project.client.cnpj)
            },
            dateOfSale: formatDateToView(project.dateOfSale || ''),
            inclusionDate: formatDateToView(project.inclusionDate || ''),
          } : undefined
        }
      }))

      setProjectRequest({
        issueDate: formatDateToView(response.issueDate),
        approvalHierarchy: response.approvalHierarchyName,
        purchaseType: purchaseTypes[response.purchaseType],
        paymentType: paymentTypes[response.paymentType],
        paymentMethod: response.paymentMethod?.description,
        paymentTerm: `${response.paymentTerm} Dia(s)`,
        serviceStartDate: formatDateToView(response.serviceStartDate),
        serviceEndDate: formatDateToView(response.serviceEndDate),
        typeRequest: response.typeRequest === TypeRequest.SIMPLE ? 'Simples' : 'Pacote de Compras',
        items: results
      })
    }

    const getSupplierQuoteInfo = async () => {
      const response = await repositoryRequest.findAnalyzeQuoteById(Number(id))
      if (!response) return

      const findWinner = response.quotes.find(quote => quote.isWinner)
      if (!findWinner) return

      const { supplier } = findWinner

      setSupplierWinner({
        ...supplier,
        cnpj: cnpj.format(supplier.cnpj),
        city: `${supplier.city}/${supplier.state}`,
        score: supplier.score || 0,
        formattedScore: (
          supplier.score
            ? `${supplier.score} Estrela(s)`
            : 'Não avaliado'
        )
      })
    }

    if (id) {
      getRequestInfo()
      getSupplierQuoteInfo()
    }
  }, [id])

  const summary = useMemo(() => {
    const summaryData = projectRequest.items?.reduce((acc, curr) => ({
      amount: acc.amount + curr.amount,
      unitCost: acc.unitCost + curr.unitCost,
      totalCost: acc.totalCost + curr.totalCost,
    }), {
      amount: 0,
      unitCost: 0,
      totalCost: 0,
    })

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell
          colSpan={2}
          index={1}
          align='right'
        >
          Total
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='right'
        >
          {summaryData?.amount}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='right'
        >
          {formatCurrency(summaryData?.unitCost, false)}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={2}
          align='right'
        >
          {formatCurrency(summaryData?.totalCost, false)}
        </Table.Summary.Cell>
        <Table.Summary.Cell
          index={7}
          colSpan={1}
        />
      </Table.Summary.Row>
    )
  }, [projectRequest.items])

  const onClickGoBack = () => {
    navigate('/requisicao-compras')
  }

  const handlePrint = () => printJS('print', 'html')

  return (
    <>
      <PageHeader
        title='Informações de Contrato'
        breadcrumbItems={['Requisições', 'Contrato']}
      />
      <Section>
        <div id='print'>
          <DetailGrid>
            <Descriptions.Item label='Emissão'>
              {projectRequest?.issueDate}
            </Descriptions.Item>
            <Descriptions.Item label='Tipo'>
              {projectRequest?.purchaseType}
            </Descriptions.Item>
            <Descriptions.Item label='Pagamento por'>
              {projectRequest?.paymentType}
            </Descriptions.Item>
            <Descriptions.Item label='Forma de pagamento'>
              {projectRequest?.paymentMethod}
            </Descriptions.Item>
            <Descriptions.Item label='Prazo de pagamento'>
              {projectRequest?.paymentTerm}
            </Descriptions.Item>
            <Descriptions.Item label='Alçada'>
              {projectRequest?.approvalHierarchy}
            </Descriptions.Item>
            <Descriptions.Item label='Requisição'>
              {projectRequest?.typeRequest}
            </Descriptions.Item>
            <Descriptions.Item label='Início do serviço'>
              {projectRequest?.serviceStartDate}
            </Descriptions.Item>
            <Descriptions.Item label='Fim do serviço'>
              {projectRequest?.serviceEndDate}
            </Descriptions.Item>
          </DetailGrid>
          <Divider plain orientation='left'>
            Fornecedor
          </Divider>
          <DetailGrid>
            <Descriptions.Item label='CNPJ'>
              {supplierWinner?.cnpj}
            </Descriptions.Item>
            <Descriptions.Item label='Razão Social'>
              {supplierWinner?.corporateName}
            </Descriptions.Item>
            <Descriptions.Item label='Email'>
              {supplierWinner?.email}
            </Descriptions.Item>
            <Descriptions.Item label='Cidade'>
              {supplierWinner?.city}
            </Descriptions.Item>
          </DetailGrid>
          {projectRequest.items?.map(item => item.project && (
            <>
              <Divider plain orientation='left'>
                Projeto
              </Divider>
              <DetailGrid>
                <Descriptions.Item label='Id ERP'>
                  {item.project?.idERP}
                </Descriptions.Item>
                <Descriptions.Item label='Projeto'>
                  {item.project?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Criado em'>
                  {item.project?.inclusionDate}
                </Descriptions.Item>
                <Descriptions.Item label='Data da venda'>
                  {item.project?.dateOfSale}
                </Descriptions.Item>
                <Descriptions.Item label='Fase'>
                  {item.project?.phase?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Categoria do Projeto'>
                  {item.project?.projectCategory?.name}
                </Descriptions.Item>
              </DetailGrid>
              <Divider plain orientation='left'>
                Empresa de Faturamento
              </Divider>
              <DetailGrid>
                <Descriptions.Item label='CNPJ'>
                  {item.project?.company?.cnpj}
                </Descriptions.Item>
                <Descriptions.Item label='Razão Social'>
                  {item.project?.company?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Filial'>
                  {item.project?.subsidiary?.name}
                </Descriptions.Item>
              </DetailGrid>
              <Divider plain orientation='left'>
                Cliente
              </Divider>
              <DetailGrid>
                <Descriptions.Item label='CNPJ'>
                  {item.project?.client?.cnpj}
                </Descriptions.Item>
                <Descriptions.Item label='Razão Social'>
                  {item.project?.client?.corporateName}
                </Descriptions.Item>
                <Descriptions.Item label='Nome Fantasia'>
                  {item.project?.client?.fantasyName}
                </Descriptions.Item>
                <Descriptions.Item label='Email'>
                  {item.project?.client?.email}
                </Descriptions.Item>
              </DetailGrid>
            </>
          ))}
          <Divider plain orientation='left'>
            Items
          </Divider>
          <Table
            size='middle'
            locale={tableLocale}
            columns={columns}
            dataSource={projectRequest.items}
            summary={() => summary}
          />
        </div>
        <Space
          size='small'
          className='mt-1'
        >
          <Button
            onClick={onClickGoBack}
            htmlType='button'
          >
            Voltar
          </Button>
          <Button
            type='primary'
            onClick={handlePrint}
          >
            Imprimir
          </Button>
        </Space>
      </Section>
    </>
  )
}

export default RequisitionContract
