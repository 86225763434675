import useRest from 'hooks/useRest'
import { useState } from 'react'

export const storageKey = 'ManagerTV1:data'

const useLogin = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const requestTokens = async (data: URLSearchParams, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }

    const url = process.env.REACT_APP_KEYCLOAK_URL as string
    const response = await rest.post(url, data, headers)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    const tokensDetails = {
      token: response.access_token,
      refresh_token: response.refresh_token,
      expires_in: response.expires_in
    }

    return tokensDetails
  }

  const syncKeycloakByEmail = async (email: string, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/system-user/sync-by-email'
    const body = {
      email,
    }
    const response = await rest.post(url, body)
    if (triggerLoading) setLoading(false)
    if (!response) return false

    return true
  }

  const logLogin = async (triggerLoading = true) => {
    const url = '/v1/system-user/log'
    if (triggerLoading) setLoading(true)
    const response = await rest.post(url)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return true
  }

  const logout = async (triggerLoading = true) => {
    const url = '/v1/system-user/logout'
    if (triggerLoading) setLoading(true)
    const response = await rest.post(url)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return true
  }

  return {
    loading,
    requestTokens,
    syncKeycloakByEmail,
    logLogin,
    logout
  }
}

export default useLogin
