import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  padding: 0px 0px 6px;
  margin: 5px 0;

  > div {
    padding: 5px 15px;
  }

  > div:last-child {
    border-right: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MB}) {
    display: flex;
    flex-direction: column;
  }
`;

interface ContentTitleProps {
  backgroundColor: string;
}

export const ContentTitle = styled.div.attrs((props: ContentTitleProps) => ({
  title: props.backgroundColor,
}))<ContentTitleProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color:${props => props.backgroundColor};
  // background-color: ${({ theme }) => theme.colors.APPROVAL_BLUE};
  color: ${({ theme }) => theme.colors.WHITE};
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ContentBox = styled.div`
  display: grid;
  grid-template-columns: 55% 1fr;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.WHITE};
  // border: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  padding: 6px 0;
  margin: 5px 0;

  > div {
    padding: 5px 15px;
  }

  > div:last-child {
    border-right: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MB}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-top: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const YellowButton = styled.button`
  background-color: #e9ad40;
  border: #c3d9d0;
  padding: 7px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e99c40; // Cor mais clara ao passar o mouse
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 15px 0 5px;
`;

export const Content = styled.div`
  border-right: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};

  &.content-spacing {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MB}) {
    width: 100%;
    border-right: none;
    border-bottom: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h1 {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.GREEN};
    font-weight: bold;
  }
`;

export const SummaryActionsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 1.2rem 0 0.5rem;
  font-weight:400;

  span {
    font-size: 14px;
  }
`;

export const SummaryActions = styled.div`
  display: flex;
  flex-direction: collumn;
  align-items: center;
  justify-content: space-between;
  // height: 100%;

  p {
    font-size: 20px;
    font-weight: 700;
  }

  span {
    font-size: 12px;
    
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MB}) {
    display: flex;
    flex-direction: column;
  }
`;
export const UserApproval = styled.div`
  display: flex;
  justify-content: space-between; 
  font-size: 12px;
  padding: 0.8rem 0;

  .ant-btn-default {
    display: flex;
    padding: 15px 16px;
    align-items: center;
    gap: 8px;
    border-radius: var(--4, 4px);
    border: 1px solid #1B5E20;
    color: #1B5E20;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 0.7rem;

    &:hover {
      border-radius: 4px;
      border: 1px solid #D4D4D4;
      background: #D4D4D4;
    }

    &:not(.disable):hover {
      border-radius: 4px;
      border: 1px solid #1B5E20;
      background: #C8E6C9;
    }
    
    &:disabled {
      color: #D4D4D4;
      border-radius: 4px;
      border: 1px solid #D4D4D4;
      // pointer-events: none;
      cursor: not-allowed;
    }
  }

  .ant-btn-dangerous {
    display: flex;
    padding: 15px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #B71C1C;
    color: #B71C1C;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 0.7rem;
    
    &:not(.disable):hover {
      border-radius: 4px;
      border: 1px solid #B71C1C;
      background: #FFCDD2;
    }

    &:disabled {
      color: #D4D4D4;
      border-radius: 4px;
      border: 1px solid #D4D4D4;
      // pointer-events: none;
      cursor: not-allowed;
    }
  }

  .ant-btn-question {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #636363;
    color: #636363;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:not(.disable):hover {
      border-radius: 4px;
      border: 1px solid #636363;
      background: #F7F7F7;
    }

    &:disabled {
      color: rgb(212, 212, 212);
      border-radius: 4px;
      border: 1px solid rgb(212, 212, 212);
      cursor: not-allowed;

      img {
        opacity: 0.4;
      }
    }

    &.new-question {
      border: 1px solid #E65100;
      color: #E65100;

      &:not(.disable):hover {
        border-radius: 4px;
        border: 1px solid #E65100;
        background: #F7F7F7;
      }
    }
    
  }
`;
export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  p {
    margin: 0;
  }

  > div div.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-items: flex-start;
    height: min-content;
    margin-bottom: 10px;
  }

  > div span {
  }

  > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1rem;
    // width: 140px;

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      // line-height: normal;
      line-height: 14px;
      color: #404040;
    }
  }

  .tagContents {
    display: flex;
    flex-direction:row;
    justify-content:space-between;

    .apportionERP{
      cursor: default;
      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #1B5E20;
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    > div:last-child p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SM}) {
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    font-size: 12px;
    margin-bottom: 10px;

    > div:last-child {
      align-items: flex-start;

      p {
        font-size: 14px;
      }
    }
  }
`;

interface LabelProps {
  textColor: string;
}

export const Label = styled.span.attrs((props: LabelProps) => ({
  textColor: props.textColor,
}))<LabelProps>`
  font-size: 10px;
  padding: 4px 10px;
  color: ${props => props.textColor};
  background-color: ${(p) => p.color};
  border-radius: 4px;
  cursor: default;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    font-size: 9px;
  }
`;

export const AdditionalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  > div {
    display: flex;
    gap: 10px;
  }

  // > div:last-child {
  //   align-self: flex-end;
  //   font-size: 12px;
  // }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    flex-direction: column;
  }
`;

export const UserInteraction = styled.div`
  display: flex;
  justify-content: space-between; 
  font-size: 12px;
  padding-top: 0.80rem;

  .ant-btn-default {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    border-radius: var(--4, 4px);
    border: 1px solid #636363;
    color: #404040;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      background-color: #F7F7F7;
    }
  }

  .ant-btn-link {
    display: flex;
    padding: 12px 0px;
    align-items: center;
    gap: 8px;
    color: #0D47A1;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;

    &:hover {
      color: #424242;
      text-decoration-line: underline;
    }
  }
`;

export const Abbreviation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.BLACK};
  }

  span.label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.WHITE};
  }
`;

export const InfoOrder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-size: 16px;
    margin: 0;
  }

  span:not(.label) {
    font-size: 12px;
  }

  span.label {
    padding: 5px 15px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  span.productTitle {
    font-size: 17px;
    // font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #1C1C1;
  }

  div.supplierContent {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }

  > div.scope {
    display:flex;
    flex-direction: column;
    align-items: start;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    span:not(.label) {
      font-size: 11px;
    }

    span.productTitle {
      font-size: 14px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const Tarja = styled.div`
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 100%;
  border-radius: 5px;
`;