/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { Container, Main, Content } from "./styles";

import Header from "./Header";
import Sidebar from "./Sidebar";

import { useProfile } from "repositories";

const Template = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const { userData, isSignedIn, hasToken, saveUserData } = useAuth();

  const repositoryProfile = useProfile();

  const getProfile = async () => {
    if (userData) {
      const userProfile = await repositoryProfile.findUserProfile();
      saveUserData({ ...userData, userId: userProfile.id });
    }
  };

  useEffect(() => {
    getProfile();
    const closeSidebar = () => {
      if (window.innerWidth <= 1024) {
        setIsSidebarOpen(false);
        return;
      }
      setIsSidebarOpen(true);
    };

    window.addEventListener("resize", closeSidebar);
    closeSidebar();
  }, []);

  if (!isSignedIn && !hasToken()) return <Navigate to="/login" />;

  return (
    <Container>
      <Header
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Main>
        <Sidebar isSidebarOpen={isSidebarOpen} />
        <Content isSidebarOpen={isSidebarOpen}>
          <Outlet />
        </Content>
      </Main>
    </Container>
  );
};

export default Template;
