import React, { useEffect, useMemo } from 'react'
import {
  Button,
  Form,
  Modal
} from 'antd'
import { TSupplierQuoteItem } from 'repositories'
import {
  formatCurrency,
  convertStringToNumber
} from 'utils/helpers'
import {
  AreaField,
  Col,
  CurrencyField,
  HiddenField,
  Row,
  TextField
} from 'components'
import type { Rule } from 'antd/lib/form'
import Attachments from './Attachments'

type TTSupplierQuoteItemData = TSupplierQuoteItem & {
  key: string
  index: number
}

type TSupplierQuoteItemForm = TSupplierQuoteItem

const validations: Record<string, Rule[]> = {
  unitValue: [
    { required: true, message: 'Campo "Valor unitário" é obrigatório' },
    () => ({
      validator(_, unitValue: number) {
        const formattedValue = convertStringToNumber(unitValue)
        if (!unitValue || formattedValue <= 0) {
          return Promise.reject(new Error('Valor Unitário deve ser maior que zero'))
        }

        return Promise.resolve()
      },
    })
  ],
  observation: [
    { required: true, message: 'Campo "Observação" é obrigatório' }
  ]
}

type TProps = {
  itemQuoteIndex: number
  setItemQuoteIndex: React.Dispatch<number>
  isVisibleModal: boolean
  setIsVisibleModal: React.Dispatch<boolean>
  quoteItems: TTSupplierQuoteItemData[]
  setQuoteItems: React.Dispatch<TTSupplierQuoteItemData[]>
}

const ItemForm = (props: TProps) => {
  const {
    itemQuoteIndex,
    setItemQuoteIndex,
    isVisibleModal,
    setIsVisibleModal,
    quoteItems,
    setQuoteItems
  } = props

  const [form] = Form.useForm<TSupplierQuoteItemForm>()
  const watchedId = Form.useWatch('id', form)

  useEffect(() => {
    const findItem = quoteItems[itemQuoteIndex]
    form.setFieldsValue(findItem)
  }, [itemQuoteIndex, isVisibleModal])

  const onBlurUnitValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = form.getFieldsValue()
    const { value } = e.target
    const unitValue = convertStringToNumber(value)
    const amount = convertStringToNumber(formData.amount)
    const daily = convertStringToNumber(formData.daily)

    const totalValue = formatCurrency(daily ? (unitValue * amount) * formData.daily : unitValue * amount, false)
    form.setFieldsValue({
      unitValue: formatCurrency(unitValue, false),
      totalValue,
    })
  }

  const handleClose = () => setIsVisibleModal(false)

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
    handleClose()
  }

  const onPreviousItem = () => {
    if (itemQuoteIndex === 0) return
    const previousItemQuoteIndex = itemQuoteIndex - 1
    setItemQuoteIndex(previousItemQuoteIndex)
  }

  const onNextItem = () => {
    const index = itemQuoteIndex + 1
    const isLastIndex = index === quoteItems.length
    if (isLastIndex) return

    setItemQuoteIndex(index)
  }

  const saveAndNextItem = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
    onNextItem()
  }

  const onFinish = (values: TSupplierQuoteItemForm) => {
    const mappedItems = quoteItems.map(quoteItem => {
      if (quoteItem.id !== values.id) return quoteItem

      return {
        ...quoteItem,
        ...values
      }
    })
    setQuoteItems(mappedItems)
  }

  const isLastItem = useMemo(() => (
    (itemQuoteIndex + 1) === quoteItems.length
  ), [quoteItems, itemQuoteIndex])

  return (
    <Modal
      visible={isVisibleModal}
      title='Editar Item'
      onCancel={handleClose}
      footer={[
        <Button
          key='back'
          onClick={handleClose}
        >
          Fechar
        </Button>,
        <Button
          key='save'
          type='primary'
          onClick={handleSubmit}
        >
          Atualizar
        </Button>,
        <Button
          key='previous'
          disabled={quoteItems?.length === 1}
          onClick={onPreviousItem}
        >
          Anterior
        </Button>,
        <Button
          key='saveAndNext'
          disabled={isLastItem}
          onClick={saveAndNextItem}
        >
          Próximo
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row>
          <HiddenField
            name='id'
          />
          <Col xl={4}>
            <TextField
              name='productId'
              label='Id Produto'
              disabled
            />
          </Col>
          <Col xl={20}>
            <TextField
              name='productDescription'
              label='Produto'
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField
              name='amount'
              label='Quantidade'
              disabled
            />
          </Col>
          <Col xl={12}>
            <TextField
              name='daily'
              label='Diárias'
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <CurrencyField
              required
              name='unitValue'
              label='Valor Unitário'
              rules={validations.unitValue}
              onBlur={onBlurUnitValue}
            />
          </Col>
          <Col xl={12}>
            <TextField
              name='totalValue'
              label='Valor Total'
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <AreaField
              required
              name='observation'
              label='Observação'
              rules={validations.observation}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <AreaField
              disabled
              name='technicalScope'
              label='Escopo Técnico'
            />
          </Col>
        </Row>
        <Attachments
          supplierQuoteItemId={watchedId}
          isFullWidth
        />
      </Form>
    </Modal>
  )
}

export default ItemForm
