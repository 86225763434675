import React, { useEffect, useMemo, useState } from 'react'
import type { ColumnsType, TableProps } from 'antd/lib/table'
import {
  Button,
  Input,
  Space,
  Table
} from 'antd'
import { Navigate, useNavigate } from 'react-router'
import {
  PageHeader,
  Section
} from 'components'
import {
  TApprovalHierarchy, TApprovalHierarchyParams,
  useApprovalHierarchy
} from 'repositories'
import {
  usePagination,
  useAuth
} from 'hooks'
import { SorterResult } from 'antd/lib/table/interface'
import { FilterContainer } from './styles'

type TApprovalHierarchyData = Pick<TApprovalHierarchy, 'id' | 'name' | 'reference' > & {
  key: string
}

const columns: ColumnsType<TApprovalHierarchyData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    sorter: true
  }
]

const ApprovalHierarchyList = () => {
  const [approvalHierarchies, setApprovalHierarchies] = useState<TApprovalHierarchyData[]>([])
  const [search, setSearch] = useState()
  const [sort, setSort] = useState('')

  const { roles: authRoles, hasRole } = useAuth()
  const repository = useApprovalHierarchy()
  const navigate = useNavigate()
  const pagination = usePagination()

  const requestProduct = async () => {
    const currentPage = pagination.data.current ?? 0

    const params: TApprovalHierarchyParams = {
      requestId: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
    }

    const response = await repository.findByFilterApprovalHierarchy(params)
    if (!response) return

    const mappedData: TApprovalHierarchyData[] = response.content.map(approvalHierarchy => ({
      key: String(approvalHierarchy.id),
      id: approvalHierarchy.id,
      name: approvalHierarchy.name,
      reference: approvalHierarchy.reference
    }))
    setApprovalHierarchies(mappedData)
    pagination.changeTotalPages(response.totalPages)
  }

  const handleChange: TableProps<TApprovalHierarchyData>['onChange'] = (paginationConfig, filters, sorter) => {
    pagination.onChangeTable(paginationConfig)
    const { field, order } = sorter as SorterResult<TApprovalHierarchyData>
    if (!field) return

    const dir = order === 'ascend' ? 'ASC' : 'DESC'
    setSort(`${field},${dir}`)
  }

  useEffect(() => {
    if (pagination.data.current) requestProduct()
  }, [pagination.data.current, pagination.data.pageSize, sort, search])

  const onClickGoToNew = () => navigate('/alcadas/novo')
  const onClickGoToView = (id: number) => () => navigate(`/alcadas/visualizar/${id}`)
  const onClickGoToEdit = (id: number) => () => navigate(`/alcadas/editar/${id}`)

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Button
            onClick={onClickGoToView(record.id)}
            disabled={!hasRole(authRoles?.approvalhierarchy_view)}
            size='small'
            type='text'
          >
            Visualizar
          </Button>
          <Button
            onClick={onClickGoToEdit(record.id)}
            disabled={!record.reference}
            size='small'
            type='text'
          >
            Editar
          </Button>
        </Space>
      ),
    }
  ], [authRoles])

  const onSearch = (value: any) => {
    setSearch(value)
    pagination.onChange(1, pagination.data.pageSize ?? 1)
  }

  if (authRoles && !hasRole(authRoles?.approvalhierarchy_view)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <PageHeader
        title='Alçadas de Aprovação'
        breadcrumbItems={['Alçadas de Aprovação', 'Listar']}
      />
      <Section>
        <FilterContainer>
          <Input.Search
            placeholder='Pesquise pelo ID da requisição'
            onSearch={onSearch}
            enterButton
          />
          <Button
            onClick={onClickGoToNew}
            disabled={!hasRole(authRoles?.approvalhierarchy_add)}
            type='primary'
          >
            Novo
          </Button>
        </FilterContainer>
        <Table
          size='middle'
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={approvalHierarchies}
          pagination={pagination.data}
        />
      </Section>
    </>
  )
}

export default ApprovalHierarchyList
