import React, { useEffect, useMemo, useState } from "react";
import type { ColumnsType, TableProps } from "antd/lib/table";
import { Button, Input, Table, Tag, Modal, message, Rate } from "antd";
import { Navigate, useNavigate } from "react-router";
import { DropdownButton, PageHeader, Section } from "components";
import {
  TFindSupplierByFilterParams,
  TSupplierFormStepOne,
  useSuppliers,
} from "repositories";
import {usePagination, useAuth, useFilters} from "hooks";
import { SorterResult } from "antd/lib/table/interface";
import { SupplierStatus, supplierStatus, SupplierType } from "utils/constants";
import { tableLocale } from "utils/lang";
import { removeMaskCnpj } from "utils/helpers";
import { cnpj } from "cpf-cnpj-validator";
import { FilterContainer } from "./styles";
import ScoreForm from "../Components/ScoreForm";
import AnalysisForm from "../Components/AnalysisForm";
import ProfileForm from "../Components/ProfileForm";
import moment from "moment";
import FiltersForm from "./components/FiltersForm";
import styled from "styled-components";


const { confirm } = Modal;

type TSupplierData = TSupplierFormStepOne & {
  key: string;
};

const SupplierList = () => {
  const [suppliers, setSuppliers] = useState<TSupplierData[]>([]);
  const [isOpenScoreForm, setIsOpenScoreForm] = useState(false);
  const [isOpenAnalysisForm, setIsOpenAnalysisForm] = useState(false);
  const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false)
  const [supplierId, setSupplierId] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState(null);
  const filters = useFilters()
  const { roles: authRoles, hasRole } = useAuth();
  const repository = useSuppliers();
  const navigate = useNavigate();
  const pagination = usePagination();
  const showModal = (id: any) => {
    setCurrentSupplier(id);
    setIsModalVisible(true);
  };

  const columns: any = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      align: "center",
      sorter: true,
    },
    {
      title: "ID TOTVS",
      dataIndex: "erpId",
      key: "erpId",
      align: "center",
      sorter: true,
    },
    {
      title: "Razão Social",
      dataIndex: "corporateName",
      key: "corporateName",
      sorter: true,
      align: "center",
    },
    {
      title: "Nome Fantasia",
      dataIndex: "fantasyName",
      key: "fantasyName",
      sorter: true,
      align: "center",
    },
    {
      title: "CNPJ",
      dataIndex: "cnpj",
      key: "cnpj",
      sorter: true,
      align: "center",
      render: (_: any, record: any) => cnpj.format(record.cnpj),
    },
    {
      title: "Tipo",
      dataIndex: "typeSupplier",
      key: "typeSupplier",
      sorter: true,
      align: "center",
      render: (_: any, record: any) =>
        record.typeSupplier === SupplierType.SUPPLIER
          ? "Fornecedor"
          : "Freelancer",
    },
    {
      title: "Cadastrado em",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
      align: "center",
      render: (_: any, record: any) => {
        return record.createdDate
          ? moment(record.createdDate).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Última homologação",
      dataIndex: "dateLastApproval",
      key: "dateLastApproval",
      sorter: true,
      align: "center",
      render: (_: any, record: any) => {
        return record.dateLastApproval
          ? moment(record.dateLastApproval).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      align: "center",
      render: (_: any, record: any) => {
        if (record.status === SupplierStatus.APPROVED) {
          return <Tag color="green">Homologado</Tag>;
        }

        if (record.status === SupplierStatus.NOT_APPROVED) {
          return <Tag color="red">Bloqueado</Tag>;
        }

        if (record.status === SupplierStatus.PRE_REGISTRATION) {
          return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
        }

        return <Tag>{supplierStatus[record.status]}</Tag>;
      },
    },
    {
      title: "Avaliação",
      dataIndex: "currentEvaluation",
      key: "currentEvaluation",
      sorter: true,
      align: "center",
      render: (_: any, record: any) => (
        // <div onClick={() => showModal(record.id)}>
        <Rate disabled value={record.currentEvaluation || 0} />
        // </div>
      ),
    },
  ];

  const requestSupplier = async () => {
    const currentPage = pagination.data.current ?? 0;
    const _filters = filters.getObjectifiedFilterItems();

    const params: TFindSupplierByFilterParams = {
      search: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
      ..._filters
    };

    const response = await repository.findSupplierByFilter(params);
    if (!response) return;

    const mappedData: TSupplierData[] = response.content.map((supplier) => ({
      key: String(supplier.id),
      ...supplier,
    }));
    setSuppliers(mappedData);
    pagination.changeTotalPages(response.totalPages);
  };

  const handleChange: TableProps<TSupplierData>["onChange"] = (
    paginationConfig,
    filters,
    sorter
  ) => {
    pagination.onChangeTable(paginationConfig);
    const { field, order } = sorter as SorterResult<TSupplierData>;
    if (!field) return;

    const dir = order === "ascend" ? "ASC" : "DESC";
    setSort(`${field},${dir}`);
  };

  useEffect(() => {
    if (pagination.data.current) requestSupplier();
  }, [pagination.data.current, pagination.data.pageSize, sort, search, filters.items]);

  const onClickGoToNew = () => navigate("/fornecedores/novo");
  const onClickGoToView = (id: number) => () =>
    navigate(`/fornecedores/visualizar/${id}`);
  const onClickGoToEdit = (id: number) => () =>
    navigate(`/fornecedores/editar/${id}`);

  const handleSendMail = async (recordId: number) => {
    await repository.sendEmailPreRegistration(recordId);
    message.success("Email enviado com sucesso!");
  };

  const showConfirmSendEmail = (id: number) => () => {
    confirm({
      title: "Confirmação",
      content: "Deseja reenviar o convite para atualização cadastral?",
      type: "confirm",
      okText: "Sim",
      cancelText: "Não",
      async onOk() {
        await handleSendMail(id);
      },
    });
  };

  const onClickOpenScoreForm = (id: number) => () => {
    setSupplierId(id);
    setIsOpenScoreForm(true);
  };

  const onClickOpenAnalysisForm = (id: number) => () => {
    setSupplierId(id);
    setIsOpenAnalysisForm(true);
  };

  const onSearch = (value: string) => setSearch(removeMaskCnpj(value));

  const updatedColumns = useMemo(
    () => [
      ...columns,
      {
        title: "",
        key: "action",
        render: (_: any, record: any) => {
          const id = Number(record.id);
          const itemsMenu = [
            {
              key: "1",
              label: "Visualizar",
              onClick: onClickGoToView(id),
            },
            {
              key: "2",
              label: "Editar",
              disabled: !hasRole(authRoles?.supplier_edit),
              onClick: onClickGoToEdit(id),
            },
            {
              key: "3",
              label: "Análise fiscal e financeira",
              disabled: record.status !== SupplierStatus.HOMOLOGATED,
              onClick: onClickOpenAnalysisForm(id),
            },
            // {
            //   key: '4',
            //   disabled: record.status !== SupplierStatus.PRE_REGISTRATION,
            //   label: 'Enviar email para atualização cadastral',
            //   onClick: showConfirmSendEmail(id)
            // },
            // {
            //   key: '5',
            //   disabled: record.status !== 'APPROVED',
            //   label: 'Avaliar fornecedor',
            //   onClick: onClickOpenScoreForm(id)
            // },
          ];
          return <DropdownButton items={itemsMenu} />;
        },
      },
    ],
    [authRoles]
  );

  if (authRoles && !hasRole(authRoles?.supplier_view)) {
    return <Navigate to="/acesso-negado" />;
  }
  const onClickOpenFilterForm = () => {
    setIsVisibleFilterForm(true)
  }

  const FilterContainer = styled.div`
  display: flex;
  justify-content: right; /* Align items to the left */
`;

  return (
    <>
      <PageHeader
        title="Fornecedores"
        breadcrumbItems={["Fornecedores", "Listar"]}
      />
      <Section>
        <FilterContainer>
          <Button
              onClick={onClickOpenFilterForm}
          >
            Filtros
          </Button>
          <Button
              onClick={onClickGoToNew}
              type="primary"
              disabled={!hasRole(authRoles?.supplier_add || authRoles.role_admin)}
          >
            Novo
          </Button>
        </FilterContainer>
        <Table
          size="middle"
          locale={tableLocale}
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={suppliers}
          pagination={pagination.data}
        />
        <ScoreForm
          supplierId={supplierId}
          isModalVisible={isOpenScoreForm}
          setIsModalVisible={setIsOpenScoreForm}
          requestFn={requestSupplier}
        />
        <AnalysisForm
          supplierId={supplierId}
          isModalVisible={isOpenAnalysisForm}
          setIsModalVisible={setIsOpenAnalysisForm}
          requestFn={requestSupplier}
        />
      </Section>
      <FiltersForm
          filters={filters}
          isVisibleDrawer={isVisibleFilterForm}
          setIsVisibleDrawer={setIsVisibleFilterForm}
      />
      <ProfileForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        supplierId={currentSupplier}
      />
    </>
  );
};

export default SupplierList;
