import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Space,
  Table
} from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import {
  TRequestItem,
  TSuggestPurchasePackageOutputDTO,
  usePurchasePackage
} from 'repositories'
import { tableLocale } from 'utils/lang'
import { useNavigate } from 'react-router'
import { formatCurrency } from 'utils/helpers'

type TSuggestPurchasePackageData = TSuggestPurchasePackageOutputDTO & {
  key: string
}

const columns: ColumnsType<TSuggestPurchasePackageData> = [
  {
    title: 'Id',
    dataIndex: 'productId',
    key: 'productId',
  },
  {
    title: 'Produto',
    dataIndex: 'productName',
    key: 'productName',
  },
  {
    title: 'N° Projetos',
    dataIndex: 'countItems',
    key: 'countItems',
    align: 'right',
    render(_, record) {
      return record.projects.length
    },
  },
  {
    title: 'Quantidade',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    align: 'right',
  },
  {
    title: 'Diárias',
    dataIndex: 'totalDaily',
    key: 'totalDaily',
    align: 'right',
  },
  {
    title: 'Valor total',
    dataIndex: 'totalSale',
    key: 'totalSale',
    align: 'right',
    render(_, record) {
      const totalProducts = record.projects.reduce((acc, curr) => (
        acc + curr.unitSale
      ), 0)
      return formatCurrency(totalProducts, false)
    },
  },
]

type TProps = {
  setAvailableRequestItems: React.Dispatch<TRequestItem[]>
  onChangeStep(goTo: number): void
}

const ProjectOrPackageList = (props: TProps) => {
  const {
    setAvailableRequestItems,
    onChangeStep
  } = props
  const [productId, setProductId] = useState(0)
  const [packages, setPackages] = useState<TSuggestPurchasePackageData[]>([])
  const purchasePackageRepository = usePurchasePackage()
  const navigate = useNavigate()

  useEffect(() => {
    const getPackages = async () => {
      const response = await purchasePackageRepository.findPackageByFilter({})
      if (!response) return

      const mappedResponse = response.map(item => ({
        ...item,
        key: String(item.productId)
      }))

      setPackages(mappedResponse)
    }

    getPackages()
  }, [])

  const onClickGoNextStep = () => {
    const findProduct = packages.find(purchasePackage => purchasePackage.productId === productId)
    if (!findProduct) return

    const mappedToRequestItems = findProduct.projects.map(project => ({
      key: String(project.projectRevisionItemId),
      productId: findProduct.productId,
      productName: findProduct.productName,
      ...project
    }) as TRequestItem)

    setAvailableRequestItems(mappedToRequestItems)
    onChangeStep(1)
  }

  const onClickGoBack = () => navigate('/requisicao-compras')

  const rowSelection = {
    onChange: (_: React.Key[], selectedRow: TSuggestPurchasePackageData[]) => {
      const [row] = selectedRow
      setProductId(row.productId)
    }
  }

  return (
    <>
      <Divider />
      <Table
        size='middle'
        locale={tableLocale}
        loading={purchasePackageRepository.loading}
        columns={columns}
        dataSource={packages}
        rowSelection={{
          type: 'radio',
          ...rowSelection
        }}
      />
      <Space
        size='small'
        className='mt-1'
      >
        <Button
          type='primary'
          htmlType='submit'
          disabled={!productId}
          onClick={onClickGoNextStep}
        >
          Próximo
        </Button>
        <Button
          onClick={onClickGoBack}
          htmlType='button'
        >
          Cancelar
        </Button>
      </Space>
    </>
  )
}

export default ProjectOrPackageList
