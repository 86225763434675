import React, { useState } from 'react'
import {
  Col,
  CurrencyField,
  HiddenField,
  NumberField,
  Row,
  SearchField
} from 'components'
import {
  Form,
  message,
  Modal
} from 'antd'
import {
  TApprovalHierarchyUser,
  TApprovalHierarchyUserRequest,
  TUser,
  useApprovalHierarchy,
  useUsers
} from 'repositories'
import {
  formatCurrencyToServer,
  formatStringToNumber
} from 'utils/helpers'
import type { Rule } from 'antd/lib/form'

type TApprovalHierarchyUserData = TApprovalHierarchyUser & {
  key: string
}

const initialValues: Omit<TApprovalHierarchyUser, 'userEmail'> = {
  localId: 0,
  id: '',
  userId: '',
  userName: '',
  minimumValue: '',
  sla: '',
  approveSequence: 0,
  key: ''
}

const validations: Record<string, Rule[]> = {
  userName: [
    {
      required: true,
      message: 'Campo "Usuário" é obrigatório'
    },
  ],
  sla: [
    {
      required: true,
      message: 'Campo "SLA" é obrigatório'
    },
  ],
  minimumValue: [
    { required: true, message: 'Campo "Valor mínimo" é obrigatório' },
    () => ({
      validator(_, minimumValue: string) {
        const formattedValue = formatStringToNumber(minimumValue)
        if (!minimumValue && formattedValue <= 0) {
          return Promise.reject(new Error('Valor mínimo deve ser maior que zero'))
        }

        return Promise.resolve()
      },
    })
  ],
}

type TProps = {
  hierarchyId: number
  approverSize: number
  isVisibleModal: boolean
  setIsVisibleModal: React.Dispatch<boolean>
  approvers: TApprovalHierarchyUserData[]
  setApprovers: React.Dispatch<TApprovalHierarchyUserData[]>
}

const ApproverForm = (props: TProps) => {
  const {
    hierarchyId,
    approverSize,
    isVisibleModal,
    setIsVisibleModal,
    approvers,
    setApprovers
  } = props
  const [value, setValue] = useState('')
  const [options, setOptions] = useState<TSearchOptions[]>([])
  const [users, setUsers] = useState<TUser[]>([])

  const repository = useApprovalHierarchy()
  const userRepository = useUsers()
  const [form] = Form.useForm<TApprovalHierarchyUser>()

  const handleClose = () => {
    setIsVisibleModal(false)
    form.resetFields()
    setUsers([])
    setOptions([])
    setValue('')
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
  }

  const onChange = (data: string) => {
    const findUser = users.find(user => user.name === data)
    if (!findUser) return

    setValue(data)
    form.setFieldsValue({ userId: findUser.id })
  }

  const onSearch = async (searchText: string) => {
    const response = await userRepository.findByFilterUsers({
      name: searchText,
      page: 0,
      size: 10,
      activityDirector: true,
    })
    if (!response) return

    const mappedUsers = response.content.map(item => ({ value: item.name }))
    setOptions(mappedUsers)
    setUsers(response.content)
  }

  const onSortMinimumValue = (list: TApprovalHierarchyUser[]) => {
    if (!list.length) return []

    return list.sort((old, after) => {
      if (Number(old.minimumValue) > Number(after.minimumValue)) return 1
      if (Number(old.minimumValue) < Number(after.minimumValue)) return -1

      return 0
    })
  }

  const onFinish = async (values: TApprovalHierarchyUser) => {
    const data: TApprovalHierarchyUserRequest = {
      userId: values.userId,
      hierarchyId,
      approveSequence: approverSize + 1,
      minimumValue: formatCurrencyToServer(values.minimumValue),
      sla: values.sla
    }

    const response = await repository.createApprover({ approvers: [data] })
    if (!response) return

    message.success('Aprovador criado com sucesso!')
    const dataToList: TApprovalHierarchyUserData[] = response.map(item => ({
      ...item,
      key: String(item.id)
    }))

    const updatedApprovers = [...approvers, ...dataToList]
    const ordenedApprovers = onSortMinimumValue(updatedApprovers)

    const _ordenedApprovers = ordenedApprovers.map((approver, index) => ({
      ...approver,
      approveSequence: index + 1
    }))

    const _response = await repository.updateOrderSequence({
      hierarchyId,
      approvers: _ordenedApprovers.map(ordenedApprover => ({
        approvalId: Number(ordenedApprover.id),
        approveSequence: ordenedApprover.approveSequence
      }))
    })
    if (!_response) return

    message.success('Ordem de aprovadores atualizada com sucesso!')
    setApprovers(_ordenedApprovers)
    handleClose()
  }

  return (
    <Modal
      visible={isVisibleModal}
      title='Adicionar Aprovador'
      onCancel={handleClose}
      okText='Salvar'
      cancelText='Fechar'
      onOk={handleSubmit}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col xl={24}>
            <HiddenField
              name='userId'
            />
            <SearchField
              required
              name='userName'
              label='Usuário'
              rules={validations.userName}
              isLoading={userRepository.loading}
              placeholder='Pesquise pelo usuário'
              onChange={onChange}
              onSearch={onSearch}
              value={value}
              options={options}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <CurrencyField
              required
              name='minimumValue'
              label='Valor Mínimo'
              rules={validations.minimumValue}
            />
          </Col>
          <Col xl={12}>
            <NumberField
              name='sla'
              label='SLA'
              required
              textHelp='Dia(s)'
              rules={validations.sla}
              max={999}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ApproverForm
