import useRest from 'hooks/useRest'
import { useState } from 'react'
import {
  TCreateProjectItem,
  TProjectItem,
  TProjectItemParams,
  TProjectItemResponse,
  TUpdateProjectItem
} from 'repositories'

const useProjectItems = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findProjectItemsByFilter = async (
    params: TProjectItemParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-item'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return [] as TProjectItemResponse[]

    return response.result.content as TProjectItemResponse[]
  }

  const findProjectItemById = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/project-item/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TProjectItem
  }

  const createProjectItem = async (data: TCreateProjectItem, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-item'
    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TProjectItem
  }

  const updateProjectItem = async (data: TUpdateProjectItem, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/project-item'
    const response = await rest.put(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TProjectItem
  }

  return {
    loading,
    findProjectItemsByFilter,
    findProjectItemById,
    createProjectItem,
    updateProjectItem
  }
}

export default useProjectItems
