import React from 'react'
import {
  PageHeader as Header,
  Section
} from 'components'
import {
  Navigate,
  useNavigate,
  useParams
} from 'react-router'
import { useAuth } from 'hooks'
import { Tabs } from 'antd'
import RequestForm from 'pages/PurchaseRequisition/Components/RequestForm'

const { TabPane } = Tabs

const ApprovalPanelDetail = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { roles: authRoles, hasRole } = useAuth()

  const onClickGoBack = () => {
    navigate('/painel-aprovacoes')
  }

  if (authRoles && !hasRole(authRoles?.approve_default || authRoles?.request_approvals)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <Header
        title='Visualizar Detalhes'
        breadcrumbItems={['Painel de Aprovação', 'Detalhes']}
      />
      <Section>
        <Tabs
          defaultActiveKey='1'
          type='card'
          size='small'
        >
          <TabPane tab='Formulário' key='1'>
            <RequestForm
              isView
              id={Number(id)}
              actionGoBack={onClickGoBack}
            />
          </TabPane>
        </Tabs>
      </Section>
    </>
  )
}

export default ApprovalPanelDetail
