import useRest from 'hooks/useRest'
import { useState } from 'react'
import { TInvitationForQuotation } from 'repositories'

const useNotification = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const sendInvitationQuotation = async (data: TInvitationForQuotation, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/notification/supplier/invitation-quotation'
    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TInvitationForQuotation
  }

  return {
    sendInvitationQuotation,
    loading
  }
}

export default useNotification
