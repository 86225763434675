/* eslint-disable camelcase */
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { AuthProvider } from 'hooks/useAuth'
import { theme } from 'styles'
import { ConfigProvider } from 'antd'
import pt_BR from 'antd/lib/locale/pt_BR'

type TProps = {
  children: React.ReactNode
}

const Providers = ({ children }: TProps) => (
  <ConfigProvider locale={pt_BR}>
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider
          theme={theme}
        >
          {children}
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  </ConfigProvider>
)

export default Providers
