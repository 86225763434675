import React, { useEffect, useMemo, useState } from "react";
import { Form, List } from "antd";
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import { Container, Header, Content } from "./styles";
import { Col, Row, TextField } from "../../../../components";
import { useProject } from "../../../../repositories";
import { formatCurrency, formatToPercent } from "../../../../utils/helpers";

const ProjectAndLineInformation = ({ projectRequest, typeRequest }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [projects, setProjects] = useState<any>({});
  const [form] = Form.useForm<any>();
  const projectRepository = useProject();

  const handleOpen = () => setIsOpen(!isOpen);

  useMemo(() => {
    setIsOpen(true);
  }, [isOpen])

  const getProjectAndLineInformation = async () => {
    // console.log('projectRequest',{projectRequest})
    // console.log('Projeto', (projectRequest[0]?.projectRequest?.items[0]?.projectRevision?.project ?.idERP))
    const response = await projectRepository.findProjectRevisionByFilter({
      projectIdERP:
      projectRequest[0]?.projectRequest?.items[0]?.projectRevision?.project
          ?.idERP,
    });
      // console.log('response',{response})

    const mappedDataProject = response.map((project: any) => ({
      ...project,
      indicators: project.indicators,
    }));
    // console.log('mappedDataProject',mappedDataProject[0])
    // setProjects(mappedDataProject[0]);

    // console.log('projects',projects);

    form.setFieldsValue({
      id: mappedDataProject[0]?.project?.idERP,
      saleTv1: formatCurrency(mappedDataProject[0].indicators?.sale?.tv1),
      percGrossMarginSold: formatToPercent(
        mappedDataProject[0]?.indicators?.news.percGrossMarginSold
      ),
      percCurrentGrossMargin: formatToPercent(
        mappedDataProject[0]?.indicators?.news.percCurrentGrossMargin
      ),
    });
    
    setProjects(mappedDataProject[0]);
  };

  const initialValues = {
    id: "",
    saleTv1: "0.00",
    percGrossMarginSold: 0,
    percCurrentGrossMargin: 0,
    idLine: "",
    budgetCostLine: "",
    sellValueLine: "",
  };

  useEffect(() => {
    getProjectAndLineInformation();
  }, [isOpen]);

  return (
    <Container>
      <Header>
      {/* <Header onClick={handleOpen}> */}
        <text>Informação do projeto e linha(s)</text>
        {/* {isOpen ? <IoIosArrowDown /> : <IoIosArrowBack />} */}
      </Header>
      {isOpen && (
        <Content>
          <List size="small">
            <Form form={form} layout="vertical" initialValues={initialValues}>
              <text>Projeto</text>
              <Row>
                <Col md={8} xl={8} xxl={8}>
                  <Form.Item name="saleTv1" label="Valor de Venda TV1">
                    <TextField name="saleTv1" title={form.getFieldValue("saleTv1")} disabled />
                  </Form.Item>
                </Col>
                <Col md={8} xl={8} xxl={8}>
                  <Form.Item name="percGrossMarginSold" label="MB vendida %">
                    <TextField name="percGrossMarginSold" title={form.getFieldValue("percGrossMarginSold")} disabled />
                  </Form.Item>
                </Col>
                <Col md={8} xl={8} xxl={8}>
                  <Form.Item name="percCurrentGrossMargin" label="MB corrente %">
                    <TextField name="percCurrentGrossMargin" title={form.getFieldValue("percCurrentGrossMargin")} disabled />
                  </Form.Item>
                </Col>
              </Row>
              {/* {typeRequest === 'SIMPLE' && ( */}
                  <>
                    <text>Linha(s)</text>
                    {projectRequest[0]?.projectRequest.items.map((item: any, index: number) => (
                        <Row key={index}>
                          <Col md={8} xl={8} xxl={8}>
                            <Form.Item
                                label={index === 0 ? "ID" : ""}
                                name={`idLine_${index}`}
                                initialValue={item?.lineIdErp + ` | `+ item?.productDescription}
                            >
                              <TextField name={`idLine_${index}`} title={item?.lineIdErp + ` | `+ item?.productDescription} disabled />
                            </Form.Item>
                          </Col>
                          <Col md={8} xl={8} xxl={8}>
                            <Form.Item
                                label={index === 0 ? "Custo Orçado" : ""}
                                name={`budgetCostLine_${index}`}
                                initialValue={formatCurrency(item?.totalCostLineProject)}
                            >
                              <TextField name={`budgetCostLine_${index}`} disabled />
                            </Form.Item>
                          </Col>
                          <Col md={8} xl={8} xxl={8}>
                            <Form.Item
                                label={index === 0 ? "Valor da Venda" : ""}
                                name={`sellValueLine_${index}`}
                                initialValue={formatCurrency(item?.totalSale)}
                            >
                              <TextField name={`sellValueLine_${index}`} disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                    ))}
                  </>
              {/* )} */}
            </Form>
          </List>
        </Content>
      )}
    </Container>
  );
};

export default ProjectAndLineInformation;
