import React from "react";
import { Form } from "antd";
import type { Rule } from "antd/lib/form";
import ReactInputMask from "react-input-mask";

type TProps = {
  name: string;
  label?: string;
  rules?: Rule[];
  disabled?: boolean;
  required?: boolean;
  textHelp?: string;
  maxLength?: number;
  placeholder?: string;
  alwaysShowMask?: boolean;
  mask: string;
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void;
};

const TextField = (props: any) => {
  const {
    name,
    label,
    rules,
    disabled = false,
    required = false,
    mask,
    textHelp,
    placeholder = "",
    alwaysShowMask = false,
    validateStatus = "",
    onBlur,
    ...rest
  } = props;

  return (
    <Form.Item
      required={required}
      name={name}
      id={name}
      label={label}
      help={textHelp}
      rules={rules}
      validateStatus={validateStatus}
      {...rest}
    >
      <ReactInputMask
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        alwaysShowMask={alwaysShowMask}
        className="ant-input"
        mask={mask}
      />
    </Form.Item>
  );
};

export default TextField;
