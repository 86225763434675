import React, { useEffect, useMemo, useState } from "react";
import type { ColumnsType, TableProps } from "antd/lib/table";
import { Button, Input, Space, Table, Tag } from "antd";
import { Navigate, useNavigate } from "react-router";
import { PageHeader, Section, DropdownButton } from "components";
import { TProduct, TProductParams, useProduct } from "repositories";
import { usePagination, useAuth } from "hooks";
import { SorterResult } from "antd/lib/table/interface";
import { FilterContainer } from "./styles";

type TProductData = Pick<
  TProduct,
  | "id"
  | "erpId"
  | "description"
  | "mandatoryContract"
  | "mandatoryQuotation"
  | "preciary"
  | "status"
> & {
  key: string;
  productType: string;
};

const columns: ColumnsType<TProductData> = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "ID TOTVS",
    dataIndex: "erpId",
    key: "erpId",
    sorter: true,
    align: "center",
  },
  {
    title: "Tipo",
    dataIndex: "productType",
    key: "productType",
    sorter: true,
    align: "center",
  },
  {
    title: "Nome do produto",
    dataIndex: "description",
    key: "description",
    sorter: true,
    align: "center",
  },
  {
    title: "Categorias",
    dataIndex: "categories",
    key: "categories",
    sorter: true,
    align: "center",
  },
  {
    title: "Contrato obrigatório?",
    dataIndex: "mandatoryContract",
    key: "mandatoryContract",
    sorter: true,
    align: "center",
    render: (_, record) => (record.mandatoryContract ? "Sim" : "Não"),
  },
  {
    title: "Cotação obrigatória?",
    dataIndex: "mandatoryQuotation",
    key: "mandatoryQuotation",
    sorter: true,
    align: "center",
    render: (_, record) => (record.mandatoryQuotation ? "Sim" : "Não"),
  },
  {
    title: "Preciário Fornecedor?",
    dataIndex: "preciarySupplier",
    key: "preciarySupplier",
    sorter: true,
    align: "center",
  },
  {
    title: "Preciário Freelancer?",
    dataIndex: "preciary",
    key: "preciary",
    sorter: true,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    align: "center",
    render: (_, record) => {
      if (record.status) return <Tag color="green">Ativo</Tag>;

      return <Tag color="red">Bloqueado</Tag>;
    },
  },
];

const ProductList = () => {
  const [products, setProducts] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const { roles: authRoles, hasRole } = useAuth();
  const repository = useProduct();
  const navigate = useNavigate();
  const pagination = usePagination();

  const requestProduct = async () => {
    const currentPage = pagination.data.current ?? 0;
    const params: TProductParams = {
      search: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
    };

    const response = await repository.findProductByFilter(params);
    if (!response) return;

    const mappedData = response.content.map((product) => {
      let categories = "";
      product.categories?.forEach((category) => {
        if (categories.length > 0) {
          categories += `, ${category.description}`;
        } else {
          categories += `${category.description}`;
        }
      });
      return {
        key: String(product.id),
        id: product.id,
        erpId: product.erpId,
        description: product.description,
        categories: categories,
        preciary: String(product.preciary ? "Sim" : "Não"),
        preciarySupplier: String(product.preciarySupplier ? "Sim" : "Não"),
        mandatoryContract: product.mandatoryContract,
        mandatoryQuotation: product.mandatoryQuotation,
        productType:
          product.productType.typeKey === "MC" ? "Commodities" : "Estratégico",
        status: product.status,
      };
    });
    // const mappedData = response.content.map(product => ({
    //   key: String(product.id),
    //   id: product.id,
    //   erpId: product.erpId,
    //   category: product.categories?[0].description,
    //   preciary: String(product.preciary ? 'Sim' : 'Não'),
    //   description: product.description,
    //   mandatoryContract: product.mandatoryContract,
    //   mandatoryQuotation: product.mandatoryQuotation,
    //   productType: product.productType.description,
    //   status: product.status
    // }))
    setProducts(mappedData);
    pagination.changeTotalPages(response.totalPages);
  };

  const handleChange: TableProps<TProductData>["onChange"] = (
    paginationConfig,
    filters,
    sorter
  ) => {
    pagination.onChangeTable(paginationConfig);
    let { field, order } = sorter as SorterResult<TProductData>;
    if (!field) return;

    if (field === "categories") field = "categoryMappings.category.description";

    const dir = order === "ascend" ? "ASC" : "DESC";
    setSort(`${field},${dir}`);
  };

  useEffect(() => {
    if (pagination.data.current) requestProduct();
  }, [pagination.data.current, pagination.data.pageSize, sort, search]);

  const onClickGoToView = (id: number) => () =>
    navigate(`/produtos/visualizar/${id}`);

  const onClickGoToEdit = (id: number) => () =>
    navigate(`/produtos/editar/${id}`);

  const updatedColumns = useMemo(
    () => [
      ...columns,
      {
        title: "",
        key: "action",
        render: (_, record) => {
          const id = Number(record.id);
          const itemsMenu = [
            {
              key: "1",
              label: "Visualizar",
              onClick: onClickGoToView(id),
            },
            {
              key: "2",
              label: "Editar",
              onClick: onClickGoToEdit(id),
              disabled: !hasRole(authRoles?.product_edit),
            },
          ];
          return <DropdownButton items={itemsMenu} />;
        },
        // (
        //   <Space size='middle'>
        //     <Button
        //       onClick={onClickGoToView(record.id)}
        //       disabled={!hasRole(authRoles?.product_view)}
        //       size='small'
        //       type='text'
        //     >
        //       Visualizar
        //     </Button>
        //     <Button
        //       onClick={onClickGoToEdit(record.id)}
        //       disabled={!hasRole(authRoles?.product_edit)}
        //       size='small'
        //       type='text'
        //     >
        //       Editar
        //     </Button>
        //   </Space>
        // ),
      },
    ],
    [authRoles]
  );

  const onSearch = (value: string) => {
    setSearch(value);
    pagination.onChange(1, pagination.data.pageSize ?? 1)
  }

  if (authRoles && !hasRole(authRoles?.product_view)) {
    return <Navigate to="/acesso-negado" />;
  }

  return (
    <>
      <PageHeader title="Produtos" breadcrumbItems={["Produtos", "Listar"]} />
      <Section>
        <FilterContainer>
          <Input.Search
            placeholder="Pesquise pela descrição"
            onSearch={onSearch}
            enterButton
          />
        </FilterContainer>
        <Table
          size="middle"
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={products}
          pagination={pagination.data}
        />
      </Section>
    </>
  );
};

export default ProductList;
