import React, { useEffect, useState } from 'react'
import { ColumnsType, TableProps } from 'antd/lib/table'
import { Table } from 'antd'
import {
  TFilterRequestHistoricParams,
  TRequestHistoric,
  useProjectRequest
} from 'repositories'
import { usePagination } from 'hooks'
import { tableLocale } from 'utils/lang'
import { formatDateTimeToView, formatDateToView } from 'utils/helpers'
import { SorterResult } from 'antd/lib/table/interface'

type TRequestHistoricData = TRequestHistoric & {
  key: string
}

const columns: ColumnsType<TRequestHistoricData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Tipo',
    dataIndex: 'typeHistoric',
    key: 'typeHistoric',
    sorter: true
  },
  {
    title: 'Campo',
    dataIndex: 'field',
    key: 'field',
    sorter: true
  },
  {
    title: 'Data',
    dataIndex: 'createdDate',
    key: 'createdDate',
    sorter: true
  },
  {
    title: 'Valor anterior',
    dataIndex: 'previousValue',
    key: 'previousValue'
  },
  {
    title: 'Valor atual',
    dataIndex: 'currentValue',
    key: 'currentValue'
  }
]

const formatValue = (term: string, value: string) => {
  switch (term) {
    case 'EVENT_DATE': return formatDateToView(value)
    default: return value
  }
}

const formatNameValue = (term: string) => {
  switch (term) {
    case 'EVENT_DATE': return 'Data de entrega'
    case 'PAYMENT_METHOD': return 'Forma de pagamento'
    case 'TECHNICAL_SCOPE': return 'Escopo técnico'
    case 'DELIVERY_LOCATION': return 'Endereço de entrega'
    case 'PAYMENT_TERM': return 'Prazo de pagamento'
    case 'SUPPLIER_QUOTE_ACCEPTED_BY_BUYER': return 'Cotação aceita pelo comprador'
    case 'SUPPLIER_QUOTE_DECLASSIFY_BY_BUYER': return 'Cotação desclassificada pelo comprador'
    default: return ''
  }
}

type TProps = {
  projectRequestId: number
}

const RequestHistory = ({ projectRequestId }: TProps) => {
  const [histories, setHistories] = useState<TRequestHistoricData[]>([])
  const [sort, setSort] = useState('createdDate,ASC')

  const repository = useProjectRequest()
  const pagination = usePagination()

  const requestUser = async () => {
    const currentPage = pagination.data.current ?? 0
    const params: TFilterRequestHistoricParams = {
      projectRequestId,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
    }

    const response = await repository.findRequestHistoricByFilter(params)
    if (!response) return

    const mappedData: TRequestHistoricData[] = response.content.map(history => ({
      ...history,
      key: String(history.id),
      typeHistoric: history.typeHistoric === 'HEADER' ? 'Capa' : 'Item',
      createdDate: formatDateTimeToView(history.createdDate),
      field: formatNameValue(history.field),
      previousValue: formatValue(history.field, history.previousValue),
      currentValue: formatValue(history.field, history.currentValue)
    }))
    setHistories(mappedData)
    pagination.changeTotalPages(response.totalPages)
  }

  const handleChange: TableProps<TRequestHistoricData>['onChange'] = (paginationConfig, filters, sorter) => {
    pagination.onChangeTable(paginationConfig)
    const { field, order } = sorter as SorterResult<TRequestHistoricData>
    if (!field) return

    const dir = order === 'ascend' ? 'ASC' : 'DESC'
    setSort(`${field},${dir}`)
  }

  useEffect(() => {
    if (projectRequestId && pagination.data.current) requestUser()
  }, [pagination.data.current, pagination.data.pageSize, sort, projectRequestId])

  return (
    <Table
      size='middle'
      locale={tableLocale}
      onChange={handleChange}
      loading={repository.loading}
      columns={columns}
      dataSource={histories}
      pagination={pagination.data}
    />
  )
}

export default RequestHistory
