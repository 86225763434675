import styled from "styled-components";
import {TextareaHTMLAttributes} from "react";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    disabled?: boolean;
    bordered?: boolean;
}

export const TextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  height: 200px;
  padding: 8px;
  font-size: 16px;
  overflow: auto;
  resize: none;
  box-sizing: border-box;
`;