import styled from "styled-components";
import logoBlack from "assets/logoBlack.png";

export const Container = styled.header`
  display: flex;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-bottom: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  padding: 11px 15px;
  height: 52px;
  width: 100vw;
`;

export const Toggle = styled.div`
  width: 240px;
  min-width: 225px;
  display: flex;
  padding: 0 15px 0 0;
  justify-content: space-between;
  align-items: center;
  border-right: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MD}) {
    border-right: none;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 10px;
  }

  svg {
    width: 19px;
    height: 19px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 15px;
  align-items: center;

  .ant-input-group-wrapper {
    width: 25%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MD}) {
    justify-content: flex-end;
  }
`;

export const Logo = styled.h1`
  background: url(${logoBlack}) no-repeat;
  width: 42px;
  height: 26px;
  background-size: 42px 26px;
  text-indent: -9999px;
  overflow: hidden;
  margin: 0;
`;

export const Toolbar = styled.div`
  color: ${({ theme }) => theme.colors.BLACK};
  display: flex;
  gap: 10px;
  padding: 11px 0;

  > div {
    display: flex;
    align-items: center;
    gap: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  > div:first-child {
    padding: 0 11px;
    border-right: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  }

  .btns-wrapper {
    cursor: pointer;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
  }

  .notifications {
    width: 400px;
    position: absolute;
    top: calc(100% + 20px);
    right: 0px;
    background: white;
    z-index: 1001;
    padding: 15px;
    border: 1px solid rgb(231, 231, 231);
    .items-not {
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-height: 500px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
        background: #f4f4f4;
      }
      .item-not {
        background: rgb(243, 243, 243);
        padding: 10px;
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          h2 {
            margin-bottom: 0px;
          }
        }
        .content {
          * {
            font-size: 13px;
          }
          p {
            margin-bottom: 0px;
          }
        }
        .footer {
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-size: 12px;
            &.status.danger {
              color: #ff4d4f;
            }
          }
        }
      }
    }
    
    h2 {
      font-size: 14px;
      font-weight: bold;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MD}) {
    > div:first-child {
      border-right: none;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MD}) {
    div:nth-of-type(2) {
      display: none;
    }
  }
`;
