import { PageHeader } from "components";
import { useAuth } from "hooks";
import { Navigate } from "react-router";
import { Container } from "./styles";
import OrdersTab from "./Components/OrdersTab";

const ApprovalPanel = () => {
  const { roles: authRoles, hasRole } = useAuth();

  if (authRoles && !hasRole(authRoles?.approve_default || authRoles?.request_approvals)) {
    return <Navigate to="/acesso-negado" />;
  }

  return (
    <>
      <PageHeader title="Aprovações" breadcrumbItems={[""]} />
      <Container>
        <OrdersTab />
      </Container>
    </>
  );
};

export default ApprovalPanel;
