import React, { useEffect } from 'react'
import {
  Alert,
  Button,
  Form
} from 'antd'
import {
  useSearchParams
} from 'react-router-dom'
import { useAuth } from 'hooks'
import { useSuppliers } from 'repositories'
import type { Rule } from 'antd/lib/form'
import { TextField } from 'components'
import { Content } from '../styles'

type TAuthorizationForm = {
  email: string
  password: string
}

export const initialValues: TAuthorizationForm = {
  email: '',
  password: ''
}

const validations: Record<string, Rule[]> = {
  email: [
    { required: true, message: 'Campo "Email" é obrigatório' },
    { type: 'email', message: 'Email está inválido' },
  ],
  password: [
    { required: true, message: 'Campo "Senha" é obrigatório' }
  ]
}

type TProps = {
  onChangeStep(value: number): void
}

const Authorization = ({ onChangeStep }: TProps) => {
  const repository = useSuppliers()
  const { saveUserData } = useAuth()
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm<TAuthorizationForm>()

  const onFinish = async (values: TAuthorizationForm) => {
    const token = await repository.validatePasswordPreRegistration(values)
    if (!token) return

    const _userData: TUserData = {
      token,
      isActiveDirectory: false,
      userName: 'User pré registration',
      userEmail: 'userpreregistration',
      userGroup: 'Pré cadastro',
      authorities: ['SUPPLIER_PRE_REGISTRATION']
    }

    saveUserData(_userData)
    onChangeStep(1)
  }

  useEffect(() => {
    const emailParam = searchParams.get('email')
    if (!emailParam) return

    form.setFieldsValue({ email: emailParam })
  }, [])

  return (
    <Content size='large'>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
          <Alert
            message='Informação'
            description='Para prosseguir com a atualização cadastral, por favor insira o email e senha recebidos no email.'
            type='info'
            showIcon
          />
        </Form.Item>
        <TextField
          label='Email'
          name='email'
          rules={validations.email}
          size='large'
        />
        <TextField
          label='Senha'
          name='password'
          type='password'
          rules={validations.password}
          size='large'
        />
        <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
          <Button
            type='primary'
            size='large'
            loading={repository.loading}
            disabled={repository.loading}
            htmlType='submit'
          >
            Validar
          </Button>
        </Form.Item>
      </Form>
    </Content>
  )
}

export default Authorization
