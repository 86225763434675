import React, {useEffect, useState} from "react";
import {
    Form,
    Divider,
    Table,
    Tag,
    Popover,
    Button,
    Modal,
    Spin,
    Upload, message
} from "antd";

import {CloudUploadOutlined, DownloadOutlined} from "@ant-design/icons";
import {
    PageHeader,
    Section,
    Row,
    Col,
    TextField,
    DateField,
    AreaField
} from "components";

import {
    SupplierStatus,
    supplierStatus,
    SupplierType,
} from "../../../../utils/constants";

import { useParams } from "react-router-dom";

import moment from "moment";

import { useRequest, useProduct, useSuppliers } from "../../../../repositories";

import { useNavigate } from "react-router";

import axios from "services/axios";
import { formatCurrency } from "../../../../utils/helpers";
import { ColFormat, RowFormat, UploadWrapper } from "./styles";
const { Dragger } = Upload;
function applyPhoneMaskWithNinthDigit(phoneNumber: any) {
    const onlyNumbers = phoneNumber.replace(/\D/g, "");

    let formattedNumber;
    if (onlyNumbers.length === 11) {
        formattedNumber = onlyNumbers.replace(
            /(\d{2})(\d{5})(\d{4})/,
            "($1) $2-$3"
        );
    } else if (onlyNumbers.length === 10) {
        formattedNumber = onlyNumbers.replace(
            /(\d{2})(\d{4,5})(\d{4})/,
            "($1) $2-$3"
        );
    } else {
        return phoneNumber;
    }

    return formattedNumber;
}

const columnsSuppliers: any = [
    {
        title: "Id",
        dataIndex: "id",
    },
    {
        title: "Razão Social",
        dataIndex: "corporateName",
    },
    {
        title: "Nome fantasia ",
        dataIndex: "fantasyName",
    },
    {
        title: "Tipo de Prestador",
        dataIndex: "typeSupplier",
        render: (_: any, record: any) =>
            record.typeSupplier === SupplierType.SUPPLIER
                ? "Fornecedor"
                : "Freelancer",
    },
    {
        title: "Telefone",
        dataIndex: "telephone",
        render: (_: any, record: any) => {
            return applyPhoneMaskWithNinthDigit(record.telephone);
        },
    },
    {
        title: "E-mail",
        dataIndex: "email",
    },
    {
        title: "Status Homologação",
        dataIndex: "status",
        render: (_: any, record: any) => {
            if (record.status === SupplierStatus.APPROVED) {
                return <Tag color="green">Homologado</Tag>;
            }

            if (record.status === SupplierStatus.NOT_APPROVED) {
                return <Tag color="red">Bloqueado</Tag>;
            }

            if (record.status === SupplierStatus.PRE_REGISTRATION) {
                return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
            }

            return <Tag>{supplierStatus[record.status]}</Tag>;
        },
    },
    {
        title: "Preciario",
        dataIndex: "preciary",
        align: "center",
        render: (_: any, record: any) => {
            if (record.preciary) {
                return <Tag>Sim</Tag>;
            } else {
                return <Tag>Não</Tag>;
            }
        },
    },
    {
        title: "Valor negociado",
        dataIndex: "value",
        align: "center",
        render: (_: any, record: any) => (
            <span>{formatCurrency(record.value)}</span>
        ),
    },
    {
        title: "Unidade de medida",
        dataIndex: "unitOfMeasurement",
        align: "center",
    },
];

const requestsColumns: any = [
    {
        title: "Emissão",
        dataIndex: "issueDate",
        render: (_: any, record: any) => {
            return record.issueDate
                ? moment(record.issueDate).format("DD/MM/YYYY")
                : "";
        },
    },
    {
        title: "Vencimento",
        dataIndex: "maturity",
        render: (_: any, record: any) => {
            return record.maturity
                ? moment(record.maturity).format("DD/MM/YYYY")
                : "";
        },
    },
    {
        title: "Produto",
        dataIndex: "productName",
    },
    {
        title: "Prestador",
        dataIndex: "typeProvider",
        render: (_: any, record: any) => {
            return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
        },
    },
    {
        title: "Tipo",
        dataIndex: "typeRequest",
        render: (_: any, record: any) => {
            if (record.typeRequest === "SIMPLE") return "Simples";
            return "Composta";
        },
    },
    {
        title: "Categoria",
        dataIndex: "category",
    },
    {
        title: "Alocação",
        dataIndex: "allocation",
    },
    {
        title: "Local",
        dataIndex: "buName",
    },
    {
        title: "Cliente",
        dataIndex: "client",
    },
    {
        title: "Timing",
        dataIndex: "timing",
        render: (_: any, record: any) => {
            if (record.timing === "PLANEJADA")
                return <Tag color="green">Planejada</Tag>;

            return <Tag color="red">Não planejada</Tag>;
        },
    },
    {
        title: "Fatura",
        dataIndex: "paymentType",
    },
    {
        title: "Valor da requisição",
        dataIndex: "totalCost",
        render: (_: any, record: any) => {
            return formatCurrency(record?.totalCost);
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        render: (_: any, record: any) => {
            switch (record.status) {
                case "NEW":
                    return <Tag color="cyan">Novo</Tag>;
                case "IN_QUOTATION":
                    return <Tag color="yellow">Em cotação</Tag>;
                case "APPROVED":
                    return <Tag color="green">Aprovado</Tag>;
                case "REFUSED":
                    return <Tag color="orange">Reprovada</Tag>;
                case "CANCELED":
                    return <Tag color="red">Cancelada</Tag>;
                case "ON_APPROVAL":
                    return <Tag color="yellow">Em aprovação</Tag>;
                case "PAID_OUT":
                    return <Tag color="green">Pago</Tag>;
                default:
                    return record.status;
            }
        },
    },
];

const RequestEdition = (requestData: any) => {
    const [form] = Form.useForm();
    const [freelancersSuppliersList, setFreelancersSuppliersList] = useState<any>(
        []
    );
    const [productsList, setProductsList] = useState<any>([]);
    const [productsLinesNotEdited, setProductsLinesNotEdited] = useState<any>([]);
    const [filesListRemoved, setFilesListRemoved] = useState<any>([]);
    const [emission, setEmission] = useState<any>([]);
    const [requestModal, setRequestModal] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filesItems, setFilesItems] = useState<any>([]);
    const [newFiles, setNewFiles] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const repository = useRequest();
    const repositoryProduct = useProduct();
    const repositorySuplier = useSuppliers();

    const [downloadLoading, setDownloadLoading] = useState(false);

    const { id } = useParams();

    const goToHome = () => {
        window.close()
    };

    const openRequests = (requests: any) => {
        setRequestModal(requests);
        setIsModalOpen(true);
    };

    const columnsLines: any = [
        {
            title: "Id Compras",
            dataIndex: "id",
        },
        {
            title: "Id da linha TOTVS",
            dataIndex: "idLinha",
        },
        {
            title: "Tipo do Faturamento",
            dataIndex: "paymentType",
        },
        {
            title: "Produto",
            dataIndex: "productDescription",
        },
        {
            title: "Categoria",
            dataIndex: "categoria",
        },
        {
            title: "Escopo da linha",
            dataIndex: "technicalScope",
            key: "technicalScope",
            render: (_: any, record: any) => {
                return (
                    <Popover content={record.technicalScopeDescription} overlayStyle={{ maxWidth: "800px" }}>
                        {record?.technicalScope}
                    </Popover>
                );
            },
        },
        {
            title: "Quantidade",
            dataIndex: "amount",
            render: (_: any, record: any) => {
                return record?.amount;
            },
        },
        {
            title: "Diárias",
            dataIndex: "daily",
            render: (_: any, record: any) => {
                return record?.daily;
            },
        },
        {
            title: "Custo Unítario",
            dataIndex: "unitCost",
            render: (_: any, record: any) => {
                return formatCurrency(record?.unitCost);
            },
        },
        {
            title: "Custo total",
            dataIndex: "totalCost",
            render: (_: any, record: any) => {
                return formatCurrency(record?.totalCost);
            },
        },
        {
            title: "Já tem requisições?",
            dataIndex: "withoutRequest",
            align: "center",
            render: (_: any, record: any) => {
                return record?.withoutRequest ? (
                    <Tag
                        color="green"
                        style={{ cursor: "pointer" }}
                        onClick={() => openRequests(record?.projectRequestItems)}
                    >
                        Sim
                    </Tag>
                ) : (
                    <Tag color="default">Não</Tag>
                );
            },
        },
        {
            title: "Requisições",
            dataIndex: "approvalRequests",
            render: (_: any, record: any) => {
                return formatCurrency(record?.approvalRequests);
            },
        },
        {
            title: "Saldo da linha",
            dataIndex: "lineBalance",
            render: (_: any, record: any) => {
                return (
                    <span style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(record?.lineBalance)}
          </span>
                );
            },
        },
    ];
    const columnsLinesEditable: any = [
        {
            title: "Id da linha TOTVS",
            dataIndex: "idLinha",
        },
        {
            title: "Tipo do Fat.",
            dataIndex: "paymentType",
        },
        {
            title: "Produto",
            dataIndex: "productDescription",
        },
        {
            title: "Categoria",
            dataIndex: "categoria",
        },
        {
            title: "Escopo",
            dataIndex: "technicalScope",
            key: "technicalScope",
            render: (_: any, record: any) => {
                return (
                    <Popover
                        content={record.technicalScopeDescription}
                        overlayStyle={{ maxWidth: "800px" }}
                    >
                        {record.technicalScope}
                    </Popover>
                );
            },
        },
        {
            title: "Quant",
            dataIndex: "amount",
            render: (_: any, record: any) => {
                return record.amount
            },
        },
        {
            title: "Diárias",
            dataIndex: "daily",
            render: (_: any, record: any) => {
                return record.daily
            },
        },
        {
            title: "Custo Unítario",
            dataIndex: "unitCost",
            render: (_: any, record: any) => {
                return formatCurrency(record.unitCost)
            },
        },
        {
            title: "Custo total",
            dataIndex: "totalCost",
        },
    ];

    let initialValues = {
        typeRequest: "",
        category: "Compra & Contratação",
        allocation: "Com projeto",
        requestLocation: "",
        client: "",
        invoiceAgainst: "TV1",
        status: "",
        purchaseType: "Projeto",
        providerType: "",
        senioridade: "",
        timing: 2,
        emission: moment(),
        startDate: moment(),
        endDate: moment(),
        pay: moment(),
        ExpenseOrInvestment: "Despesa",
        productType: "",
        quoteDeadline: "",
        SupplierPricing: "",
        unitMinimunPrice: 0,
        unitMaximunPrice: 0,
        productWithPrice: "",
        cotation: "",
        technicalScope: "",
        objectiveFunction: "",
        justifyIfItIsAnEmergency: "",
        JustifyReasonSingleSupplier: "",
        JustifyUnapprovedSupplier: "",
        JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
        JustifyTheDueDateBeingShorter: "",
        indicationSingleSupplierJustifiable: "",
        needSupportFromTv1corporateTI: "",
        useTv1Email: "",
        equipmentFreelancers: "",
        equipmentTypeFreelancers: "",
        workplace: "",
        accessInternalSystem: "",
        othersAccessInternalSystem: "",
        otherMarketSoftware: "",
        freelanceDirectManager: "",
        contractStartDate: "",
        contractEndDate: "",
    };

    const loadData = async () => {

        setLoading(true)

        const requestResponse = await repository.findRequestById(id, {});

        const productResponse = await repositoryProduct.findProductById(
            requestResponse?.items[0].productId
        );

        const supplierResponse = await repositorySuplier.findSupplierByFilter({
            id: requestResponse?.suppliers[0].id,
        });

        const productPriceRangeResponse =
            await repositorySuplier.getProductPriceRange(
                requestResponse?.suppliers[0].id
            );

        const paramsProjectItems: any = {
            projectId: requestResponse?.items[0].projectRevision.project.id,
            projectRevisionId: requestResponse?.items[0].projectRevision.id,
            size: 10000
        };

        const responseProjectItems = await repository.findProjectItems(
            paramsProjectItems
        );
        if (!responseProjectItems) return;

        const valuesProducts: any[] = [];

        for (let i = 0; i < requestResponse?.items.length; i++) {
            const item = responseProjectItems?.content[0]?.items
                ?.map((request: any) => ({
                    id: String(request.id),
                    key: request.id,
                    idLinha: request.lineIdErp,
                    paymentType: request.billingType === "0" ? "TV1" : "Cliente",
                    productId: request.productId,
                    productDescription: request.productDescription,
                    categoria: request?.categories[0].description,
                    technicalScope: request?.description?.slice(0, 10) + " ...",
                    technicalScopeDescription: request?.description,
                    amount: request.amount,
                    daily: request.daily,
                    unitCost: request.unitCost,
                    totalCost: request.totalCost,
                    withoutRequest: request.existsRequisitions,
                    packageOrApportionment: request.activePackageOrRating,
                    approvalRequests: request.valueApprovedRequisitions,
                    lineBalance: request.valueApprovedRequisitions ? request.balanceLine : request.totalCost,
                    projectRequestItems: request.projectRequestItems,
                }))
                .filter(
                    (item: any) =>
                        item.productId === requestResponse?.items[i]?.productId &&
                        String(item.idLinha) === String(requestResponse?.items[i]?.lineIdErp)
                );

            const valuesProduct = {
                ...item[0],
                categoria: productResponse?.categories[0]?.description,
                totalCost: formatCurrency(requestResponse?.items[i]?.totalCost),
                unitCost: (requestResponse?.items[i]?.totalCost / requestResponse?.items[i]?.daily) / requestResponse?.items[i]?.amount,
                amount: requestResponse?.items[i]?.amount,
                daily: requestResponse?.items[i]?.daily
            };

            valuesProducts.push(valuesProduct)
        }

        const valuesProductsNotEdited: any[] = [];
        for (let i = 0; i < requestResponse?.items.length; i++) {
            const item = responseProjectItems?.content[0]?.items
                ?.map((request: any) => ({
                    id: String(request.id),
                    key: request.id,
                    idLinha: request.lineIdErp,
                    paymentType: request.billingType === "0" ? "TV1" : "Cliente",
                    productId: request.productId,
                    productDescription: request.productDescription,
                    categoria: request?.categories[0].description,
                    technicalScope: request?.description?.slice(0, 10) + " ...",
                    technicalScopeDescription: request?.description,
                    amount: request.amount,
                    daily: request.daily,
                    unitCost: request.unitCost,
                    totalCost: request.totalCost,
                    withoutRequest: request.existsRequisitions,
                    packageOrApportionment: request.activePackageOrRating,
                    approvalRequests: request.valueApprovedRequisitions,
                    lineBalance: request.valueApprovedRequisitions ? request.balanceLine : request.totalCost,
                    projectRequestItems: request.projectRequestItems,
                }))
                .filter(
                    (item: any) =>
                        item.productId === requestResponse?.items[i]?.productId &&
                        String(item.idLinha) === String(requestResponse?.items[i]?.lineIdErp)
                );
            valuesProductsNotEdited.push(item[0])
        }
        setProductsLinesNotEdited(valuesProductsNotEdited)

        let unitMinimunPriceClone = "";
        let unitMaximunPriceClone = "";

        switch (supplierResponse?.content[0]?.seniority) {
            case "JUNIOR":
                unitMinimunPriceClone = productResponse?.minimumPriceJunior;
                unitMaximunPriceClone = productResponse?.maximumPriceJunior;
                break;
            case "PLENO":
                unitMinimunPriceClone = productResponse?.minimumPricePleno;
                unitMaximunPriceClone = productResponse?.maximumPricePleno;
                break;
            case "SENIOR":
                unitMinimunPriceClone = productResponse?.minimumPriceSenior;
                unitMaximunPriceClone = productResponse?.maximumPriceSenior;
                break;
        }

        let mappedStatus = "";

        switch (requestResponse?.statusRequest) {
            case "NEW":
                mappedStatus = "Novo";
                break;
            case "IN_QUOTATION":
                mappedStatus = "Em cotação";
                break;
            case "APPROVED":
                mappedStatus = "Aprovado";
                break;
            case "REFUSED":
                mappedStatus = "Reprovada";
                break;
            case "CANCELED":
                mappedStatus = "Cancelada";
                break;
            case "ON_APPROVAL":
                mappedStatus = "Em aprovação";
                break;
            case "PAID_OUT":
                mappedStatus = "Pa   go";
                break;
            default:
                return requestResponse?.statusRequest;
        }
        const valuesRequest = {
            ...requestResponse,
            needSupportFromTv1corporateTI:
                requestResponse.projectRequestFreelancers
                    ?.needSupportFromTv1corporateTI === true
                    ? "Sim"
                    : "Não",
            useTv1Email:
                requestResponse.projectRequestFreelancers?.useTv1Email === true
                    ? "Sim"
                    : "Não",
            equipmentFreelancers:
            requestResponse.projectRequestFreelancers?.equipmentFreelancers,
            equipmentTypeFreelancers:
            requestResponse.projectRequestFreelancers?.equipmentTypeFreelancers,
            workplace: requestResponse.projectRequestFreelancers?.workplace,
            accessInternalSystem:
            requestResponse.projectRequestFreelancers?.accessInternalSystem,
            othersAccessInternalSystem:
            requestResponse.projectRequestFreelancers?.othersAccessInternalSystem,
            otherMarketSoftware:
            requestResponse.projectRequestFreelancers?.otherMarketSoftware,
            freelanceDirectManager:
            requestResponse.projectRequestFreelancers?.freelanceDirectManager,
            contractStartDate: moment(
                requestResponse.projectRequestFreelancers?.contractStartDate
            ),
            contractEndDate: moment(
                requestResponse.projectRequestFreelancers?.contractEndDate
            ),
            typeRequest:
                requestResponse?.typeRequest === "SIMPLE" ? "Simples" : "Composta",
            client: requestResponse?.items[0]?.projectRevision?.project?.clientName,
            requestLocation:
            requestResponse?.items[0]?.projectRevision?.project?.buName,
            status: mappedStatus,
            timing: requestResponse?.approvalHierarchyTiming === "PLANEJADA" ? "Planejada" : "Não planejada",

            emission: moment(requestResponse?.issueDate).format("DD/MM/YYYY"),

            dateTimeSuspended: moment(requestResponse?.dateTimeSuspended).format("DD/MM/YYYY"),
            projectId: requestResponse.items[0].projectRevision.project.idERP,
            projectName: requestResponse.items[0].projectRevision.project.name,
            typeSupplier:
                requestResponse?.typeProvider === SupplierType.SUPPLIER
                    ? "Fornecedor"
                    : "Freelancer",
            technicalScope: requestResponse?.items[0]?.technicalScope,
            seniority: supplierResponse?.content[0]?.seniority,
            unitMinimunPrice: unitMinimunPriceClone,
            unitMaximunPrice: unitMaximunPriceClone,
            ...requestResponse?.projectRequestAdditionalQuestions,
        };

        valuesRequest.productType = productResponse.productType.description;

        const filtered = productPriceRangeResponse.filter(
            (product: any) => product.productId === valuesProducts[0]?.productId
        );
        const valuesSuppliers = {
            ...supplierResponse?.content[0],
            preciary: filtered[0]?.preciary,
            value: filtered[0]?.value ? filtered[0]?.value : "",
            unitOfMeasurement: filtered[0]?.unitOfMeasurement
                ? filtered[0]?.unitOfMeasurement
                : "",
        };

        setFreelancersSuppliersList([valuesSuppliers]);
        setProductsList(valuesProducts);

        const fileConcat = requestResponse.files.concat(filesItems)
        setFilesItems(fileConcat);
        form.setFieldValue('startDate', moment(requestResponse?.serviceStartDate))
        form.setFieldValue('endDate', moment(requestResponse?.serviceEndDate))
        form.setFieldValue('pay', moment(requestResponse?.maturity))
        form.setFieldsValue(valuesRequest);
        setEmission(valuesRequest.emission)
        setLoading(false)
    };

    const downloadArchive = async (fileName: any) => {
        try {
            setDownloadLoading(true);
            const response = await axios.get(
                "/v1/manager/project-request/download/" + fileName,
                {
                    responseType: "arraybuffer",
                }
            );
            const blob = new Blob([response.data], {
                type: "application/octet-stream",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(error);
        } finally {
            setDownloadLoading(false);
        }
    };

    const onFinish = async (values: any) => {
        const formData = new FormData();

        const maturityValid = moment(values.pay).isValid();
        const startDateValid = moment(values.startDate).isValid();
        const endDateValid = moment(values.endDate).isValid();

        if (!maturityValid) {
            return message.error("A data de vencimento é inválida.");
        }
        if (!startDateValid) {
            return message.error("A data de início do serviço é inválida.");
        }
        if (!endDateValid) {
            return message.error( "A data de término do serviço é inválida.");
        }

        formData.append("id", String(id));
        formData.append("maturity", values.pay.format("YYYY-MM-DD"));
        formData.append("serviceStartDate", values.startDate.format("YYYY-MM-DD"));
        formData.append("serviceEndDate", values.endDate.format("YYYY-MM-DD"));
        formData.append("timing", values.timing === "Planejada" ? "PLANEJADA" : "EMERGENCIAL");
        formData.append("technicalScope", values.technicalScope);

        for (let i = 0; i < newFiles.length; i++) {
            formData.append("newFiles", newFiles[i]);
        }
        let filesListRemovedEdited = JSON.stringify(filesListRemoved);

        formData.append("removeFiles", filesListRemovedEdited);


        const headers = {
            "content-type": "multipart/form-data",
        };

        try {
            const request = await repository.updateRequests(
                formData,
                headers
            );
            message.success(`Requisição #${request?.id} editada com sucesso!`);
            navigate(`/`);
        } catch (error) {}

    };

    useEffect(() => {
        loadData();

    }, []);

    const propsUpload = {
        name: "file",
        multiple: true,
        action: "",
        customRequest: (file: any) => {
            let fileEdit = file.file;
            fileEdit.fileName = fileEdit.name;
            delete fileEdit.name;
            setFilesItems([...filesItems, fileEdit]);
            setNewFiles([...newFiles, file.file])
        },
        onDrop(e: any) {
            // console.log("Dropped files", e.dataTransfer.files);
        },
    };

    const removeFile = (file: any) => {
        const indexFile = filesItems.findIndex((obj: any) => obj.id === file.id);
        const indexNewFile = newFiles.findIndex((obj: any) => obj.uid === file.uid);
        const arrayFile = [...filesItems];
        const newArrayFile = [...newFiles];
        if (file.id) setFilesListRemoved(filesListRemoved.concat([file.id]))
        if(indexNewFile !== -1){
            newArrayFile.splice(indexNewFile, 1);
            setNewFiles(newArrayFile);
        }
        if (indexFile !== -1) {
            arrayFile.splice(indexFile, 1);
            setFilesItems(arrayFile);
        }
    };
    const disabledDate = (current: any) => {
        const today = new Date(form.getFieldValue("startDate"));
        today.setHours(0, 0, 0, 0);
        return current && current < today;
    };
    const disabledStartDate = (current: any) => {
        const endDate = new Date(form.getFieldValue("endDate"));
        endDate.setHours(0, 0, 0, 0);
        return current && current > endDate;
    };

    const onChangeDate = (date: moment.Moment | null) => {
        const startDate = form.getFieldValue("startDate")
        const endDate = form.getFieldValue("endDate")
        const pay = form.getFieldValue("pay")
        const typeSupplier = form.getFieldValue("typeSupplier")

        const diffInDays = pay.diff(typeSupplier === "Fornecedor" ? endDate : startDate, 'days');
        let timing;

        if (!moment(date).isValid()){
            return
        }

        if (startDate.isSameOrAfter(moment(emission, 'DD/MM/YYYY'), 'day')) {
            if (
                (typeSupplier === "Fornecedor" && diffInDays >= 70) ||
                (typeSupplier === "Freelancer" && diffInDays >= 30)
            ) {
                timing = "Planejada";
            } else if (
                (typeSupplier === "Fornecedor" && diffInDays < 70) ||
                (typeSupplier === "Freelancer" && diffInDays < 30)
            ) {
                timing = "Não planejada";
            }
        }else if(startDate.isBefore(moment(emission, 'DD/MM/YYYY'))) {
            timing = "Não planejada";
        }
        form.setFieldValue("timing", timing)

    };

    return (
        <>
            <PageHeader
                title="Detalhes da Requisição"
                breadcrumbItems={["Requisições", "Detalhes"]}
            />
            {
                loading ?
                    <Section style={{display: 'flex', justifyContent: 'center', padding: '50px 0'}}>
                        <Spin />
                    </Section>
                    :
                    <Section>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={initialValues}
                        >
                            <Col xl={24}> <b>Categorização, datas e gatilhos</b> </Col>
                            <RowFormat>
                                <ColFormat>
                                    <TextField name="client" label="Cliente" disabled />
                                </ColFormat>
                                <ColFormat>
                                    <TextField
                                        name="projectName"
                                        label="Nome do projeto"
                                        disabled
                                    />
                                </ColFormat>
                                <ColFormat>
                                    <TextField
                                        name="projectId"
                                        label="ID do projeto"
                                        disabled
                                    />
                                </ColFormat>
                                <ColFormat>
                                    <TextField
                                        name="requestLocation"
                                        label="Local da requisição"
                                        disabled
                                    />
                                </ColFormat>
                            </RowFormat>
                            <Col xl={24}>
                                <b>Preencha as datas do serviço</b>
                            </Col>
                            <RowFormat>
                                <ColFormat>
                                    <DateField
                                        name="startDate"
                                        label="Data do início do serviço"
                                        onChange={onChangeDate}
                                        disabledDate={disabledStartDate}
                                        required
                                    />
                                </ColFormat>
                                <ColFormat>
                                    <DateField
                                        name="endDate"
                                        label="Data do fim do serviço"
                                        onChange={onChangeDate}
                                        disabledDate={disabledDate}
                                        required
                                    />
                                </ColFormat>
                                <ColFormat>
                                    <DateField
                                        name="pay"
                                        label="Vencimento [Política]"
                                        onChange={onChangeDate}
                                        disabledDate={disabledDate}
                                        required
                                    />
                                </ColFormat>
                                <ColFormat>
                                    <TextField
                                        name="timing"
                                        label="Timing da requisição"
                                        disabled
                                    />
                                </ColFormat>
                            </RowFormat>
                            <Col>
                                <Divider />
                            </Col>
                            <Col xl={24}>
                                <b>Fornecedores ou freelancers para essa requisição</b>
                            </Col>
                            <br/>
                            <RowFormat>
                                <ColFormat>
                                    <TextField
                                        name="typeSupplier"
                                        label="Tipo de Prestador"
                                        disabled
                                    />
                                </ColFormat>
                                <ColFormat>
                                    <TextField
                                        name="seniority"
                                        label="Senioridade"
                                        disabled
                                    />
                                </ColFormat>
                                <ColFormat>
                                    {
                                        form.getFieldValue("typeSupplier") === 'Freelancer' &&
                                        <TextField
                                            name="unitMaximunPrice"
                                            label="Preço Máximo [Freela]"
                                            disabled
                                        />
                                    }
                                </ColFormat>
                            </RowFormat>
                            <Col>
                                <Table
                                    columns={columnsSuppliers}
                                    dataSource={freelancersSuppliersList}
                                    pagination={false}
                                />
                            </Col>
                            <Col xl={24}>
                                <div style={{ marginTop: "20px" }}>
                                    <b>Linhas e produtos escolhidos para a requisição</b>
                                </div>
                            </Col>
                            <br/>
                            <Col xl={24}>
                                <Table
                                    columns={columnsLines}
                                    dataSource={productsLinesNotEdited}
                                    pagination={false}
                                    size='middle'
                                />
                            </Col>
                            <Col xl={24}>
                                <div style={{ marginTop: "20px" }}>
                                    <b>Edite abaixo a quantidade, diarias e o valor dessa requisição</b>
                                </div>
                            </Col>
                            <br/>
                            <Col xl={24}>
                                <Table
                                    columns={columnsLinesEditable}
                                    dataSource={productsList}
                                    pagination={false}

                                />
                            </Col>
                            <Col>
                                <div style={{ marginBottom: "40px" }}></div>
                            </Col>
                            <Row>
                                <Col xl={24}>
                                    <AreaField
                                        required
                                        name="technicalScope"
                                        maxLength={-1}
                                        label="Por favor, descreva o escopo técnico detalhado, o objetivo da contratação e se há disponibilidade ou possibilidade de realizar essa função internamente na empresa."
                                        rules={[
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={24}>
                                    <UploadWrapper>
                                        <Dragger {...propsUpload} showUploadList={false}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <CloudUploadOutlined
                                                    style={{ fontSize: "24px", marginTop: "-5px" }}
                                                />
                                                <p className="ant-upload-text">
                                                    Clique ou arraste para realizar o upload
                                                </p>
                                            </div>
                                        </Dragger>
                                    </UploadWrapper>
                                </Col>
                                <Col xl={24}>
                                    <Table
                                        pagination={false}
                                        dataSource={filesItems}
                                        columns={[
                                            {
                                                title: "Nome do arquivo",
                                                dataIndex: "fileName",
                                                key: "fileName",
                                            },
                                            {
                                                title: "Formato",
                                                dataIndex: "type",
                                                key: "type",
                                                render(_: any, record: any) {
                                                    return record?.fileName?.split(".").pop();
                                                },
                                            },
                                            {
                                                title: "",
                                                dataIndex: "Dawnload",
                                                key: "dawnload",
                                                render(_: any, record: any) {
                                                    return (
                                                        <Button
                                                            type="link"
                                                            icon={<DownloadOutlined />}
                                                            size="small"
                                                            onClick={() => downloadArchive(record.fileName)}
                                                            loading={downloadLoading}
                                                        >
                                                            Baixar
                                                        </Button>
                                                    );
                                                },
                                            },
                                            {
                                                title: "",
                                                dataIndex: "delete",
                                                key: "delete",
                                                render(_: any, record: any) {
                                                    return (
                                                        <span
                                                            onClick={() => removeFile(record)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            excluir
                                                        </span>
                                                    );
                                                },
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Button onClick={goToHome} htmlType="button">
                                Voltar
                            </Button>
                            <Button
                                style={{ margin: "0 4px" }}
                                type="primary"
                                htmlType="submit"
                            >
                                Salvar
                            </Button>
                        </Form>
                    </Section>
            }
            <Modal
                title="Requisições"
                className="horizontal-scrollable-modal"
                open={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                width={1300}
            >
                <Table
                    columns={requestsColumns}
                    dataSource={requestModal}
                    pagination={false}
                    style={{ overflowX: "auto" }}
                />
            </Modal>
        </>
    );
};

export default RequestEdition;
