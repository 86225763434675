import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { KeycloakInstance } from "keycloak-js";
import keycloak from "services/keycloak";
import { storageKey } from "services/axios";
import { useProfile, useLogin } from "repositories";
import { messageUserNotAssociatedGroups } from "utils/constants";
import { message as toast } from "antd";
import { parseJwt } from "utils/helpers";
import useRest from "hooks/useRest";

type TUseAuth = {
  isSignedIn: boolean;
  userData: TUserData;
  roles: TRoles;
  keycloak: KeycloakInstance;
  hasToken(): boolean;
  hasRole(permission?: boolean): boolean;
  saveUserData(user: TUserData): void;
  removeUserData(): void;
  logoutKeycloakAd(): Promise<void>;
  refreshTokens(): void;
};

const AuthContext = createContext<TUseAuth>({} as TUseAuth);

type TProps = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: TProps) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [roles, setRoles] = useState<TRoles>();
  const [tokenUpdatedAt, setTokenUpdatedAt] = useState(new Date());

  const repositoryLogin = useLogin();
  const repositoryProfile = useProfile();

  const rest = useRest();
  // const updateUserDataAndSyncWithKeycloak = async (_userData: TUserData) => {
  //   await repositoryLogin.syncKeycloakByEmail(_userData.userEmail);
  //
  //   hasRole(roles?.role_tv1);
  //   if (_userData.authorities.includes("ROLE_TV1")) {
  //     const userProfile = await repositoryProfile.findUserProfile();
  //     if (!userProfile) return;
  //
  //     const _updateUserData: TUserData = {
  //       ..._userData,
  //       userId: userProfile.id,
  //       userPicture: userProfile?.picture ?? null,
  //     };
  //     saveUserData(_updateUserData);
  //   }
  //
  //   if (!_userData.userGroup) {
  //     toast.warning(messageUserNotAssociatedGroups);
  //   }
  // };

  const initKeycloak = useCallback(async () => {
    await keycloak.init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    });
    if (!keycloak.authenticated) return;

    const token = String(keycloak.token);
    const refreshToken = String(keycloak.refreshToken);
    const parsedUser = parseJwt(token);
    const _userData: TUserData = {
      refreshToken,
      token,
      isActiveDirectory: true,
      userName: decodeURIComponent(parsedUser.given_name),
      userEmail: decodeURIComponent(parsedUser.email),
      userGroup: parsedUser?.group?.length ? parsedUser.group[0] : "",
      authorities: parsedUser.authorities,
    };
    localStorage.setItem(storageKey, JSON.stringify(_userData));

    await repositoryLogin.syncKeycloakByEmail(_userData.userEmail);

    hasRole(roles?.role_tv1);
    if (_userData.authorities.includes("ROLE_TV1")) {
      const userProfile = await repositoryProfile.findUserProfile();
      if (!userProfile) return;

      const dataToRefresh = new URLSearchParams({
        refresh_token: refreshToken,
        grant_type: "refresh_token",
        client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
      });

      const tokensRefreshDetails = await repositoryLogin.requestTokens(dataToRefresh);
      const parseJwtRefresh = parseJwt(tokensRefreshDetails?.token);

      const userDataRefresh = {..._userData,
        token: String(tokensRefreshDetails?.token),
        refreshToken: String(tokensRefreshDetails?.refresh_token),
        userGroup: parseJwtRefresh?.group?.length ? parseJwtRefresh.group[0] : "",
        authorities: parseJwtRefresh.authorities,
      };

      const _updateUserData: TUserData = {
        ...userDataRefresh,
        userId: userProfile.id,
        userPicture: userProfile?.picture ?? null,
      };
      saveUserData(_updateUserData);
    }

    if (!_userData.userGroup) {
      toast.warning(messageUserNotAssociatedGroups);
    }

    const dataToRefresh = new URLSearchParams({
      refresh_token: refreshToken,
      grant_type: "refresh_token",
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
    });

    const tokensRefreshDetails = await repositoryLogin.requestTokens(dataToRefresh);
    const parseJwtRefresh = parseJwt(tokensRefreshDetails?.token);

    const userDataRefresh = {..._userData, token: String(tokensRefreshDetails?.token),
      refreshToken: String(tokensRefreshDetails?.refresh_token),
      userGroup: parseJwtRefresh?.group?.length ? parseJwtRefresh.group[0] : "",
      authorities: parseJwtRefresh.authorities,
    };
    saveUserData(userDataRefresh);

    await repositoryLogin.logLogin();
  }, []);

  const logoutKeycloakAd = useCallback(async () => {
    await keycloak.logout();
  }, []);

  useEffect(() => {
    const setUserLogged = () => {
      const storageData = localStorage.getItem(storageKey);
      if (!storageData) return;

      const parsedData = JSON.parse(storageData);
      const _roles = getObjectifyRoles(parsedData.authorities);

      setUserData(parsedData);
      setRoles(_roles);
      setIsSignedIn(true);
    };

    initKeycloak();
    setUserLogged();
  }, [initKeycloak]);

  const saveUserData = (user: TUserData) => {
    localStorage.setItem(storageKey, JSON.stringify(user));
    const _roles = getObjectifyRoles(user.authorities);
    setUserData(user);
    setRoles(_roles);
    setIsSignedIn(true);
  };

  const removeUserData = () => {
    localStorage.removeItem(storageKey);
    setUserData(undefined);
    setIsSignedIn(false);
  };

  const getObjectifyRoles = (rolesArray: string[]): TRoles => {
    const filterRoles = rolesArray?.filter((role) => role.includes("_"));
    const _roles = filterRoles?.reduce((acc, curr) => {
      const role = curr.toLowerCase();
      return {
        ...acc,
        [role]: true,
      };
    }, {} as TSingleLevelObject);
    return _roles as TRoles;
  };

  const hasToken = () => Boolean(localStorage.getItem(storageKey));

  const hasRole = (permission?: boolean) => roles?.role_admin || permission;

  const refreshTokens = async () => {
    const present = new Date();

    if (!userData) return;

    const elapsedTime = (present.getTime() - tokenUpdatedAt.getTime()) / 1000;

    if (elapsedTime < 60) {
      return;
    }

    setTokenUpdatedAt(present);

    const storageData = localStorage.getItem(storageKey);
    if (!storageData) return undefined;

    const { refreshToken } = JSON.parse(storageData);
    const data = new URLSearchParams({
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    });

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    // const url = process.env.REACT_APP_KEYCLOAK_URL as string
    // const response = await rest.post(url, data, headers)
    // if (!response.access_token || !response.refresh_token) return
    // saveUserData({
    //   ...userData,
    //   token: response.access_token,
    //   refreshToken: response.refresh_token
    // })
  };

  const values = useMemo(
    () =>
      ({
        isSignedIn,
        userData,
        roles,
        hasToken,
        logoutKeycloakAd,
        keycloak,
        saveUserData,
        removeUserData,
        hasRole,
        refreshTokens,
      } as TUseAuth),
    [isSignedIn, userData, logoutKeycloakAd]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

const useAuth = (): TUseAuth => useContext(AuthContext);

export { useAuth, AuthProvider };
