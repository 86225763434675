import useRest from 'hooks/useRest'
import { useState } from 'react'
import {
  TCostCenterParams,
  TCostCenterResponse
} from 'repositories'

const useCostCenter = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findByFilterCostCenter = async (params: TCostCenterParams, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/cost-center'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TCostCenterResponse
  }

  const findCostCenterById = async (id: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/cost-center/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result
  }

  return {
    findByFilterCostCenter,
    findCostCenterById,
    loading
  }
}

export default useCostCenter
