import React, { useEffect, useMemo, useState } from 'react'
import { v4 as genId } from 'uuid'
import { Divider, Empty, Table } from 'antd'
import { Col, Row } from 'components'
import {
  TPaymentScheduleData,
} from 'repositories'
import type { ColumnsType } from 'antd/lib/table'
import { tableLocale } from 'utils/lang'
import moment from 'moment'
import {
  formatCurrency,
  formatStringToNumber
} from 'utils/helpers'

type TPaymentScheduleRow = {
  [key: string]: string
}

type TProps = {
  schedule: TPaymentScheduleData[]
}

const fixedColumn: ColumnsType<TPaymentScheduleRow> = [
  {
    title: '',
    dataIndex: 'responsiblePayment',
    key: 'responsiblePayment'
  }
]

const PaymentSchedule = ({ schedule }: TProps) => {
  const [columns, setColumns] = useState<ColumnsType<TPaymentScheduleRow>>([])
  const [data, setData] = useState<TPaymentScheduleRow[]>([])
  const [countMonths, setCountMonths] = useState(0)
  const [apportionsMonth, setApportionsMonth] = useState<{ value: number }[]>([])

  useEffect(() => {
    const _months = schedule.flatMap(_schedule => _schedule.apportions.map(apportion => apportion.dueDate))
    const uniqueMonths = Array.from(new Set(_months))
    const ordenedMonths = uniqueMonths.sort()
    const updatedColumns = ordenedMonths.map((month, index) => ({
      title: moment(month).format('DD/MM/YYYY'),
      dataIndex: `month${index}`,
      key: `month${index}`,
      align: 'center'
    })) as ColumnsType<TPaymentScheduleRow>
    setCountMonths(ordenedMonths.length)

    const updatedData = schedule.map(_schedule => {
      const { apportions } = _schedule

      const mappedApportions = ordenedMonths.map(month => {
        const findApportion = apportions.find(apportion => apportion.dueDate === month)
        if (!findApportion) return { value: '' }

        return {
          value: formatCurrency(findApportion.value, false)
        }
      })
      const formattedApportions = mappedApportions.map(item => ({
        value: formatStringToNumber(item.value)
      }))

      setApportionsMonth(prevState => [...prevState, ...formattedApportions])

      const objectifyApportion = mappedApportions.reduce((acc, curr, index) => ({
        ...acc,
        [`month${index}`]: curr.value
      }), {} as TPaymentScheduleRow)

      return {
        responsiblePayment: _schedule.responsiblePayment,
        ...objectifyApportion
      }
    })

    setData(updatedData)
    setColumns([...fixedColumn, ...updatedColumns])
  }, [schedule])

  const summary = useMemo(() => {
    const formattedData = data.map(item => Object.values(item))
    const monthKeys = Array(countMonths).keys()
    const totalMonths = Array.from(monthKeys).map(month => {
      const _index = month + 1
      const totalMonth = formattedData.flatMap(item => formatStringToNumber(item[_index]))
      return totalMonth.reduce((acc, curr) => acc + curr, 0)
    })
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell
          index={1}
          align='right'
        />
        {totalMonths.map((totalMonth, index) => {
          const key = genId()
          const formattedTotalMonth = formatCurrency(totalMonth, false)
          return (
            <Table.Summary.Cell
              index={index}
              key={key}
              align='center'
            >
              {formattedTotalMonth}
            </Table.Summary.Cell>
          )
        })}
      </Table.Summary.Row>
    )
  }, [countMonths, apportionsMonth])

  return (
    <>
      <Row>
        <Col>
          <Divider
            plain
            orientation='left'
          >
            Cronograma de pagamentos
          </Divider>
        </Col>
      </Row>
      <Row>
        <Col>
          {schedule.length ? (
            <Table
              sticky
              size='middle'
              scroll={{ x: 1400 }}
              columns={columns}
              locale={tableLocale}
              dataSource={data}
              summary={() => summary}
            />
          ) : (
            <Empty />
          )}
        </Col>
      </Row>
    </>
  )
}

export default PaymentSchedule
