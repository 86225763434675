import React, { useEffect, useState } from 'react'
import {
  Button,
  Empty,
  message,
  Modal,
  Spin
} from 'antd'
import { cnpj } from 'cpf-cnpj-validator'
import {
  TSupplierQuoteItem,
  TSupplierRequest,
  useProjectRequest,
  useSupplierQuote
} from 'repositories'
import {
  formatCurrency,
  formatDateToView
} from 'utils/helpers'
import {
  answerQuote,
  StatusQuote,
  statusQuote,
  StatusSupplierAnswer
} from 'utils/constants'
import { useNavigate } from 'react-router'
import {
  Container,
  Content,
  Divider,
  EmptyContent,
  Info
} from './styles'

const { confirm } = Modal

type TCompareQuoteItem = {
  productName: string
  amount: number
  deliveryLocation: string
  eventDate: string
  projectName?: string
  clientName: string
  unitValue: string
  totalValue: string
}

type TSupplier = Pick<TSupplierRequest, 'cnpj' | 'corporateName' | 'email' | 'telephone'> & {
  city: string
  state: string
  score: number
  formattedScore: string
}

type TCompareQuote = {
  idSupplierQuote: number
  statusSupplierQuote: string
  formattedStatusSupplierQuote: string
  formattedSupplierAnswer: string
  supplierAnswer: string
  supplier: TSupplier
  items: TCompareQuoteItem[]
  enableActions: boolean
  footer: {
    totalValue: string
    totalAmount: number
  }
}

type TProps = {
  projectRequestId: number
}

const CompareQuote = ({ projectRequestId }: TProps) => {
  const [totalItems, setTotalItems] = useState(0)
  const [quotes, setQuotes] = useState<TCompareQuote[]>([])

  const repository = useProjectRequest()
  const quoteRepository = useSupplierQuote()
  const navigate = useNavigate()

  useEffect(() => {
    const onSort = (list: TSupplierQuoteItem[]) => {
      if (!list.length) return []

      return list.sort((old, after) => {
        if (old.productDescription > after.productDescription) return 1
        if (old.productDescription < after.productDescription) return -1

        return 0
      })
    }

    const getAnalyzeQuote = async () => {
      const response = await repository.findAnalyzeQuoteById(projectRequestId)
      if (!response) return

      const formattedData = response.quotes?.map(quote => {
        const { supplier, items } = quote

        setTotalItems(items.length)
        const sortedItems = onSort(items)

        const total = items.reduce((acc, curr) => ({
          totalValue: acc.totalValue + Number(curr.totalValue),
          totalAmount: acc.totalAmount + Number(curr.amount),
        }), { totalValue: 0, totalAmount: 0 })

        return {
          ...quote,
          formattedSupplierAnswer: answerQuote[quote.supplierAnswer],
          formattedStatusSupplierQuote: statusQuote[quote.statusSupplierQuote],
          supplier: {
            ...supplier,
            cnpj: cnpj.format(supplier.cnpj),
            city: `${supplier.city}/${supplier.state}`,
            score: supplier.score || 0,
            formattedScore: (
              supplier.score
                ? `${supplier.score} Estrela(s)`
                : 'Não avaliado'
            )
          },
          items: sortedItems.map(item => ({
            productName: item.productDescription,
            projectName: item.project?.projectName,
            clientName: item.project?.corporateName,
            amount: item.amount,
            unitValue: formatCurrency(item.unitValue, false),
            totalValue: formatCurrency(item.totalValue, false),
            deliveryLocation: item.deliveryLocation,
            eventDate: formatDateToView(item.eventDate)
          } as TCompareQuoteItem)),
          footer: {
            totalAmount: total.totalAmount,
            totalValue: formatCurrency(total.totalValue, false)
          },
          enableActions: (
            quote.supplierAnswer === StatusSupplierAnswer.ACCEPT && quote.statusSupplierQuote === StatusQuote.PROPOSAL_SENT
          )
        }
      })
      setQuotes(formattedData)
    }

    if (projectRequestId) getAnalyzeQuote()
  }, [projectRequestId])

  const onClickConfirmChoice = (
    supplierQuoteId: number,
    classify: boolean
  ) => () => {
    const messageContent = classify
      ? 'Confirma a "Classificação" da proposta?'
      : 'Confirma a "Desclassificação" da proposta?'

    confirm({
      title: 'Confirmação',
      type: 'confirm',
      content: messageContent,
      okText: 'Sim, confirmar',
      async onOk() {
        if (classify) {
          await chooseQuoteWinner(supplierQuoteId)
          return
        }

        await declassifyQuote(supplierQuoteId)
      },
    })
  }

  const chooseQuoteWinner = async (supplierQuoteId: number) => {
    const response = await quoteRepository.choiceQuoteWinner(supplierQuoteId)
    if (!response) return

    message.success('Cotação escolhida como vencedora, fornecedor ira receber o email.')
    navigate('/requisicao-compras')
  }

  const declassifyQuote = async (supplierQuoteId: number) => {
    const response = await quoteRepository.declassifyQuote(supplierQuoteId)
    if (!response) return

    message.success('Cotação desclassificada, fornecedor ira receber o email.')
    navigate('/requisicao-compras')
  }

  return (
    <Container>
      {repository.loading && (
        <EmptyContent>
          <Spin />
        </EmptyContent>
      )}
      {!quotes?.length ? (
        <EmptyContent>
          <Empty />
        </EmptyContent>
      ) : (
        <>
          <Content>
            {Array.from(new Array(6)).map(key => (
              <Info
                key={key}
                noBorder
              />
            ))}
            <Divider />
            <Info noBorder />
            <Info alignedRight>N° Cotação</Info>
            <Info alignedRight>Status</Info>
            <Info alignedRight>Aceite?</Info>
            {Array.from(new Array(totalItems)).map(() => (
              <>
                <Divider />
                <Info noBorder />
                <Info alignedRight>Quantidade</Info>
                <Info alignedRight>Valor</Info>
                <Divider />
                <Info alignedRight>Projeto</Info>
                <Info alignedRight>Cliente</Info>
                <Info alignedRight>Data do evento</Info>
                <Info alignedRight>Local de entrega</Info>
                <Info alignedRight>Anexo</Info>
              </>
            ))}
          </Content>
          {quotes?.map((quote, index) => {
            const currentIndex = index + 1
            const { supplier, items } = quote

            const formattedItems = items.map(item => (
              <>
                <Divider />
                <Info highlight>{item.productName}</Info>
                <Info>{item.amount}</Info>
                <Info>{item.unitValue}</Info>
                <Divider />
                <Info>{item.projectName}</Info>
                <Info>{item.clientName}</Info>
                <Info>{item.eventDate}</Info>
                <Info>{item.deliveryLocation}</Info>
                <Info />
              </>
            ))

            return (
              <Content key={quote.idSupplierQuote}>
                <Info highlight>{`Fornecedor ${currentIndex}`}</Info>
                <Info>{supplier.cnpj}</Info>
                <Info>{supplier.corporateName}</Info>
                <Info>{supplier.email}</Info>
                <Info>{supplier.city}</Info>
                <Info>{supplier.formattedScore}</Info>
                <Divider />
                <Info highlight>{`Proposta ${currentIndex}`}</Info>
                <Info>{quote.idSupplierQuote}</Info>
                <Info>{quote.formattedStatusSupplierQuote}</Info>
                <Info>{quote.formattedSupplierAnswer}</Info>
                {formattedItems}
                <Divider />
                <Info highlight>{quote.footer.totalAmount}</Info>
                <Info highlight>{quote.footer.totalValue}</Info>
                <Divider />
                <Button
                  type='primary'
                  disabled={!quote.enableActions}
                  onClick={onClickConfirmChoice(quote.idSupplierQuote, true)}
                >
                  Classificar
                </Button>
                <Button
                  danger
                  disabled={!quote.enableActions}
                  onClick={onClickConfirmChoice(quote.idSupplierQuote, false)}
                >
                  Desclassificar
                </Button>
              </Content>
            )
          })}
        </>
      )}
    </Container>
  )
}

export default CompareQuote
