import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 10px;
  overflow: auto;
  height: calc(100vh - 168px);
`

type TContentProps = {
  width?: string
}

export const Content = styled.div<TContentProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '300px'};
  gap: 5px;
  flex-shrink: 0;
`

type TInfoProps = {
  highlight?: boolean
  noBorder?: boolean
  alignedRight?: boolean
}

export const Info = styled.div<TInfoProps>`
  padding: 4px 8px;
  min-height: 27px;
  border: ${({ theme, noBorder }) => (
    noBorder
      ? 0
      : theme.borders.SOLID_LIGHT_GRAY
  )};
  
  font-size: 12px;
  font-weight: ${({ highlight }) => (highlight ? '600' : '400')};
  text-align: ${({ alignedRight }) => (alignedRight ? 'right' : 'center')};
`

export const Divider = styled.div`
  margin-top: 10px;
`

export const EmptyContent = styled.div`
  width: 100%;
`
