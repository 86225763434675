import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { message } from 'antd';

message.config({
  duration: 10,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
