import { useState } from 'react'
import useRest from 'hooks/useRest'
import {
  TCompanyResponse
} from 'repositories'

const useCompany = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findByFilterCompany = async (triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/company'
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TCompanyResponse
  }

  return {
    findByFilterCompany,
    loading
  }
}

export default useCompany
