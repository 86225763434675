import React, { useEffect, useMemo, useState } from "react";
import type { Rule } from "antd/lib/form";
import {
  TApprovalHierarchyForm,
  useApprovalHierarchy,
  useBusinessUnits,
} from "repositories";
import {
  Section,
  PageHeader,
  Row,
  Col,
  TextField,
  SelectField,
  CheckField,
  SearchField,
} from "components";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import {
  Button,
  Form,
  message,
  Space,
  Radio,
  Checkbox,
  TreeSelect,
} from "antd";
import { useAuth } from "hooks";
import ApproverList from "../Components/ApproverList";
import ApproverReferenceList from "../Components/ApproverReferenceList";
import axios from "../../../services/axios";
import { AxiosError } from "axios";

const initialValues: TApprovalHierarchyForm = {
  id: "",
  requestId: "",
  name: "",
  businessUnits: {},
  timing: "",
  reference: false,
  categoryRequest: [],
  typeRequest: "",
  typeProvider: "",
  approvers: [],
  approversReference: [],
};

const validations: Record<string, Rule[]> = {
  name: [
    {
      required: true,
      message: 'Campo "Nome" é obrigatório',
    },
    {
      max: 150,
      message: "Nome não pode ser maior que 150 caracteres",
    },
  ],
};

const createMessageSuccess = "Alçada criada com sucesso!";
const updateMessageSuccess = "Alçada atualizada com sucesso!";

const ApprovalHierarchyForm = () => {
  const [approvalHierarchy, setApprovalHierarchy] =
    useState<TApprovalHierarchyForm>({} as TApprovalHierarchyForm);

  const { roles: authRoles, hasRole } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id: IdApprovalHierarchy } = useParams<{ id: string }>();
  const repository = useApprovalHierarchy();
  const buRepository = useBusinessUnits();
  const [form] = Form.useForm<TApprovalHierarchyForm>();
  const [typeRequest, setTypeRequest] = useState(1);
  const [typeProvider, setTypeProvider] = useState(1);
  const [timing, setTiming] = useState("");
  const [checkBoxSimpleRequest, setCheckBoxSimpleRequest] = useState([]);
  const [checkBoxCompostRequest, setCheckBoxCompostRequest] = useState([]);
  const [bus, setBUs] = useState([]);
  const [optionsBU, setBUOptions] = useState([]);
  const [isReference, setIsReference] = useState(false);
  const [simpleRequest, setSimpleRequest] = useState<any>(1);
  const [compositeRequest, setCompositeRequest] = useState<any>(1);
  const [linkedRequest, setLinkedRequest] = useState(1);
  const [notLinkedType, setNotLinkedType] = useState(1);

  const [bu, setBu] = useState<any>();
  const [value, setValue] = useState<any>();
  const [BuName, setBuName] = useState("");

  const onChangeBU = (newValue: any) => {
    const _bu = bus
      .map((bu: any) => ({ id: bu.id, name: bu.name }))
      .filter((bu: any) => bu.name === newValue);
    setBu(_bu[0].id);
  };

  const onSearchBU = async (searchText: string) => {
    const response = await buRepository.findBus({
      name: searchText,
      page: 0,
      size: 10,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.name }));
    setBUOptions(mapped);
    setBUs(response.content);
  };

  // useEffect(() => {
  //   console.warn
  // }, [approvalHierarchy])

  useEffect(() => {
    const getApprovalHierarchyById = async () => {
      const response = await repository.findByIdApprovalHierarchy(
        Number(IdApprovalHierarchy)
      );
      if (!response) return;
      const approvers = response.approvers.map((approver, index) => ({
        ...approver,
        localId: index + 1,
      }));
      const approversReference = response.approversReference.map(
        (approver, index) => ({
          ...approver,
          localId: index + 1,
        })
      );

      const {
        id,
        name,
        timing,
        businessUnits,
        requestId,
        typeProvider,
        typeRequest,
        reference,
        categoryRequest,
      } = response;

      const data = {
        id,
        name,
        timing,
        businessUnits,
        typeProvider,
        typeRequest,
        reference,
        categoryRequest,
        requestId: requestId ? response.requestId : "",
        selectBu: businessUnits.name,
        approvers,
        approversReference,
      };

      form.setFieldsValue(data);

      setIsReference(data.reference);
      setTiming(data.timing);
      setValue(response.businessUnits.id);
      setBuName(response.businessUnits.name);
      setApprovalHierarchy(data);
    };
    // const getBU = async () => {
    //   const params = {
    //     name: "",
    //     page: 0,
    //     size: 1000,
    //     // sort,
    //   };

    //   const response = await buRepository.findBus(params);
    //   if (!response) return;
    //   const mappedBU = response?.content?.map((bu: any) => {
    //     return {
    //       label: bu?.name,
    //       value: bu?.id,
    //     };
    //   });
    //   setBu(mappedBU);
    // };

    form.resetFields();
    // getBU();
    if (IdApprovalHierarchy) {
      getApprovalHierarchyById();
    }
  }, [IdApprovalHierarchy]);
  const onFinish = async (values: any) => {
    // const _arrayCategory = typeRequest === 1 ? checkBoxSimpleRequest : checkBoxCompostRequest
    // const _options = typeRequest === 1 ? options : secondOptions
    // const categoryRequest = _arrayCategory.map((value) => {
    //   const option = _options.find((opt) => opt.value === value);
    //   return option ? `'${option.label}'` : null;
    // })
    if (!IdApprovalHierarchy) {
      const response = await repository.createApprovalHierarchy({
        name: values.name,
        businessUnits: bu,
        timing: timing,
        reference: isReference,
        categoryRequest: "['Compras e contratações']", // `[${categoryRequest.filter(Boolean).join(", ")}]`
        typeRequest: typeRequest === 1 ? "SIMPLE" : "COMPOSED",
        // typeProvider: typeProvider === 1 ? "SUPPLIER" : "FREELANCE"
      });
      if (!response) return;

      message.success(createMessageSuccess);
      navigate(`/alcadas/editar/${response.id}`);
      return;
    }

    const response = await repository.updateApprovalHierarchy({
      id: Number(IdApprovalHierarchy),
      name: values.name,
      businessUnits: bu,
      timing: timing,
      reference: isReference,
      categoryRequest: "['Compras e contratações']", // `[${categoryRequest.filter(Boolean).join(", ")}]`
      typeRequest: typeRequest === 1 ? "SIMPLE" : "COMPOSED",
      // typeProvider: typeProvider === 1 ? "SUPPLIER" : "FREELANCE"
    });
    if (!response) return;

    message.success(updateMessageSuccess);
    onClickGoBack();
  };

  const onClickGoBack = () => {
    navigate("/alcadas");
  };

  const { title, isView } = useMemo(() => {
    const [, , page] = pathname.split("/");
    const getTitle = () => {
      if (page === "visualizar") return "Visualizar";
      if (IdApprovalHierarchy) return "Editar";

      return "Nova";
    };

    return {
      title: getTitle(),
      isView: page === "visualizar",
    };
  }, [IdApprovalHierarchy]);

  if (
    authRoles &&
    !hasRole(
      authRoles?.approvalhierarchy_edit || authRoles?.approvalhierarchy_view
    )
  ) {
    return <Navigate to="/acesso-negado" />;
  }

  const onChangeTypeRequest = (e: any) => {
    setTypeRequest(e.target.value);
  };

  const onChangeTiming = (e: any) => {
    setTiming(e.target.value);
  };

  const onChangeTypeProvider = (e: any) => {
    setTypeProvider(e.target.value);
  };

  const onChangeCheckBoxSimpleRequest = (e: any) => {
    setCheckBoxSimpleRequest(e);
  };

  const onChangeCheckBoxCompostRequest = (e: any) => {
    setCheckBoxCompostRequest(e);
  };

  const onChangeIsReference = (e: any) => {
    setIsReference(e.target.checked);
  };

  const onChangeSimpleRequest = (e: any) => {
    setSimpleRequest(e.target.value);
  };

  const onChangeCompositeRequest = (e: any) => {
    setCompositeRequest(e.target.value);
  };

  const onChangeLinkedRequest = (e: any) => {
    setLinkedRequest(e.target.value);
  };

  const onChangeNotLinkedRequestType = (e: any) => {
    setNotLinkedType(e.target.value);
  };

  const onChange = (checkedValues: any) => {
    // console.log("checked = ", checkedValues);
  };

  const options = [
    { label: "Compras e contratações", value: 1 },
    { label: "Solicitação de venda", value: 2 },
    { label: "Cartão corporativo", value: 3 },
    { label: "Prestação de contas", value: 4 },
  ];

  const secondOptions = [
    { label: "Pacote", value: 11 },
    { label: "Roteiro", value: 22 },
  ];

  return (
    <>
      <PageHeader
        title={`${title} Alçada de Aprovação`}
        breadcrumbItems={["Alçadas", title]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col xl={12} xxl={12}>
              <TextField required disabled name="id" label="Id" />
            </Col>
            <Col xl={12} xxl={12}>
              <TextField
                required
                disabled
                name="requestId"
                label="ID da requisição"
              />
            </Col>
          </Row>
          <Row>
            <Col xl={14}>
              <TextField
                required
                disabled={isView}
                rules={validations.name}
                name="name"
                label="Nome"
              />
            </Col>
            <Col xl={14}>
              <Checkbox
                name="reference"
                disabled={isView}
                onChange={onChangeIsReference}
                checked={isReference}
              />
              Alçada de Referência
            </Col>
          </Row>
          <Row>
            <Col xl={24}>
              <Form.Item>
                Parâmetros para configuração de alçada de aprovação de
                requisições
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  disabled={isView}
                  onChange={onChangeTypeRequest}
                  value={typeRequest}
                >
                  <Radio value={1}>Requisições Simples</Radio>
                  <Radio value={2}>Requisições Compostas</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={1}>
              <></>
            </Col>
            <Col xl={23}>
              <Form.Item>
                {typeRequest === 1 ? (
                  <Radio.Group
                    disabled={isView}
                    onChange={onChangeCheckBoxSimpleRequest}
                    value={1}
                  >
                    <Radio value={1}>Compras e contratações</Radio>
                  </Radio.Group>
                ) : (
                  <Checkbox.Group
                    options={secondOptions}
                    disabled={isView}
                    onChange={onChangeCheckBoxCompostRequest}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  disabled={isView}
                  onChange={onChangeTiming}
                  value={timing}
                >
                  <Radio value="PLANEJADA">Planejada</Radio>
                  <Radio value="EMERGENCIAL">Não planejada</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <Form.Item>*/}
          {/*      <Radio.Group*/}
          {/*          onChange={onChangeTypeProvider} value={typeProvider}*/}
          {/*      >*/}
          {/*        <Radio value={1}>Fornecedor</Radio>*/}
          {/*        <Radio value={2}>Freelancer</Radio>*/}
          {/*      </Radio.Group>*/}
          {/*    </Form.Item>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  onChange={onChangeLinkedRequest}
                  value={linkedRequest}
                  disabled={isView}
                >
                  <Radio value={1}>Atrelada a projeto</Radio>
                  <Radio value={2}>Não atrelada a projeto</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <SearchField
                onSearch={onSearchBU}
                onChange={onChangeBU}
                required
                allowClear
                name="selectBu"
                disabled={isView}
                placeholder="Selecione uma unidade de negócio"
                options={optionsBU}
                value={BuName}
              />
            </Col>
          </Row>
          {IdApprovalHierarchy && (
            <ApproverList
              isView={isView}
              initialApprovers={approvalHierarchy.approvers}
            />
          )}

          {!isReference ? (
            <ApproverReferenceList
              isView={isView}
              initialApprovers={approvalHierarchy.approversReference}
            />
          ) : (
            <></>
          )}
          <Form.Item className="mt-1">
            <Space size="small">
              <Button onClick={onClickGoBack} htmlType="button">
                Voltar
              </Button>
              <Button
                loading={repository.loading}
                disabled={isView || repository.loading}
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Section>
    </>
  );
};

export default ApprovalHierarchyForm;
