import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 15px;
`

export const FilterContainer = styled.div`
  display: flex;
  gap: 5px;
  float: right;
  margin-bottom: 10px;
`
export const WrapperDrop = styled.span`
  .ant-form-item-label {
   text-align: left !important;
  }
  .ant-row {
    flex-direction: column;
  }
`