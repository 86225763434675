import React, {
  useEffect, useMemo, useState
} from 'react'
import {
  Button,
  Divider,
  Form,
  Space
} from 'antd'
import {
  CheckField,
  Col, HiddenField, MaskedField, Row, SearchField, SelectField, TextField
} from 'components'
import ContactList from 'pages/Suppliers/Components/ContactList'
import { useParams } from 'react-router'
import {
  TAddressRequest,
  TBank,
  TBankAccountRequest,
  useBanks,
  useCommon,
  useSuppliers
} from 'repositories'
import { YesNo, yesNoOptions } from 'utils/constants'
import type { Rule } from 'antd/lib/form'
import { Content } from '../styles'

type TComplementStepForm = {
  bankId: number | string
  bankName: string
  accountId: number
  bankAgency: string
  bankAgencyDigit: string
  bankAccount: string
  bankAccountDigit: string
  legalPersonAccount: string
  addressId: number
  isForeign: boolean
  zipCode: string
  publicPlace: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  country: string
}

const initialValues: TComplementStepForm = {
  bankId: '',
  bankName: '',
  accountId: 0,
  bankAgency: '',
  bankAgencyDigit: '',
  bankAccount: '',
  bankAccountDigit: '',
  legalPersonAccount: YesNo.YES,
  addressId: 0,
  isForeign: false,
  zipCode: '',
  publicPlace: '',
  number: '',
  complement: '',
  neighborhood: '',
  state: '',
  city: '',
  country: ''
}

const validations: Record<string, Rule[]> = {
  bankName: [
    { required: true, message: 'Campo "Banco" é obrigatório' },
  ],
  legalPersonAccount: [
    { required: true, message: 'Campo "Tipo de Conta" é obrigatório' },
  ],
  bankAgencyDigit: [
    { required: true, message: 'Campo "N° Agência" é obrigatório' },
  ],
  bankAccount: [
    { required: true, message: 'Campo "N° Conta" é obrigatório' },
  ],
  bankAccountDigit: [
    { required: true, message: 'Campo "N° Dígito" é obrigatório' },
  ],
  zipCode: [
    ({ getFieldValue }) => ({
      validator(_, zipCode: string) {
        const isForeign = getFieldValue('isForeign')
        if (isForeign && !zipCode) {
          return Promise.reject(new Error('Campo "Zip Code" é obrigatório"'))
        }

        if (!isForeign && !zipCode) {
          return Promise.reject(new Error('Campo "CEP" é obrigatório"'))
        }

        return Promise.resolve()
      },
    })
  ],
  publicPlace: [
    { required: true, message: 'Campo "Logradouro" é obrigatório' },
  ],
  neighborhood: [
    { required: true, message: 'Campo "Bairro" é obrigatório' },
  ],
  city: [
    { required: true, message: 'Campo "Cidade" é obrigatório' },
  ],
  state: [
    { required: true, message: 'Campo "Estado" é obrigatório' },
  ],
  country: [
    { required: true, message: 'Campo "Páis" é obrigatório' },
  ],
}

type TProps = {
  onChangeStep(value: number): void
}

const Complement = ({ onChangeStep }: TProps) => {
  const [bank, setBank] = useState('')
  const [banks, setBanks] = useState<TBank[]>([])
  const [bankOptions, setBankOptions] = useState<{ value: string }[]>([])
  const [complementData, setComplementData] = useState<TComplementStepForm>({} as TComplementStepForm)

  const { id } = useParams<{ id: string }>()
  const repository = useSuppliers()
  const bankRepository = useBanks()
  const repositoryCommon = useCommon()
  const [form] = Form.useForm<TComplementStepForm>()
  const watchedForeign = Form.useWatch('isForeign', form)

  const onFinish = async (values: TComplementStepForm) => {
    const dataAddress: TAddressRequest = {
      id: Number(values.addressId),
      supplierId: Number(id),
      publicPlace: values.publicPlace ?? '',
      number: String(values.number),
      complement: values.complement ?? '',
      neighborhood: values.neighborhood ?? '',
      city: values.city ?? '',
      state: values.state ?? '',
      country: values.country ?? '',
      zipCode: values.zipCode ?? '',
      foreigner: values.isForeign as boolean
    }
    const responseAddress = complementData.addressId
      ? await repository.updateAddress(dataAddress)
      : await repository.createAddress(dataAddress)
    if (!responseAddress) return

    const agency = values.bankAgencyDigit
      ? `${values.bankAgency}-${values.bankAgencyDigit}`
      : values.bankAgency

    const dataBankAccount: TBankAccountRequest = {
      id: Number(values.accountId),
      supplierId: Number(id),
      number: values.bankAccount ?? '',
      digit: values.bankAccountDigit ?? '',
      branch: agency ?? '',
      bankId: Number(values.bankId),
      legalPersonAccount: values.legalPersonAccount === 'ON'
    }

    const responseBankAccount = complementData.accountId
      ? await repository.updateBankAccount(dataBankAccount)
      : await repository.createBankAccount(dataBankAccount)
    if (!responseBankAccount) return

    onChangeStep(3)
  }

  useEffect(() => {
    const getSupplier = async () => {
      if (!id) return
      const response = await repository.findSupplierById(Number(id))
      if (!response) return

      const isForeign = response.supplierAddresses?.zipCode.length !== 8

      const data: TComplementStepForm = {
        bankId: response.bankAccount?.bank.id || 0,
        bankName: response.bankAccount?.bank.name || '',
        bankAccount: response.bankAccount?.number || '',
        bankAccountDigit: response.bankAccount?.digit || '',
        bankAgency: response.bankAccount?.branch.split('-')[0] || '',
        bankAgencyDigit: response.bankAccount?.branch.split('-')[1] || '',
        accountId: response.bankAccount?.id || 0,
        legalPersonAccount: (
          response.bankAccount?.legalPersonAccount
            ? YesNo.YES
            : YesNo.NO
        ),
        isForeign,
        zipCode: response.supplierAddresses?.zipCode ?? '',
        publicPlace: response.supplierAddresses?.publicPlace ?? '',
        number: response.supplierAddresses?.number ?? '',
        complement: response.supplierAddresses?.complement ?? '',
        neighborhood: response.supplierAddresses?.neighborhood ?? '',
        city: response.supplierAddresses?.city ?? '',
        state: response.supplierAddresses?.state ?? '',
        country: response.supplierAddresses?.country ?? '',
        addressId: response.supplierAddresses?.id || 0
      }
      setComplementData(data)
      form.setFieldsValue(data)
    }

    form.resetFields()
    getSupplier()
  }, [id])

  const onBlurCep = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target) return
    const { value } = e.target

    const getInfoAddress = async () => {
      const response = await repositoryCommon.getAddressByBrasilApi(value)
      if (!response) return

      form.setFieldsValue({
        zipCode: value,
        publicPlace: response.street,
        neighborhood: response.neighborhood,
        city: response.city,
        state: response.state,
        country: 'Brasil'
      })
    }

    if (value.length === 9 && !watchedForeign) getInfoAddress()
  }

  const onSearchBank = async (searchText: string) => {
    if (searchText.length < 3) return

    const response = await bankRepository.findBanksByFilter({
      name: searchText,
      page: 0,
      size: 10
    })
    if (!response) return

    const mappedBankToOptions = response.content.map(item => ({ value: item.name }))
    setBankOptions(mappedBankToOptions)
    setBanks(response.content)
  }

  const onChangeBank = (data: string) => {
    setBank(data)
    const findBank = banks.find(_bank => _bank.name === data)
    if (!findBank) return

    form.setFieldsValue({
      bankId: findBank.id,
      bankName: findBank.name
    })
  }

  const onClickGoBack = () => onChangeStep(1)

  const { labelZipCode, maskZipCode } = useMemo(() => ({
    labelZipCode: watchedForeign ? 'Zip Code' : 'CEP',
    maskZipCode: watchedForeign ? '' : '99999-999'
  }), [watchedForeign])

  return (
    <Content size='large'>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col xl={24}>
            <Divider plain orientation='left'>Conta bancária</Divider>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <HiddenField
              name='accountId'
            />
            <HiddenField
              name='bankId'
            />
            <SearchField
              required
              name='bankName'
              label='Banco'
              isLoading={repositoryCommon.loading}
              rules={validations.bankName}
              value={bank}
              options={bankOptions}
              onChange={onChangeBank}
              onSearch={onSearchBank}
              placeholder='Pesquise pelo nome do banco'
            />
          </Col>
          <Col xl={12}>
            <SelectField
              required
              name='legalPersonAccount'
              rules={validations.legalPersonAccount}
              label='Conta PJ?'
              options={yesNoOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={16} xl={8}>
            <TextField
              required
              maxLength={50}
              name='bankAgency'
              label='N° Agência'
              rules={validations.bankAgency}
            />
          </Col>
          <Col xs={16} xl={4}>
            <TextField
              maxLength={1}
              name='bankAgencyDigit'
              label='N° Dígito'
            />
          </Col>
          <Col xs={16} xl={8}>
            <TextField
              required
              maxLength={50}
              name='bankAccount'
              label='N° Conta'
              rules={validations.bankAccount}
            />
          </Col>
          <Col xs={16} xl={4}>
            <TextField
              required
              maxLength={1}
              name='bankAccountDigit'
              label='N° Dígito'
              rules={validations.bankAccountDigit}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <Divider plain orientation='left'>Endereço</Divider>
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <HiddenField
              name='addressId'
            />
            <CheckField
              name='isForeign'
              label='Estrangeira'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <MaskedField
              required
              name='zipCode'
              label={labelZipCode}
              onBlur={onBlurCep}
              mask={maskZipCode}
              rules={validations.zipCode}
            />
          </Col>
          <Col xl={14}>
            <TextField
              required
              name='publicPlace'
              label='Logradouro'
              rules={validations.publicPlace}
            />
          </Col>
          <Col xl={4}>
            <TextField
              required
              maxLength={10}
              name='number'
              label='Número'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField
              required
              maxLength={50}
              name='neighborhood'
              rules={validations.neighborhood}
              label='Bairro'
            />
          </Col>
          <Col xl={12}>
            <TextField
              name='complement'
              label='Complemento'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <TextField
              required
              maxLength={50}
              rules={validations.city}
              name='city'
              label='Cidade'
            />
          </Col>
          <Col xl={6}>
            <TextField
              required
              maxLength={50}
              rules={validations.state}
              name='state'
              label='Estado'
            />
          </Col>
          <Col xl={6}>
            <TextField
              required
              maxLength={50}
              rules={validations.country}
              name='country'
              label='País'
            />
          </Col>
        </Row>
        <ContactList
          supplierId={Number(id)}
          isFullWidth
        />
        <Form.Item>
          <Space size='small'>
            <Button
              onClick={onClickGoBack}
              htmlType='button'
            >
              Voltar
            </Button>
            <Button
              loading={repository.loading}
              disabled={repository.loading}
              type='primary'
              htmlType='submit'
            >
              Próximo
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Content>
  )
}

export default Complement
