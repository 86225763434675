import React from 'react'
import Providers from 'providers'
import Routes from 'routes'
import GlobalStyle from 'styles/global'
import './App.css'

const App = () => (
  <Providers>
    <Routes />
    <GlobalStyle />
  </Providers>
)

export default App
