import { Breadcrumb } from 'antd'
import React from 'react'
import { Container } from './styles'

type TProps = {
  title: string
  breadcrumbItems: string[]
  children?: React.ReactNode
}

const Header = (props: TProps) => {
  const {
    title,
    breadcrumbItems: items,
    children
  } = props
  return (
    <Container>
      <div>
        <span>{title}</span>
        <Breadcrumb separator='>'>
          {items.map(item => (
            <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
      <div>
        {children}
      </div>
    </Container>
  )
}

export default Header
