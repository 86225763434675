import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Space,
  Table
} from 'antd'
import {
  TApprovalHierarchyUser,
  TUpdateOrderSequenceRequest,
  useApprovalHierarchy
} from 'repositories'
import { ColumnsType } from 'antd/lib/table'
import { usePagination } from 'hooks'
import { formatCurrency } from 'utils/helpers'
import {
  BiDownArrowAlt,
  BiTrash,
  BiUpArrowAlt
} from 'react-icons/bi'
import { useParams } from 'react-router'
import ApproverForm from './ApproverForm'

type TApprovalHierarchyUserData = TApprovalHierarchyUser & {
  key: string
}

const columns: ColumnsType<TApprovalHierarchyUserData> = [
  {
    title: 'Id',
    dataIndex: 'approveSequence',
    key: 'approveSequence'
  },
  {
    title: 'Nome',
    dataIndex: 'userName',
    key: 'userName'
  },
  {
    title: 'SLA',
    dataIndex: 'sla',
    key: 'sla',
    align: 'center'
  },
  {
    title: 'Valor mínimo',
    dataIndex: 'minimumValue',
    key: 'minimumValue',
    align: 'right',
    render: (_, record) => formatCurrency(record.minimumValue),
  }
]

type TProps = {
  initialApprovers: TApprovalHierarchyUser[]
  isView?: boolean
}

const ApproverReferenceList = (props: TProps) => {
  const {
    initialApprovers,
    isView = false
  } = props
  const [approvers, setApprovers] = useState<TApprovalHierarchyUserData[]>([])

  const pagination = usePagination()

  useEffect(() => {
    const formattedInitialApprovers = initialApprovers?.map(initialApprover => ({
      ...initialApprover,
      key: String(initialApprover.id),
    })) || []
    setApprovers(formattedInitialApprovers)
  }, [initialApprovers])

  return (
    <>
      <Row>
        <Col xs={24} xl={16}>
          <Divider plain orientation='left'>Alçada de referência</Divider>
        </Col>
      </Row>
      <Row>
        <Col xs={24} xl={16}>
          <Table
            size='middle'
            columns={columns}
            dataSource={approvers}
            pagination={pagination.data}
          />
        </Col>
      </Row>
    </>
  )
}

export default ApproverReferenceList
