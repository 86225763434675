import React, { useState } from 'react';
import { Modal, Input, message } from 'antd';
import useRequest from "../../../../../repositories/useRequest";

const { TextArea } = Input;

const RequestCancelFormComposed = (props: any) => {
  const { id,  isVisible, setIsVisible, setRecharg } = props;

  const [reason, setReason] = useState('');
  const [justification, setJustification] = useState('');

  const repository = useRequest();

  const handleOk = async () => {
    if (!reason || !justification) {
      message.error('Por favor, preencha o motivo e a justificativa!');
      return;
    }
    const params = {
      reasonSuspended: reason,
      descriptionSuspended: justification
    }

    try {
      const cancelRequest = await repository.cancelRequest(id, params);
      message.success('Requisição composta cancelada com sucesso');
      setRecharg(Date.now() + Math.floor(Math.random() * 100000))
      setIsVisible(false);
      setReason('');
      setJustification('');
    } catch (error) {
      message.error('Erro ao cancelar a requisição');
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  return (
      <>
        <Modal
            title={
              <span style={{ color: 'red' }}>
                Informe abaixo o motivo e justificativa para cancelar a requisição de compra!
              </span>
            }
            visible={isVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Cancelar requisição"
            cancelText="Fechar"
        >
          <div>
            <label>Motivo:</label>
            <Input
                name='reason'
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Digite o motivo"
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <label>Justifique abaixo porque você está cancelando a requisição:</label>
            <TextArea
                value={justification}
                name="justification"
                onChange={(e) => setJustification(e.target.value)}
                rows={4}
                placeholder="Digite a justificativa"
            />
          </div>
        </Modal>
      </>
  );
};

export default RequestCancelFormComposed;
