import { useState } from "react";
import useRest from "hooks/useRest";
import {
  TAddressRequest,
  TApproveSupplier,
  TApproveSupplierResponse,
  TBankAccountRequest,
  TBusinessContact,
  TCreateOrUpdateProductPrice,
  TEmailPasswordPreRegistrationRequest,
  TEvaluationRequest,
  TFindSupplierByFilterParams,
  TProductRangePrice,
  TSupplierEvaluation,
  TSupplierForm,
  TSupplierOutput,
  TSupplierRequest,
  TSupplierResponse,
  TUpdateCategoryRequest,
  TUpdateProductToSupplier,
} from ".";
import { message } from "antd";

const useSuppliers = () => {
  const [loading, setLoading] = useState(false);
  const rest = useRest();

  const findSupplierByFilter = async (params: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TSupplierResponse;
  };
  const findProductPriceRangeByFilter = async (params: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/product-price-range";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as any;
  };

  const getProductPriceRange = async (id: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/supplier/product-price-range/by-supplier/${id}`;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  const findBanksByFilter = async (
    params: TFindSupplierByFilterParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TSupplierResponse;
  };

  const findSupplierById = async (
    id: TStringableNumber,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/supplier/${id}`;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TSupplierOutput;
  };

  const findSupplierByEmail = async (email: string, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/supplier/email/${email}`;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TSupplierOutput;
  };

  const createSupplier = async (
    supplier: TSupplierRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier";
    const response = await rest.post(url, supplier);
    if (triggerLoading) setLoading(false);
    if (!response) return;
    if (response.message === "error totvs!") {
      message.error("Fornecedor criado! Falha na integração, contate um administrador.");
    }

    return response.result as TSupplierForm;
  };

  const updateSupplier = async (
    supplier: TSupplierRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier";
    const response = await rest.put(url, supplier);
    if (triggerLoading) setLoading(false);
    if (!response) return;
    if (response.message === "error totvs!") {
      message.error("Fornecedor atualizado! Falha na integração, contate um administrador.");
    }
    return response.result as TSupplierForm;
  };

  const sendEmailPreRegistration = async (
    id: TStringableNumber,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/supplier/pre-registration-supplier/${id}`;
    await rest.post(url);
    if (triggerLoading) setLoading(false);

    return true;
  };

  const validatePasswordPreRegistration = async (
    data: TEmailPasswordPreRegistrationRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/validate-password-pre-registration";
    const response = await rest.post(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return "";

    return response.result.token as string;
  };

  const findEvaluationBySupplierId = async (
    suppliersId: string,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/evaluation";
    const params = { suppliersId };
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return [] as TSupplierEvaluation[];

    return response.result as TSupplierEvaluation[];
  };

  const saveOrUpdateEvaluation = async (
    data: TEvaluationRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/evaluation";
    const response = await rest.post(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return;

    return response.result as TSupplierEvaluation;
  };

  const updateCategoriesBySupplierId = async (
    data: TUpdateCategoryRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/categories";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return true;
  };

  const createBankAccount = async (
    data: TBankAccountRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/bank-account";
    const response = await rest.post(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return response.result.id as number;
  };

  const updateBankAccount = async (
    data: TBankAccountRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/bank-account";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return;

    return response.result.id as number;
  };

  const createAddress = async (
    data: TAddressRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/address";
    const response = await rest.post(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return response.result.id as number;
  };

  const updateAddress = async (
    data: TAddressRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/address";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return response.result.id as number;
  };

  const createBusinessContact = async (
    data: TBusinessContact,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/business-contact";
    const response = await rest.post(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return response.result.id as number;
  };

  const updateBusinessContact = async (
    data: TBusinessContact,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/business-contact";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return response.result.id as number;
  };

  const deleteBusinessContact = async (
    id: TStringableNumber,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/supplier/business-contact/${id}`;
    const response = await rest.destroy(url);
    if (triggerLoading) setLoading(false);
    if (!response) return false;

    return response;
  };

  const approveSupplier = async (data: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/approved";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return {} as TApproveSupplierResponse;

    return response.result as TApproveSupplierResponse;
  };

  const updateProductToSupplier = async (
    data: TUpdateProductToSupplier,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/products";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return true;

    return response.result as boolean;
  };

  const findProductBySupplier = async (
    supplierId: number,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/supplier/product-price-range/by-supplier/${supplierId}`;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return [] as TProductRangePrice[];

    return response.result as TProductRangePrice[];
  };

  const createOrUpdateProductRangePrice = async (
    data: TCreateOrUpdateProductPrice,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/product-price-range";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return {} as TProductRangePrice;

    return response.result as TProductRangePrice;
  };

  const createEvaluationSupplier = async (data: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/evaluation";
    const response = await rest.post(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return {};

    return response.result;
  };

  return {
    loading,
    findSupplierByFilter,
    findSupplierById,
    findSupplierByEmail,
    createSupplier,
    updateSupplier,
    sendEmailPreRegistration,
    validatePasswordPreRegistration,
    findEvaluationBySupplierId,
    saveOrUpdateEvaluation,
    findBanksByFilter,
    updateCategoriesBySupplierId,
    createBankAccount,
    updateBankAccount,
    createAddress,
    updateAddress,
    createBusinessContact,
    updateBusinessContact,
    deleteBusinessContact,
    approveSupplier,
    updateProductToSupplier,
    findProductBySupplier,
    createOrUpdateProductRangePrice,
    createEvaluationSupplier,
    getProductPriceRange,
    findProductPriceRangeByFilter,
  };
};

export default useSuppliers;
