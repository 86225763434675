import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  overflow: hidden;
  > div:first-child {
    font-size: 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.XL}) {
    width: 100%;
  }
`

export const TimelineContainer = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0 10px;
  z-index: 1;
`

export const LeftArrow = styled.div`
  position: absolute;
  left: 5px;
  top: 50%;
  
  svg {
    width: 17px;
    height: 17px;
  }
`

export const RightArrow = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;

  svg {
    width: 17px;
    height: 17px;
  }
`

export const Items = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0;
  margin: 5px 0 0 0;
  padding-bottom: 10px;
`

type TItemProps = {
  color?: string
}

export const Item = styled.li<TItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
  list-style-type: none;

  
  > div {
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 25px;
    height: 25px;
    color: ${({ theme }) => theme.colors.WHITE};
    background-color: ${({ color }) => color};

    background-color: ${({ color, theme }) => (!color ? theme.colors.WHITE : color)} !important;
    border: ${({ color, theme }) => (!color ? theme.borders.SOLID_MEDIUM_GRAY : 'none')} !important;
  }

  p {
    width: 80px;
  }

  p, div, span {
    font-size: 11px;
    text-align: center;
  }

  :first-child:after{
    content: none;
  }

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
    top: 55px;
    left: -50%;
    z-index: -1;
  }

  :after{
      background: ${({ color }) => color};
    }
`

export const Question = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  svg {
    width: 17px;
    height: 17px;
  }

  a {
    font-size: 12px;
  }
`
