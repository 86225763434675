import React, { useCallback } from "react";
import { Empty, Form, Select, Spin } from "antd";
import type { Rule } from "antd/lib/form";
import { debounce } from "lodash";
import { FlexCenterContainer } from "components/FlexContainer";

type TProps = {
  name: string;
  label?: string;
  rules?: Rule[];
  disabled?: boolean;
  required?: boolean;
  bordered?: boolean;
  allowClear?: boolean;
  textHelp?: string;
  placeholder?: string;
  value: any;
  options: TSearchOptions[];
  isLoading?: boolean;
  onSearch(value: string): void;
  onChange(value: string): void;
  debouncedTime?: number;
};

const SearchField = (props: any) => {
  const {
    name,
    label,
    rules,
    value,
    mode,
    disabled = false,
    required = false,
    textHelp,
    placeholder = "Digite para pesquisar",
    options = [],
    debouncedTime = 500,
    bordered = true,
    onSearch,
    onChange,
    isLoading = false,
    ...rest
  } = props;

  const debouncedSearch = useCallback(
    debounce((nextValue) => onSearch(nextValue), debouncedTime),
    []
  );

  const handleSearch = (searchText: string) => debouncedSearch(searchText);

  const renderNotFoundContent = isLoading ? (
    <FlexCenterContainer>
      <Spin size="default" />
    </FlexCenterContainer>
  ) : (
    <Empty description="Nenhum resultado encontrado" />
  );

  return (
    <Form.Item
      required={required}
      label={label}
      rules={rules}
      name={name}
      help={textHelp}
      id={name}
      style={{ flex: 1 }}
      {...rest}
    >
      <Select
        showSearch
        loading={isLoading}
        showArrow={false}
        value={value}
        mode={mode}
        disabled={disabled}
        options={options}
        virtual={false}
        onChange={onChange}
        onSearch={handleSearch}
        placeholder={placeholder}
        filterOption={false}
        notFoundContent={renderNotFoundContent}
        bordered={bordered}
      />
    </Form.Item>
  );
};

export default SearchField;
