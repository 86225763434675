import React, { useEffect, useState} from "react";
import {
  Button,
  Divider,
  Form,
  message,
  Rate,
  Table,
  Tag,
} from "antd";
import { Navigate, useNavigate } from "react-router";
import {
  DateField,
  SearchField,
  Section,
  SelectField,
  TextField,
} from "components";
import {formatCurrency, formatDate} from "utils/helpers";
import {
  useBusinessUnits,
  useProduct,
  useSuppliers,
} from "repositories";
import { useAuth, usePagination } from "hooks";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import { DefaultOptionType } from "antd/lib/select";
import moment from "moment";
import {
  supplierStatus,
  SupplierStatus,
  SupplierType,
} from "../../../../utils/constants";

const timingOptions = [
  { name: "Planejada", id: 1 },
  { name: "Não planejada", id: 2 },
];
const providerTypeOptions = [
  { name: "Freelancer", id: 1 },
  { name: "Fornecedor", id: 2 },
];


export type TOptions = {
  id: number | string;
  name: string;
};

const convertToOptions = (items: TOptions[]) =>
  items.map(
    (item) =>
      ({
        label: item.name,
        value: item.id,
      } as DefaultOptionType)
  );

const totalTimeCalculated = (initialDate: moment.Moment, finalDate: moment.Moment) => {

  if(initialDate && finalDate){
    return finalDate.diff(initialDate, 'days') + 1;
  }else {
    return ''
  }

}

const FormProvider = ({
  productSelected,
  supplierSelected,
  selectDataForm,
  dataForm,
  BUSelected,
  handleProviderType,
  nextStep,
  prevStep
}: any) => {

  function applyPhoneMaskWithNinthDigit(phoneNumber: any) {
    const onlyNumbers = phoneNumber.replace(/\D/g, ""); // Remove caracteres não numéricos

    let formattedNumber;
    if (onlyNumbers?.length === 11) {
      formattedNumber = onlyNumbers.replace(
          /(\d{2})(\d{5})(\d{4})/,
          "($1) $2-$3"
      );
    } else if (onlyNumbers?.length === 10) {
      formattedNumber = onlyNumbers.replace(
          /(\d{2})(\d{4,5})(\d{4})/,
          "($1) $2-$3"
      );
    } else {
      return phoneNumber;
    }

    return formattedNumber;
  }

  const columnsSuppliers: any = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Razão Social",
      dataIndex: "corporateName",
    },
    {
      title: "Nome fantasia ",
      dataIndex: "fantasyName",
    },
    {
      title: "Tipo de Prestador",
      dataIndex: "typeSupplier",
      render: (_: any, record: any) =>
          record.typeSupplier === SupplierType.SUPPLIER
              ? "Fornecedor"
              : "Freelancer",
    },
    {
      title: "Telefone",
      dataIndex: "telephone",
      render: (_: any, record: any) => {
        return applyPhoneMaskWithNinthDigit(record.telephone);
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Status Homologação",
      dataIndex: "status",
      render: (_: any, record: any) => {
        if (record.status === SupplierStatus.APPROVED) {
          return <Tag color="green">Homologado</Tag>;
        }

        if (record.status === SupplierStatus.NOT_APPROVED) {
          return <Tag color="red">Bloqueado</Tag>;
        }

        if (record.status === SupplierStatus.PRE_REGISTRATION) {
          return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
        }

        return <Tag>{supplierStatus[record.status]}</Tag>;
      },
    },
    // {
    //   title: "Avaliações",
    //   dataIndex: "currentAmountEvaluation",
    //   align: "center",
    //   render: (_: any, record: any) => (
    //       <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
    //         <Rate disabled value={record.currentEvaluation}/>
    //         <span>{`(${record.currentAmountEvaluation})`}</span>
    //       </div>
    //   ),
    // },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => (
          <Button onClick={() => removeSupplier()}>Excluir</Button>
      ),
    },
  ];

  const [form] = Form.useForm();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [suppliersSearch, setSuppliersSearch] = useState<any>([]);
  const [productOptions, setProductOptions] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [valueProduct, setValueProduct] = useState<any>([]);
  const [productChosen, setProductChosen] = useState<any>([]);
  const [productsList, setProductsList] = useState<any>([]);
  const [loadingProductsSearch, setLoadingProductsSearch] = useState(false)
  const [bus, setBUs] = useState([]);
  const [optionsBU, setBUOptions] = useState([]);
  const [bu, setBu] = useState<any>();
  const [optionsSearchSuppliers, setOptionsSearchSuppliers] = useState<any>([]);
  const [mappedSuppliers, setMappedSuppliers] = useState<any>([]);
  const [suppliersId, setSuppliersId] = useState<any>("");
  const timingOptionsConvert = convertToOptions(timingOptions);

  const providerTypeOptionsConvert = convertToOptions(providerTypeOptions);

  const requestsColumns: any = [
    {
      title: "Emissão",
      dataIndex: "issueDate",
      render: (_: any, record: any) => {
        return record.issueDate
            ? moment(record.issueDate).format("DD/MM/YYYY")
            : "";
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      render: (_: any, record: any) => {
        return record.maturity
            ? moment(record.maturity).format("DD/MM/YYYY")
            : "";
      },
    },
    {
      title: "Produto",
      dataIndex: "productName",
    },
    {
      title: "Prestador",
      dataIndex: "typeProvider",
      render: (_: any, record: any) => {
        return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if (record.typeRequest === "SIMPLE") return "Simples";
        return "Composta";
      },
    },
    {
      title: "Categoria",
      dataIndex: "category",
    },
    {
      title: "Alocação",
      dataIndex: "allocation",
    },
    {
      title: "Local",
      dataIndex: "buName",
    },
    {
      title: "Cliente",
      dataIndex: "client",
    },
    {
      title: "Timing",
      dataIndex: "timing",
      render: (_: any, record: any) => {
        if (record.timing === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;

        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Fatura",
      dataIndex: "paymentType",
    },
    {
      title: "Valor da requisição",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record?.totalCost);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return record.status;
        }
      },
    },
  ];

  const SenioridadeOptionsConvert = [
    {
      label: "Júnior",
      value: "JUNIOR",
    },
    {
      label: "Pleno",
      value: "PLENO",
    },
    {
      label: "Sênior",
      value: "SENIOR",
    },
  ];

  const {roles: authRoles, hasRole} = useAuth();

  const repositoryProduct = useProduct();
  const repositorySupplier = useSuppliers();
  const buRepository = useBusinessUnits();

  const pagination = usePagination();

  const requestForm = async () => {
    const response = await buRepository.findBus({
      page: 0,
      size: 10000,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.name }));
    setBUOptions(mapped);
    setBUs(response.content);

    if(dataForm?.businessUnits) {
      const _bu = response.content
        .map((bu: any) => ({ id: bu.id, name: bu.name }))
        .filter((bu: any) => bu.name === dataForm?.businessUnits);
      setBu(_bu[0]);
    }



  };

  const removeSupplier = () => {
    setSuppliers([])
    form.setFieldValue("providerType", null);
    form.setFieldValue("seniority", null);
    form.setFieldValue("unitMaximunPrice", '');

  }

  const onChangeStartDate = (date: moment.Moment | null) => {
    if(date){
      const today = moment();
      const startDate = moment(date);
      const endDate = moment(form.getFieldValue("endDate"));
      if (endDate && startDate && endDate.isBefore(startDate)) {
        form.setFieldValue("endDate", startDate);
      }
      form.setFieldValue("timing", startDate.isBefore(today, 'day') ? 2 : 1)
      form.setFieldValue("TotalTime", totalTimeCalculated(form.getFieldValue("startDate"), form.getFieldValue("endDate")));
    }else {
      form.setFieldValue("endDate", null);
      form.setFieldValue("timing", null)
      form.setFieldValue("TotalTime", '');
    }
  };

  const onChangeEndDate = (date: moment.Moment | null) => {
    if (date) {
      const endDate = moment(date);
      form.setFieldValue("endDate", endDate);
      form.setFieldValue("TotalTime", totalTimeCalculated(form.getFieldValue("startDate"), form.getFieldValue("endDate")));      
    }else {
      form.setFieldValue("TotalTime", '');
    }
  };

  const onChangetiming = (data: number) => {
    if (data === 1) {
      form.setFieldsValue({
        pay: form.getFieldValue("endDate")?.clone()?.add(30, "days"),
      });
    }
    const emissao = moment(form.getFieldValue("emission"));
    const start = moment(form.getFieldValue("startDate"));
    if (data === 1 && start.isBefore(emissao)) {
      form.setFieldValue("startDate", emissao);
    }
  };

  const onChangeProvider = (data: any) => {
    setSuppliers([]);
    setSuppliersId('');
    setOptionsSearchSuppliers([]);
    form.setFieldValue("SearchFieldSuppliers","")
    form.setFieldValue("seniority", "")
    form.setFieldValue("unitMinimunPrice", "")
    form.setFieldValue("unitMaximunPrice", "")
    handleProviderType(data);
  };

  let initialValues = {
    typeRequest: "Composta",
    category: "Rateios e contratos",
    allocation: "Com projeto",
    status: "Nova",
    timing: 1,
    businessUnits: '',
    providerType: '',
    seniority: '',
    emission: moment(),
    startDate: null,
    endDate: null,
    TotalTime: "",
    ExpenseOrInvestment: "",
    unitMinimunPrice: "",
    unitMaximunPrice: "",
  };

  useEffect(() => {
    getProductsList()
    console.log(dataForm)
    if(dataForm?.startDate){
      form.setFieldValue('startDate', moment(dataForm?.startDate))
      form.setFieldValue('endDate', moment(dataForm?.endDate))
      form.setFieldValue('timing', dataForm?.timing)
      form.setFieldValue('TotalTime', dataForm?.totalTime)
      form.setFieldValue('businessUnits', dataForm?.businessUnits)
      form.setFieldValue('providerType', dataForm?.providerType)
      form.setFieldValue('seniority', dataForm?.seniority)
      form.setFieldValue('unitMaximunPrice', dataForm?.unitMaximunPrice !== 'Não há' ? dataForm?.unitMaximunPrice : 'Não há')
      
      setProductOptions(dataForm?.productsChosen?.map((item: any) => ({
        id: item.id,
        value: item.description,
      })));
      setProducts(dataForm?.productsChosen);

      setValueProduct(dataForm?.productSelection)
      form.setFieldValue('productSelection', dataForm?.productSelection)
      setProductChosen(dataForm?.productsChosen)
      form.setFieldValue("productId", dataForm?.productsChosen)

      setSuppliers(dataForm?.supplierSelec);
    }
  }, [])

  useEffect(() => {
    if (pagination.data.current) requestForm();
  }, [
    pagination.data.current,
    pagination.data.pageSize,
  ]);

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado"/>;
  }

  const onFinish = async (values: any) => {
    const data = {
      typeRequest: "Composta",
      category: "Rateios e contratos",
      allocation: "Com projeto",
      status: "Nova",
      timing: values.timing,
      productSelection: values.productSelection,
      productsChosen: productChosen,
      businessUnits: values.businessUnits,
      providerType: values.providerType,
      seniority: values.seniority,
      emission: formatDate(values.emission),
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
      totalTime: values.TotalTime,
      supplierSelec: suppliers,
      ExpenseOrInvestment: "",
      unitMinimunPrice: values.unitMinimunPrice,
      unitMaximunPrice: values.unitMaximunPrice,
    };

    if(!values.startDate && !values.endDate) {
      message.error("Preencha todas as datas corretamente");
      return
    }

    if (suppliers?.length !== 0 && productChosen?.length !== 0) {

      selectDataForm(data);
      productSelected(productChosen)
      supplierSelected(suppliers[0])
      BUSelected(bu)
      nextStep();

    } else {

      selectDataForm();
      productSelected()
      supplierSelected()
      BUSelected()

      message.error("Preencha todos os campos corretamente");
    }
  };

  const prev = (e: any) => {
    prevStep();
  };
  const next = (e: any) => {
    const values = form.getFieldsValue();
    onFinish(values);
  };

  const onChangeSuppliers = (data: string) => {
    const indexOf = suppliersSearch.findIndex(
        (obj: { corporateName: any,  fantasyName: any, cnpj: any}) => `${obj.fantasyName}/${obj.corporateName} - ${obj.cnpj}` === data
    );

    const mappedSuppliers = suppliersSearch.map((request: any) => ({
      id: String(request?.id),
      currentEvaluation:
          request.currentEvaluation !== null ? request.currentEvaluation : 0,
      currentAmountEvaluation: request.currentAmountEvaluation
          ? request.currentAmountEvaluation
          : 0,
      status: request.status,
      typeSupplier: request.typeSupplier,
      seniority: request.seniority,
      telephone: request.telephone,
      email: request.email,
      fantasyName: request.fantasyName,
      corporateName: request.corporateName,
    }));
    setMappedSuppliers([mappedSuppliers[indexOf]]);
    setSuppliersId(mappedSuppliers[indexOf].id);
  };

  const onChangeSenioridade = async (data: any) => {
    const params: any = {
      id: productChosen[0]?.id,
    };

    const r = await repositoryProduct.findProductByFilter(params);
    let seniorityData = {} as any;
    switch (data) {
      case "JUNIOR":
        form.setFieldsValue({
          unitMinimunPrice: formatCurrency(
              Number(r?.content[0]?.minimumPriceJunior)
          ),
        });
        form.setFieldsValue({
          unitMaximunPrice: formatCurrency(
              Number(r?.content[0]?.maximumPriceJunior)
          ),
        });
        seniorityData.unitMinimunPrice = Number(
            r?.content[0]?.minimumPriceJunior
        );
        seniorityData.unitMaximunPrice = Number(
            r?.content[0]?.maximumPriceJunior
        );
        break;
      case "PLENO":
        form.setFieldsValue({
          unitMinimunPrice: formatCurrency(
              Number(r?.content[0]?.minimumPricePleno)
          ),
        });
        form.setFieldsValue({
          unitMaximunPrice: formatCurrency(
              Number(r?.content[0]?.maximumPricePleno)
          ),
        });
        seniorityData.unitMinimunPrice = Number(
            r?.content[0]?.minimumPricePleno
        );
        seniorityData.unitMaximunPrice = Number(
            r?.content[0]?.maximumPricePleno
        );
        break;
      case "SENIOR":
        form.setFieldsValue({
          unitMinimunPrice: formatCurrency(
              Number(r?.content[0]?.minimumPriceSenior)
          ),
        });
        form.setFieldsValue({
          unitMaximunPrice: formatCurrency(
              Number(r?.content[0]?.maximumPriceSenior)
          ),
        });
        seniorityData.unitMinimunPrice = Number(
            r?.content[0]?.minimumPriceSenior
        );
        seniorityData.unitMaximunPrice = Number(
            r?.content[0]?.maximumPriceSenior
        );
        break;
    }
    setSuppliers([]);
    setSuppliersId('');
    setOptionsSearchSuppliers([]);
    form.setFieldValue("SearchFieldSuppliers","")
  };

  const disabledDateEnd = (current: any) => {
    const today = new Date(form.getFieldValue("startDate"));
    today.setHours(0, 0, 0, 0);
    return current && current < today;
  };

  const getProductsList = async () => {
    setLoadingProductsSearch(true)
    const response = await repositoryProduct.findProductByFilter({
      page: 0,
      size: 10000,
      activityDirector: true,
    })
    if (!response) return
    setProductsList(response.content)
    setLoadingProductsSearch(false)
  }

  const onSearchProduct = async (searchText: any) => {
    setProductOptions([]);
    const response = await repositoryProduct.findProductByFilter({
      description: searchText,
      page: 0,
      size: 10000,
      activityDirector: true,
    })
    if (!response) return

    const products = [...response.content];

    const ordened = response.content.sort((a, b) => { if (a.description.toLowerCase() < b.description.toLowerCase()) { return -1; } if (a.description.toLowerCase() > b.description.toLowerCase()) { return 1; } return 0; });

    const mappedProducts = ordened.map(item => ({
      id: item.id,
      value: item.description,
    }));


    setProductOptions(mappedProducts);
  
    setProducts(response.content);
  };

  const onChangeProduct = (data: any) => {
    let findProducts: any = []

    data.forEach((productSelected: any) => {
      const findProduct = productsList.find((product: any) => product.description === productSelected)
      if (findProduct) findProducts = [...findProducts, findProduct]
    });

    if (!findProducts) return

    setValueProduct(data)
    setProductChosen(findProducts)
    form.setFieldValue("productId", findProducts)
    setSuppliers([]);
    setSuppliersId("");
    setOptionsSearchSuppliers([]);
    form.setFieldValue('SearchFieldSuppliers', '')
    form.setFieldValue('seniority', '')
    form.setFieldValue('unitMinimunPrice', '')
    form.setFieldValue('unitMaximunPrice', '')
    form.setFieldValue('providerType', '')
  };

  const onSearchSuppliers = async (searchText: any) => {

    const productId = form.getFieldValue("productId").map((obj: any) => obj.id).join(', ');
    
    const queryParams = new URLSearchParams({
      searchAnd: searchText,
      page: "0",
      size: "10000",
    });
    queryParams.append("productIdsAnd", `${productId}`);
    const response = await repositorySupplier.findSupplierByFilter(queryParams);
    if (!response) return;
    const mappedSuppliers = response.content.map((item) => ({
      value: `${item.fantasyName}/${item.corporateName} - ${item.cnpj}`,
      id: item.id,
    }));
    setOptionsSearchSuppliers(mappedSuppliers);
    setSuppliersSearch(response.content);
  }


  const onAdd = async () => {
    const indexOf = mappedSuppliers.findIndex(
        (obj: { id: string }) => String(obj?.id) === String(suppliersId)
    );
    const r = await repositorySupplier.getProductPriceRange(
        mappedSuppliers[indexOf]?.id
    );
    if (r === undefined){
      message.error("Selecione corretamente o fornecedor ou freelancer para essa requisição")
      return
    }
    const filtered = r.filter(
        (product: any) => product.productId === productChosen[0]?.id
    );

    let mapped = mappedSuppliers[indexOf];
    mapped.preciary = filtered[0]?.preciary;
    mapped.value = filtered[0]?.value ? filtered[0]?.value : "";
    mapped.unitOfMeasurement = filtered[0]?.unitOfMeasurement
        ? filtered[0]?.unitOfMeasurement
        : "";
    setSuppliers([mapped]);
    setOptionsSearchSuppliers([]);
    form.setFieldValue("providerType", mapped?.typeSupplier === "FREELANCE" ? 1 : 2)
    form.setFieldValue("SearchFieldSuppliers", "");
    if(mapped?.typeSupplier === "FREELANCE"){
      switch (mapped?.seniority) {
        case "JUNIOR":
          form.setFieldValue("seniority", 'JUNIOR');
          break;
        case "PLENO":
          form.setFieldValue("seniority", 'PLENO');
          break;
        case "SENIOR":
          form.setFieldValue("seniority", 'SENIOR');
          break;
        default:
          break;
      }

      const params: any = {
        id: productChosen[0]?.id,
      };
      const r = await repositoryProduct.findProductByFilter(params);
      let seniorityData = {} as any;

      switch (mapped?.seniority) {
        case "JUNIOR":
          form.setFieldsValue({
            unitMinimunPrice: formatCurrency(
                Number(r?.content[0]?.minimumPriceJunior)
            ),
          });
          form.setFieldsValue({
            unitMaximunPrice: r?.content[0]?.maximumPriceJunior ? 
            formatCurrency(
                Number(r?.content[0]?.maximumPriceJunior)
            ) : 'Não há',
          });
          seniorityData.unitMinimunPrice = Number(
              r?.content[0]?.minimumPriceJunior
          );
          seniorityData.unitMaximunPrice = Number(
              r?.content[0]?.maximumPriceJunior
          );
          break;
        case "PLENO":
          form.setFieldsValue({
            unitMinimunPrice: formatCurrency(
                Number(r?.content[0]?.minimumPricePleno)
            ),
          });
          form.setFieldsValue({
            unitMaximunPrice: r?.content[0]?.maximumPricePleno ? 
            formatCurrency(
                Number(r?.content[0]?.maximumPricePleno)
            ) : 'Não há',
          });
          seniorityData.unitMinimunPrice = Number(
              r?.content[0]?.minimumPricePleno
          );
          seniorityData.unitMaximunPrice = Number(
              r?.content[0]?.maximumPricePleno
          );
          break;
        case "SENIOR":
          form.setFieldsValue({
            unitMinimunPrice: formatCurrency(
                Number(r?.content[0]?.minimumPriceSenior)
            ),
          });
          form.setFieldsValue({
            unitMaximunPrice: r?.content[0]?.maximumPriceSenior ? 
            formatCurrency(
                Number(r?.content[0]?.maximumPriceSenior)
            ) : 'Não há',
          });
          seniorityData.unitMinimunPrice = Number(
              r?.content[0]?.minimumPriceSenior
          );
          seniorityData.unitMaximunPrice = Number(
              r?.content[0]?.maximumPriceSenior
          );
          break;
      }
    }
  };

  const onChangeBU = (newValue: any) => {
    const _bu = bus
        .map((bu: any) => ({ id: bu.id, name: bu.name }))
        .filter((bu: any) => bu.name === newValue);
    setBu(_bu[0]);
    console.log(_bu[0])

    setSuppliers([]);
    setSuppliersId("");
    setOptionsSearchSuppliers([]);
    form.setFieldValue('SearchFieldSuppliers', '')
    form.setFieldValue('seniority', '')
    form.setFieldValue('unitMinimunPrice', '')
    form.setFieldValue('unitMaximunPrice', '')
    form.setFieldValue('providerType', '')
  };

  const disabledSearchField = (productSelection: any, businessUnits: any) => {
    return businessUnits?.length > 0 && productSelection?.length > 0 ? false : true
  };

  return (
      <>
        <Section>
          <div style={{marginTop: 24}}>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={initialValues}
            >
              <>
                <Row>
                  <Col xl={24}>
                    <b>Preencha as datas do serviço</b>
                  </Col>
                  <Col xl={6} xxl={6}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.timing !== currentValues.timing
                        }
                    >
                      {({getFieldValue}) => (
                          <DateField
                              name="startDate"
                              label="Data do início do serviço"
                              onChange={onChangeStartDate}
                              required
                          />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={6} xxl={6}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.startDate !== currentValues.startDate
                        }
                    >
                      {({getFieldValue}) => (
                        <DateField
                            name="endDate"
                            label="Data do Termino do serviço"
                            onChange={onChangeEndDate}
                            disabled={!getFieldValue("startDate")}
                            disabledDate={disabledDateEnd}
                            required
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col xl={6} xxl={6}>
                    <DateField name="emission" label="Emissão" disabled/>
                  </Col> */}
                  <Col xl={6} xxl={6}>
                    <SelectField
                        name="timing"
                        label="Timing da requisição"
                        onChange={onChangetiming}
                        disabled
                        options={timingOptionsConvert}
                    />
                  </Col>
                  <Col xl={6} xxl={6}>
                    <TextField
                        name="TotalTime"
                        label="Tempo total do serviço"
                        disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} xxl={6}>
                    <SearchField
                      name="productSelection"
                      label="Escolha o produto"
                      options={productOptions}
                      onChange={onChangeProduct}
                      onSearch={onSearchProduct}
                      value={valueProduct}
                      disabled={loadingProductsSearch}
                      mode="multiple"
                      placeholder={loadingProductsSearch ? 'Carregando...' : 'Digite o nome do produto'}
                      required
                    />
                  </Col>
                  <Col xl={6} xxl={6}>
                    <SelectField
                        name="businessUnits"
                        label="Escolha a BU"
                        options={optionsBU}
                        onChange={onChangeBU}
                        value={bu}
                        required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Divider/>
                  </Col>
                  <Col xl={24}>
                    <b><span style={{color: '#ff4d4f'}}>*</span> Fornecedores ou freelancers para essa requisição</b>
                  </Col>
                  <Col xl={24} xxl={24}>
                    <div style={{display: "flex", width: "100%"}}>
                      <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                              prevValues.providerType !==
                              currentValues.providerType ||
                              prevValues.senioridade !== currentValues.senioridade
                          }
                      >
                        {({getFieldValue}) => (
                            <SearchField
                                onSearch={onSearchSuppliers}
                                onChange={onChangeSuppliers}
                                name="SearchFieldSuppliers"
                                placeholder="Digite aqui o nome"
                                options={optionsSearchSuppliers}
                                disabled={disabledSearchField(
                                    getFieldValue("productSelection"),
                                    getFieldValue("businessUnits")
                                ) || suppliers?.length > 0}
                                value={""}
                            />
                        )}
                      </Form.Item>
                      <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                              prevValues.providerType !==
                              currentValues.providerType ||
                              prevValues.senioridade !== currentValues.senioridade
                          }
                      >
                        {({getFieldValue}) => (
                            <Button
                                type="primary"
                                onClick={onAdd}
                                disabled={disabledSearchField(
                                    getFieldValue("productSelection"),
                                    getFieldValue("businessUnits")
                                ) || suppliers?.length > 0}
                            >
                              Adicionar
                            </Button>
                        )}
                      </Form.Item>
                    </div>
                    <small style={{marginTop: '-10px', display: 'block', marginBottom: '20px'}}>
                      <strong>Atenção: </strong>Serão listados apenas os prestadores que tiverem todos os produtos selecionados em seu cadastro.
                    </small>
                  </Col>
                  <Col xl={6} xxl={6}>
                    <SelectField
                        name="providerType"
                        label="Tipo de Prestador"
                        options={providerTypeOptionsConvert}
                        onChange={onChangeProvider}
                        disabled
                    />
                  </Col>
                  <Col xl={6} xxl={6}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.providerType !== currentValues.providerType
                        }
                    >
                      {({getFieldValue}) => (
                        <SelectField
                            name="seniority"
                            label="Senioridade"
                            options={SenioridadeOptionsConvert}
                            onChange={onChangeSenioridade}
                            disabled
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {
                    suppliers[0]?.typeSupplier === 'FREELANCE' &&
                    productChosen?.length > 0 &&
                    <Col xl={6} xxl={6}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.unitMaximunPrice !== currentValues.unitMaximunPrice
                        }
                      >
                        {({getFieldValue}) => (
                          <TextField
                            name="unitMaximunPrice"
                            label="Preço Máximo [Freela]"
                            disabled
                          />
                        )}
                      </Form.Item>
                    </Col>
                  }
                </Row>
                <Row>
                  <Col>
                    <Table
                        columns={
                            columnsSuppliers
                        }
                        dataSource={suppliers}
                        pagination={false}
                        style={{ overflowX: "auto" }}
                    />
                  </Col>
                  <Col>
                    <div style={{marginBottom: "40px"}}></div>
                  </Col>
                </Row>
                <Button style={{margin: "0 4px"}} onClick={prev} disabled  >
                  Voltar
                </Button>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.providerType !== currentValues.providerType
                    }
                >
                  <Button
                      style={{margin: "0 4px"}}
                      type="primary"
                      htmlType="button"
                      onClick={next}
                  >
                    Próximo
                  </Button>
                </Form.Item>
              </>
            </Form>
          </div>
        </Section>
      </>
  );
};

export default FormProvider;
