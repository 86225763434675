import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import {
  BiChevronDown,
  BiChevronUp
} from 'react-icons/bi'
import { TItem } from '..'

import {
  Container,
  Options,
  SubItem
} from './styles'

type TProps = {
  title: string
  icon: React.ReactNode
  items: TItem[]
  isSidebarOpen: boolean
}

const Group = (props: TProps) => {
  const {
    title,
    icon,
    items,
    isSidebarOpen
  } = props
  const { pathname } = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  const onClickOpen = () => setIsOpen(!isOpen)

  const mainPath = `/${pathname.split('/')[1]}`

  const _menuItems = useMemo(() => (
    items.map(item => (
      mainPath === item.path
        ? { ...item, active: true }
        : item
    ))
  ), [mainPath])

  useEffect(() => {
    const isOpenMenu = items.some(item => item.path === mainPath)
    setIsOpen(isOpenMenu)
  }, [mainPath])

  const arrowIcon = isOpen
    ? <BiChevronUp />
    : <BiChevronDown />

  return (
    <>
      <Container
        isOpen={isOpen}
        isSidebarOpen={isSidebarOpen}
        onClick={onClickOpen}
        title={title}
      >
        <div>
          {icon}
          <span>
            {title}
          </span>
        </div>
        {arrowIcon}
      </Container>
      {isOpen && (
        <Options>
          {_menuItems.map(item => {
            const {
              title: text,
              icon: iconItem,
              path,
              active
            } = item
            return (
              <SubItem
                key={text}
                title={text}
                to={String(path)}
                $active={active}
              >
                <div>
                  {iconItem}
                </div>
                <span>
                  {text}
                </span>
              </SubItem>
            )
          })}
        </Options>
      )}
    </>
  )
}

export default Group
