import styled from 'styled-components'

export const SubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  p {
    font-weight: 900;
    font-size: 22px;
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
  }
`
