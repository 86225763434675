import styled from 'styled-components'
import {Tooltip} from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 5px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  margin-bottom: 15px;

  span {
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  button {
    padding: 0;
    margin: 0;
  }

  button svg {
    width: 16px;
    height: 16px;
  }
`

export const Content = styled.div`
  div {
    font-size: 14px;
    align-items: baseline;
    gap: 1vw;
  }
  span {
    font-size: 14px;
  }
  .success {
    color: #019973;
    filter: brightness(0) saturate(100%) invert(39%) sepia(97%) saturate(755%) hue-rotate(128deg) brightness(90%) contrast(99%);
  }
  .warning {
    color:#ffb601;
    filter: brightness(0) saturate(100%) invert(77%) sepia(18%) saturate(7132%) hue-rotate(360deg) brightness(105%) contrast(103%);
  }
  .error {
    color:#e53e3e;
    filter: brightness(0) saturate(100%) invert(32%) sepia(100%) saturate(1098%) hue-rotate(333deg) brightness(92%) contrast(95%);
  }
  .ant-spin-container {
    height: auto;
    max-height: 240px;
    overflow-y: scroll;
    padding-right: 1.25rem;
  }
  .ant-list-item {
    padding: 4px 16px;
    background: #F7F7F7;
    margin-bottom: 5px;
    border-radius: 8px;
  }

  .ant-list {
    opacity: 1;
    &.disabled {
      opacity: 0.5;
    }
  }
  .ant-row {
    display:flex;
    flex-direction:column;
    line-height: 0.6rem;
    padding: 0.3rem 0;
  }
  .ant-commentary {
    display:flex;
    justify-content:space-between;
    width: 100%;

    span {
      color: #636363;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .ant-badge-status-dot{
    width: 8px;
    height: 8px;
    top: -11px;
    left:-11px;
  }
`
