import React from 'react'
import { Outlet } from 'react-router'

import {
  Container,
  Background,
  Logo,
  Content,
  Footer
} from './styles'

const Unauthenticated = () => (
  <Container>
    <Background>
      <Logo />
    </Background>
    <Content>
      <Outlet />
      <Footer>
        <p>Todos os direitos reservados</p>
        <p>Grupo TV1. &copy; {new Date().getFullYear()} </p>
      </Footer>
    </Content>
  </Container>
)

export default Unauthenticated
