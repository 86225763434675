import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Divider,
  Space,
  Table,
  Modal,
  message
} from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import { useAuth, usePagination } from 'hooks'
import {
  TBusinessContact,
  useSupplierContact,
  useSuppliers
} from 'repositories'
import { maskTelephone } from 'utils/helpers'
import { tableLocale } from 'utils/lang'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { cpf } from 'cpf-cnpj-validator'
import { Col, Row } from 'components'
import ContactForm from './ContactForm'

const { confirm } = Modal

type TBusinessContactData = Omit<TBusinessContact, 'id'> & {
  id: number
  key: string
}

const columns: ColumnsType<TBusinessContactData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'CPF',
    dataIndex: 'cpf',
    key: 'cpf',
    render: (_, record) => cpf.format(record.cpf),
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'RG',
    dataIndex: 'rg',
    key: 'rg'
  },
  {
    title: 'Telefone',
    dataIndex: 'telephone',
    key: 'telephone',
    render: (_, record) => maskTelephone(record.telephone),
  },
]

type TProps = {
  supplierId: number
  isView?: boolean
  isFullWidth?: boolean
}

const ContactList = (props: TProps) => {
  const {
    supplierId,
    isView = false,
    isFullWidth = false,
  } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [contactId, setContactId] = useState(0)
  const [contacts, setContacts] = useState<TBusinessContactData[]>([])
  const repository = useSupplierContact()
  const supplierRepository = useSuppliers()
  const pagination = usePagination()

  const { roles: authRoles, hasRole } = useAuth()

  const requestContactsBySupplier = async () => {
    const currentPage = pagination.data.current ?? 0
    const response = await repository.findContactByFilter({
      supplierId,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
    })
    if (!response) return
    const mappedContacts = response.content?.map(content => ({
      ...content,
      key: String(content.id)
    }))
    setContacts(mappedContacts)
  }

  const onOpenModal = () => {
    setIsModalVisible(true)
    setContactId(0)
  }

  const onEdit = (recordId: number) => () => {
    setIsModalVisible(true)
    setContactId(recordId)
  }

  const showDeleteConfirm = (
    _contactId: number,
    _contacts: TBusinessContactData[]
  ) => () => {
    confirm({
      title: 'Confirmação',
      content: 'Deseja excluir esse contato?',
      icon: (
        <AiOutlineExclamationCircle
          size={18}
          color='orange'
        />
      ),
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      async onOk() {
        const filteredContacts = _contacts.filter(filterContact => filterContact.id !== _contactId)
        await repository.deleteContact(_contactId)
        message.success('Contato excluído com sucesso')
        setContacts(filteredContacts)
      }
    })
  }

  const hasPermission = !hasRole(
    authRoles?.supplier_contact_add ||
    authRoles?.supplier_contact_edit ||
    authRoles?.supplier_contact_delete ||
    authRoles?.supplier_pre_registration
  )

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: (
        <Button
          type='primary'
          onClick={onOpenModal}
          disabled={isView || hasPermission}
        >
          Novo
        </Button>
      ),
      key: 'action',
      render: (_: any, record: any) => (
        <Space size='small'>
          <Button
            disabled={isView || hasPermission}
            onClick={onEdit(record.id)}
            size='small'
            type='text'
          >
            Editar
          </Button>
          <Button
            disabled={isView || hasPermission}
            onClick={showDeleteConfirm(record.id, contacts)}
            size='small'
            type='text'
          >
            Excluir
          </Button>
        </Space>
      ),
    }
  ], [contacts, authRoles])

  const handleChange: TableProps<TBusinessContactData>['onChange'] = paginationConfig => {
    pagination.onChangeTable(paginationConfig)
  }

  useEffect(() => {
    if (supplierId && pagination.data.current) requestContactsBySupplier()
  }, [pagination.data.current, pagination.data.pageSize, supplierId])

  const fullWidth = isFullWidth ? 24 : 16

  return (
    <>
      <Row>
        <Col xl={fullWidth}>
          <Divider plain orientation='left'>Informações de contato</Divider>
        </Col>
      </Row>
      <Row>
        <Col xl={fullWidth}>
          <Table
            size='small'
            locale={tableLocale}
            loading={repository.loading || supplierRepository.loading}
            columns={updatedColumns}
            dataSource={contacts}
            pagination={pagination.data}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <ContactForm
        supplierId={supplierId}
        contactId={contactId}
        actionFn={requestContactsBySupplier}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  )
}

export default ContactList
