import React, { useEffect, useMemo, useState } from "react";
import type { ColumnsType, TableProps } from "antd/lib/table";
import { Button, Input, Table, Tag } from "antd";
import { Navigate, useNavigate } from "react-router";
import { PageHeader, Section } from "components";
import { TClient } from "repositories";
import useCostCenter from "repositories/useCostCenter";
import { usePagination, useAuth } from "hooks";
import { SorterResult } from "antd/lib/table/interface";
import { FilterContainer } from "./styles";

type TClientData = Pick<TClient, "id" | "cnpj" | "corporateName" | "status"> & {
  key: string;
};

const columns: ColumnsType<TClientData> = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    align: "center",
    sorter: true,
  },
  {
    title: "ID TOTVS",
    dataIndex: "erpId",
    key: "erpId",
    sorter: true,
    align: "center",
  },
  {
    title: "Nome",
    dataIndex: "description",
    key: "description",
    sorter: true,
    align: "center",
  },
  {
    title: "Classe",
    dataIndex: "classe",
    key: "classe",
    sorter: true,
    align: "center",
  },
  {
    title: "ID BU",
    dataIndex: "idBUErp",
    key: "idBUErp",
    sorter: true,
    align: "center",
  },
  {
    title: "Descrição BU",
    dataIndex: "descriptionBU",
    key: "descriptionBU",
    sorter: true,
    align: "center",
  },
  {
    title: "Classificação",
    dataIndex: "classification",
    key: "classification",
    sorter: true,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    align: "center",
    render: (_, record) => {
      if (record.status) return <Tag color="green">Ativo</Tag>;
      return <Tag color="red">Inativo</Tag>;
    },
  },
];

const CostCenters = () => {
  const [clients, setClients] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const { roles: authRoles, hasRole } = useAuth();
  const repository = useCostCenter();
  const navigate = useNavigate();
  const pagination = usePagination();

  const requestClient = async () => {
    const currentPage = pagination.data.current ?? 0;
    const params: any = {
      description: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
    };

    const response = await repository.findByFilterCostCenter(params);
    if (!response) return;

    const mappedData: any = response.content.map((CostCenter: any) => ({
      id: CostCenter.id,
      erpId: CostCenter.erpId,
      description: CostCenter.description,
      classe: CostCenter.classe,
      idBUErp: CostCenter.idBUErp,
      descriptionBU: CostCenter.descriptionBU,
      classification: CostCenter.classification,
      status: CostCenter.status,
    }));
    setClients(mappedData);

    pagination.changeTotalPages(response.totalPages);
  };

  const handleChange: TableProps<TClientData>["onChange"] = (
    paginationConfig,
    filters,
    sorter
  ) => {
    pagination.onChangeTable(paginationConfig);
    const { field, order } = sorter as SorterResult<TClientData>;
    if (!field) return;

    const dir = order === "ascend" ? "ASC" : "DESC";
    setSort(`${field},${dir}`);
  };

  useEffect(() => {
    if (pagination.data.current) requestClient();
  }, [pagination.data.current, pagination.data.pageSize, sort, search]);

  const onClickGoToView = (id: number) => () =>
    navigate(`/centros-de-custo/visualizar/${id}`);

  const updatedColumns = useMemo(
    () => [
      ...columns,
      {
        title: "",
        key: "action",
        render: (_, record) => (
          <Button
            onClick={onClickGoToView(record.id)}
            disabled={!hasRole(authRoles?.client_view)}
            size="small"
            type="text"
          >
            Visualizar
          </Button>
        ),
      },
    ],
    [authRoles]
  );

  const onSearch = (value: string) => setSearch(value);

  if (authRoles && !hasRole(authRoles?.client_view)) {
    return <Navigate to="/acesso-negado" />;
  }

  return (
    <>
      <PageHeader
        title="Centros de Custo"
        breadcrumbItems={["Centros de Custo", "Listar"]}
      />
      <Section>
        <FilterContainer>
          <Input.Search
            placeholder="Pesquise pelo nome"
            onSearch={onSearch}
            enterButton
          />
        </FilterContainer>
        <Table
          size="middle"
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={clients}
          pagination={pagination.data}
        />
      </Section>
    </>
  );
};

export default CostCenters;
