import React from "react";
import { DatePicker, Form } from "antd";
import type { Rule } from "antd/lib/form";
import moment from "moment";

type TProps = {
  name: string;
  id?: string;
  value?: string | number;
  label?: string;
  rules?: Rule[];
  disabled?: boolean;
  rangePicker?: boolean;
  required?: boolean;
  textHelp?: string;
  format?: string;
  picker?: "date" | "month" | "year" | "quarter" | "time" | "week";
  disabledDate?(date: moment.Moment): boolean;
  onChange?(date: moment.Moment | null, dateString: string): void;
};

const { RangePicker } = DatePicker;

const DateField = (props: TProps) => {
  const {
    name,
    label,
    rules,
    disabled = false,
    required = false,
    textHelp,
    format = "DD/MM/YYYY",
    picker = "date",
    rangePicker,
    disabledDate,
    onChange,
    ...rest
  } = props;

  return (
    <Form.Item
      required={required}
      id={name}
      name={name}
      label={label}
      rules={rules}
      help={textHelp}
      {...rest}
    >
      {rangePicker ? (
        <RangePicker
          format={format}
          disabled={disabled}
          picker={picker}
          disabledDate={disabledDate}
          style={{ width: "100%" }}
          // onChange={onChange}
        />
      ) : (
        <DatePicker
          format={format}
          disabled={disabled}
          picker={picker}
          disabledDate={disabledDate}
          style={{ width: "100%" }}
          onChange={onChange}
        />
      )}
    </Form.Item>
  );
};

export default DateField;
