import React, { useMemo, useState } from 'react'
import {
  PageHeader as Header,
  Section
} from 'components'
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router'
import { useAuth } from 'hooks'
import { Tabs } from 'antd'
import RequestHistory from 'pages/Components/RequestHistory'
import RequestForm from '../Components/RequestForm'
import CompareQuote from '../Components/CompareQuote'

const { TabPane } = Tabs

const PurchaseRequsitionForm = () => {
  const [currentTab, setCurrentTab] = useState('0')
  const [isDisableTabs, setDisableTabs] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { roles: authRoles, hasRole } = useAuth()

  const onClickGoBack = () => {
    navigate('/requisicao-compras')
  }

  const {
    title,
    isView,
    isClone
  } = useMemo(() => {
    const [, , page] = pathname.split('/')
    const getTitle = () => {
      if (page === 'clonar') return 'Clonar'
      if (page === 'visualizar') return 'Visualizar'
      if (id) return 'Editar'

      return 'Novo'
    }

    return {
      title: getTitle(),
      isView: page === 'visualizar',
      isClone: page === 'clonar',
    }
  }, [id])

  const onChangeTab = (activeKey: string) => setCurrentTab(activeKey)

  const onDisableTabs = (disable: boolean) => setDisableTabs(disable)

  if (authRoles && !hasRole(authRoles?.request_buyer || authRoles?.project_edit)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <Header
        title={`${title} Requisição`}
        breadcrumbItems={['Requisições', title]}
      />
      <Section>
        <Tabs
          onChange={onChangeTab}
          defaultActiveKey={currentTab}
          type='card'
          size='small'
        >
          <TabPane tab='Formulário' key='0'>
            <RequestForm
              id={Number(id)}
              isView={isView}
              isClone={isClone}
              actionGoBack={onClickGoBack}
              onDisableTabs={onDisableTabs}
            />
          </TabPane>
          {(id && !isClone) && (
            <>
              <TabPane
                tab='Comparar Cotações'
                disabled={isDisableTabs}
                key='1'
              >
                <CompareQuote
                  projectRequestId={Number(id)}
                />
              </TabPane>
              <TabPane
                tab='Histórico de alterações'
                key='2'
              >
                <RequestHistory
                  projectRequestId={Number(id)}
                />
              </TabPane>
            </>
          )}
        </Tabs>
      </Section>
    </>
  )
}

export default PurchaseRequsitionForm
