import React, { useEffect, useState } from 'react'
import {
  Alert,
  message,
  Modal,
  Rate,
  Space
} from 'antd'
import { TEvaluationRequest, useSuppliers } from 'repositories'

type TProps = {
  supplierId: number
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<boolean>
  requestFn(): Promise<void>
}

const ScoreForm = (props: TProps) => {
  const {
    supplierId,
    isModalVisible,
    setIsModalVisible,
    requestFn
  } = props
  const [currentScore, setCurrentScore] = useState(0)

  const repository = useSuppliers()

  const handleOk = async () => {
    const data: TEvaluationRequest = {
      supplierId,
      evaluation: currentScore
    }
    const response = await repository.saveOrUpdateEvaluation(data)
    if (!response) return

    handleClose()
    requestFn()
    message.success('Avaliação salva com sucesso!')
  }

  const handleClose = () => {
    setCurrentScore(0)
    setIsModalVisible(false)
  }

  useEffect(() => {
    const getScore = async () => {
      const response = await repository.findEvaluationBySupplierId(String(supplierId))
      if (!response.length) return

      const [score] = response
      setCurrentScore(score.evaluation)
    }
    if (isModalVisible && supplierId) getScore()
  }, [supplierId, isModalVisible])

  return (
    <Modal
      title='Avaliar Fornecedor'
      visible={isModalVisible}
      okText='Salvar'
      confirmLoading={repository.loading}
      okButtonProps={{
        disabled: !currentScore
      }}
      cancelText='Fechar'
      onOk={handleOk}
      onCancel={handleClose}
    >
      <Space direction='vertical' size='small'>
        <span>Avalie em até 5 estrelas</span>
        <Rate
          defaultValue={0}
          value={currentScore}
          onChange={setCurrentScore}
        />
      </Space>
      <Alert
        className='mt-1'
        message='Informação'
        description='A devida avaliação do fornecedor irá refletir nas tomadas de decisões nos processos que o mesmo participar de cotações.'
        type='warning'
        showIcon
      />
    </Modal>
  )
}

export default ScoreForm
