import React, { useEffect, useMemo, useState } from 'react'
import { v4 as genId } from 'uuid'
import {
  useNavigate,
  useLocation,
  useParams,
  Navigate
} from 'react-router'
import {
  TAcceptOrDeclineQuoteRequest,
  TChatOutputDTO,
  TMemberChat,
  TSupplierQuoteDetail,
  TSupplierQuoteItem,
  TUpdateSupplierQuoteItemRequest,
  TUpdateSupplierQuoteRequest,
  useChat,
  useSupplierQuote
} from 'repositories'
import {
  answerQuote,
  statusQuote,
  StatusQuote,
  StatusSupplierAnswer
} from 'utils/constants'
import {
  convertStringToNumber,
  formatDateToView
} from 'utils/helpers'
import {
  DetailGrid,
  PageHeader as Header,
  Section,
} from 'components'
import {
  Button,
  PageHeader,
  Tabs,
  Modal,
  message,
  Descriptions
} from 'antd'
import {
  AiOutlineExclamationCircle,
  AiOutlineMessage
} from 'react-icons/ai'
import RequestHistory from 'pages/Components/RequestHistory'
import { useAuth } from 'hooks'
import Chat from 'pages/Components/Chat'
import ItemList from '../Components/ItemList'

const { confirm } = Modal
const { TabPane } = Tabs

const messageAccept = 'Confirma o "ACEITE" da cotação?'
const messageRefuse = 'Confirma a "RECUSA" da cotação?'

const defaultPagination = {
  page: 0,
  size: 10
}

type TSupplierQuoteItemData = TSupplierQuoteItem & {
  key: string
  index: number
}

type TLocationState = {
  supplierId: number
}

const QuoteDetail = () => {
  const [member, setMember] = useState<TMemberChat>({} as TMemberChat)
  const [quote, setQuote] = useState<TSupplierQuoteDetail>({} as TSupplierQuoteDetail)
  const [quoteItems, setQuoteItems] = useState<TSupplierQuoteItemData[]>([])
  const [activeTab, setActiveTab] = useState('')
  const [isVisibleChat, setIsVisibleChat] = useState(false)
  const [isLoadingAccept, setIsLoadingAccept] = useState(false)
  const [isLoadingRefuse, setIsLoadingRefuse] = useState(false)

  const { id } = useParams<{ id: string }>()
  const repository = useSupplierQuote()
  const chatRepository = useChat()
  const location = useLocation()
  const navigate = useNavigate()

  const { roles: authRoles, hasRole, userData } = useAuth()

  const { supplierId } = location.state as TLocationState

  useEffect(() => {
    const getChat = (chats: TChatOutputDTO[], userId: number) => (
      chats.find(chat => chat.users.some(user => user.id === userId))
    )

    const makeMemberChat = (
      chat: TChatOutputDTO | undefined,
      userToId: number,
      userToName: string,
      userToDescription?: string
      // eslint-disable-next-line max-params
    ): TMemberChat => ({
      key: genId(),
      chatId: chat?.id,
      messagesNotViewed: chat?.messagesNotViewed || 0,
      userFromId: userData.userId,
      userFromName: userData.userName,
      userToId,
      userToName,
      userToDescription
    })

    const getBuyer = (chats: TChatOutputDTO[]) => {
      const findedChat = getChat(chats, quote.buyerId)

      return makeMemberChat(
        findedChat,
        quote.buyerId,
        quote.buyerName
      )
    }

    const getMember = async () => {
      const chats = await getCreatedChats()
      const buyer = getBuyer(chats)
      setMember(buyer)
    }

    if (!member.key && quote.buyerId && quote.projectRequestId) getMember()
  }, [quote, userData])

  const getCreatedChats = async () => {
    const response = await chatRepository.findChatByFilter({
      projectRequestId: quote.projectRequestId,
      ...defaultPagination
    })
    if (!response) return []
    return response.content
  }

  const getQuoteDetail = async () => {
    console.warn(activeTab)
    const response = await repository.findSupplierQuoteDetails({
      projectRequestId: Number(id),
      supplierId: Number(supplierId),
    })
    if (!response) return

    const mappedItems = response.items.map((item, index) => ({
      ...item,
      index,
      key: String(item.id),
      observation: item.observation || '',
      technicalScope: item.technicalScope || 'Sem informação',
      eventDate: formatDateToView(item.eventDate)
    }))

    const formattedQuote: TSupplierQuoteDetail = {
      ...response,
      formattedStatus: statusQuote[response.status],
      formattedAnswerQuote: answerQuote[response.supplierAnswer],
      issueDate: formatDateToView(response.issueDate),
      serviceStartDate: formatDateToView(response.serviceStartDate),
      items: mappedItems
    }

    setQuote(formattedQuote)
    setQuoteItems(mappedItems)
  }

  useEffect(() => {
    if (!supplierId) {
      return navigate('/cotacoes')
    }

    getQuoteDetail()
  }, [location])

  const onChangeTab = (key: string) => setActiveTab(key)

  const onClickGoBack = () => navigate('/cotacoes')

  const handleUpdateQuote = async (quoteDetail: TSupplierQuoteDetail) => {
    const data: TUpdateSupplierQuoteRequest = {
      id: quoteDetail.idSupplierQuote,
      items: quoteDetail.items?.map(quoteItem => ({
        id: quoteItem.id,
        observation: quoteItem.observation,
        unitValue: convertStringToNumber(quoteItem.unitValue),
      } as TUpdateSupplierQuoteItemRequest)),
    }
    const response = await repository.updateQuote(data)
    if (!response) return

    message.success('Proposta de cotação enviada com sucesso!')
    navigate('/cotacoes')
  }

  const showConfirmSendProposalQuote = (quoteDetail: TSupplierQuoteDetail) => () => {
    const invalid = quoteDetail.items?.some(item => (
      !item.observation || convertStringToNumber(item.unitValue) <= 0
    ))
    if (invalid) {
      message.error('Não é possivel confirmar a proposta se os items não estiverem devidamente preenchidos')
      return
    }

    confirm({
      title: 'Confirmação',
      okText: 'Sim',
      cancelText: 'Cancelar',
      icon: (
        <AiOutlineExclamationCircle
          size={18}
          color='orange'
        />
      ),
      content: 'Confirma o envio da proposta de cotação?',
      async onOk() {
        await handleUpdateQuote(quoteDetail)
      },
    })
  }

  const handleAcceptOrRefuseQuote = async (
    quoteId: number,
    answer: 'ACCEPT' | 'REFUSE'
  ) => {
    const data: TAcceptOrDeclineQuoteRequest = {
      supplierQuoteId: quoteId,
      answer,
    }
    if (answer === 'ACCEPT') setIsLoadingAccept(true)
    if (answer === 'REFUSE') setIsLoadingRefuse(true)
    const response = await repository.answerQuote(data, false)
    if (answer === 'ACCEPT') setIsLoadingAccept(false)
    if (answer === 'REFUSE') setIsLoadingRefuse(false)
    if (!response) return

    if (answer === 'ACCEPT') {
      message.success('Cotação aceita com sucesso!')
      getQuoteDetail()
      return
    }

    message.success('Cotação recusada com sucesso!')
    getQuoteDetail()
  }

  const onClickShowModal = () => {
    setIsVisibleChat(true)
  }

  const showConfirmAcceptOrRefuseQuote = (
    quoteId: number,
    answer: 'ACCEPT' | 'REFUSE',
    contentMessage: string
  ) => () => {
    confirm({
      title: 'Confirmação',
      okText: 'Sim',
      cancelText: 'Cancelar',
      icon: (
        <AiOutlineExclamationCircle
          size={18}
          color='orange'
        />
      ),
      content: contentMessage,
      async onOk() {
        await handleAcceptOrRefuseQuote(quoteId, answer)
      },
    })
  }

  const extraActions = useMemo(() => {
    const _statusQuote = quote.status as StatusQuote
    const enabledSendProposal = [
      StatusQuote.WAITING_ANSWER,
      StatusQuote.QUOTE_REVISED
    ].includes(_statusQuote) &&
      quote.supplierAnswer === StatusSupplierAnswer.ACCEPT

    const enabledAcceptOrRefused = quote.supplierAnswer === StatusSupplierAnswer.WAITING_ANSWER

    return (
      <>
        <Button
          key='1'
          disabled={!enabledAcceptOrRefused || isLoadingAccept}
          loading={isLoadingAccept}
          onClick={showConfirmAcceptOrRefuseQuote(quote.idSupplierQuote, 'ACCEPT', messageAccept)}
          type='primary'
        >
          Aceitar
        </Button>
        <Button
          danger
          key='2'
          disabled={!enabledAcceptOrRefused || isLoadingRefuse}
          onClick={showConfirmAcceptOrRefuseQuote(quote.idSupplierQuote, 'REFUSE', messageRefuse)}
          loading={isLoadingRefuse}
        >
          Recusar
        </Button>
        <Button
          key='3'
          onClick={showConfirmSendProposalQuote(quote)}
          disabled={!enabledSendProposal || repository.loading}
          loading={repository.loading}
        >
          Enviar Proposta
        </Button>
        <Button
          key='4'
          danger={!!member.messagesNotViewed}
          onClick={onClickShowModal}
          disabled={!quote.buyerId || !member.userFromId}
          loading={repository.loading}
        >
          <span>Chat</span>
        </Button>
        {!!member.messagesNotViewed && (
          <AiOutlineMessage
            color='red'
            size={16}
          />
        )}
      </>
    )
  }, [quote, member])

  if (authRoles && !hasRole(authRoles?.supplier_default)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <Header
        title='Detalhes da Cotação'
        breadcrumbItems={['Cotações', 'Detalhes']}
      />
      <PageHeader
        onBack={onClickGoBack}
        title='Detalhes'
        extra={extraActions}
      >
        <DetailGrid>
          <Descriptions.Item label='N° Cotação'>
            {quote?.idSupplierQuote}
          </Descriptions.Item>
          <Descriptions.Item label='N° Requisição'>
            {quote?.projectRequestId}
          </Descriptions.Item>
          <Descriptions.Item label='Data'>
            {quote?.issueDate}
          </Descriptions.Item>
          <Descriptions.Item label='Início em'>
            {quote?.serviceStartDate}
          </Descriptions.Item>
          <Descriptions.Item label='Status'>
            {quote?.formattedStatus}
          </Descriptions.Item>
          <Descriptions.Item label='Aceite ?'>
            {quote?.formattedAnswerQuote}
          </Descriptions.Item>
          <Descriptions.Item label='Prazo de pagamento'>
            {quote?.paymentTerm}
          </Descriptions.Item>
          <Descriptions.Item label='Condição de pagamento'>
            {quote?.paymentMethodDescription}
          </Descriptions.Item>
        </DetailGrid>
      </PageHeader>
      <Section>
        <Tabs
          onChange={onChangeTab}
          type='card'
        >
          <TabPane
            tab='Items da cotação'
            key='1'
          >
            <ItemList
              quote={quote}
              initialQuoteItems={quoteItems}
              setQuote={setQuote}
              isView={quote.supplierAnswer === StatusSupplierAnswer.ACCEPT &&
                (quote.status === StatusQuote.QUOTE_REVISED || quote.status === StatusQuote.WAITING_ANSWER)}
            />
          </TabPane>
          <TabPane
            tab='Histórico'
            key='2'
          >
            <RequestHistory
              projectRequestId={quote?.projectRequestId}
            />
          </TabPane>
        </Tabs>
      </Section>
      <Chat
        isVisible={isVisibleChat}
        setIsVisible={setIsVisibleChat}
        projectRequestId={quote.projectRequestId}
        setMember={setMember}
        member={member}
      />
    </>
  )
}

export default QuoteDetail
