/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {Form, Select} from 'antd'
import { Rule } from 'antd/lib/form'
import { DefaultOptionType } from 'antd/lib/select'


type TProps = {
  name: string
  label?: string
  rules?: Rule[]
  value?: any,
  disabled?: boolean
  required?: boolean
  bordered?: boolean
  textHelp?: string
  options?: DefaultOptionType[] | undefined
  mode?: 'multiple' | 'tags' | undefined
  onChange?(value: any, option: DefaultOptionType | DefaultOptionType[]): void
}

const SelectField = (props: TProps) => {
  const {
    name,
    label,
    value,
    rules,
    disabled = false,
    required = false,
    bordered = true,
    textHelp,
    options = [],
    mode,
    onChange,
    ...rest
  } = props

  return (
    <Form.Item
      required={required}
      id={name}
      name={name}
      label={label}
      rules={rules}
      help={textHelp}
      {...rest}
    >
      <Select
        mode={mode}
        disabled={disabled}
        options={options}
        onChange={onChange}
        bordered={bordered}
      />
    </Form.Item>
  )
}

export default SelectField

