import React from "react";
import { Result } from "antd";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";
import { Container } from "./styles";
import { useAuth } from "hooks/useAuth";
import { useNavigate } from "react-router";
import { useLogin } from "repositories";

import { AiOutlineExclamationCircle } from "react-icons/ai";

const { confirm } = Modal;

const AccessDenied = () => {
  const repositoryLogin = useLogin();
  const { removeUserData, userData, roles, logoutKeycloakAd } = useAuth();
  const navigate = useNavigate();

  const onClickSystemExit = async () => {
    if (userData && !userData.isActiveDirectory) await repositoryLogin.logout();
    removeUserData();
    navigate("/login");
  };

  const onClickSystemExitAndLogoutAd = async () => {
    removeUserData();
    if (userData && userData.isActiveDirectory) await logoutKeycloakAd();
    navigate("/login");
  };

  const showConfirmExit = () => {
    confirm({
      title: "Deseja sair do sistema?",
      okText: "Sim",
      icon: <AiOutlineExclamationCircle size={18} color="orange" />,
      async onOk() {
        if (userData.isActiveDirectory) {
          await onClickSystemExitAndLogoutAd();
        }
        onClickSystemExit();
      },
    });
  };

  const LinkButton = (
    <>
      <Link to="/">Voltar para a home</Link>
      <br />
      <Button type="link" onClick={showConfirmExit}>
        Sair
      </Button>
    </>
  );

  return (
    <Container>
      <Result
        status="403"
        title="Sem Permissão!"
        subTitle="Você não possui permissão para acessar essa página."
        extra={LinkButton}
      />
    </Container>
  );
};

export default AccessDenied;
