import styled from 'styled-components'
import Attachments from 'pages/PurchaseRequisition/Components/Attachments'
import Card from 'antd/lib/card/Card'

type TLabelProps = {
  color: string
}

export const ModalAttachments = styled(Attachments)`
`
export const Chatbox = styled.div`
  div{ 
    padding: 0;
  }
`

export const Label = styled.span<TLabelProps>`
  font-size: 10px;
  padding: 4px 10px;
  margin: 0 2px;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${p => p.color};
  border-radius: 4px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    font-size: 9px;
  }
`

export const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1200px;
  padding-top: 5px;
`

export const Tagbox = styled.div`
  display: flex;
  justify-content: space-around;
  width: fit-content;
  height: 25px;
`
export const TagNameBox = styled.div`
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 75px;
`

export const Abbreviation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 30px;

  > span {
    font-size: 15px;
    color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
  }
  
  span.label {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.WHITE};
  }
`

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: space-around;
`

export const LabelBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: left;
  span, p {
    font-size: 15px;
    margin: 5px;
  }
`
export const PriceBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 15px;

  div {
    width: 235px;
    height: 75px;
    border-style: solid;
    border-radius: 15px;
    border-color: lightgray;
    border-width: 1.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    span:first-child{
      padding-top: 5px;
    }

    span:last-child{
      padding-bottom: 5px;
      font-size: 20px;
    }
  }
`
export const TextBox = styled.div`
  padding-top: 10px;
`
export const InfoAndCostBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
export const DetailedInfoBox = styled.div`
  width: 50%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: space-around;
`
export const TotalCostBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 50%;
  padding-top: 108px;

  div {
    width: 250px;
    height: 90px;
    border-style: solid;
    border-radius: 15px;
    border-color: gray;
    border-width: 1.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    span:first-child{
      padding-top: 5px;
    }

    span:last-child{
      padding-bottom: 5px;
      font-size: 20px;
    }
  }
`
export const CustomCard = styled(Card)`
  margin-top: 10px;
  width: 400px;

  .ant-card-body {
      padding: 0;
  }
`

export const CustomTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

`
