import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 10px;
`

type TContentProps = {
  size: 'small' | 'large'
}

export const Content = styled.div<TContentProps>`
  padding: ${({ size }) => (size === 'small' ? '10px 0' : '30px 0')};
`
