import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Dropdown,
  Menu
} from 'antd'
import { BiChevronDown } from 'react-icons/bi'
import {
  TGroupMemberChat,
  TMemberChat,
  useChat
} from 'repositories'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import Chat from 'pages/Components/Chat'
import {
  Container,
  Flex
} from './styles'

type TProps = {
  projectRequestId: number
  groupMembers: TGroupMemberChat[]
  setGroupMembers: React.Dispatch<TGroupMemberChat[]>
}

const ChatRequisition = (props: TProps) => {
  const {
    projectRequestId,
    groupMembers,
    setGroupMembers
  } = props
  const [isVisible, setIsVisible] = useState(false)
  const [talkTo, setTalkTo] = useState<TMemberChat>({} as TMemberChat)
  const [menuOptions, setMenuOptions] = useState<ItemType[]>([])

  const chatRepository = useChat()

  useEffect(() => {
    const getChats = async () => {
      const response = await chatRepository.findChatByFilter({
        projectRequestId,
        page: 0,
        size: 100
      })
      if (!response) return
      const { content } = response

      const mappedChats = groupMembers.map(groupChat => {
        const mappedMembers = groupChat.members.map(member => {
          const userIds = [member.userToId]
          const chat = content.find(item => item.users.some(user => userIds.includes(user.id)))

          if (!chat?.id) return { ...member, chatId: 0 }

          return {
            ...member,
            chatId: chat.id || 0,
            messagesNotViewed: member.userFromId !== member.userToId ? chat.messagesNotViewed : 0
          }
        })

        return {
          ...groupChat,
          members: mappedMembers
        }
      })

      const filterGroupOptions = mappedChats.filter(groupMember => (
        groupMember.members.some(member => member.userToId)
      ))

      const mappedGroupOptions = filterGroupOptions.map(groupMember => {
        const { title, members } = groupMember
        return {
          label: title,
          type: 'group',
          key: title,
          children: members.map(member => ({
            danger: !!member.messagesNotViewed,
            disabled: member.userFromId === member.userToId,
            label: member.userToName,
            key: member.key,
            onClick: onClickTalkTo(member)
          }))
        } as ItemType
      })
      setMenuOptions(mappedGroupOptions)
    }

    if (projectRequestId && groupMembers.length) getChats()
  }, [projectRequestId, groupMembers])

  useEffect(() => {
    const updateChatIdAfterCreateChat = () => {
      const mappedGroupMembers = groupMembers.map(groupMember => ({
        ...groupMember,
        members: groupMember.members.map(member => {
          if (member.key !== talkTo.key) return member

          return {
            ...member,
            chatId: talkTo.chatId || 0,
            messagesNotViewed: 0
          }
        })
      }))

      setGroupMembers(mappedGroupMembers)
    }

    if (talkTo.chatId) updateChatIdAfterCreateChat()
  }, [talkTo])

  const onClickTalkTo = (member: TMemberChat) => () => {
    setIsVisible(true)
    setTalkTo(member)
  }

  const menu = useMemo(() => (
    <Menu items={menuOptions} />
  ), [menuOptions, projectRequestId])

  const onClickPreventDefault = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => () => {
    e.preventDefault()
  }

  return (
    <Container>
      <Dropdown
        overlay={menu}
      >
        <Button
          type='link'
          onClick={onClickPreventDefault}
        >
          <Flex>
            <span>Chat com</span>
            <BiChevronDown />
          </Flex>
        </Button>
      </Dropdown>
      <Chat
        isVisible={isVisible}
        member={talkTo}
        setMember={setTalkTo}
        projectRequestId={projectRequestId}
        setIsVisible={setIsVisible}
      />
    </Container>
  )
}

export default ChatRequisition
