/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios";
import axios from "services/axios";

import useErrorHandler from "hooks/useErrorHandler";
import { useAuth } from "./useAuth";

const useRest = () => {
  const { refreshTokens } = useAuth();
  const { handleApiErrorDesambiguation } = useErrorHandler();

  const get = async (
    url: string,
    params: object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const _params = {
        params,
      };

      refreshTokens();
      const response = await axios.get(url, _params);
      return response.data;
    } catch (error) {
      const _error = error as AxiosError;
      if (customHandleError) {
        customHandleError(_error);
        return;
      }
      const { response } = _error;
      if (response) handleApiErrorDesambiguation(response);
    }
  };

  const post = async (
    url: string,
    body: object = {},
    headers: object = {},
    customHandleError?: (error: AxiosError) => any
    // eslint-disable-next-line max-params
  ) => {
    try {
      const response = await axios.post(url, body, headers);
      return response.data;
    } catch (error) {
      const _error = error as AxiosError;
      if (customHandleError) {
        customHandleError(_error);
        return;
      }
      const { response } = _error;
      if (response) handleApiErrorDesambiguation(response);
    }
  };

  const put = async (
    url: string,
    body: object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const response = await axios.put(url, body);
      return response.data;
    } catch (error) {
      const _error = error as AxiosError;
      if (customHandleError) {
        customHandleError(_error);
        return;
      }

      const { response } = _error;
      if (response) handleApiErrorDesambiguation(response);
    }
  };

  const destroy = async (
    url: string,
    body: object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const response = await axios.delete(url, body);
      return response.data;
    } catch (error) {
      const _error = error as AxiosError;
      if (customHandleError) {
        customHandleError(_error);
        return;
      }
      const { response } = _error;
      if (response) handleApiErrorDesambiguation(response);
    }
  };

  return {
    get,
    post,
    put,
    destroy,
  };
};

export default useRest;
