import useRest from 'hooks/useRest'
import { useState } from 'react'
import {
  TAddUserToChatInputDTO,
  TChatOutputDTO,
  TChatResponse,
  TChatWithMessageResponse,
  TCreateChatInputDTO,
  TFindByFilterChatInputDTO,
  TFindByFilterMessagesChatInputDTO,
  TSendMessageChatOutputDTO,
  TSendMessageInputDTO,
  TUpdateChatInputDTO,
} from 'repositories'

const useChat = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const createChat = async (data: TCreateChatInputDTO, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/chat'
    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TChatOutputDTO

    return response.result as TChatOutputDTO
  }

  const addUserToChat = async (data: TAddUserToChatInputDTO, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/chat/add-user'
    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TChatOutputDTO

    return response.result as TChatOutputDTO
  }

  const sendMessage = async (data: TSendMessageInputDTO, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/chat/send-message'
    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TSendMessageChatOutputDTO

    return response.result as TSendMessageChatOutputDTO
  }

  const updateChat = async (data: TUpdateChatInputDTO, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/chat'
    const response = await rest.put(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TChatOutputDTO

    return response.result as TChatOutputDTO
  }

  const confirmViewer = async (chatId: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(false)
    const url = `/v1/manager/chat/confirm-viewed/${chatId}`
    const response = await rest.post(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TChatOutputDTO

    return response.result as TChatOutputDTO
  }

  const findChatByFilter = async (
    params: TFindByFilterChatInputDTO,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/chat'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TChatResponse

    return response.result as TChatResponse
  }

  const findMessagesByFilter = async (
    params: TFindByFilterMessagesChatInputDTO,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(false)
    const url = '/v1/manager/chat/message'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {}

    return response.result
  }
  const confirmMessagesByChatId = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(false)
    const url = `/v1/manager/chat/confirm-viewed/${id}`
    await rest.post(url)
    if (triggerLoading) setLoading(false)
  }


  const findChatById = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/chat/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TChatOutputDTO

    return response.result as TChatOutputDTO
  }

  return {
    loading,
    findChatByFilter,
    findMessagesByFilter,
    findChatById,
    confirmMessagesByChatId,
    createChat,
    confirmViewer,
    updateChat,
    addUserToChat,
    sendMessage
  }
}

export default useChat
