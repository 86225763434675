/* eslint-disable */
import React from 'react'
import { Form } from 'antd'
import type { Rule } from 'antd/lib/form'
import NumberFormat from 'react-number-format'

type TProps = {
  name: string
  label?: string
  rules?: Rule[]
  disabled?: boolean
  required?: boolean
  textHelp?: string
  maxLength?: number
  placeholder?: string
  decimal?: number
  thousandSeparator?: string
  decimalSeparator?: string
  fixedScale?: boolean
  bordered?: boolean
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
}

const CurrencyField = (props: TProps) => {
  const {
    name,
    label,
    rules,
    disabled = false,
    required = false,
    bordered = true,
    textHelp,
    placeholder = '',
    decimal = 2,
    thousandSeparator = '.',
    decimalSeparator = ',',
    onBlur,
    onChange,
    ...rest
  } = props

  const isBordered = bordered ? '' : 'ant-input-borderless'

  return (
    <Form.Item
      required={required}
      id={name}
      name={name}
      label={label}
      rules={rules}
      help={textHelp}
      {...rest}
    >
      <NumberFormat
        disabled={disabled}
        className={`ant-input ${isBordered}`}
        placeholder={placeholder}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={decimal}
        fixedDecimalScale
        onBlur={onBlur}
        onChange={onChange}
      />
    </Form.Item>
  )
}

export default CurrencyField
