import React from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'
import { Container } from './styles'

const LinkButton = (
  <Link to='/'>
    Voltar para a home
  </Link>
)

const NotFound = () => (
  <Container>
    <Result
      status='404'
      title='Página não encontrada!'
      subTitle='A página que você tentou acessar não existe.'
      extra={LinkButton}
    />
  </Container>
)

export default NotFound
