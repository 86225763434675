import React from 'react'
import { SubTitle } from './styles'

type TProps = {
  title: string
  description: string
}

const Title = (props: TProps) => {
  const {
    title,
    description
  } = props

  return (
    <SubTitle>
      <p>{title}</p>
      <span>{description}</span>
    </SubTitle>
  )
}

export default Title
