import React, { useEffect, useMemo, useState } from "react";
import { Modal, Form, message } from "antd";
import { Col, Row, SelectField } from "components";
import { TGroup, TUser, TUserRequest, useUsers } from "repositories";
import type { Rule } from "antd/lib/form";
import type { DefaultOptionType } from "antd/lib/select";

type TChangeGroupForm = {
  groupId: string;
};

const initialValues: TChangeGroupForm = {
  groupId: "",
};

const validations: Record<string, Rule[]> = {
  groupId: [{ required: true, message: 'Campo "Grupo" é obrigatório' }],
};

type TProps = {
  userId: number;
  groups: TGroup[];
  isVisible: boolean;
  setIsVisible: React.Dispatch<boolean>;
  requestFn(): Promise<void>;
  value: any;
};

const ChangeGroupForm = (props: any) => {
  const { userId, groups, isVisible, setIsVisible, requestFn, value } = props;

  const [user, setUser] = useState<TUser>({} as TUser);

  const [form] = Form.useForm<TChangeGroupForm>();
  const userRepository = useUsers();

  useEffect(() => {
    const requestUser = async () => {
      const response = await userRepository.findUserById(userId);
      if (!response) return;

      setUser(response);
    };
    form.setFieldsValue({ groupId: value });
    if (userId) requestUser();
  }, [userId]);

  const handleClose = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const handleSubmit = () => {
    form.submit();
  };

  const groupOptions = useMemo(
    () =>
      groups.map(
        (group: any) =>
          ({
            value: group.id,
            label: group.name,
          } as DefaultOptionType)
      ),
    [groups]
  );

  const onFinish = async (values: TChangeGroupForm) => {
    const data: TUserRequest = {
      ...user,
      userGroupId: Number(values.groupId),
    };

    try {
      await userRepository.updateUser(data);
      message.success("Grupo vinculado ao usuário com sucesso!");
      handleClose();
      requestFn();
    } catch (error) {}
  };

  return (
    <Modal
      title="Vincular Grupo"
      visible={isVisible}
      onOk={handleSubmit}
      okText="Salvar"
      cancelText="Fechar"
      confirmLoading={userRepository.loading}
      onCancel={handleClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col>
            <SelectField
              required
              name="groupId"
              label="Grupo"
              rules={validations.groupId}
              options={groupOptions}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangeGroupForm;
