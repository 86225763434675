import React, { useMemo, useState } from 'react'
import { LogoWithTitle } from 'components'
import { Steps } from 'antd'
import { Container } from './styles'
import Authorization from './Steps/Authorization'
import Register from './Steps/Register'
import Complement from './Steps/Complement'
import Attachment from './Steps/Attachment'

const { Step } = Steps

const CompleteRegistration = () => {
  const [activeStep, setActiveStep] = useState(0)

  const onChangeStep = (value: number) => setActiveStep(value)

  const componentProps = {
    onChangeStep
  }

  const renderedComponent = useMemo(() => {
    switch (activeStep) {
      case 0: return <Authorization {...componentProps} />
      case 1: return <Register {...componentProps} />
      case 2: return <Complement {...componentProps} />
      case 3: return <Attachment {...componentProps} />
      default: return <div />
    }
  }, [activeStep])

  return (
    <Container>
      <LogoWithTitle />
      <Steps
        progressDot
        current={activeStep}
      >
        <Step title='Autorização' />
        <Step title='Cadastro' />
        <Step title='Complemento' />
        <Step title='Anexos' />
      </Steps>
      {renderedComponent}
    </Container>
  )
}

export default CompleteRegistration
