import styled from 'styled-components'
import logoImage from 'assets/logo.svg'
import backgroundImage from 'assets/backgroundUnauthenticated.jpg'

export const Container = styled.div`
  display: flex;
`

export const Background = styled.div`
  width: 50%;
  height: 100vh;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MD}) {
      display: none;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  width: 50%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.MD}) {
    width: 100%;
    padding: 16px 20px;
  }
`

export const Logo = styled.h1`
  width: 434px;
  height: 246px;
  background: url(${logoImage}) no-repeat;
  background-size: 434px 246px;
  text-indent: -9999px;
  overflow: hidden;
`

export const Footer = styled.footer`
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  text-align: center;

  p {
    padding: 0;
    margin: 0;
  }
`
