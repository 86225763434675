import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 15px;
`

export const FilterContainer = styled.div`
  display: flex;
  gap: 5px;
  float: right;
  margin-bottom: 10px;
`

export const RowFormat = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  
`;

export const ColFormat = styled.div`
  flex: auto;
  max-width: 24%;
  min-width: 24%;
`;

export const UploadWrapper = styled.span`
  .ant-upload.ant-upload-drag {
    height: 75px;
  }
`
export const MessageContainer = styled.div`
  border: 1px solid #ccc;
  padding: 24px;
  background-color: #fff2f0;
  border-radius: 8px;
  width: fit-content;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
  font-size: 14px;
`;

export const Title = styled.b`
  font-weight: bold;
  font-size: 14px;
`;

export const Paragraph = styled.p`
  margin: 0;
  color: #333;
  font-size: 14px;
`;

