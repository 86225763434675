import styled from "styled-components";

export const WrapperMoney = styled.div`
  position: realtive;
  span {
    position: absolute;
    left: 0 px;
    top: 0px;
    z-index: 5;
    display: block;
    padding: 4px 0 4px 11px;
    border: 1px solid transparent;
    height: min-content;
  }
  .ant-input {
    padding-left: 35px;
  }
`;
