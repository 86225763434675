import React from 'react'
import { Checkbox, Form } from 'antd'
import { Rule } from 'antd/lib/form'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

type TProps = {
  name: string
  label: string
  rules?: Rule[]
  disabled?: boolean
  required?: boolean
  onChange?: (e: CheckboxChangeEvent)=> void
}

const CheckField = (props: TProps) => {
  const {
    name,
    label,
    rules,
    disabled = false,
    required = false,
    onChange,
    ...rest
  } = props

  return (
    <Form.Item
      name={name}
      valuePropName='checked'
      required={required}
      id={name}
      rules={rules}
      {...rest}
    >
      <Checkbox onChange={onChange} disabled={disabled}>
        {label}
      </Checkbox>
    </Form.Item>
  )
}

export default CheckField
