import React, { useEffect, useMemo } from 'react'
import {
  Button,
  Divider,
  Form,
  Modal
} from 'antd'
import {
  TRequestItem,
} from 'repositories'
import {
  AreaField,
  Col,
  CurrencyField,
  DateField,
  HiddenField,
  NumberField,
  Row,
  TextField,
  SelectField
} from 'components'
import type { Rule } from 'antd/lib/form'
import {
  convertNumberToInt,
  convertStringToNumber,
  formatDateToServer,
  formatDateToView,
  formatStringToNumber
} from 'utils/helpers'
import { useParams } from 'react-router'
import { useAuth } from 'hooks'
import moment, { Moment } from 'moment'
import { StatusRequest } from 'utils/constants'
import { DefaultOptionType } from 'antd/lib/select'
import Attachments from '../../Attachments'

type TRequestItemForm = Omit<TRequestItem, 'categories' | 'eventDate'> & {
  eventDate: Moment | string
}

const validations: Record<string, Rule[]> = {
  amount: [
    { required: true, message: 'Campo "Quantidade" é obrigatório' }
  ],
  costCenterId: [
    {
      required: true,
      message: 'Campo "Centro de Custo" é obrigatório'
    },
  ],
  unitCost: [
    { required: true, message: 'Campo "Custo unitário" é obrigatório' },
    () => ({
      validator(_, unitCost: string) {
        const formattedValue = formatStringToNumber(unitCost)
        if (unitCost && formattedValue <= 0) {
          return Promise.reject(new Error('Custo unitário deve ser maior que zero'))
        }

        return Promise.resolve()
      },
    })
  ]
}

type TProps = {
  index: number
  setIndex: React.Dispatch<number>
  isVisible: boolean
  isClone: boolean
  setIsVisible: React.Dispatch<boolean>
  items: TRequestItem[]
  setItems: React.Dispatch<TRequestItem[]>
  status: string
  isRequestNotTiedToProject?: boolean
  costCenterOptions: DefaultOptionType[]
}

const ItemForm = (props: TProps) => {
  const {
    index,
    setIndex,
    isVisible,
    isClone,
    setIsVisible,
    items,
    setItems,
    status,
    isRequestNotTiedToProject = false,
    costCenterOptions
  } = props

  const { roles: authRoles, hasRole } = useAuth()
  const { id } = useParams<{ id: string }>()
  const [form] = Form.useForm<TRequestItemForm>()
  const watchedProjectRequestItemId = Form.useWatch('projectRequestItemId', form)
  const watchedDaily = Form.useWatch('daily', form)
  const watchedAmount = Form.useWatch('amount', form)
  const watchedUnitCost = Form.useWatch('unitCost', form)

  useEffect(() => {
    if (isVisible) {
      const item = items[index]
      const formattedEventDate = formatDateToServer(item.eventDate || '')
      if (item.eventDate) {
        form.setFieldsValue({
          ...item,
          eventDate: moment(formattedEventDate)
        })
        return
      }

      form.setFieldsValue(item)
    }
  }, [index, isVisible])

  const resetForm = () => {
    form.resetFields()
  }

  const handleClose = () => {
    setIsVisible(false)
    resetForm()
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
    handleClose()
  }

  const handleSubmitAndNext = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
    setIndex(index + 1)
  }

  const onFinish = (values: TRequestItemForm) => {
    const updatedItems = items.map(item => {
      if (item.key !== values.key) return item
      return {
        ...item,
        daily: convertNumberToInt(values.daily),
        amount: convertNumberToInt(values.amount),
        totalCost: convertStringToNumber(values.totalCost),
        unitCost: convertStringToNumber(values.unitCost),
        totalSalesWithoutTax: convertStringToNumber(item.unitSale) * convertStringToNumber(values.amount),
        eventDate: values.eventDate ? formatDateToView(values.eventDate) : '',
        deliveryLocation: values.deliveryLocation,
        technicalScope: values.technicalScope,
        costCenterId: values.costCenterId,
        costCenterName: values.costCenterName
      }
    })
    form.resetFields()
    setItems(updatedItems)
  }

  const onClickChangePreviousIndex = () => {
    setIndex(index - 1)
  }

  const onChangeCostCenter = () => {
    const costCenterId = form.getFieldValue('costCenterId')
    const costCenter = costCenterOptions.find(item => item.value === costCenterId)
    if (!costCenter) return

    form.setFieldValue('costCenterName', costCenter.label)
  }

  const isLastItem = useMemo(() => (
    (index + 1) === items.length
  ), [items, index])

  const isNewRequest = useMemo(() => (
    status === StatusRequest.NEW
  ), [status])

  const isBuyer = useMemo(() => (
    hasRole(authRoles?.request_buyer)
  ), [authRoles])

  /**
   * Calculo do valor total
   */
  useEffect(() => {
    const result = watchedDaily ?
      watchedDaily * watchedAmount * formatStringToNumber(form.getFieldValue('unitCost')) :
      watchedAmount * formatStringToNumber(form.getFieldValue('unitCost'))
    form.setFieldValue('totalCost', result)
  }, [watchedDaily, watchedAmount, watchedUnitCost])

  return (
    <Modal
      visible={isVisible}
      title='Editar Item'
      onCancel={handleClose}
      footer={[
        <Button
          key='1'
          onClick={handleClose}
        >
          Fechar
        </Button>,
        <Button
          key='2'
          type='primary'
          onClick={handleSubmit}
        >
          Atualizar
        </Button>,
        <Button
          key='3'
          onClick={onClickChangePreviousIndex}
          disabled={index === 0}
        >
          Anterior
        </Button>,
        <Button
          key='4'
          onClick={handleSubmitAndNext}
          disabled={isLastItem}
        >
          Próximo
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <HiddenField
          name='key'
        />
        <HiddenField
          name='projectRequestItemId'
        />
        <HiddenField
          name='projectRevisionItemId'
        />
        <Row>
          <Col xl={4}>
            <TextField
              disabled
              name='productId'
              label='Id Produto'
            />
          </Col>
          <Col xl={20}>
            <TextField
              disabled
              name='productName'
              label='Produto'
            />
          </Col>
        </Row>
        {isRequestNotTiedToProject && (
          <Row>
            <Col>
              <HiddenField
                name='costCenterName'
              />
              <SelectField
                required
                name='costCenterId'
                label='Centro de Custo'
                rules={validations.costCenterId}
                onChange={onChangeCostCenter}
                options={costCenterOptions}
              />
            </Col>
          </Row>
        )}
        {!isRequestNotTiedToProject && (
          <Row>
            <Col xl={4}>
              <TextField
                disabled
                name='projectId'
                label='Id Projeto'
              />
            </Col>
            <Col xl={20}>
              <TextField
                disabled
                name='projectName'
                label='Projeto'
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xl={12}>
            <NumberField
              name='daily'
              label='Diárias'
              disabled={!isNewRequest}
              min={0}
            />
          </Col>
          <Col xl={12}>
            <CurrencyField
              name='unitCost'
              label='Custo Unitário'
              disabled={!isRequestNotTiedToProject}
              required={isRequestNotTiedToProject}
              rules={validations.unitCost}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <NumberField
              name='amount'
              label='Quantidade'
              disabled={!isBuyer || !isNewRequest}
              required
              rules={validations.amount}
            />
          </Col>
          <Col xl={12}>
            <CurrencyField
              name='totalCost'
              label='Custo Total'
              disabled
            />
          </Col>
        </Row>
        {(id && !isClone && isBuyer) && (
          <>
            <Row>
              <Col>
                <Divider plain orientation='left'>Acordos</Divider>
              </Col>
            </Row>
            <Row>
              <Col xl={8}>
                <DateField
                  name='eventDate'
                  label='Data do evento'
                />
              </Col>
              <Col xl={16}>
                <TextField
                  name='deliveryLocation'
                  label='Local de entrega'
                  maxLength={150}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <AreaField
                  name='technicalScope'
                  label='Escopo Técnico'
                  maxLength={250}
                  rows={4}
                />
              </Col>
            </Row>
            <Attachments
              projectRequestItemId={watchedProjectRequestItemId || 0}
              isFullWidth
            />
          </>
        )}
      </Form>
    </Modal>
  )
}

export default ItemForm
