import React from 'react'
import { Button, Dropdown, Menu } from 'antd'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import type { IconType } from 'react-icons'
import { FlexContainer } from 'components/FlexContainer'
import { BiChevronDown } from 'react-icons/bi'

type TProps = {
  type?: 'link' | 'text' | 'ghost' | 'primary' | 'default' | 'dashed' | undefined
  disabled?: boolean
  label?: string
  icon?: IconType
  items?: ItemType[] | undefined
}

const DropdownButton = (props: TProps) => {
  const {
    type = 'text',
    disabled = false,
    label = 'Opções',
    icon = <BiChevronDown />,
    items = []
  } = props

  const menu = (
    <Menu items={items} />
  )

  const handlePreventDefault = (e: React.MouseEvent<HTMLElement, MouseEvent>) => () => {
    e.preventDefault()
  }

  const Children = () => {
    return (
        <>
          <span>{label}</span>
          {icon}
        </>
    )
  }

  return (
    <Dropdown overlay={menu}>
      <Button
        type={type}
        disabled={disabled}
        onClick={handlePreventDefault}
      >
        <FlexContainer>
            <Children />
        </FlexContainer>
      </Button>
    </Dropdown>
  )
}

export default DropdownButton
