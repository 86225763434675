import styled from 'styled-components'

export const Container = styled.div`
  > a div {
    display: flex;
    flex-direction: row;
  }

  > a div div {
    color: ${({ theme }) => theme.colors.BLACK};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
  }

  i {
    margin: 0 !important;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column !important;
  line-height: 1;
  gap: 0;
  
  p {
    margin: 0;
    font-size: 12px;
  }

  span {
    color: ${({ theme }) => theme.colors.MEDIUM_GRAY};
    font-size: 10px;
  }
`
