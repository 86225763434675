import React, { useMemo, useState } from 'react'
import { Navigate, useLocation } from 'react-router'
import {
  Steps
} from 'antd'
import {
  Section,
  PageHeader as Header
} from 'components'
import { TRequestItem } from 'repositories'
import { useAuth } from 'hooks'
import ProjectOrPackageList from './Steps/ProjectOrPackageList'
import ItemList from './Steps/ItemList'
import RequestForm from '../Components/RequestForm'
import RevisionList from './Steps/RevisionList'
import ProductList from './Steps/ProductList'


const { Step } = Steps

type TLocationState = {
  isPackage: boolean
  isRequestNotTiedToProject: boolean
}

const PurchaseRequisitionCreate = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [availableRequestItems, setAvailableRequestItems] = useState<TRequestItem[]>([])
  const [requestItems, setRequestItems] = useState<TRequestItem[]>([])

  const { roles: authRoles, hasRole } = useAuth()
  const location = useLocation()

  const { isPackage, isRequestNotTiedToProject } = location.state as TLocationState

  const onChangeStep = (goTo: number) => setCurrentStep(goTo)

  const { title, titleFirstStep } = useMemo(() => ({
    title: isPackage
      ? 'Novo Pacote de Compra'
      : 'Nova Requisição de Compra',
    titleFirstStep: isPackage ? 'Pacote(s)' : 'Projeto(s)'
  }), [isPackage])

  if (isRequestNotTiedToProject && currentStep === 0) setCurrentStep(1)

  const renderContent = useMemo(() => {
    if (isPackage && currentStep === 0) {
      return (
        <ProjectOrPackageList
          onChangeStep={onChangeStep}
          setAvailableRequestItems={setAvailableRequestItems}
        />
      )
    }

    if (!isPackage && currentStep === 0) {
      return (
        <RevisionList
          onChangeStep={onChangeStep}
          setAvailableRequestItems={setAvailableRequestItems}
        />
      )
    }

    if (!isRequestNotTiedToProject && currentStep === 1) {
      return (
        <ItemList
          currentStep={currentStep}
          isPackage={isPackage}
          onChangeStep={onChangeStep}
          requestItems={requestItems}
          availableRequestItems={availableRequestItems}
          setRequestItems={setRequestItems}
        />
      )
    }

    if (isRequestNotTiedToProject && currentStep === 1) {
      return (
        <ProductList
          currentStep={currentStep}
          onChangeStep={onChangeStep}
          requestItems={requestItems}
          availableRequestItems={availableRequestItems}
          setAvailableRequestItems={setAvailableRequestItems}
          setRequestItems={setRequestItems}
        />
      )
    }

    if (currentStep === 2) {
      return (
        <RequestForm
          initialItems={requestItems}
          actionGoBack={() => onChangeStep(1)}
        />
      )
    }
  }, [currentStep, availableRequestItems])



  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to='/acesso-negado' />
  }

  const nameSecondStep = isRequestNotTiedToProject ? 'Produtos' : 'Items'

  const descriptionSecondStep = isRequestNotTiedToProject ?
    'Escolha os produtos' :
    `Escolha os items do(s) ${titleFirstStep}`

  return (
    <>
      <Header
        title={title}
        breadcrumbItems={['Requisições', 'Novo']}
      />
      <Section spacedTop>
        <Steps current={currentStep}>
          {!isRequestNotTiedToProject && (
            <Step
              title={titleFirstStep}
              description={`Selecione o(s) ${titleFirstStep}`}
            />
          )}
          <Step
            title={nameSecondStep}
            description={descriptionSecondStep}
          />
          <Step
            title='Formulário'
            description='alçada, target de preços'
          />
        </Steps>
        {renderContent}
      </Section>
    </>
  )
}

export default PurchaseRequisitionCreate
