import { useState } from 'react'
import useRest from 'hooks/useRest'

export type TContactDTO = {
  id: number
  supplierId: number
  name: string
  cpf: string
  rg: string
  email: string
  telephone: string
}

type TCreateContactRequest = Omit<TContactDTO, 'id'>

type TUpdateContactRequest = TContactDTO

type TFilterContactParams = TPaginationRequest & Partial<TContactDTO>

type TContactResponse = TPaginationResponse & {
  content: TContactDTO[]
}

const useSupplierContact = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findContactByFilter = async (
    params: TFilterContactParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/supplier/business-contact'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TContactResponse

    return response.result as TContactResponse
  }

  const findContactById = async (
    id: number,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/supplier/business-contact/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TContactDTO

    return response.result as TContactDTO
  }

  const createContact = async (
    data: TCreateContactRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/supplier/business-contact'
    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TContactDTO

    return response.result as TContactDTO
  }

  const updateContact = async (
    data: TUpdateContactRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/supplier/business-contact'
    const response = await rest.put(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return {} as TContactDTO

    return response.result as TContactDTO
  }

  const deleteContact = async (
    id: number,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/supplier/business-contact/${id}`
    const response = await rest.destroy(url)
    if (triggerLoading) setLoading(false)
    if (!response) return false

    return true
  }

  return {
    loading,
    findContactByFilter,
    findContactById,
    createContact,
    updateContact,
    deleteContact
  }
}

export default useSupplierContact
