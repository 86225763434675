import React, { useState, useEffect } from 'react'
import {
  Button,
  Form,
  Modal,
  Checkbox
} from 'antd'
import {
  TSupplierProjectRequest,
  useSuppliers
} from 'repositories'
import {
  Col,
  HiddenField,
  Row,
  SearchField,
  SelectField
} from 'components'
import type { DefaultOptionType } from 'antd/lib/select'
import type { Rule } from 'antd/lib/form'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { SupplierStatus } from 'utils/constants'

type TSupplierRequestData = TSupplierProjectRequest & {
  key: string
}

type TSupplierRequestForm = {
  filterBy: 'corporateName' | 'cnpj' | 'productIds'
  supplierId: number
  supplierName: string
}

const initialValues: TSupplierRequestForm = {
  filterBy: 'corporateName',
  supplierId: 0,
  supplierName: ''
}

export const filterByOptions: DefaultOptionType[] = [
  { value: 'corporateName', label: 'Razão Social' },
  { value: 'cnpj', label: 'CNPJ' }
]

const validations: Record<string, Rule[]> = {
  supplierName: [
    { required: true, message: 'Campo "Fornecedor" é obrigatório' }
  ]
}

type TProps = {
  isVisible: boolean
  setIsVisible: React.Dispatch<boolean>
  initialSuppliers: TSupplierRequestData[]
  setInitialSuppliers: React.Dispatch<TSupplierRequestData[]>
  productIds?: number[]
}

const SupplierRequestForm = (props: TProps) => {
  const {
    isVisible,
    setIsVisible,
    initialSuppliers,
    setInitialSuppliers,
    productIds
  } = props
  const [value, setValue] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [options, setOptions] = useState<TSearchOptions[]>([])
  const [suppliers, setSuppliers] = useState<TSupplierRequestData[]>([])

  const supplierRepository = useSuppliers()
  const [form] = Form.useForm<TSupplierRequestForm>()

  const formattedProductIds = productIds?.join(',')

  useEffect(() => {
    const firstRequestSuppliers = async () => {
      await requestSuppliers({
        productIds: formattedProductIds
      })
    }
    if (formattedProductIds?.length) firstRequestSuppliers()
  }, [formattedProductIds])

  const onChangeFilterBy = async () => {
    form.setFieldsValue({
      supplierId: 0,
      supplierName: ''
    })

    setValue('')
    setOptions([])
    setSuppliers([])

    await requestSuppliers({
      productIds: formattedProductIds
    })
  }

  const onChange = (data: string) => {
    const findSupplier = suppliers.find(supplier => supplier.corporateName === data)
    if (!findSupplier) return

    setValue(data)
    form.setFieldsValue({
      supplierId: Number(findSupplier.id),
      supplierName: findSupplier.corporateName
    })
  }

  const onChangeCheckBox = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked)
  }

  const requestSuppliers = async (params: object) => {
    const response = await supplierRepository.findSupplierByFilter({
      ...params,
      status: SupplierStatus.APPROVED,
      page: 0,
      size: 10
    })
    if (!response) return
    const { content } = response

    const supplierToOptions = content.map(item => ({ value: item.corporateName }))
    const mappedToItems = content
      .map(item => ({
        id: item.id,
        corporateName: item.corporateName,
        email: item.email,
        status: item.status,
        score: item.currentEvaluation
      }) as TSupplierRequestData)
    setOptions(supplierToOptions)
    setSuppliers(mappedToItems)
  }

  const onSearch = async (searchText: string) => {
    const filterBy = form.getFieldValue('filterBy')
    await requestSuppliers({
      [filterBy]: searchText,
      productIds: formattedProductIds
    })
  }

  const resetForm = () => {
    form.resetFields()
    setValue('')
    setOptions([])
    setSuppliers([])
  }

  const handleClose = () => {
    setIsVisible(false)
    resetForm()
  }

  const handleSubmit = async () => {
    const validatedData = await form.validateFields()
    if (!validatedData) return

    onFinish(validatedData)
    if (isChecked) {
      resetForm()
      return
    }
    handleClose()
  }

  const onFinish = (values: TSupplierRequestForm) => {
    const findedSupplier = initialSuppliers.filter(supplier => supplier.id === values.supplierId)
    if (findedSupplier.length) return

    const supplier = suppliers.filter(item => item.id === values.supplierId)
    if (!supplier) return

    setInitialSuppliers([
      ...initialSuppliers,
      ...supplier
    ])
  }

  return (
    <Modal
      visible={isVisible}
      title='Adicionar Fornecedor'
      onCancel={handleClose}
      footer={[
        <Button
          key='1'
          onClick={handleClose}
        >
          Fechar
        </Button>,
        <Button
          key='2'
          type='primary'
          onClick={handleSubmit}
        >
          Adicionar
        </Button>
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col xl={8}>
            <SelectField
              name='filterBy'
              label='Filtrar por'
              options={filterByOptions}
              onChange={onChangeFilterBy}
            />
          </Col>
          <Col xl={16}>
            <HiddenField
              name='supplierId'
            />
            <SearchField
              required
              rules={validations.supplierName}
              isLoading={supplierRepository.loading}
              name='supplierName'
              label='Fornecedor'
              placeholder='Pesquise pelo termo'
              onChange={onChange}
              onSearch={onSearch}
              value={value}
              options={options}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox onChange={onChangeCheckBox}>
              Adicionar outro
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default SupplierRequestForm
