import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Divider,
  Space,
  Table,
  Modal,
  message
} from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import { useAuth, usePagination } from 'hooks'
import {
  TProductPreciary,
  useProduct,
  useSuppliers
} from 'repositories'
import { formatCurrency } from 'utils/helpers'
import { tableLocale } from 'utils/lang'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Col, Row } from 'components'
import RelatedProductForm from './RelatedProductForm'

const { confirm } = Modal

type TProductPreciaryData = TProductPreciary & {
  key: string
  value: any
  unitOfMeasurement: any
}

const columns: ColumnsType<TProductPreciaryData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Produto',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Descrição',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: 'Preciário ?',
    dataIndex: 'preciary',
    key: 'preciary'
  },
  {
    title: 'Valor',
    dataIndex: 'value',
    key: 'value',
    align: 'right',
    render: (_, record) => `R$ ${formatCurrency(record.value || 0, false)}`
  },
  {
    title: 'Unidade de medida',
    dataIndex: 'unitOfMeasurement ',
    key: 'unitOfMeasurement ',
    align: 'right',
    render: (_, record) => record.unitOfMeasurement
  }
]

type TProps = {
  supplierId: number
  isView?: boolean
  isFullWidth?: boolean
}

const RelatedProducts = (props: TProps) => {
  const {
    supplierId,
    isFullWidth = false,
    isView = false
  } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [productId, setProductId] = useState(0)
  const [products, setProducts] = useState<TProductPreciaryData[]>([])

  const { roles: authRoles, hasRole } = useAuth()
  const productRepository = useProduct()
  const supplierRepository = useSuppliers()
  const pagination = usePagination()

  const requestProductsBySupplier = async () => {
    const currentPage = pagination.data.current ?? 0
    const response = await productRepository.findProductByFilter({
      supplierId,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: 10000,
    })
    if (!response) return
    // console.log({response})
    const preciaries = await supplierRepository.findProductBySupplier(supplierId)

    const result = response.content.map(item => {
      const _preciary = preciaries.find(preciary => preciary.productId === item.id)

      if (!_preciary && item.preciary) return item

      return {
        ...item,
        key: String(item.id),
        minimumPrice: _preciary?.minimumPrice,
        maximumPrice: _preciary?.maximumPrice,
        comment: _preciary?.comment,
        preciaryId: _preciary?.id,
        preciary: _preciary?.preciary ? "Sim" : "Não",
        value: _preciary?.value,
        unitOfMeasurement: _preciary?.unitOfMeasurement
      }
    }) as TProductPreciaryData[]
    // console.log({result})
    setProducts(result)
  }

  const onOpenModal = () => {
    setProductId(0)
    setIsModalVisible(true)
  }

  const onEdit = (recordId: number) => () => {
    onOpenModal()
    setProductId(recordId)
  }

  const showDeleteConfirm = (
    _productId: number,
    _products: TProductPreciaryData[]
  ) => () => {
    confirm({
      title: 'Confirmação',
      content: 'Deseja desvincular esse produto?',
      icon: (
        <AiOutlineExclamationCircle
          size={18}
          color='orange'
        />
      ),
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      async onOk() {
        const filteredProducts = _products.filter(filterProduct => filterProduct.id !== _productId)
        await supplierRepository.updateProductToSupplier({
          supplierId,
          products: filteredProducts.map(item => item.id)
        })
        message.success('Produto desvinculado com sucesso')
        setProducts(filteredProducts)
      }
    })
  }

  const updatedColumns = useMemo(() => {
    const hasPermission = !hasRole(
      authRoles?.product_add ||
      authRoles?.product_edit ||
      authRoles?.supplier_pre_registration
    )

    return [
      ...columns,
      {
        title: (
          <Button
            type='primary'
            onClick={onOpenModal}
            disabled={isView || hasPermission}
          >
            Novo
          </Button>
        ),
        key: 'action',
        render: (_, record) => (
          <Space size='small'>
            <Button
              disabled={!record.preciary || isView || hasPermission}
              onClick={onEdit(record.id)}
              size='small'
              type='text'
            >
              Editar
            </Button>
            <Button
              disabled={isView || hasPermission}
              onClick={showDeleteConfirm(record.id, products)}
              size='small'
              type='text'
            >
              Excluir
            </Button>
          </Space>
        ),
      }
    ] as ColumnsType<TProductPreciaryData>
  }, [products, authRoles])

  // const handleChange: TableProps<TProductPreciaryData>['onChange'] = paginationConfig => {
  //   pagination.onChangeTable(paginationConfig)
  // }

  useEffect(() => {
    if (pagination.data.current) requestProductsBySupplier()
  }, [pagination.data.current, pagination.data.pageSize, supplierId])

  const fullWidth = isFullWidth ? 24 : 16

  return (
    <>
      <Row>
        <Col>
          <Divider plain orientation='left'>Produtos e Preciário</Divider>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            size='small'
            locale={tableLocale}
            loading={productRepository.loading || supplierRepository.loading}
            columns={updatedColumns}
            dataSource={products}
            pagination={false}
            // onChange={handleChange}
          />
        </Col>
      </Row>
      <RelatedProductForm
        supplierId={supplierId}
        productId={productId}
        actionFn={requestProductsBySupplier}
        initialProducts={products}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  )
}

export default RelatedProducts
