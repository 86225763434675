import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, Form, message, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useProfile, useLogin } from "repositories";
import { useAuth } from "hooks";
import { LogoWithTitle, Title, FlexContainer, TextField } from "components";
import { messageUserNotAssociatedGroups, RoleOperator } from "utils/constants";
import { parseJwt } from "utils/helpers";
import { AiOutlineWindows } from "react-icons/ai";
import type { Rule } from "antd/lib/form";
import { Link, Container, Content, ButtonLogin } from "./styles";

type TLoginForm = {
  email: string;
  password: string;
};

export const initialValues: TLoginForm = {
  email: "",
  password: "",
};

const validations: Record<string, Rule[]> = {
  email: [
    { required: true, message: "Por favor insira seu email!" },
    { type: "email", message: "Por favor insira um email válido!" },
    () => ({
      validator(_, email: string) {
        if (email.includes("@tv1.com.br")) {
          return Promise.reject(
            new Error('Para login com a conta "@tv1", utilizar o botão acima')
          );
        }

        return Promise.resolve();
      },
    }),
  ],
  password: [{ required: true, message: "Por favor insira sua senha!" }],
};

const Login = () => {
  const [form] = Form.useForm<TLoginForm>();
  const repositoryLogin = useLogin();
  const repositoryProfile = useProfile();
  const navigate = useNavigate();
  const { keycloak, userData, saveUserData } = useAuth();

  const [loadingAd, setLoadingAd] = useState(false);

  const [loginAsSupplier, setLoginAsSupplier] = useState(false);

  const updateUserData = async (_userData: TUserData) => {
    const filterPermission = _userData.authorities?.filter(
      (authority: string) =>
        authority === RoleOperator.PROFILE_VIEW ||
        authority === RoleOperator.SUPPLIER_DEFAULT ||
        authority === RoleOperator.ROLE_ADMIN ||
        authority === RoleOperator.APPROVE_DEFAULT
    );
    const userProfile = await repositoryProfile.findUserProfile();

    if (!userProfile) return;

    const _updateUserData = {
      ..._userData,
      userId: userProfile.id,
      userPicture: userProfile?.picture ?? null,
    };
    saveUserData(_updateUserData);
  };

  const onFinish = async (values: TLoginForm) => {
    const { email, password } = values;
    const data = new URLSearchParams({
      username: email,
      password,
      grant_type: "password",
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
    });

    const tokensDetails = await repositoryLogin.requestTokens(data);
    const token = tokensDetails?.token;
    const refreshToken = tokensDetails?.refresh_token;

    localStorage.setItem("token", token);

    if (!token || !refreshToken) return;

    const parsedUser = parseJwt(tokensDetails.token) as TKeycloakUser;
    const associatedGroup = parsedUser?.group?.length
      ? parsedUser.group[0]
      : "";
    const formattedGroupNameDefault =
      associatedGroup === "supplierGroupDefault"
        ? "Fornecedor"
        : associatedGroup;
    const _userData: TUserData = {
      refreshToken,
      token,
      isActiveDirectory: false,
      userName: decodeURIComponent(escape(parsedUser.name)),
      userEmail: parsedUser.email,
      userGroup: decodeURIComponent(escape(formattedGroupNameDefault)),
      authorities: parsedUser.authorities,
    };
    saveUserData(_userData);
    updateUserData(_userData);
    await repositoryLogin.logLogin();
    if (associatedGroup === "supplierGroupDefault") {
      navigate("/cotacoes");
      return;
    }

    navigate("/");
    if (!associatedGroup) {
      message.warning(messageUserNotAssociatedGroups);
    }
  };

  useEffect(() => {
    if (userData && userData?.isActiveDirectory && userData?.token && keycloak.authenticated) navigate("/");
  }, [userData, navigate, keycloak]);

  const onRedirectKeycloakLoginAd = async () => {
    setLoadingAd(true);
    await keycloak.login();
    setLoadingAd(false);
  };

  const LoginAsSupplierComponent = () => (
    <>
      <Space size="middle" direction="vertical">
        <TextField
          name="email"
          textHelp="Digite o seu usuário como fornecedor/freelancer"
          // rules={validations.email}
          placeholder="Email"
          size="large"
        />
        <TextField
          name="password"
          type="password"
          rules={validations.password}
          placeholder="Senha"
          size="large"
        />
      </Space>
      <Container>
        <Link to="/forgot-password">Esqueceu a sua senha?</Link>
      </Container>
      <ButtonLogin
        type="primary"
        size="large"
        loading={repositoryLogin.loading}
        htmlType="submit"
      >
        Entrar
      </ButtonLogin>
    </>
  );

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      autoComplete="none"
    >
      {/* <FlexContainer $spaced>
        <LogoWithTitle />
      </FlexContainer> */}
      <Content>
        {/* ou acesse como fornecedor/freelancer */}
        <Title
          title="Bem-vindo(a)!"
          description={
            !loginAsSupplier ? "Faça o login com a sua conta TV1" : ""
          }
        />
        {loginAsSupplier ? (
          <LoginAsSupplierComponent />
        ) : (
          <>
            {/* <Button
          size='large'
          type='dashed'
          loading={loadingAd}
          onClick={onRedirectKeycloakLoginAd}
          icon={<AiOutlineWindows />}
        >
          Acessar com a conta TV1
        </Button> */}
            <ButtonLogin
              type="primary"
              size="large"
              loading={loadingAd}
              onClick={onRedirectKeycloakLoginAd}
              icon={<AiOutlineWindows />}
            >
              Acessar com a conta TV1
            </ButtonLogin>
            {/* <Divider></Divider>
              <Button
                size='large'
                type='default'
                onClick={() => setLoginAsSupplier(true)}
              >
                Acessar como fornecedor/freelancer
              </Button> */}
            {/* <Divider>
          Ou acesse como fornecedor
        </Divider> */}
          </>
        )}
      </Content>
    </Form>
  );
};

export default Login;
