import React, { useEffect, useState } from 'react'
import { Form, Modal, Rate } from 'antd'
import { TProfileForm } from 'repositories'
import Row from 'components/Grid/Row'
import Col from 'components/Grid/Col'
import TextField from 'components/Form/TextField'
import SelectField from 'components/Form/SelectField'
import CheckField from 'components/Form/CheckField'
import AreaField from 'components/Form/AreaField'
import DateField from 'components/Form/DateField'
import { FilterContainer } from './styles'
import { useSuppliers } from 'repositories'

const initialValues = {
  id: '',
  name: '',
  email: '',
  telephone: '',
  userGroupId: '',
  userGroupName: '',
  userFunctionId: '0',
  userAreaId: '0',
  picture: ''
}

const ProfileForm = ({ isModalVisible, setIsModalVisible, supplierId }: any) => {

  const [form] = Form.useForm()
  const [currentRate, setCurrentRate] = useState(0)
  const handleOk = () => form.submit()
  const handleClose = () => setIsModalVisible(false)

  const repository = useSuppliers()

  const onFinish = async () => {
    const response = await repository.createEvaluationSupplier({
      supplierId: supplierId,
      evaluation: currentRate
    })
    // console.log(response)
    // handleClose()
  }

  useEffect(() => {
    form.resetFields()
  }, [isModalVisible])

  return (
    <Modal
      visible={isModalVisible}
      okText='Salvar'
      cancelText='Fechar'
      onOk={handleOk}
      onCancel={handleClose}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row>
          <Col xl={24} >
            <p>Avalie o Fornecedor ou o Freelancer que trabalhou no seu projeto ou na sua área!</p><br></br>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <SelectField
              name='sub'
              label='Id Projeto'
            />
          </Col>
          <Col xl={12}>
            <SelectField
              name='sub'
              label='Centro de Custo'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24} >
            <p>Avalie</p>
          </Col>
          <Col xl={24} >
            <FilterContainer>
              <Rate
                className="crate"
                value={currentRate}
                style={{width: '100%'}}
                onChange={(value) => setCurrentRate(value)}
              />
            </FilterContainer>
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <p>Adicione um comentario (Opcional)</p>
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <AreaField
              name='sub'
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <div style={{ background: '#fcf8e3', padding: 15 }}>
              <strong>Atenção!</strong>
              <p>
                Sua avaliação é muito importante pois será uma fonte de decisão
                para a escolha do fornecedor ou freelancer para outros projetos e
                iniciativas.
              </p>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ProfileForm