import React, { useEffect, useMemo } from 'react'
import { cpf as cpfValidator } from 'cpf-cnpj-validator'
import {
  Form,
  message,
  Modal
} from 'antd'
import {
  TBusinessContact,
  useSupplierContact
} from 'repositories'
import { MaskPhone } from 'utils/constants'
import {
  Col,
  HiddenField,
  MaskedField,
  Row,
  TextField
} from 'components'
import type { Rule } from 'antd/lib/form'

const initialValues: TBusinessContact = {
  name: '',
  email: '',
  telephone: '',
  cpf: '',
  rg: ''
}

type TProps = {
  contactId: number
  supplierId: number
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  actionFn(): Promise<void>
}

const validations: Record<string, Rule[]> = {
  name: [
    { required: true, message: 'Campo "Nome" é obrigatório' }
  ],
  email: [
    { required: true, message: 'Campo "Email" é obrigatório' },
    { type: 'email', message: 'Email está inválido' },
  ],
  cpf: [
    { required: true, message: 'Campo "CPF" é obrigatório' },
    () => ({
      validator(_, cpf: string) {
        if (cpfValidator.isValid(cpf)) return Promise.resolve()
        if (cpf.length && !cpfValidator.isValid(cpf)) {
          return Promise.reject(new Error('CPF informado está inválido'))
        }
      },
    })
  ],
  telephone: [
    { required: true, message: 'Campo "Telefone" é obrigatório' },
  ]
}

const ContactForm = (props: TProps) => {
  const {
    contactId,
    supplierId,
    isModalVisible,
    setIsModalVisible,
    actionFn
  } = props

  const repository = useSupplierContact()
  const [form] = Form.useForm<TBusinessContact>()
  const watchedPhone = Form.useWatch('telephone', form)

  const onFinish = async (values: TBusinessContact) => {
    if (!contactId) {
      const response = await repository.createContact({ ...values, supplierId })
      if (!response) return

      message.success('Contato criado com sucesso!')
      actionFn()
      onResetForm()
      return
    }

    const response = await repository.updateContact({ ...values, id: contactId, supplierId })
    if (!response) return

    message.success('Contato atualizado com sucesso!')
    actionFn()
    onResetForm()
  }

  const onResetForm = () => {
    form.resetFields()
    handleClose()
  }

  const handleSubmit = () => {
    form.submit()
  }

  const handleClose = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    const getContactById = async () => {
      const response = await repository.findContactById(contactId)
      if (!response) return

      form.setFieldsValue(response)
    }

    if (contactId) getContactById()
  }, [contactId, supplierId, isModalVisible])

  const maskPhone = useMemo(() => {
    const removeMaskChar = watchedPhone?.replace(/_/g, '')
    return removeMaskChar?.length < 15
      ? MaskPhone.TELEPHONE
      : MaskPhone.CELLPHONE
  }, [watchedPhone])

  const title = useMemo(() => (
    contactId ? 'Editar Contato' : 'Novo Contato'
  ), [contactId])

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onOk={handleSubmit}
      okText='Salvar'
      cancelText='Fechar'
      confirmLoading={repository.loading}
      onCancel={handleClose}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <HiddenField
          name='id'
        />
        <Row>
          <Col xl={8}>
            <MaskedField
              required
              name='cpf'
              label='CPF'
              mask='999.999.999-99'
              rules={validations.cpf}
            />
          </Col>
          <Col xl={16}>
            <TextField
              required
              name='name'
              label='Nome'
              rules={validations.name}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={24}>
            <TextField
              required
              name='email'
              label='Email'
              rules={validations.email}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <MaskedField
              required
              name='telephone'
              label='Telefone'
              mask={maskPhone}
              rules={validations.telephone}
            />
          </Col>
          <Col xl={12}>
            <TextField
              name='rg'
              label='RG'
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ContactForm
