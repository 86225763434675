import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Divider,
  Checkbox,
} from "antd";
import { Navigate } from "react-router";
import {
  Section,
  TextField,
  DateField,
  SelectField,
  AreaField,
} from "components";
import { usePagination, useAuth } from "hooks";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import moment from "moment";


const options = [
  { label: "Monday", value: "Monday" },
  { label: "Manager [Compras]", value: "Compras" },
  { label: "Elaw", value: "Elaw" },
  { label: "Pipefy", value: "Pipefy" },
  { label: "Power BI", value: "Power BI" },
  { label: "TOTVS", value: "TOTVS" },
  { label: "Pacote Office", value: "Pacote Office" },
];

const FormFreelancers = ({
  prevStep,
  freelancersData,
  dataForm,
  loading,
}: any) => {
  const [form] = Form.useForm();
  const [access, setAccess] = useState([]);

  const [btnClick, setBtnClick] = useState("");

  const { roles: authRoles, hasRole } = useAuth();
  const pagination = usePagination();

  const requestCreate = async () => {
  };

  const onChange = (checkedValues: any) => {
    setAccess(checkedValues);
  };

  let initialValues = {
    contractStartDate: moment(dataForm?.startDate),
    contractEndDate: moment(dataForm?.endDate),
  };

  useEffect(() => {
    if (pagination.data.current) requestCreate();
  }, [pagination.data.current, pagination.data.pageSize]);

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado" />;
  }

  const onFinish = async (values: any) => {
    values.accessInternalSystem = access;
    switch (btnClick) {
      case "back":
        values.btnStatus = "back";
        freelancersData();
        prevStep();
        break;
      case "save":
        values.btnStatus = "save";
        freelancersData(values);
        break;
    }
  };
  return (
    <>
      <Section>
        <div style={{ marginTop: 24 }}>
          <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            onFinish={onFinish}
          >
            <>
              <Row>
                <Col>
                  <label>
                    Formulário funcional complementar para Freelancers
                  </label>
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="needSupportFromTv1corporateTI"
                    label="Requer suporte da TI da TV1?"
                    options={[
                      {
                        label: "Sim",
                        value: "sim",
                      },
                      {
                        label: "Não",
                        value: "nao",
                      },
                    ]}
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="useTv1Email"
                    label="Usará e-mail da TV1?"
                    options={[
                      {
                        label: "Sim",
                        value: "sim",
                      },
                      {
                        label: "Não",
                        value: "nao",
                      },
                    ]}
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="equipmentFreelancers"
                    label="Equipamento"
                    options={[
                      {
                        label: "Próprio",
                        value: "OWN",
                      },
                      {
                        label: "TV1",
                        value: "TV1",
                      },
                    ]}
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="equipmentTypeFreelancers"
                    label="Tipo de Equipamento"
                    options={[
                      {
                        label: "Notebook",
                        value: "NOTEBOOK",
                      },
                      {
                        label: "Desktop",
                        value: "DESKTOP",
                      },
                      {
                        label: "IMac",
                        value: "IMAC",
                      },
                      {
                        label: "MacBook",
                        value: "MACBOOK",
                      },
                    ]}
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <SelectField
                    name="workplace"
                    label="Local de trabalho"
                    options={[
                      {
                        label: "Escritório TV1",
                        value: "tv1_office",
                      },
                      {
                        label: "Externo",
                        value: "external",
                      },
                      {
                        label: "Home Office",
                        value: "home_office",
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Acesso a sistemas internos da TV1:</label>
                </Col>
                <Col>
                  <Checkbox.Group
                    name="accessInternalSystem"
                    options={options}
                    value={access}
                    onChange={onChange}
                  />
                </Col>
                <br />
                <br />
              </Row>
              <Row>
                <Col xl={12} xxl={12}>
                  <AreaField
                    name="othersAccessInternalSystem"
                    label="Outros (descreva)"
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={12} xxl={12}>
                  <TextField
                    name="personalEmail"
                    label="E-mail pessoal em caso de necessidade de acesso ao Monday.com"
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={12} xxl={12}>
                  <TextField
                    name="freelanceDirectManager"
                    label="Quem é o Gestor direto do Freelancer no período em que ele prestar serviço para a TV1?"
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={6} xxl={4}>
                  <DateField
                    name="contractStartDate"
                    label="Data do início do contrato."
                    disabled
                  />
                </Col>
                <Col xl={6} xxl={4}>
                  <DateField
                    name="contractEndDate"
                    label="Data do fim do contrato."
                    disabled
                  />
                </Col>
              </Row>
              <Button
                style={{ margin: "0 4px" }}
                htmlType="submit"
                onClick={() => prevStep()}
              >
                Voltar
              </Button>
              <Button
                style={{ margin: "0 4px" }}
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={() => setBtnClick("save")}
              >
                Salvar
              </Button>
            </>
          </Form>
        </div>
      </Section>
    </>
  );
};

export default FormFreelancers;
