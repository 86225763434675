import React, { useEffect, useMemo, useState } from 'react'
import type { ColumnsType, TableProps } from 'antd/lib/table'
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Space,
  Table,
  Tag,
} from 'antd'
import {
  Navigate,
  useNavigate
} from 'react-router'
import {
  DropdownButton,
  PageHeader,
  Section
} from 'components'
import {
  TPurchaseOrder,
  TPurchaseOrderParams,
  usePurchaseOrder
} from 'repositories'
import {
  usePagination,
  useAuth,
  useFilters
} from 'hooks'
import type { SorterResult } from 'antd/lib/table/interface'
import { tableLocale } from 'utils/lang'
import {
  PurchaseOrderStatus,
  purchaseOrderStatus
} from 'utils/constants'
import { formatCurrency } from 'utils/helpers'
import { AiOutlineDown } from 'react-icons/ai'
import { FilterContainer } from './styles'
import FiltersForm from '../Components/FiltersForm'

const { confirm } = Modal

type TPurchaseOrderData = TPurchaseOrder & {
  key: string
  approvalHierarchyName: string
}

const columns: ColumnsType<TPurchaseOrderData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Fornecedor',
    dataIndex: 'supplierName',
    key: 'supplierName'
  },
  {
    title: 'Produto',
    dataIndex: 'productName',
    key: 'productName'
  },
  {
    title: 'Alçada',
    dataIndex: 'approvalHierarchyName',
    key: 'approvalHierarchyName'
  },
  {
    title: 'Dia de pagamento',
    dataIndex: 'payDay',
    key: 'payDay',
    align: 'center'
  },
  {
    title: 'Valor',
    dataIndex: 'purchaseValue',
    key: 'purchaseValue',
    align: 'right',
    render: (_, record) => formatCurrency(record.purchaseValue),
  },
  {
    title: 'Tipo',
    dataIndex: 'isRequestNotTiedToProject',
    key: 'isRequestNotTiedToProject',
    render: (_, record) => {
      if (record.isRequestNotTiedToProject) {
        return <Tag color='blue'>Sem vinculo com projeto</Tag>
      }

      return <Tag>Vinculo com projeto</Tag>
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => {
      switch (record.status) {
        case 'IN_ANALYSIS': return <Tag color='blue'>Em análise</Tag>
        case 'APPROVED': return <Tag color='green'>Aprovado</Tag>
        case 'REFUSED': return <Tag color='red'>Reprovado</Tag>
        case 'SEND_TO_ERP': return <Tag color='black'>Enviado ao ERP</Tag>
        case 'PAID_OUT': return <Tag color='green'>Pago</Tag>
        case 'CANCELED': return <Tag color='red'>Cancelado</Tag>
        default: return <Tag>{purchaseOrderStatus[record.status]}</Tag>
      }
    },
  }
]

const PurchaseOrderList = () => {
  const [purchaseOrders, setPurchaseOrders] = useState<TPurchaseOrderData[]>([])
  const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false)
  const [sort, setSort] = useState('id,DESC')

  const { roles: authRoles, hasRole } = useAuth()
  const repository = usePurchaseOrder()
  const navigate = useNavigate()
  const filters = useFilters()
  const pagination = usePagination()

  const requestPurchaseOrder = async () => {
    const currentPage = pagination.data.current ?? 0
    const _filters = filters.getObjectifiedFilterItems()
    const params: TPurchaseOrderParams = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
      ..._filters
    }

    const response = await repository.findPurchaseOrderByFilter(params)
    if (!response) return

    const mappedData: TPurchaseOrderData[] = response.content.map(purchaseOrder => ({
      ...purchaseOrder,
      key: String(purchaseOrder.id),
    }))
    setPurchaseOrders(mappedData)
    pagination.changeTotalPages(response.totalPages)
  }

  const handleChange: TableProps<TPurchaseOrderData>['onChange'] = (paginationConfig, sorter) => {
    pagination.onChangeTable(paginationConfig)
    const { field, order } = sorter as SorterResult<TPurchaseOrderData>
    if (!field) return

    const dir = order === 'ascend' ? 'ASC' : 'DESC'
    setSort(`${field},${dir}`)
  }

  useEffect(() => {
    if (filters.items && pagination.data.current) requestPurchaseOrder()
  }, [pagination.data.current, pagination.data.pageSize, sort, filters.items])

  const onClickGoToView = (id: number) => () => navigate(`/compra-direta/visualizar/${id}`)
  const onClickGoToEdit = (id: number) => () => navigate(`/compra-direta/editar/${id}`)
  const onClickGoToContract = (id: number) => () => navigate(`/compra-direta/contrato/${id}`)

  const handleSendApprovedRequestToErp = async (id: number) => {
    const response = await repository.sendApprovedOrderToERP(id)
    if (!response) return

    message.success('Pedido enviado com sucesso!')
    requestPurchaseOrder()
  }

  const handleCancelPurchaseOrder = async (id: number) => {
    const response = await repository.cancelPurchaseOrder(id)
    if (!response) return

    message.success('Compra direta cancelada com sucesso!')
    requestPurchaseOrder()
  }

  const showConfirmSendOrderToErp = (id: number) => () => {
    confirm({
      title: 'Confirmação',
      content: 'Deseja enviar o pedido ao ERP (TOTVS)?',
      okText: 'Sim, enviar',
      async onOk() {
        await handleSendApprovedRequestToErp(id)
      },
    })
  }

  const showComfirmCancelPurchaseOrder = (id: number) => () => {
    confirm({
      title: 'Confirmação',
      content: 'Deseja realmente cancelar esta compra direta?',
      okText: 'Sim, cancelar',
      cancelText: 'Não',
      async onOk() {
        await handleCancelPurchaseOrder(id)
      },
    })
  }

  const onClickGoToNew = (isRequestNotTiedToProject = false) => () => {
    navigate('/compra-direta/novo', {
      state: {
        isRequestNotTiedToProject
      }
    })
  }

  const menu = (
    <Menu
      items={[
        {
          label: 'Vinculado a projeto',
          key: '1',
          onClick: onClickGoToNew()
        },
        {
          label: 'Não vinculado a projeto',
          key: '2',
          onClick: onClickGoToNew(true)
        },
      ]}
    />
  )

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_, record) => {
        const id = Number(record.id)
        const itemsMenu = [
          {
            key: '1',
            label: 'Visualizar',
            onClick: onClickGoToView(id)
          },
          {
            key: '2',
            label: 'Editar',
            disabled: (
              !hasRole(authRoles?.group_edit) ||
              record.status !== PurchaseOrderStatus.NEW
            ),
            onClick: onClickGoToEdit(id)
          },
          {
            key: '3',
            label: 'Cancelar',
            disabled: (
              !hasRole(authRoles?.group_edit) ||
              (record.status !== PurchaseOrderStatus.NEW &&
              record.status !== PurchaseOrderStatus.IN_ANALYSIS)
            ),
            onClick: showComfirmCancelPurchaseOrder(id)
          },
          {
            key: '4',
            label: 'Contrato',
            disabled: record.status === PurchaseOrderStatus.NEW,
            onClick: onClickGoToContract(id)
          },
          {
            key: '5',
            label: 'Enviar pedido para o ERP',
            onClick: showConfirmSendOrderToErp(id),
            disabled: (
              record.status !== PurchaseOrderStatus.APPROVED ||
              !hasRole(authRoles?.purchase_order_edit)
            )
          },
        ]
        return (
          <DropdownButton
            items={itemsMenu}
          />
        )
      },
    }
  ], [authRoles])

  const onClickOpenFilterForm = () => {
    setIsVisibleFilterForm(true)
  }

  if (authRoles && !hasRole(authRoles?.group_view)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <PageHeader
        title='Compra direta'
        breadcrumbItems={['Compra direta', 'Listar']}
      />
      <Section>
        <FilterContainer>
          <Button
            onClick={onClickOpenFilterForm}
          >
            Filtros
          </Button>
          <Dropdown overlay={menu}>
            <Button
              type='primary'
            >
              <Space align='center'>
                Novo
                <AiOutlineDown />
              </Space>
            </Button>
          </Dropdown>
        </FilterContainer>
        <Table
          sticky
          size='middle'
          locale={tableLocale}
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={purchaseOrders}
          pagination={pagination.data}
        />
      </Section>
      <FiltersForm
        filters={filters}
        isVisibleDrawer={isVisibleFilterForm}
        setIsVisibleDrawer={setIsVisibleFilterForm}
      />
    </>
  )
}

export default PurchaseOrderList
