import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

type TContainerProps = {
  isOpen: boolean
  isSidebarOpen: boolean
}

export const Container = styled.div<TContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.BLACK};
  padding: 6px 8px;
  margin: 5px 11px;
  border-radius: 8px;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  background-color: ${({ isOpen, theme }) => (
    isOpen ? theme.colors.LIGHT_GRAY : 'transparent'
  )};
  
  &:hover {
    color: ${({ theme }) => theme.colors.BLACK};
    background-color: ${({ theme }) => theme.colors.LIGHT_GRAY};
  }

  svg {
    width: 25px;
    text-align: center;
  }

  ${({ isSidebarOpen }) => !isSidebarOpen && css`
    > svg {
      display: none;
    }
  `}
`

type TSubItemProps = {
  $active?: boolean
}

export const SubItem = styled(Link)<TSubItemProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.BLACK};
  padding: 8px;
  margin: 5px 8px;
  border-radius: 8px;
  font-weight: 400;
  
  svg, > div {
    width: 25px;
    text-align: center;
  }

  font-weight: ${({ $active }) => (
    $active ? 600 : 400
  )};
  
  &:hover {
    color: ${({ theme }) => theme.colors.BLACK};
    background-color: ${({ theme }) => theme.colors.LIGHT_GRAY};
  }
`

export const Options = styled.div`
  a:hover {
    color: ${({ theme }) => theme.colors.BLACK};
    font-weight: 600;
    background-color: transparent;
  }
`
