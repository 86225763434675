import styled from 'styled-components'


export const FilterContainer = styled.div`
  display: flex;
  gap: 5px;
  float: right;
  margin-bottom: 10px;
`
export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: Arial, sans-serif;
`;

export const FormField = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
`;

export const UploadWrapper = styled.span`
  .ant-upload.ant-upload-drag {
    height: 75px;
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 1/3;
  gap: 20px;
  width: 100%;
  max-width: 100%;
`;

export const GridContainerButton = styled.div`
  display: flex;
  grid-column: 1/3;
  justify-content: center;
`;

export const ButtonFormated = styled.button`
  display: flex;
`;