/* eslint-disable */
import React from 'react';
import { Form } from 'antd';
import type { Rule } from 'antd/lib/form';
import NumberFormat from 'react-number-format';

type TProps = {
  name: string;
  label?: string;
  rules?: Rule[];
  disabled?: boolean;
  required?: boolean;
  textHelp?: string;
  placeholder?: string;
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
};

const NormalizedCurrencyField = (props: any) => {
  const {
    name,
    label,
    rules,
    disabled = false,
    required = false,
    textHelp,
    placeholder = '',
    onBlur,
    onChange,
    ...rest
  } = props;

  return (
      <Form.Item
          required={required}
          id={name}
          name={name}
          label={label}
          rules={rules}
          help={textHelp}
          {...rest}
      >
        <NumberFormat
            disabled={disabled}
            className="ant-input"
            placeholder={placeholder}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={false}
            allowNegative={false}
            isNumericString
            onBlur={onBlur}
            onChange={onChange}
            onValueChange={(values) => {
              const { value } = values;
              if (onChange) {
                onChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
              }
            }}
            format={(value) => {

              const formatValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, "")

              const formatValueWithDecimals = formatValue.replace(/(\d+)(\d{2})$/,'$1,$2')

              let formattedInteger
              let formattedDecimal

              if(formatValueWithDecimals.length > 2) {
                const [integer, decimal] = formatValueWithDecimals.split(',');

                formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                formattedDecimal = decimal
              }

              const result = formattedDecimal ? `${formattedInteger},${formattedDecimal}` : formatValueWithDecimals

              return result;
            }}
        />
      </Form.Item>
  );
};

export default NormalizedCurrencyField;
