import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { Button } from 'antd'

export const Container = styled.div`
  margin-bottom: 15px;
`

export const Content = styled.div`
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 32px;
`

export const Link = styled(RouterLink)`
  font-size: 12px;
`

export const ButtonLogin = styled(Button)`
  background-color: ${({ theme }) => theme.colors.BLACK};
  border-color: ${({ theme }) => theme.colors.BLACK};
  
  :hover, :active, :focus {
    background-color: ${({ theme }) => theme.colors.BLACK};
    border-color: ${({ theme }) => theme.colors.BLACK};
  }

  :hover {
    filter: brightness(0.8);
  }
`
