import React, { useEffect, useMemo, useState } from "react";
import {
  TRole,
  TUserGroupRequest,
  useRoles,
  useUserGroups,
} from "repositories";
import {
  Section,
  PageHeader,
  Row,
  Col,
  TextField,
  AreaField,
  SelectField,
  CheckField,
} from "components";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import { Button, Divider, Form, message, Space, Transfer } from "antd";
import { excludeRoles, RoleOperator, Status } from "utils/constants";
import { transferLocale, translateTerms } from "utils/lang";
import type { Rule } from "antd/lib/form";
import type { DefaultOptionType } from "antd/lib/select";
import { useAuth } from "hooks";

type TRoleTransfer = TRole & {
  key: string;
};

const initialValues: TUserGroupRequest = {
  id: 0,
  name: "",
  admin: false,
  groupStatus: Status.ACTIVE,
  observation: "",
  roles: [],
};

const statusOptions: DefaultOptionType[] = [
  {
    label: "Ativo",
    value: Status.ACTIVE,
  },
  {
    label: "Inativo",
    value: Status.INACTIVE,
  },
];

const validations: Record<string, Rule[]> = {
  name: [{ required: true, message: 'Campo "Nome" é obrigatório' }],
};

const createMessageSuccess = "Grupo criado com sucesso!";
const updateMessageSuccess = "Grupo atualizado com sucesso!";

const UserGroupForm = () => {
  const [userGroup, setUserGroup] = useState<TUserGroupRequest>(
    {} as TUserGroupRequest
  );
  const [roles, setRoles] = useState<TRoleTransfer[]>([]);
  const [targetRoles, setTargetRoles] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const { roles: authRoles, hasRole } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const repository = useUserGroups();
  const roleRepository = useRoles();
  const [form] = Form.useForm<TUserGroupRequest>();
  const isAdmin = Form.useWatch("admin", form);

  // useEffect(() => {
  //   console.warn
  // }, [userGroup])

  function order(a: any, b: any) {
    var nomeA = a.label.toUpperCase();
    var nomeB = b.label.toUpperCase();

    if (nomeA < nomeB) {
      return -1;
    }
    if (nomeA > nomeB) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const getGroupById = async () => {
      const response = await repository.findGroupById(Number(id));
      if (!response) return;

      const mappedUserRoles = response.userRoles.map((filteredItem) => ({
        id: filteredItem.id,
        key: String(filteredItem.id),
        name: filteredItem.name,
        label: translateTerms(filteredItem.name),
      }));

      // console.log(mappedUserRoles.sort(order));

      const roleList = mappedUserRoles.sort(order).map((role) => role.id);

      // const roleList = response.userRoles.map((role) => role.id);
      const values: TUserGroupRequest = {
        ...response,
        id: response.id,
        observation: response.observation ?? "",
        roles: roleList,
      };

      const mappedRoleList = roleList.map((item) => String(item));
      // console.log(mappedRoleList)
      // mappedRoleList.sort(order);
      setTargetRoles(mappedRoleList);
      form.setFieldsValue(values);
      setUserGroup(values);
    };

    const getRoles = async () => {
      const response = await roleRepository.findRolesByFilter();
      if (!response) return;

      const filteredRoles = response.filter(
        (item) => !excludeRoles.includes(item.name as RoleOperator)
      );
      const mappedRoles = filteredRoles.map(
        (filteredItem) =>
          ({
            id: filteredItem.id,
            key: String(filteredItem.id),
            name: filteredItem.name,
            label: translateTerms(filteredItem.name),
          } as TRoleTransfer)
      );
      mappedRoles.sort(order);
      setRoles(mappedRoles);
    };

    getRoles();
    form.resetFields();
    if (id) getGroupById();
  }, [id]);

  const onFinish = async (values: TUserGroupRequest) => {
    const { name, observation, groupStatus, admin } = values;
    const allRoles = targetRoles.map((targetRole) => Number(targetRole));

    if (!allRoles.length) {
      message.error(
        "Não é possivel criar ou atualizar um grupo sem atribuir as permissões!"
      );
      return;
    }

    const data: TUserGroupRequest = {
      name: name.trim(),
      observation,
      groupStatus,
      admin,
      roles: allRoles,
    };
    const response = id
      ? await repository.updateGroup({ id: Number(id), ...data })
      : await repository.createGroup(data);
    if (!response) return;

    navigate("/grupos-usuarios");
    message.success(id ? updateMessageSuccess : createMessageSuccess);
  };

  const onChange = (nextTargetKeys: string[]) => {
    setTargetRoles(nextTargetKeys);
  };

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedRoles([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onClickGoBack = () => {
    navigate("/grupos-usuarios");
  };

  const { title, isView } = useMemo(() => {
    const [, , page] = pathname.split("/");
    const getTitle = () => {
      if (page === "visualizar") return "Visualizar";
      if (id) return "Editar";

      return "Novo";
    };

    return {
      title: getTitle(),
      isView: page === "visualizar",
    };
  }, [id]);

  if (
    authRoles &&
    !hasRole(
      authRoles?.group_view || authRoles?.group_edit || authRoles?.group_add
    )
  ) {
    return <Navigate to="/acesso-negado" />;
  }

  return (
    <>
      <PageHeader
        title={`${title} Grupo de Usuário`}
        breadcrumbItems={["Usuários", "Grupos", title]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col xl={12}>
              <TextField
                required
                name="name"
                label="Nome"
                disabled={isView}
                rules={validations.name}
              />
            </Col>
            <Col xl={4}>
              <SelectField
                required
                name="groupStatus"
                label="Status"
                disabled={isView}
                options={statusOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={16}>
              <AreaField
                name="observation"
                label="Observação"
                disabled={isView}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <CheckField
                name="admin"
                disabled={isView}
                label="Administrador?"
              />
            </Col>
          </Row>
          {!isAdmin && (
            <>
              <Row>
                <Col xl={16}>
                  <Divider plain orientation="left">
                    Permissões de acesso
                  </Divider>
                </Col>
              </Row>
              <Row>
                <Col xl={16}>
                  <Transfer
                    locale={transferLocale}
                    listStyle={{ width: 500, height: 200 }}
                    disabled={isView}
                    dataSource={roles}
                    titles={["Permissões", "Atribuídas"]}
                    targetKeys={targetRoles}
                    selectedKeys={selectedRoles}
                    onChange={onChange}
                    onSelectChange={onSelectChange}
                    render={(item) => item.label || item.name}
                  />
                </Col>
              </Row>
            </>
          )}
          <Form.Item className="mt-1">
            <Space size="small">
              <Button onClick={onClickGoBack} htmlType="button">
                Voltar
              </Button>
              <Button
                loading={repository.loading}
                disabled={isView || repository.loading}
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Section>
    </>
  );
};

export default UserGroupForm;
